<div
  bsModal
  #childModal="bs-modal"
  [config]="modalOptions"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="margin-top: 150px">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="tagForm">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                />
                <div ngxErrors="name" style="color: red">
                  <div ngxError="required" when="touched">
                    Este campo es obligatorio
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button
                type="button"
                class="btn btn-success"
                (click)="save($event, tagForm)"
              >
                GUARDAR
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
