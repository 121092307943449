<div class="page">
  <div class="page_body">
    <div class="centered">
      <div style="text-align: center">
        <br/>
        <h2>{{ title }}</h2>
      </div>
      <br />
      <div [innerHTML]="new_body">
      </div>
      <div>
        <div>Últimas Noticias</div>
      <div *ngFor="let lat of latestNews; index as i" [ngClass]="i > 0 ? 'latest-post not-first': 'latest-post'">
        <article class="post-new" role="article">
          <h3 class="post-new-title">
            <a [href]="getLink(lat)">{{ lat && lat.title ? lat.title : '' }}</a>
          </h3>
          <div>{{lat && lat.date ? lat.date : ''}}</div>
          <div class="post-new-content">
            <div class="post-new-thumbnail">
              <img [src]="lat && lat.img ? lat.img : ''"/>
            </div>
            <div class="post-new-snippet">
              <div class="post-new-snippet-item">{{lat && lat.data ? lat.data : ''}}</div>
            </div>
            <div class="post-new-jump-link">
              <a [href]="getLink(lat)">LEER MÁS</a>
            </div>
          </div>
        </article>
      </div>
      </div>
    </div>
  </div>
</div>
