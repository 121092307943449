import { Component } from '@angular/core';

@Component({
  selector: 'cms-navbar',
  templateUrl: './navbar.component.html',
  styles: [
    `
      .navbar {
        margin-bottom: unset;
      }
    `
  ]
})
export class NavBarComponent {}
