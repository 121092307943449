import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CMSEnv } from '@cms-app/shared/models/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/models/environment";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(env, http, router) {
        this.env = env;
        this.http = http;
        this.router = router;
        this.endpoint = '/login';
    }
    AuthService.prototype.login = function (name, pass) {
        var _this = this;
        this.http.post("" + this.env.baseUrl + this.endpoint, { name: name, pass: pass }).subscribe(function (res) {
            _this.router.navigate(['admin/users']);
            localStorage.setItem('auth_token', res.access_token);
        });
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem('auth_token');
    };
    AuthService.prototype.logout = function () {
        localStorage.removeItem('token');
    };
    AuthService.prototype.isLoggedIn = function () {
        var authToken = localStorage.getItem('access_token');
        return (authToken !== null) ? true : false;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
