import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

import { SharedModule } from '@cms-app/shared/shared.module';

import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { CouresDetailComponent } from './components/course-detail/course-detail.component';
import { CouresGridComponent } from './components/courses-grid/courses-grid.component';
import { CourseRequestInfoComponent } from './components/course-request-info/course-request-info.component';
import { CourseCartComponent } from './components/course-cart/course-cart.component';
import { CourseEntrustCompanyComponent } from './components/course-entrust-company/course-entrust-company.component';
import { CourseEntrustStudentComponent } from './components/course-entrust-student/course-entrust-student.component';
import { CourseEntrustStepper } from './components/course-entrust-stepper/course-entrust-stepper.component';
import { CourseEntrustMiniLoader } from './components/course-entrust-miniloader/course-entrust-miniloader.component';

import { CatalogueComponent } from './containers/catalogue/catalogue.component';
import { CoursePageComponent } from './containers/course-page/course-page.component';
import { CourseEntrustComponent } from './containers/course-entrust/course-entrust.component';
import { CourseEntrustOkComponent } from './containers/course-entrust-ok/course-entrust-ok.component';

import { CategoriesFilterPipe } from './pipes/categories-filter.pipe';

import { CoursesService } from './providers/courses.service';
import { FamiliesService } from './providers/families.service';
import { CourseEntrustCreditComponent } from './components/course-entrust-credit/course-entrust-credit.component';
import { CourseEntrustBonifComponent } from './components/course-entrust-bonif/course-entrust-bonif.component';
import { CourseEntrustNoBonifComponent } from './components/course-entrust-nobonif/course-entrust-nobonif.component';

const components = [
  CategoriesListComponent,
  CourseCartComponent,
  CouresDetailComponent,
  CourseRequestInfoComponent,
  CouresGridComponent,
  CourseEntrustCompanyComponent,
  CourseEntrustStudentComponent,
  CourseEntrustStepper,
  CourseEntrustMiniLoader,
  CourseEntrustCreditComponent,
  CourseEntrustBonifComponent,
  CourseEntrustNoBonifComponent
];

const containers = [
  CatalogueComponent,
  CoursePageComponent,
  CourseEntrustComponent,
  CourseEntrustOkComponent
];

const pipes = [CategoriesFilterPipe];

@NgModule({
  declarations: [...components, ...containers, ...pipes],
  exports: [...components, ...containers, ...pipes],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    InfiniteScrollModule
  ],
  providers: [
    CoursesService,
    FamiliesService,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    }
  ]
})
export class CoursesModule {}
