import { Inject, Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Provider } from '../interfaces/provider';
import { Log } from '../interfaces/log';
import { CMSEnv, CMS_ENV } from '@cms-app/shared/models/environment';
import { map } from 'rxjs/operators';
import { ResponseLong } from '../interfaces/response';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  endpoint= '/scrapper'
  constructor(@Inject(CMS_ENV) private env: CMSEnv,private http: HttpClient) { }

  getProviders(): Observable<Provider[]>{
    return this.http.get<Provider[]>(`${this.env.baseUrl}${this.endpoint}/providers`);
  }

  getProviderById(id: string):Observable<Provider>{
    return this.http.get<Provider>(`${this.env.baseUrl}${this.endpoint}/providers/${id}`);
  }

  getProviderBySource(source: string): Observable<Provider[]>{
    return this.http.get<Provider[]>(`${this.env.baseUrl}${this.endpoint}/providers/source?providerSource=${source}`);
  }

  createProvider(provider: Provider): Observable<Provider>{
    return this.http.post<Provider>(`${this.env.baseUrl}${this.endpoint}/providers`, provider);
  }

  deleteProvider(id: string): Observable<Provider>{
    return this.http.delete<Provider>(`${this.env.baseUrl}${this.endpoint}/providers/${id}`);
  }

  updateProvider(id : string,provider: Provider): Observable<Provider>{
    return this.http.put<Provider>(`${this.env.baseUrl}${this.endpoint}/providers/${id}`, provider);
  }

  //comparator
  compareFamilies(): Observable<ResponseLong>{
    return this.http.get<ResponseLong>(`${this.env.baseUrl}${this.endpoint}/comparator/compareFamilies`).pipe(map(res => res));
  }

  compareCourses(): Observable<ResponseLong>{
    return this.http.get<ResponseLong>(`${this.env.baseUrl}${this.endpoint}/comparator/compareCourses`).pipe(map(res => res));
  }

  updateFamilies(): Observable<ResponseLong>{
    return this.http.get<ResponseLong>(`${this.env.baseUrl}${this.endpoint}/comparator/updateFamilies`).pipe(map(res => res));
  }

  updateCourses(): Observable<ResponseLong>{
    return this.http.get<ResponseLong>(`${this.env.baseUrl}${this.endpoint}/comparator/updateCourses`).pipe(map(res => res));
  }

  //Logger
  getLogs():Observable<Log[]>{
    return this.http.get<Log[]>(`${this.env.baseUrl}${this.endpoint}/logs`);
  }

  getLogById(id: string): Observable<Log>{
    return this.http.get<Log>(`${this.env.baseUrl}${this.endpoint}/logs/${id}`);
  }
}
