<cms-admin>
  <div class="row">
    <div class="col-sm-4">
      <div class="card card-body">
        <form (submit)="edit ? updateProvider() : submitProvider()">
          <div>
            <h3>General</h3>
            <div class="form-group">
              <label for="inputName">Nombre del proveedor</label>
              <input
                type="text"
                name="name"
                id="inputName"
                [(ngModel)]="provider.name"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputNextButton">Boton Siguiente</label>
              <input
                type="text"
                name="nextButton"
                id="inputNextButton"
                [(ngModel)]="provider.nextButton"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputListItems">Lista de Objetos</label>
              <input
                type="text"
                name="listItems"
                id="inputListItems"
                [(ngModel)]="provider.listItems"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputLinks">Link del objeto</label>
              <input
                type="text"
                name="links"
                id="inputLinks"
                [(ngModel)]="provider.links"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputDataName">Nombre del objeto</label>
              <input
                type="text"
                name="dataName"
                id="inputDataName"
                [(ngModel)]="provider.dataName"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputType">Tipo</label>
              <input
                type="text"
                name="type"
                id="inputType"
                [(ngModel)]="provider.type"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputCategory">Categoria</label>
              <input
                type="text"
                name="category"
                id="inputCategory"
                [(ngModel)]="provider.category"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputContactContainer">Contenedor de datos contacto</label>
              <input
                type="text"
                name="contacContainer"
                id="inputContactContainer"
                [(ngModel)]="provider.contactContainer"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputLikes">Likes</label>
              <input
                type="text"
                name="likes"
                id="inputLikes"
                [(ngModel)]="provider.likes"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputUrl">Url del objeto</label>
              <input
                type="text"
                name="url"
                id="inputUrl"
                [(ngModel)]="provider.url"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputEmail">e-mail</label>
              <input
                type="text"
                name="email"
                id="inputEmail"
                [(ngModel)]="provider.email"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputPhone">Telefono</label>
              <input
                type="text"
                name="phone"
                id="inputPhone"
                [(ngModel)]="provider.phone"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputState">Elemento de Provincia</label>
              <input
                type="text"
                name="state"
                id="inputState"
                [(ngModel)]="provider.stateElem"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputLocation">Elemento de Localidad</label>
              <input
                type="text"
                name="location"
                id="inputLocation"
                [(ngModel)]="provider.locationElem"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputAddress">Elemento de Direccion</label>
              <input
                type="text"
                name="address"
                id="inputAddress"
                [(ngModel)]="provider.addressElem"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputContactDataList">Lista de elementos de contacto</label>
              <input
                type="text"
                name="contactDataList"
                id="inputContactDataList"
                [(ngModel)]="provider.contactDataList"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputImg">Imagenes</label>
              <input
                type="text"
                name="img"
                id="inputImg"
                [(ngModel)]="provider.img"
                class="form-control"
                autofocus
              />
            </div>
          </div>
          <button class="btn btn-primary btn-block">Guardar</button>
        </form>
      </div>
    </div>
  </div>
</cms-admin>
