import { HttpClient } from '@angular/common/http';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/models/environment";
import * as i2 from "@angular/common/http";
var ScrapperService = /** @class */ (function () {
    function ScrapperService(env, http) {
        this.env = env;
        this.http = http;
    }
    ScrapperService.prototype.launch = function (millis, sources) {
        var params = {
            millis: millis,
            sources: sources,
        };
        return this.http.post(this.env.scrapperUrl + "/launch", params);
    };
    ScrapperService.prototype.backup = function (sources) {
        return this.http.post(this.env.scrapperUrl + "/backup", sources);
    };
    ScrapperService.prototype.restore = function (sources) {
        return this.http.post(this.env.scrapperUrl + "/restore", sources);
    };
    ScrapperService.ngInjectableDef = i0.defineInjectable({ factory: function ScrapperService_Factory() { return new ScrapperService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: ScrapperService, providedIn: "root" });
    return ScrapperService;
}());
export { ScrapperService };
