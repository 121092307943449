import { Component, OnInit } from '@angular/core';

import { ProviderService } from '../../services/provider.service'
import { Provider } from '../../interfaces/provider';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
})
export class ProviderListComponent implements OnInit {
  rows: Provider[] = [];
  count = 0;
  page = 1;
  query = '';
  loading = false;
  scrapperType = '';


  constructor(private providerService: ProviderService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
        params => {
            const type = params['type'];
            this.scrapperType = type;
            this.getProviders(type);
        }
    );

  }

  getProviders(scrapperType :String){
    this.providerService.getProviders().subscribe(res => {
      let list = [];
      for(let provider of res){
        if(provider.scrapperType === scrapperType){
          list.push(provider);
        }
      }
      this.rows = list;
    },
       err => console.log(err));
  }

  onAdd() {
      this.router.navigate(['admin', 'scrapper', 'providers', this.scrapperType, 'create']);
  }

  update(row: Provider) {
    this.router.navigate(['admin', 'scrapper', 'providers', this.scrapperType, row._id]);
  }

}
