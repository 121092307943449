import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CopyBlogsService } from '@cms-app/copyblogs';
var AdminCopyblogsComponent = /** @class */ (function () {
    function AdminCopyblogsComponent(router, copyblogsService) {
        this.router = router;
        this.copyblogsService = copyblogsService;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.per_page = 20;
        this.query = '';
        this.loading = false;
    }
    AdminCopyblogsComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminCopyblogsComponent.prototype.checkLink = function (row) {
        window.open(row.url, '_blank');
    };
    AdminCopyblogsComponent.prototype.update = function (row) {
        this.router.navigate([
            'admin',
            'copyblogs',
            'articles',
            row._id,
            { state: { data: row } }
        ]);
    };
    AdminCopyblogsComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminCopyblogsComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminCopyblogsComponent.prototype.find = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        if (this.query) {
            options['header>$like'] = this.query;
        }
        this.loading = true;
        this.copyblogsService
            .fetch(options, { page: 1, per_page: this.per_page })
            .subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    return AdminCopyblogsComponent;
}());
export { AdminCopyblogsComponent };
