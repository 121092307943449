<cms-admin>
  <cms-section-title (add)="add()" (query)="onQuery($event)"
    >Etiquetas</cms-section-title
  >
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
    [externalPaging]="true"
    (page)="onPage($event)"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="update(row)">E</button>
          <button class="btn btn-danger" (click)="remove(row)">D</button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <cms-tag-modal (update)="find()"></cms-tag-modal>
</cms-admin>
