import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@cms-app/shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { ROUTES } from './admin-rss.routing';
import { RssChooserItem } from './containers/rss-chooser-item/rss-chooser-item.component';
import { AdminRssChooser } from './containers/rss-chooser/rss-chooser.component';
import { RssNewsComponent } from './containers/rss-news/rss-news.component';
import { RssSrcDetailComponent } from './containers/rss-src/rss-src-detail/rss-src-detail.component';
import { AdminRssSrcComponent } from './containers/rss-src/rss-src.component';

@NgModule({
  declarations:[
    AdminRssSrcComponent,
    RssSrcDetailComponent,
    RssChooserItem,
    AdminRssChooser,
    RssNewsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxDatatableModule,
    AdminSharedModule,
    SharedModule,
  ],

})
export class AdminRssModule { }
