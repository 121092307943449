    <cms-course-entrust-stepper
      [step]="step"
      [type]="'yes'"
      (stepChange)="onStepChange($event)"
      #stepper
    ></cms-course-entrust-stepper>
    <div style="margin-top: 20px"></div>
    <cms-course-entrust-student
    [parent]="inscriptionForm"
    [preselectedCourse]="!!course"
    [agent]="agent"
    [ucm]="ucm"
    *ngIf="step === 1"
    (student)="addStudent()"
    (next)="step = 2"
  ></cms-course-entrust-student>
  <cms-course-entrust-company
    [parent]="inscriptionForm"
    [agent]="agent"
    [privacy]="privacy"
    *ngIf="step === 2"
    (previous)="step = 1"
  >
  <div class="col-xs-3 pull-right" [formGroup]="inscriptionForm">
    <div class="center-block">
    <input id="agent" type="text" formControlName="agent" class="form-control" placeholder="Agente (Persona que le ha atendido)"/>
    </div>
  </div>
    <button
      class="btn btn-info pull-right"
      [disabled]="sending"
      (click)="send()"
    >
      <span *ngIf="!sending">ENVIAR</span>
      <span *ngIf="sending">ENVIANDO ...</span>
    </button>
</cms-course-entrust-company>


