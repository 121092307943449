<cms-admin>
  <div class="container">
    <div class="col align-self-center">
      <div class="card card-body">
        <button class="btn-primary btn-block" (click)="compareFamilies()">
          Compare Families
        </button>
        <h4 [ngClass]="compareFamiliesError ? 'error': 'ok'">{{ compareFamiliesString }}</h4>
        <button
          class="btn-primary btn-block"
          (click)="updateFamilies()"
          [disabled]="disableUpdateFamiliesButton"
        >
          Update Families
        </button>
        <h4 [ngClass]="updateFamiliesError ? 'error': 'ok'">{{ updateFamiliesString }}</h4>
        <button class="btn-primary btn-block" (click)="compareCourses()">
          Compare Courses
        </button>
        <h4 [ngClass]="compareCoursesError ? 'error': 'ok'">{{ compareCoursesString }}</h4>
        <button
          class="btn-primary btn-block"
          (click)="updateCourses()"
          [disabled]="disableUpdateCoursesButton"
        >
          Update Courses
        </button>
        <h4 [ngClass]="updateCoursesError ? 'error': 'ok'">{{ updateCoursesString }}</h4>
      </div>
    </div>
  </div>
</cms-admin>
