/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./source.component";
var styles_SourceComponent = [];
var RenderType_SourceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SourceComponent, data: {} });
export { RenderType_SourceComponent as RenderType_SourceComponent };
function View_SourceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["IEDITORIAL"]))], null, null); }
function View_SourceComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["VERTICE"]))], null, null); }
function View_SourceComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-warning"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["GALICIA"]))], null, null); }
function View_SourceComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-success"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ESPACIO"]))], null, null); }
function View_SourceComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["FCONTENIDO"]))], null, null); }
export function View_SourceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SourceComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SourceComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SourceComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SourceComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SourceComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.row.source === "ieditorial"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.row.source === "vertice"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.row.source === "galicia"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.row.source === "espacio"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.row.source === "fcontenido"); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_SourceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-source", [], null, null, null, View_SourceComponent_0, RenderType_SourceComponent)), i0.ɵdid(1, 49152, null, 0, i2.SourceComponent, [], null, null)], null, null); }
var SourceComponentNgFactory = i0.ɵccf("cms-source", i2.SourceComponent, View_SourceComponent_Host_0, { row: "row" }, {}, []);
export { SourceComponentNgFactory as SourceComponentNgFactory };
