import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryStringService {
  parse(queryString: string) {
    const query = {};
    const pairs = (queryString[0] === '?'
      ? queryString.substr(1)
      : queryString
    ).split('&');

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }
}
