import { Component, Input, OnInit } from "@angular/core";
import { Blognew } from "@cms-app/rss/models/blog-new.model";

@Component({
  selector: 'cms-blog-new',
  templateUrl: './blognew.component.html',
  styleUrls: ['./blognew.component.css'],
})
export class BlogNewComponent implements OnInit{
  @Input() new: Blognew;
  @Input() postLink = null;
  @Input() location;
  @Input() columns;

  ngOnInit(): void {
  }

  getLink(){
    if(this.new && this.new.link){
      if(this.postLink){
        let encodedLink = encodeURIComponent(btoa(this.new.link));
        return this.postLink+'?params='+encodedLink+'&columns='+this.columns+'&location='+this.location;
      }else{
        return this.new.link;
      }
    }else{
      return '';
    }
  }
  cutText(text: string){
    if(text.length > 53){
      return text.replace(/^(.{50}[^\s]*).*/, "$1")+"...";
    }else{
      return text;
    }

  }
}
