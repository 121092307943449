<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle collapsed"
        aria-expanded="false"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand">PORTALES VERTICALES</a>
    </div>

    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown" dropdown>
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle
            >Scrapper <span class="caret"></span
          ></a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li><a [routerLink]="['/admin/scrapper/maintenance']">Mantenimiento</a></li>
            <li><a [routerLink]="['/admin/scrapper/logs']">Logs</a></li>
            <li><a [routerLink]="['/admin/scrapper/comparator']">Comparador</a></li>
            <li><a [routerLink]="['/admin/scrapper/providers/type/', 'cursos']">Proveedores de Cursos</a></li>
            <li role="separator" class="divider"></li>
            <li><a [routerLink]="['/admin/scrapper/providers/type/geodestinos']">Proveedores de Geodestinos</a></li>
          </ul>
        </li>
        <li class="dropdown" dropdown>
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle
            >Formación <span class="caret"></span
          ></a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li><a [routerLink]="['/admin/iefp/courses']">Cursos</a></li>
            <li><a [routerLink]="['/admin/iefp/entrusts']">Encomiendas</a></li>
            <li><a [routerLink]="['/admin/iefp/families']">Familias</a></li>
            <li>
              <a [routerLink]="['/admin/iefp/pv-families']">Familias Sistema</a>
            </li>
            <li role="separator" class="divider"></li>
            <li><a [routerLink]="['/admin/iefp/tags']">Etiquetas</a></li>
          </ul>
        </li>
        <li class="dropdown" dropdown>
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle
            >GeoDestinos <span class="caret"></span
          ></a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li><a [routerLink]="['/admin/geodestinos/hotels']">Hoteles</a></li>
            <li>
              <a [routerLink]="['/admin/geodestinos/restaurants']"
                >Restaurantes</a
              >
            </li>
            <li>
              <a [routerLink]="['/admin/geodestinos/activities']">Actividades</a>
            </li>
            <li role="separator" class="divider"></li>
            <li>
              <a [routerLink]="['/admin/geodestinos/locations']">Regiones</a>
            </li>
          </ul>
        </li>
        <li class="dropdown" dropdown>
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle
            >CopyBlog <span class="caret"></span
          ></a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li>
              <a [routerLink]="['/admin/copyblogs/articles']">Articulos</a>
            </li>
            <li role="separator" class="divider"></li>
            <li><a [routerLink]="['/admin/rss/rss-src']">Fuentes Rss</a></li>
            <li><a [routerLink]="['/admin/rss/rss-chooser']">Selector Noticias</a></li>
            <li><a [routerLink]="['/admin/rss/rss-src/news']">Noticias Guardadas</a></li>
          </ul>
        </li>
        <li class="dropdown" dropdown>
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle
            >Usuarios <span class="caret"></span
          ></a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li>
              <a [routerLink]="['/admin/users']">Usuarios</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
