import { OnInit, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { FilesService } from '../../services/files.service';
var FilePickerComponent = /** @class */ (function () {
    function FilePickerComponent(filesService) {
        this.filesService = filesService;
        this.preview = false;
        this.manual = false;
        this.required = false;
        this.url = null;
        this.uploaded = new EventEmitter();
    }
    FilePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var transformations = this.optionsBuilder();
        this.filesService
            .getUploader({ transformations: transformations }, this.url)
            .subscribe(function (uploader) {
            _this.uploader = uploader;
            if (_this.preview) {
                _this.uploader.onAfterAddingFile = function (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        _this.display = e.target.result;
                    };
                    reader.readAsDataURL(file._file);
                    while (_this.uploader.queue.length > 1) {
                        _this.uploader.removeFromQueue(_this.uploader.queue[0]);
                    }
                };
            }
            _this.uploader.events.subscribe(function (_a) {
                var path = _a.path, status = _a.status;
                _this.uploader.clearQueue();
                _this.uploaded.emit({ path: path, status: status });
                _this.display = null;
            });
        });
    };
    FilePickerComponent.prototype.upload = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.uploader.getNotUploadedItems().length > 0) {
                _this.uploader.uploadAll();
                _this.uploaded.subscribe(function (_a) {
                    var path = _a.path, status = _a.status;
                    observer.next(path);
                });
            }
            else {
                observer.next(null);
            }
        });
    };
    FilePickerComponent.prototype.optionsBuilder = function () {
        if (this.transformations) {
            if (this.transformations instanceof Array) {
                return this.transformations
                    .map(function (row) {
                    return row.width + "," + row.height + "," + row.name + "," + (row.extent ? 1 : 0);
                })
                    .join('|');
            }
            else {
                return this.transformations;
            }
        }
        else if (this.width || this.height) {
            return this.width + "," + this.height + ",," + (Boolean(this.width && this.height) ? 1 : 0);
        }
        else {
            return null;
        }
    };
    return FilePickerComponent;
}());
export { FilePickerComponent };
