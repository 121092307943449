<cms-admin>
  <cms-section-title
    (query)="onQuery($event)"
    >Noticias
  </cms-section-title>
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
    [externalPaging]="true"
    (page)="onPage($event)"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Titular
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.title }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Region
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.region }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fuente
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.source }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fecha
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.isoDate | date: 'dd/MM/yyyy'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Eliminar
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button (click)="delete(row)">X</button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
