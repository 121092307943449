<div
  [class]="
    block ? 'tabs-header-container tabs-header-block' : 'tabs-header-container'
  "
>
  <div
    [class]="
      tab.selected ? 'tab-header-element tab-selected' : 'tab-header-element'
    "
    (click)="selectTab(i)"
    *ngFor="let tab of tabs; let i = index"
  >
    <img class="tab-header-picture" [src]="tab.picture" *ngIf="!!tab.picture" />
    <div class="text-center">{{ tab.name }}</div>
  </div>
</div>
<div><ng-content></ng-content></div>
