import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import {
  GeoDestino,
  GeodestinosService,
  GeoDestinoType
} from '@cms-app/geodestinos';
import { MessagingService } from '@cms-app/admin/shared';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'cms-geodestino-detail',
  templateUrl: './geodestino-detail.component.html'
})
export class AdminGeoDestinoDetailComponent implements OnInit {
  geodestino: GeoDestino;
  type: GeoDestinoType;

  geodestinoForm: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private geodestinosService: GeodestinosService,
    private messaging: MessagingService
  ) {}

  ngOnInit() {
    this.type=this.route.snapshot.params.type;
    this.setupGeoDestino(this.route.snapshot.data.geodestino);
    this.route.params
      .pipe(
        filter(params => params.id !== this.geodestino._id || params.id === 'new'),
        switchMap(params => {
          return params.id === 'new'
            ? of(this.geodestinosService.empty(this.route.snapshot.params.type))
            : this.geodestinosService.findOne(this.route.snapshot.params.type,params.id);
        })
      )
      .subscribe(geodestino => this.setupGeoDestino(geodestino));

  }

  goToSection() {
    switch (this.type) {
      case 'quedar':
        this.router.navigate(['admin', 'geodestinos', 'bars']);
        break;
      case 'comer':
        this.router.navigate(['admin', 'geodestinos', 'restaurants']);
        break;
      case 'visitar':
        this.router.navigate(['admin', 'geodestinos', 'activities']);
        break;
      case 'alojamiento':
      default:
        this.router.navigate(['admin', 'geodestinos', 'hotels']);
    }
  }

  sectionName() {
    switch (this.type) {
      case 'quedar':
        return 'Bares';
      case 'visitar':
        return 'Actividades';
      case 'comer':
        return 'Restaurantes';
      case 'alojamiento':
      default:
        return 'Hoteles';
    }
  }

  isActivity(){
    return this.type === 'visitar';
  }

  save() {
      if (this.geodestinoForm.valid) {
        let request;
        if(this.geodestino._id === null){
          let data = {
            ...this.geodestinoForm.value,
            name: this.geodestinoForm.get('namePV').value,
            url: this.geodestinoForm.get('urlPV').value,
            location: this.geodestinoForm.get('locationPV').value,
            zipCode: this.geodestinoForm.get('zipCodePV').value,
            state: this.geodestinoForm.get('statePV').value,
            description: this.geodestinoForm.get('descriptionPV').value,
            address: this.geodestinoForm.get('addressPV').value,
            email: this.geodestinoForm.get('emailPV').value,
            category: this.geodestinoForm.get('categoryPV').value
          };
          request = this.geodestinosService.add(this.type, data);
        }else{
           request = this.geodestinosService.patch(this.type, this.geodestino._id, this.geodestinoForm.value);
        }

        request.subscribe(
          (saved: GeoDestino) => {
            // ACTUALIZACION CORRECTA
            this.messaging.success(
              'Geodestino actualizado correctamente',
              'CMS ADMIN'
            );

            // redireccionar
            if (!!saved) {
              this.goToSection();
            }
          },
          () => {
            // ACTUALIZACION ERRONEA
            this.messaging.error('Error actualizando el geodestino', 'CMS ADMIN');
          }
        );
  }
}

  get img() {
    return <FormArray>this.geodestinoForm.get('img');
  }

  addImg(stringImg = '') {
    this.img.push(this.fb.control(stringImg));
  }

  removeImg(idx: number) {
    this.img.removeAt(idx);
  }

  get phone() {
    return <FormArray>this.geodestinoForm.get('phone');
  }

  addPhone(stringImg = '') {
    this.phone.push(this.fb.control(stringImg));
  }

  removePhone(idx: number) {
    this.phone.removeAt(idx);
  }

  //IMPLEMENTATION
  private mapToFormGroup(geodestino: GeoDestino): FormGroup {
    const img: any[] = this.geodestino ? this.geodestino.img : [];
    const phone: any[] = this.geodestino ? this.geodestino.phone : []
    let form;
    switch(this.type){
      case 'visitar':
        form = this.fb.group({
          namePV: [geodestino.namePV],
          type: [geodestino.type],
          urlPV: [geodestino.urlPV],
          statePV: [geodestino.statePV],
          locationPV: [geodestino.locationPV],
          zipCodePV: [geodestino.zipCodePV],
          coordinatesPV: [geodestino.coordinatesPV],
          img: this.fb.array(
            img.map((src: string) => this.fb.control(src))
          ),
          addressPV: [geodestino.addressPV],
          phone: this.fb.array(
            phone.map((phone: string) => this.fb.control(phone))
          ),
          emailPV: [geodestino.emailPV],
          descriptionPV: [geodestino.descriptionPV],
          disable: [geodestino.disable],
          certCalidad: [geodestino.certCalidad],
          certCalidadLink: [geodestino.certCalidadLink],
          fidelity: [geodestino.fidelity],
          fidelityLink: [geodestino.fidelityLink],
          galicia: [geodestino.galicia],
          actTypePV: [geodestino.actTypePV],
          promo: [geodestino.promo],
          promoUrl: [geodestino.promoUrl],
          promoDiscount: [geodestino.promoDiscount]
        });
        break;
      default:
        form = this.fb.group({
          namePV: [geodestino.namePV],
          type: [geodestino.type],
          urlPV: [geodestino.urlPV],
          statePV: [geodestino.statePV],
          locationPV: [geodestino.locationPV],
          zipCodePV: [geodestino.zipCodePV],
          coordinatesPV: [geodestino.coordinatesPV],
          img: this.fb.array(
            img.map((src: string) => this.fb.control(src))
          ),
          addressPV: [geodestino.addressPV],
          phone: this.fb.array(
            phone.map((phone: string) => this.fb.control(phone))
          ),
          emailPV: [geodestino.emailPV],
          categoryPV: [geodestino.categoryPV],
          descriptionPV: [geodestino.descriptionPV],
          disable: [geodestino.disable],
          certCalidad: [geodestino.certCalidad],
          certCalidadLink: [geodestino.certCalidadLink],
          fidelity: [geodestino.fidelity],
          fidelityLink: [geodestino.fidelityLink],
          galicia: [geodestino.galicia],
          promo: [geodestino.promo],
          promoUrl: [geodestino.promoUrl],
          promoDiscount: [geodestino.promoDiscount]
        });

    }
    return form;
  }

  private setupGeoDestino(geodestino: GeoDestino = this.geodestinosService.empty(this.type)) {
    this.geodestino = geodestino;
    this.geodestinoForm = this.mapToFormGroup(this.geodestino);
  }
}
