/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blognew.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./blognew.component";
var styles_BlogNewComponent = [i0.styles];
var RenderType_BlogNewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogNewComponent, data: {} });
export { RenderType_BlogNewComponent as RenderType_BlogNewComponent };
export function View_BlogNewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "new image new-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "new-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "new-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "a", [["class", "blog-title-link blog-link"]], [[8, "href", 4], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "new-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "new-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "new-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["class", "copyblog-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "new-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "wsite-button wsite-button-large wsite-button-highlight"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "wsite-button-inner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leer m\u00E1s"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLink(); var currVal_1 = ((_co.new && _co.new.title) ? _co.cutText(_co.new.title) : ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ((_co.new && _co.new.date) ? _co.new.date : ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.new && _co.new.img) ? _co.new.img : ""); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.getLink(); _ck(_v, 11, 0, currVal_4); }); }
export function View_BlogNewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-blog-new", [], null, null, null, View_BlogNewComponent_0, RenderType_BlogNewComponent)), i1.ɵdid(1, 114688, null, 0, i2.BlogNewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlogNewComponentNgFactory = i1.ɵccf("cms-blog-new", i2.BlogNewComponent, View_BlogNewComponent_Host_0, { new: "new", postLink: "postLink", location: "location", columns: "columns" }, {}, []);
export { BlogNewComponentNgFactory as BlogNewComponentNgFactory };
