import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeodestinosService } from '@cms-app/geodestinos';
var AdminLocationsComponent = /** @class */ (function () {
    function AdminLocationsComponent(router, geodestinosService) {
        this.router = router;
        this.geodestinosService = geodestinosService;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.per_page = 20;
        this.query = '';
        this.loading = false;
    }
    AdminLocationsComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminLocationsComponent.prototype.update = function (row) {
        this.router.navigate(['admin', 'geodestinos', 'location-detail', row._id], {
            state: {
                data: row,
            }
        });
    };
    AdminLocationsComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminLocationsComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminLocationsComponent.prototype.find = function () {
        var _this = this;
        this.loading = true;
        this.geodestinosService.getGeoDestinoLocations({}).subscribe(function (_a) {
            var count = _a.count, list = _a.list;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    AdminLocationsComponent.prototype.onAdd = function () {
        this.router.navigate(['admin', 'geodestinos', 'location-detail', 'new']);
    };
    return AdminLocationsComponent;
}());
export { AdminLocationsComponent };
