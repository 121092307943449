import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CoursePlatformsService, EntrustService, } from '@cms-app/courses';
import { CMSEnv } from '@cms-app/shared';
import { combineLatest } from 'rxjs';
var CourseEntrustBonifComponent = /** @class */ (function () {
    function CourseEntrustBonifComponent(env, fb, entrust, platforms, router) {
        this.env = env;
        this.fb = fb;
        this.entrust = entrust;
        this.platforms = platforms;
        this.router = router;
        this.okurl = 'inscripcion-ok.html';
        this.platform = this.platforms.guess();
        this.sending = false;
        this.loading = false;
        this.ucm = false;
        this.step = 1;
    }
    CourseEntrustBonifComponent.prototype.ngOnInit = function () {
        if (this.course) {
            this.ucm = this.course.ucm;
        }
        this.inscriptionForm = this.fb.group({
            agent: [''],
            bonus: ['yes'],
            company: this.fb.group({
                businessName: ['', [Validators.required]],
                vatIdentifier: ['', [Validators.maxLength(9), Validators.required]],
                address: ['', [Validators.required]],
                zipCode: ['', [Validators.maxLength(5), Validators.pattern(/[0-9]*/)]],
                city: ['', [Validators.required]],
                state: ['', [Validators.required]],
                since: [
                    '',
                    [Validators.required, Validators.pattern(/[0-9]*/), Validators.maxLength(4), Validators.required],
                ],
                agreement: ['', [Validators.required]],
                ssAccount: [0, [Validators.required]],
                email: ['', [Validators.required, Validators.email]],
                phone: ['', [Validators.required]],
                phone2: [''],
                accountNumber: ['', [Validators.pattern(this.entrust.accountNumberRE)]],
                delegate: [false],
                contactPerson: ['', [Validators.required]],
                employees: [1, [Validators.pattern(/[0-9]*/), Validators.maxLength(9), Validators.required]],
                sector: ['', [Validators.required]],
                year: [new Date().getFullYear(), [Validators.pattern(/[0-9]*/)]],
                observations: [''],
            }),
            privacy: [false, [Validators.requiredTrue]],
            newsletter: [false],
            representative: this.fb.group({
                name: ['', [Validators.required]],
                vatIdentifier: ['', [Validators.required]],
            }),
            students: this.fb.array([]),
        });
        this.addStudent();
    };
    CourseEntrustBonifComponent.prototype.addStudent = function () {
        var students = this.inscriptionForm.get('students');
        var bonus = 'yes';
        var student = this.fb.group({
            name: ['', [Validators.required]],
            subname: ['', [Validators.required]],
            sex: ['', [Validators.required]],
            subname2: [''],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', bonus ? [Validators.required] : []],
            course: [this.course ? this.course.name ? this.course.name : this.course.namePV : '', [Validators.required]],
            vatIdentifier: ['', bonus ? [Validators.required, Validators.maxLength(9)] : []],
            ssNumber: ['', bonus ? [Validators.required] : []],
            birthdate: ['', [Validators.required, this.ValidateDate]],
            studies: ['', bonus ? [Validators.required] : []],
            cotization: ['', bonus ? [Validators.required] : []],
            observations: [''],
            nationality: ['']
        });
        if (this.course && this.course.ucm) {
            student.addControl('poll', this.fb.group({
                title: ['', [Validators.required]],
                graduation_year: ['', [Validators.required]],
                first_time: ['', [Validators.required]],
                ucm_link: ['', [Validators.required]],
                actual_job: ['', [Validators.required]],
                job_type: ['', [Validators.required]],
                actual_occupation: ['', [Validators.required]],
                sector: ['', [Validators.required]],
                job: ['', [Validators.required]],
                motivation: ['', [Validators.required]],
                ucm_motivation: ['', [Validators.required]],
                course_motivation: ['', [Validators.required]]
            }));
        }
        students.push(student);
    };
    CourseEntrustBonifComponent.prototype.onStepChange = function (step) {
        this.step = step;
    };
    CourseEntrustBonifComponent.prototype.send = function () {
        var _this = this;
        if (!this.sending && this.inscriptionForm.valid) {
            var formData = this.inscriptionForm.value;
            var agentElement = this.inscriptionForm.get('agent');
            if (agentElement) {
                this.agent = agentElement.value;
            }
            else {
                this.agent = '-';
            }
            this.inscriptionForm.removeControl('agent');
            this.sending = true;
            combineLatest(this.entrust.pushOnlyEntrust(formData, this.platform, this.agent), this.entrust.pushYesEntrust(formData, this.platform, this.agent)).subscribe(function (_a) {
                var _ = _a[0], entrusts = _a[1];
                if (entrusts[0]) {
                    if (_this.env.elements) {
                        window.open(window.location.origin + "/" + _this.okurl + "?entrust=" + entrusts[0]._id, '_self');
                    }
                    else {
                        _this.router.navigate(['entrust', 'ok', entrusts[0]._id]);
                    }
                }
                _this.sending = false;
            }, function () {
                _this.sending = false;
            });
        }
        else {
            this.handleError(this.inscriptionForm);
        }
    };
    CourseEntrustBonifComponent.prototype.handleError = function (form) {
        var _this = this;
        if (window.console) {
            console.log(this.inscriptionForm);
        }
        var privacy = form.get('privacy');
        if (privacy.invalid) {
            privacy.markAsTouched();
            this.step = 2;
            return;
        }
        var studentsArray = form.get('students');
        if (studentsArray.invalid) {
            this.step = 1;
            studentsArray.controls.forEach(function (student) {
                if (student.invalid) {
                    _this.handleErrorFormGroup(student);
                }
            });
            return;
        }
        var companyForm = form.get('company');
        if (companyForm.invalid) {
            this.step = 2;
            this.handleErrorFormGroup(companyForm);
        }
    };
    CourseEntrustBonifComponent.prototype.handleErrorFormGroup = function (group) {
        Object.keys(group.controls).forEach(function (key) {
            group.controls[key].markAsTouched();
        });
    };
    CourseEntrustBonifComponent.prototype.onNext = function () {
        this.step = 2;
        window.scrollTo(0, 0);
    };
    CourseEntrustBonifComponent.prototype.ValidateDate = function (control) {
        if (control.value) {
            var today = new Date();
            if (control.value > today) {
                return { 'dateBeforeToday': true };
            }
            else {
                return null;
            }
        }
        return null;
    };
    return CourseEntrustBonifComponent;
}());
export { CourseEntrustBonifComponent };
