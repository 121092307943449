import { Injectable } from '@angular/core';

@Injectable()
export class AjaxDownloadService {
  createAndDownloadBlobFile(
    body: string,
    options: { type: string },
    filename: string
  ) {
    const blob = new Blob([body], options);

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');

      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
