<cms-admin *ngIf="!!location" [formGroup]="geodestinoForm">
  <cms-section-title [showAdd]="false" [showQuery]="false">{{
    location.fullName
  }}</cms-section-title>
  <div
  class="row"
  style="margin-left: 0px; margin-right: 0px"
  [formGroup]="geodestinoForm"
  *ngIf="!!location"
>
<div>
  <span style="font-weight: bold"> Codigo: </span>
  <input
    type="text"
    class="form-control"
    formControlName="name"
    placeholder="Codigo"
    size="150"
  />
</div>
<div>
  <span style="font-weight: bold"> Nombre: </span>
  <input
    type="text"
    class="form-control"
    formControlName="fullName"
    placeholder="Nombre"
    size="150"
  />
</div>
<div>
  <span style="font-weight: bold"> Localidades: </span>
  <table>
    <tr *ngFor="let item of getLocations()">
      <td>
        {{item}}
      </td>
      <td>
        <span class="glyphicon glyphicon-trash" style="cursor: pointer;" (click)="remove(item)">
        </span>
      </td>
    </tr>
    <tr>
      <td><input type="text" placeholder="Añadir Localidad" [(ngModel)]="addedLocation"  [ngModelOptions]="{standalone: true}"/></td>
      <td><button class="btn btn-primary" (click)="addLocation()" tooltip="Añadir Localidad" placement="top">
        Añadir Localidad
      </button></td>
    </tr>
  </table>
</div>
<div class="row" style="margin-left: 0px; margin-right: 0px">
  <div class="col-md-12 pull-right">
    <button class="btn btn-success" (click)="save()">GUARDAR</button>
  </div>
</div>
  </div>

