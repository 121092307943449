import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ERROR,
  FINISHED,
  WORKING,
} from '@cms-app/admin/scrapper/interfaces/response';
import { CoursesService } from '@cms-app/courses';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'cms-admin-course-upload-excel',
  templateUrl: './course-upload-excel.component.html',
  styleUrls: ['./course-upload-excel.component.css'],
})
export class AdminCourseUploadExcelComponent implements OnInit {
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  fileUploadForm: FormGroup;
  working: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    this.fileUploadForm = this.formBuilder.group({
      file: [''],
      source: [''],
      clear: [false],
    });
  }

  onFileSelect(event) {
    let af = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      let isValid = false;

      for (let a of af) {
        if (file.type === a) {
          isValid = true;
        }
      }
      if (!isValid) {
        alert('Solo documentos de excel permitidos!');
      } else {
        this.fileUploadForm.get('file').setValue(file);
      }
    }
  }

  async onFormSubmit() {
    if (!this.fileUploadForm.get('file').value) {
      alert('Por favor introduzca valores válidos');
      return false;
    }

    const formData = new FormData();
    formData.append('file', this.fileUploadForm.get('file').value);
    formData.append('source', this.fileUploadForm.get('source').value);
    formData.append('clear', this.fileUploadForm.get('clear').value);

    let subs = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.coursesService.uploadFile(formData))
      )
      .subscribe((res) => {
        if (res.status === WORKING) {
          this.working = true;
        }
        if (res.status === FINISHED || res.status === ERROR) {
          subs.unsubscribe();
          this.working = false;
          alert(res.msg);
          this.uploadFileInput.nativeElement.value = '';
        }
      });
  }
}
