<div [ngClass]="{ container: !env.elements }">
  <div class="row">
    <div
      class="col-md-3 no-padding"
      style="padding-left: 0px; padding-right: 10px"
    >
      <accordion [closeOthers]="true" *ngIf="!isMobile">
        <div *ngFor="let location of locations; index as i">
          <accordion-group
            panelClass="geoguide-category-group"
            id="{{ 'accordion' + i }}"
          >
            <div
              class="btn btn-link btn-block clearfix"
              accordion-heading
              (click)="scrollTo(i, location)"
            >
              <div class="pull-left float-left geolocation-title">
                {{ location }}
              </div>
            </div>
            <ul
              style="padding: 0px 5px; font-size: 12px; list-style-type: none"
            >
              <li
                (click)="filter(row.name, location)"
                style="cursor: pointer"
                *ngFor="let row of getSectionsDetail()"
              >
                {{ row.display }}
              </li>
            </ul>
          </accordion-group>
        </div>
      </accordion>
      <div *ngIf="isMobile">
        <div class="form-group" style="padding: 0 20px">
          <select
            class="form-control"
            (change)="onSelectChangeLocation($event)"
          >
            <option value="">LOCALIDAD ...</option>
            <option [value]="location" *ngFor="let location of locations">
              {{ location }}
            </option>
          </select>
          <select
            class="form-control"
            (change)="onSelectChangeSection($event)"
            [(ngModel)]="selectedValue"
          >
            <option value="">TIPO ESTABLECIMIENTO ...</option>
            <option [value]="section" *ngFor="let section of sections">
              {{ section }}
            </option>
          </select>
          <div class="form-inline text-center" [formGroup]="searchForm">
            <div class="input-group">
              <input
                type="search"
                class="form-control cms-search"
                (keyup.enter)="doSearch()"
                formControlName="query"
                placeholder="Buscar"
              />
              <span class="input-group-btn">
                <button class="btn btn-default" (click)="doSearch()">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isMobile">
      <div
        class="no-padding"
        id="cms-geoguide-container"
        style="margin: 20px 15px 30px 20px"
      >
        <div class="row" *ngIf="loaded">
          <div class="col-md-12" style="text-align: right">
            <p style="margin-top: 15px; margin-bottom: 0px">
              <strong>{{ count }}</strong> resultados encontrados
            </p>
            <pagination
              geoDestinoPager
              [totalItems]="count"
              [(ngModel)]="page"
              (pageChanged)="pageChanged($event)"
              *ngIf="count > per_page"
            ></pagination>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngFor="let geodestino of rows">
            <cms-geodestino-section-item
              [geodestino]="geodestino"
            ></cms-geodestino-section-item>
          </div>
          <div class="col-md-12">
            <cms-geodestino-contact-item></cms-geodestino-contact-item>
          </div>
        </div>
        <div class="row" *ngIf="loaded && count > 5">
          <div class="col-md-12" style="text-align: right">
            <p style="margin-top: 15px; margin-bottom: 0px">
              <strong>{{ count }}</strong> resultados encontrados
            </p>
            <pagination
              geoDestinoPager
              [totalItems]="count"
              [(ngModel)]="page"
              (pageChanged)="pageChanged($event)"
              *ngIf="count > per_page"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-9 no-padding"
      id="cms-geoguide-container"
      *ngIf="!isMobile"
    >
      <div
        class="form-inline text-center"
        style="margin-left: -10px"
        [formGroup]="searchForm"
      >
        <div class="form-group cms-search">
          <div class="input-group">
            <input
              type="search"
              class="form-control cms-search"
              (keyup.enter)="doSearch()"
              formControlName="query"
              placeholder="Buscar"
            />
            <span class="input-group-btn">
              <button class="btn btn-default" (click)="doSearch()">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="loaded">
        <div class="col-md-6">
          <h4>{{ title }}</h4>
        </div>
        <div class="col-md-6" style="text-align: right">
          <p style="margin-top: 15px; margin-bottom: 0px">
            <strong>{{ count }}</strong> resultados encontrados
          </p>
          <pagination
            geoDestinoPager
            [totalItems]="count"
            [(ngModel)]="page"
            (pageChanged)="pageChanged($event)"
            *ngIf="count > per_page"
          ></pagination>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12" *ngFor="let geodestino of rows">
          <cms-geodestino-section-item
            [geodestino]="geodestino"
          ></cms-geodestino-section-item>
        </div>
        <div class="col-md-12">
          <cms-geodestino-contact-item></cms-geodestino-contact-item>
        </div>
      </div>
      <div class="row" *ngIf="loaded && count > 5">
        <div class="col-md-12" style="text-align: right">
          <p style="margin-top: 15px; margin-bottom: 0px">
            <strong>{{ count }}</strong> resultados encontrados
          </p>
          <pagination
            geoDestinoPager
            [totalItems]="count"
            [(ngModel)]="page"
            (pageChanged)="pageChanged($event)"
            *ngIf="count > per_page"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
