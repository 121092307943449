import { InjectionToken } from '@angular/core';

export const CMS_ENV = new InjectionToken<string>('CMS_ENV');

export interface CMSEnv {
  production: boolean;
  elements: boolean;
  baseUrl: string;
  assetsUrl: string;
  picturesUrl: string;
  copyblogUrl: string;
  filesUrl: string;
  scrapperUrl: string;
}
