<cms-admin [formGroup]="family">
  <cms-section-title [showAdd]="false" [showQuery]="false"
    >Familia de Sistema</cms-section-title
  >
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="">Nombre</label>
        <input type="text" class="form-control" formControlName="name" />
      </div>
    </div>
  </div>
  <div formArrayName="families">
    <label for="">Familias</label>
    <div
      style="padding: 5px"
      *ngFor="let item of subfamilies.controls; let i = index"
      fxLayout="row"
      fxLayoutGap="10px"
    >
      <div fxFlex>
        <ng-select
          [items]="subfamiliesList"
          [selectOnTab]="true"
          bindValue="_id"
          bindLabel="display"
          placeholder="Seleccione Familia"
          [formControl]="item"
        ></ng-select>
      </div>
      <div>
        <button type="button" (click)="remove(i)" class="btn btn-danger">
          X
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" style="padding: 0px 20px">
        <button class="btn btn-success" (click)="add()">AÑADIR</button>
      </div>
    </div>
  </div>
  <button class="btn btn-primary pull-right" (click)="update()">
    GUARDAR
  </button>
</cms-admin>
