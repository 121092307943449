import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import {
  BsDatepickerConfig,
  BsDatepickerDirective
} from 'ngx-bootstrap/datepicker';
import { CMS_DATEPICKER_CONFIG } from '@cms-app/shared';

import { EntrustService } from '../../providers/entrust.service';

@Component({
  selector: 'cms-course-entrust-student',
  templateUrl: './course-entrust-student.component.html',
  styleUrls: ['./course-entrust-student.component.css']
})
export class CourseEntrustStudentComponent {
  @ViewChildren(BsDatepickerDirective) pickers = new QueryList<
    BsDatepickerDirective
  >();

  @Input() parent: FormGroup;
  @Input() preselectedCourse = false;
  @Input() agent: string;
  @Input() ucm: boolean = false;

  @Output() student = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  loading = false;


  constructor(
    @Inject(CMS_DATEPICKER_CONFIG) public bsConfig: Partial<BsDatepickerConfig>,
    private entrust: EntrustService
  ) {}

  get bonus() {
    const value = this.parent.get('bonus').value;

    return value === 'yes' || value === 'entrust';
  }

  get students(): FormArray {
    return <FormArray>this.parent.get('students');
  }

  remove(idx: number) {
    const students = <FormArray>this.parent.get('students');

    students.removeAt(idx);
  }

  onVatBlur(idx: number) {
    if (!this.loading && this.parent.get('bonus').value === 'yes') {
      const students = <FormArray>this.parent.get('students');
      const student = students.at(idx);
      const vat = student.get('vatIdentifier').value;

      if (vat && vat.length > 0) {
        this.loading = true;
        this.entrust.checkStudentVat(vat).subscribe(
          data => {
            if (data) {
              student.patchValue(data);
            }

            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    }

    const bonus = this.parent.get('bonus').value;
    if (bonus === 'no') {
      const companyVatIdentifier = this.parent.get('company').value
        .vatIdentifier;
      const representativeVatIdentifier = this.parent.get('representative')
        .value.vatIdentifier;
      const student = this.getStudent(idx);

      if (
        !companyVatIdentifier ||
        (companyVatIdentifier.length < 1 && student.value)
      ) {
        this.parent.get('company').patchValue({
          vatIdentifier: student.value.vatIdentifier
        });
      }

      if (
        !representativeVatIdentifier ||
        (representativeVatIdentifier.length < 1 && student.value)
      ) {
        this.parent.get('representative').patchValue({
          vatIdentifier: student.value.vatIdentifier
        });
      }
    }
  }

  onNameBlur(idx: number) {
    const bonus = this.parent.get('bonus').value;
    if (bonus === 'no') {
      const student = this.getStudent(idx);

      if (student.value) {
        this.parent.get('company').patchValue({
          businessName: [
            student.value.name,
            student.value.subname,
            student.value.subname2
          ]
            .filter(Boolean)
            .join(' ')
        });
      }

      if (student.value) {
        this.parent.get('representative').patchValue({
          name: [
            student.value.name,
            student.value.subname,
            student.value.subname2
          ]
            .filter(Boolean)
            .join(' ')
        });
      }
    }
  }

  onEmailBlur(idx: number) {
    const bonus = this.parent.get('bonus').value;
    if (bonus === 'no') {
      const companyEmail = this.parent.get('company').value.email;
      const student = this.getStudent(idx);

      if (!companyEmail || (companyEmail.length < 1 && student.value)) {
        this.parent.get('company').patchValue({
          email: student.value.email
        });
      }
    }
  }

  onPhoneBlur(idx: number) {
    const bonus = this.parent.get('bonus').value;
    if (bonus === 'no') {
      const companyPhone = this.parent.get('company').value.phone;
      const student = this.getStudent(idx);

      if (!companyPhone || (companyPhone.length < 1 && student.value)) {
        this.parent.get('company').patchValue({
          phone: student.value.phone
        });
      }
    }
  }

  triggerDatepicker(idx: number) {
    console.log({
      pickers: this.pickers,
      idx
    });

    this.pickers.find((_, i) => i == idx).toggle();
  }

  private getStudent(idx: number): FormGroup {
    const students = <FormArray>this.parent.get('students');

    return students.at(idx) as FormGroup;
  }
}
