import {
  Component,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  Inject
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';

import { Tag, TagsService } from '@cms-app/courses';
import { MODAL_OPTIONS } from '@cms-app/shared';

@Component({
  selector: 'cms-tag-modal',
  templateUrl: './tag-modal.component.html'
})
export class TagModalComponent implements OnInit {
  @ViewChild('childModal') public childModal: ModalDirective;
  @Output() update = new EventEmitter<void>();

  title = 'Nuevo Registro';
  buttonText = 'save';
  tagForm: FormGroup;
  original: Tag;
  editMode = false;

  constructor(
    @Inject(MODAL_OPTIONS)
    public modalOptions: Partial<ModalOptions>,
    private fb: FormBuilder,
    private tagsService: TagsService
  ) {}

  ngOnInit() {
    this.tagForm = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  add() {
    this.title = 'Nuevo Registro';
    this.editMode = false;
    this.childModal.show();
  }

  edit(row: Tag) {
    this.title = 'Editar Registro';
    this.original = { ...row };
    this.editMode = true;

    this.tagForm.setValue({
      name: row.name
    });

    this.childModal.show();
  }

  save(ev: Event, form: FormGroup) {
    if (ev) {
      ev.stopPropagation();
    }

    if (form.valid) {
      const obs = this.editMode
        ? this.tagsService.patch(form.value, this.original._id)
        : this.tagsService.add(form.value);
      obs.subscribe(() => {
        this.update.emit();
        this.close();
      });
    } else {
      Object.keys(form.controls).forEach(key => {
        form.controls[key].markAsTouched();
      });
    }
  }

  close() {
    this.tagForm.reset();
    this.childModal.hide();
  }
}
