import { Component, OnInit } from '@angular/core';

import { CoursesService, Course } from '@cms-app/courses';
import { Router } from '@angular/router';

@Component({
  selector: 'cms-admin-courses',
  templateUrl: './courses.component.html'
})
export class AdminCoursesComponent implements OnInit {
  rows: Course[] = [];
  count = 0;
  page = 1;
  query = '';
  queryBySource = '';
  loading = false;

  constructor(private router: Router, private coursesService: CoursesService) {}

  ngOnInit() {
    this.find();
  }

  checkLink(row: Course) {
    window.open(row.url, '_blank');
  }

  onAdd() {
    this.router.navigate(['admin', 'iefp', 'courses', 'new']);
  }

  update(row: Course) {
    this.router.navigate(['admin', 'iefp', 'courses', row._id]);
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  onQueryBySource(query:string){
    this.queryBySource=query;
    this.findBySource();
  }

  onExport(query: string) {
    this.coursesService.excel(query);
  }

  find() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }
    if (this.query) {
      options['namePV>$like'] = this.query;
      options['source>$like'] = this.queryBySource;
    }

    this.loading = true;
    this.coursesService.find(options).subscribe(
      ({ list, count }) => {
        this.rows = list;
        this.count = count;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  findBySource() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }
    if (this.queryBySource) {
      options['source>$like'] = this.queryBySource;
    }

    this.loading = true;
    this.coursesService.find(options).subscribe(
      ({ list, count }) => {
        this.rows = list;
        this.count = count;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
