<cms-admin *ngIf="!!course" [formGroup]="courseForm">
  <ol class="breadcrumb">
    <li><a (click)="goToCourses()" style="cursor: pointer">Cursos</a></li>
    <li class="active">{{ course.name }}</li>
  </ol>
  <cms-section-title [showAdd]="false" [showQuery]="false">Curso</cms-section-title>
  <div style="margin-top: 20px"></div>
  <tabset>
    <tab heading="Datos Generales" id="tab1">
      <div style="margin-top: 10px"></div>

      <table class="table">
        <thead>
          <tr>
            <th style="width: 50%;" *ngIf="!isGalicia()">
              <h2><span>Fuente: </span>{{ course.source }}</h2>
            </th>
            <th style="width: 50%;" *ngIf="!isGalicia()">
              <h2><span>Fuente: Galicia</span></h2>
            </th>
          </tr>
        </thead>
        <tr>
          <td>
            <!-- Nombre -->
            <div style="margin-right: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Nombre: </span><span>{{ course.name }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="namePV">Nombre del curso: </label>
            <div style="display: flex; flex-direction: row">
              <div>
                <input type="text" class="form-control" formControlName="namePV" placeholder="Nombre del curso"
                  size="150" />
              </div>

              <div>
                <button style="margin-left: 2px;" *ngIf="!isGalicia()" class="btn btn-info"
                  (click)="copy('name', 'namePV')" tooltip="Copiar nombre">
                  <i class="fa fa-files-o" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Duración -->
            <div style="margin-bottom: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Duración: </span><span>{{ course.duration }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="durationPV">Duración del curso: </label>
            <div style="display: flex; flex-direction: row">
              <div>
                <input type="text" class="form-control" formControlName="durationPV" placeholder="Duración" />
              </div>
              <div>
                <button style="margin-left: 2px;" *ngIf="!isGalicia()" class="btn btn-info"
                  (click)="copy('duration', 'durationPV')" tooltip="Copiar duración">
                  <i class="fa fa-files-o" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Referencia -->
            <div style="margin-bottom: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Referencia: </span><span>{{ course.reference }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="referencePV">Referencia: </label>
            <div style="display: flex; flex-direction: row">
              <input type="text" class="form-control" formControlName="referencePV" placeholder="Referencia" />
              <button style="margin-left: 2px;" *ngIf="!isGalicia()" class="btn btn-info"
                (click)="copy('reference', 'referencePV')" tooltip="Copiar referencia">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Titulación -->
            <div style="margin-bottom: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Titulación: </span><span>{{ course.titulation }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="titulationPV">Titulación: </label>
            <div style="display: flex; flex-direction: row;">
              <input type="text" class="form-control" formControlName="titulationPV" placeholder="Titulación" />
              <button *ngIf="!isGalicia()" style="margin-left: 2px;" class="btn btn-info"
                (click)="copy('titulation', 'titulationPV')" tooltip="Copiar titulación">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Descripción -->
            <div style="margin-bottom: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Descripción: </span><span>{{ course.description }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="descriptionPV">Descripción del curso: </label>
            <div style="display: flex; flex-direction: row;">
              <textarea class="form-control" formControlName="descriptionPV" placeholder="Descripción"
                rows="6"></textarea>
              <button style="margin-left: 2px;" *ngIf="!isGalicia()" class="btn btn-info"
                (click)="copy('description', 'descriptionPV')" tooltip="Copiar descripción">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Descripción breve -->
            <div style="margin-bottom: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Descripción Breve:
                </span><span>{{ course.shortDescription }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="shortDescriptionPV">Descripción breve: </label>
            <div style="display: flexbox; flex-direction: row;">
              <textarea class="form-control" formControlName="shortDescriptionPV" placeholder="Descripción breve"
                rows="3"></textarea>
              <button style="margin-left: 2px;" *ngIf="!isGalicia()" class="btn btn-info"
                (click)="copy('shortDescription', 'shortDescriptionPV')" tooltip="Copiar descripción breve">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Modo -->
            <div *ngIf="!isGalicia()" style="margin-bottom: 10px">
              <span style="font-weight: bold;">Modo: </span><span>{{ course.mode }}</span><br />
            </div>
          </td>
          <td>
            <div style="margin-bottom: 10px">
              <label for="modePV">Modo: </label>
              <select style="width: 150px;" class="form-control" type="text" formControlName="modePV">
                <option value="presencial">Presencial</option>
                <option value="distancia">Distancia</option>
                <option value="mixto">Mixto</option>
                <option value="online" selected>Online</option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <!-- Precio -->
            <div style="margin-bottom: 10px">
              <div *ngIf="!isGalicia()">
                <span style="font-weight: bold;"> Precio: </span><span>{{ course.price }}</span><br />
              </div>
            </div>
          </td>
          <td>
            <label for="pricePV">Precio del curso: </label>
            <div style="display: flexbox; flex-direction: row;">
              <input type="text" class="form-control" formControlName="pricePV" placeholder="Precio" />
              <button *ngIf="!isGalicia()" style="margin-left: 2px;" class="btn btn-info"
                (click)="copy('price', 'pricePV')" tooltip="Copiar precio">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
          </td>
          <td>
            <label for="pricePV">Orden: </label>
            <div style="display: flexbox; flex-direction: row;">
              <input type="text" class="form-control" formControlName="orderBy" placeholder="Orden" />
              <button *ngIf="!isGalicia()" style="margin-left: 2px;" class="btn btn-info"
                (click)="copy('price', 'pricePV')" tooltip="Copiar precio">
                <i class="fa fa-files-o" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <img [src]="course.img" [alt]="course.name" width="100%" *ngIf="
                (course.source === 'ieditorial' && course.img) ||
                (course.source === 'vertice' &&
                  (!course.media || !course.media[0]))
              " style="max-height: 300px; object-fit: cover;" />
          </td>
          <td>
            <div class="form-group">
              <label>Foto: </label><br />
              <!-- Mostrar imagen del curso galicia -->
              <img [src]="course.img" [alt]="course.name" width="100%" *ngIf="course.source === 'galicia' && course.img"
                style="max-height: 300px; object-fit: scale-down;" />
              <pv-filepicker transformations="800,450,card,1" [preview]="true" [manual]="true">
              </pv-filepicker>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div formArrayName="families">
              <label for="">Familias: </label>
              <div style="padding: 5px" *ngFor="let item of families.controls; let i = index" fxLayout="row"
                fxLayoutGap="10px">
                <div fxFlex>
                  <ng-select [items]="subfamiliesList" [selectOnTab]="true" bindValue="_id" bindLabel="name"
                    placeholder="Seleccione Familia" [formControl]="item"></ng-select>
                </div>
                <div>
                  <button type="button" (click)="removeFamily(i)" class="btn btn-danger">
                    X
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12" style="padding: 0px 20px">
                  <button class="btn btn-success" (click)="addFamily()">
                    AÑADIR
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div formArrayName="tags">
              <label for="">Etiquetas: </label>
              <div style="padding: 5px" *ngFor="let item of tags.controls; let i = index" fxLayout="row"
                fxLayoutGap="10px">
                <div fxFlex>
                  <ng-select [items]="tagsList" [selectOnTab]="true" bindValue="_id" bindLabel="name"
                    placeholder="Seleccione Etiqueta" [formControl]="item"></ng-select>
                </div>
                <div>
                  <button type="button" (click)="removeTag(i)" class="btn btn-danger">
                    X
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12" style="padding: 0px 20px">
                  <button class="btn btn-success" (click)="addTag()">
                    AÑADIR
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label for="activo">Activo
              <input class="form-control" type="checkbox" formControlName="active" id="activo" />
            </label>
          </td>
          <td>
            <!-- <label for="sourcePV">Fuente: </label>
            <select style="width: 150px;" class="form-control" type="text" formControlName="sourcePV">
              <option value="presencial">Presencial</option>
              <option value="distancia">Distancia</option>
              <option value="mixto">Mixto</option>
              <option value="online" selected>Online</option>
            </select> -->
          </td>
        </tr>
      </table>

      <!-- <div style="margin-bottom: 10px"> -->

      <!-- </div> -->
    </tab>
    <tab heading="Contenido">
      <table>
        <tr>
          <td *ngIf="!isGalicia()">
            <div [innerHTML]="course.syllabus" style="padding: 10px"></div>
          </td>
          <td>
            <div>
              <label for="syllabusPV">Contenido del curso: </label>
              <div style="display: flexbox; flex-direction: row;">
                <ckeditor formControlName="syllabusPV" debounce="500" [config]="ckConfig">
                </ckeditor>
                <button style="margin-left: 2px;" *ngIf="!isGalicia()" class="btn btn-info"
                  (click)="copy('syllabus', 'syllabusPV')" tooltip="Copiar contenido">
                  <i class="fa fa-files-o" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </tab>
    <tab heading="Metadatos">
      <div formArrayName="metadata">
        <div class="row" [formGroupName]="i" style="padding: 5px" *ngFor="let item of array.controls; let i = index">
          <div class="col-xs-4">
            <input class="form-control" type="text" formControlName="key" />
          </div>
          <div class="col-xs-2">
            <select class="form-control" formControlName="type">
              <option value="text">Texto</option>
              <option value="number">Número</option>
              <option value="boolean">Checkbox</option>
            </select>
          </div>
          <div class="col-xs-4">
            <input class="form-control" type="text" formControlName="value" *ngIf="item.get('type').value === 'text'" />
            <input class="form-control" type="number" formControlName="value"
              *ngIf="item.get('type').value === 'number'" />
            <div class="checkbox" *ngIf="item.get('type').value === 'boolean'">
              <label>
                <input type="checkbox" formControlName="value" />
                Valor
              </label>
            </div>
          </div>
          <div class="col-xs-2">
            <button type="button" (click)="remove(i)" class="btn btn-danger">
              X
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" style="padding: 0px 20px">
            <button class="btn btn-success" (click)="add()">AÑADIR</button>
          </div>
        </div>
      </div>
    </tab>
    <tab heading="Relacionados">
      <div style="margin-top: 10px"></div>
      <div class="row">
        <div class="col-md-12">
          <h3>Relacionados</h3>
        </div>
        <div class="col-md-4" *ngFor="let related of course.related">
          <div class="cms-card" style="padding: 10px" (click)="goToCourse(related)" style="cursor: pointer">
            <img [src]="related.img" [alt]="related.name" style="width: 100%; height: 250px; object-fit: cover" />
            <div style="line-height: 20px; height: 40px;">
              {{ related.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Familias</h3>
        </div>
        <div class="col-md-4" *ngFor="let family of course.families">
          <div class="cms-card" style="padding: 10px">
            <div style="line-height: 20px; height: 40px;">
              {{ family.name }}
            </div>
          </div>
        </div>
      </div>
    </tab>
    <tab heading="Data">
      <div style="margin-top: 10px"></div>
      <pre>
          <div>{{ course | json }}</div>
      </pre>
    </tab>
  </tabset>
  <button class="btn btn-danger pull-right" (click)="openModal(template)" *ngIf="isGalicia()">
    ELIMINAR
  </button>
  <button class="btn btn-primary pull-right" (click)="save()">
    GUARDAR
  </button>
  <button class="btn btn-primary pull-right" (click)="upload()">
    UPLOAD
  </button>
</cms-admin>
<ng-template #template>
  <div class="modal-body text-center">
    <p>¿Confirma la eliminación del curso?</p>
    <button type="button" class="btn btn-default" (click)="confirm()">
      Sí
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      No
    </button>
  </div>
</ng-template>
