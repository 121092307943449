<cms-admin>
  <cms-section-title
    >Noticias</cms-section-title
  >
  <div [ngClass]="{ container: !env.elements }">
    <div class="row">
      <div
        class="col-md-3 no-padding"
        style="padding-left: 0px; padding-right: 10px"
      >
        <h3>RSS FEEDS</h3>
        <br />
        <nav>
          <ul>
            <li
              [class.active]="source._id === currentFilter"
              *ngFor="let source of sources"
            >
              <a (click)="setSource(source)">{{ source.name }}</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-9 no-padding" id="cms-geoguide-container">
        <div class="row" *ngIf="loaded">
          <div class="col-md-6">
            <h4>{{ source.name }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngFor="let row of rows">
            <admin-rss-chooser-item
              [rssNew]="row"
              [regions]="regions"
              [articleSelector]="articleSelector"
            ></admin-rss-chooser-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</cms-admin>
