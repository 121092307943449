<cms-admin>
  <cms-section-title
    (query)="onQuery($event)"
    (queryBySource)="onQueryBySource($event)"
    [showExcel]="true"
    [showSearchBySource]="true"
    (add)="onAdd()"
    (excel)="onExport($event)"
    >Cursos
  </cms-section-title>
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
    [externalPaging]="true"
    (page)="onPage($event)"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.namePV || row.name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fuente
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <cms-source [row]="row"></cms-source>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Duración (h)
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.duration }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right actions">
          <button
            class="btn btn-primary"
            (click)="update(row)"
            tooltip="Ver / Editar"
            placement="top"
          >
            E
          </button>
          <button
            class="btn btn-info"
            (click)="checkLink(row)"
            tooltip="Comprobar Enlace"
            placement="top"
          >
            <i class="fa fa-link" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
