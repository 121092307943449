<div class="row cms-card">
  <div class="col-xs-12 cms-card-content">
    <div style="padding: 5px;">
      <h4 class="cms-card-header">
        {{ course.namePV || course.name | removeReference }}
      </h4>
    </div>
    <div class="col-xs-12" style="padding: 0px; margin-bottom: -40px">
      <img
        class="cms-card-picture"
        [src]="course.img"
        [alt]="course.name"
        width="100%"
        *ngIf="course.img"
      />
      <div
        class="figure-caption"
        [style.backgroundColor]="theme.translucent | color"
        *ngIf="!!course.duration"
      >
        <span class="cms-card-body-font" *ngIf="!course.credits || course.credits < 0"
          ><span style="font-size: 10px;">Modalidades</span><span class="cms-duration">{{ courses.getVariants(course).first_duration }}</span>
          <span class="cms-duration">{{ courses.getVariants(course).second_duration }}</span>
          <span class="cms-duration">{{ courses.getVariants(course).third_duration }}</span> - Titulo IEFP
        </span>
        <span class="cms-card-body-font" *ngIf="course.credits"
        ><span style="font-size: 10px;">Modalidades</span><span class="cms-duration">{{ courses.getVariants(course).first_duration }}</span>
        <span class="cms-duration">{{ courses.getVariants(course).second_duration }}</span>
        <span class="cms-duration">{{ courses.getVariants(course).third_duration }}</span> - Titulo I - {{course.credits}} creditos U. de Nebrija
      </span>
      </div>
      <div class="empty-caption" *ngIf="!course.duration">
        <span class="cms-card-body-font">horas</span>
      </div>
    </div>
    <div class="col-xs-12 cms-card-body-container">
      <div class="cms-card-body">
        <div>{{ course.shortDescriptionPV || course.shortDescription }}</div>
        <div [ngSwitch]="course.source">
          <div *ngSwitchCase="'ieditorial'" class="course-source-badge">
            I
          </div>
          <div *ngSwitchCase="'vertice'" class="course-source-badge">
            V
          </div>
          <div *ngSwitchCase="'espacio'" class="course-source-badge">
            E
          </div>
          <div *ngSwitchCase="'galicia'" class="course-source-badge">
            G
          </div>
          <div *ngSwitchCase="'fcontenido'" class="course-source-badge">
            F
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <hr style="margin-top: 7px" />
    </div>
    <div class="col-xs-5 cms-card-actions-secondary">
      <a target="_blank">
        <button
          type="button"
          class="btn btn-default btn-block cms-card-body-font"
          (click)="info()"
        >
          <i class="fa fa-info" aria-hidden="true"></i>
          + INFO
        </button>
      </a>
    </div>
    <div class="col-xs-7 cms-card-actions-primary">
      <a target="_blank">
        <button
          type="button"
          class="btn btn-block cms-card-body-font"
          (click)="book()"
          [style.backgroundColor]="theme.color | color"
          style="padding: 6px; color: white"
        >
          ME INTERESA
        </button>
      </a>
    </div>
    <div class="col-xs-12" style="margin: 10px"></div>
  </div>
</div>
