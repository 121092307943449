import { Component, OnInit } from '@angular/core';
import { Provider } from '../../interfaces/provider';
import { ProviderService } from '../../services/provider.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cms-provider-detail',
  templateUrl: './provider-detail.component.html',
})
export class ProviderDetailComponent implements OnInit {
  provider: Provider = {
    name: '',
    url: '',
    ref: '',
    titulation: '',
    description: '',
    shortDescription: '',
    mode: '',
    syllabus: '',
    price: '',
    duration: '',
    img: '',
    familyList: '',
    families: '',
    tags: '',
    familySource: '',
    coursePagination: '',
    urlPages: '',
    courseList: '',
    media: '',
    related: '',
    goals: '',
    courseName: '',
    scrapperType: 'courses',
  };
  edit: boolean = false;

  constructor(
    private providerService: ProviderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.params;
    if (params) {
      this.providerService.getProviderById(params.id).subscribe(
        (res) => {
          this.provider = res;
          this.edit = true;
        },
        (err) => console.log(err)
      );
    }
  }

  submitProvider() {
    this.providerService.createProvider(this.provider).subscribe(
      (res) => {
        console.log(res);
        this.router.navigate(['/admin/scrapper/providers']);
      },
      (err) => console.log(err)
    );
  }

  updateProvider() {
    this.providerService
      .updateProvider(this.provider._id, this.provider)
      .subscribe(
        (res) => {
          console.log(res);
          this.router.navigate(['/admin/scrapper/providers']);
        },
        (err) => console.log(err)
      );
  }
}
