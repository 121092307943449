import { Component, Input } from '@angular/core';

import { GeoDestinoType } from '../../models/geodestino.model';
import { GeodestinosService } from '../../providers/geodestinos.service';

@Component({
  selector: 'cms-geodestino',
  templateUrl: './geodestino.component.html'
})
export class GeoDestinoComponent {
  @Input() q: string;
  @Input() code: string;
  @Input() city: string;
  @Input() family: string;

  @Input() initial: GeoDestinoType = 'hotel';
  @Input() hide = false;

  constructor(public geodestinos: GeodestinosService) {}

  isSectionActive(type: GeoDestinoType): boolean {
    return this.geodestinos.isSectionActive(type);
  }
}
