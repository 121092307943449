import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { Page } from '../models/seo.model';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private static keywords =
    'e-learning,cursos,bonificados,formacion,empresas,fundae,online,scorm,educacion,online,contenidos';
  private static description =
    'Comercialización de contenidos e-learming en formato SCORM 1.2. La mayor oferta nacional de contenidos didácticos online';

  _baseTitle = 'Contenidos E-Learning';

  constructor(private titleService: Title, private metaService: Meta) {}

  set baseTitle(title: string) {
    this._baseTitle = title;
  }

  set title(title: string) {
    this.titleService.setTitle(title || this._baseTitle);
  }

  set description(description: string) {
    const currentMetaDescription = this.metaService.getTag('name=description');
    if (currentMetaDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: description
      });
    } else {
      this.metaService.addTag({
        name: 'description',
        content: description
      });
    }
  }

  set keywords(keywords: string) {
    const currentMetaKeywords = this.metaService.getTag('name=keywords');
    const currentKeywords = currentMetaKeywords
      ? currentMetaKeywords.content.split(',')
      : [];
    const newKeywords = keywords
      ? keywords.split(',')
      : SeoService.keywords.split(',');

    const nextKeywords = newKeywords.reduce(
      (acc, key) => (acc.indexOf(key) >= 0 ? acc : [...acc, key]),
      currentKeywords
    );

    if (currentMetaKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: nextKeywords.join(',')
      });
    } else {
      this.metaService.addTag({
        name: 'keywords',
        content: nextKeywords.join(',')
      });
    }
  }

  setPage(page: Page = {}) {
    // TITLE
    this.title = page.title || this._baseTitle;

    // KEYWORDS
    if (page.replaceKeywords) {
      this.cleanKeywords();
    }
    this.keywords = page.keywords || SeoService.keywords;

    // DESCRIPTION
    this.description = page.description || SeoService.description;
  }

  private cleanKeywords() {
    const currentMetaKeywords = this.metaService.getTag('name=keywords');
    if (currentMetaKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: ''
      });
    }
  }
}
