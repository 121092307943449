<div class="new image new-summary">
  <div class="new-header">
    <h2 class="new-title">
      <a class="blog-title-link blog-link" [href]='getLink()' [innerHTML]="(new && new.title) ? cutText(new.title): ''"></a>
    </h2>
    <p class="new-date">
      <span class="new-text">
        {{ (new && new.date ) ? new.date : ''}}
      </span>
    </p>
  </div>
  <div class="new-content">
    <div>
      <img class="copyblog-img"
        [src]="(new && new.img) ? new.img : ''"/>
    </div>
    <div class="new-bottom">
      <a
        class="wsite-button wsite-button-large wsite-button-highlight"
        target="_blank"
        [href]="getLink()"
      >
        <span class="wsite-button-inner">Leer más</span>
      </a>
    </div>
  </div>
</div>
