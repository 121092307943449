/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/ngx-bootstrap/alert/ngx-bootstrap-alert.ngfactory";
import * as i2 from "ngx-bootstrap/alert";
import * as i3 from "@angular/common";
import * as i4 from "./messages.component";
var styles_MessagesComponent = [];
var RenderType_MessagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessagesComponent, data: {} });
export { RenderType_MessagesComponent as RenderType_MessagesComponent };
function View_MessagesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.AlertComponent, [i2.AlertConfig, i0.ChangeDetectorRef], { type: [0, "type"], dismissible: [1, "dismissible"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.type; var currVal_1 = (_v.parent.context.$implicit.options.dismissible || false); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.message; _ck(_v, 2, 0, currVal_2); }); }
function View_MessagesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.AlertComponent, [i2.AlertConfig, i0.ChangeDetectorRef], { type: [0, "type"], dismissOnTimeout: [1, "dismissOnTimeout"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.type; var currVal_1 = _v.parent.context.$implicit.options.timeout; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.message; _ck(_v, 2, 0, currVal_2); }); }
function View_MessagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessagesComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessagesComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.options.timeout; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.options.timeout; _ck(_v, 4, 0, currVal_1); }, null); }
function View_MessagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessagesComponent_2)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MessagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessagesComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alerts.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MessagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-messages", [], null, null, null, View_MessagesComponent_0, RenderType_MessagesComponent)), i0.ɵdid(1, 49152, null, 0, i4.MessagesComponent, [], null, null)], null, null); }
var MessagesComponentNgFactory = i0.ɵccf("cms-messages", i4.MessagesComponent, View_MessagesComponent_Host_0, {}, {}, []);
export { MessagesComponentNgFactory as MessagesComponentNgFactory };
