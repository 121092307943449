/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../new/new.component.ngfactory";
import * as i3 from "../new/new.component";
import * as i4 from "../../../shared/models/environment";
import * as i5 from "../../providers/rss-new.service";
import * as i6 from "@angular/common";
import * as i7 from "./news-list.component";
var styles_NewsListComponent = [i0.styles];
var RenderType_NewsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsListComponent, data: {} });
export { RenderType_NewsListComponent as RenderType_NewsListComponent };
function View_NewsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-new", [], null, null, null, i2.View_NewComponent_0, i2.RenderType_NewComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewComponent, [i4.CMS_ENV, i5.RssNewService], { rssNew: [0, "rssNew"], postLink: [1, "postLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.postlink; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NewsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsListComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.list; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClassName(); _ck(_v, 0, 0, currVal_0); }); }
export function View_NewsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-news-list", [], null, null, null, View_NewsListComponent_0, RenderType_NewsListComponent)), i1.ɵdid(1, 114688, null, 0, i7.NewsListComponent, [i4.CMS_ENV, i5.RssNewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsListComponentNgFactory = i1.ɵccf("cms-news-list", i7.NewsListComponent, View_NewsListComponent_Host_0, { columns: "columns", region: "region", location: "location", postlink: "postlink" }, {}, []);
export { NewsListComponentNgFactory as NewsListComponentNgFactory };
