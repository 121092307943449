import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  BaseFileUploader,
  FileUploadOptions,
  FileUploader,
  FileUpload,
  TOKEN,
  BASE_URL,
} from '../models/files.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class FilesService {
  constructor(
    @Inject(TOKEN) private token: string,
    @Inject(BASE_URL) private baseUrl: string
  ) {}

  getUploader(
    options?: FileUploadOptions,
    _url: string = null
  ): Observable<FileUploader> {
    let url =
      _url || this.baseUrl || 'https://next.api.cms.galicia.info/files/upload';
    if (
      options &&
      options.transformations &&
      options.transformations.length > 0
    ) {
      url += `?transformations=${options.transformations}`;
    }
    return of(
      new BaseFileUploader({
        url,
        authToken: this.token,
      })
    ).pipe(
      map((uploader: FileUploader) => {
        uploader.events = new Observable<FileUpload>((observer) => {
          uploader.onCompleteItem = (_, path, status, __) => {
            observer.next({
              path: path.replace(/"/g, ''),
              status,
            });
          };
        });

        return uploader;
      })
    );
  }
}
