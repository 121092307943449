import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { CoursePlatform } from '../models/platform.course';
import { Entrust } from '../models/entrust.model';

import { CoursePlatformsService } from './platforms.service';

@Injectable({
  providedIn: 'root'
})
export class EntrustService {
  accountNumberRE = /ES\d{2}[\s-]{1}\d{4}[\s-]\d{4}[\s-]\d{4}[\s-]\d{4}[\s-]\d{4}|ES\d{22}|ES\d{2}[\s-]\d{4}[\s-]\d{4}[\s-]\d{2}[\s-]\d{10}/;
  endpoint = '/entrusts';

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv,
    private platforms: CoursePlatformsService,
    private http: HttpClient
  ) {}

  find(
    options: any = { page: 1, per_page: 10, top: true }
  ): Observable<{
    list: Entrust[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<Entrust[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  findOne(entrustId: string): Observable<any> {
    return this.http.get(`${this.env.baseUrl}/entrust/ok/${entrustId}`);
  }

  checkCompanyVat(vat: string): Observable<any> {
    const params = new HttpParams().set('vat', vat);

    return this.http
      .get(`${this.env.baseUrl}/entrust/company`, {
        params
      })
      .pipe(
        map((company: any) => {
          if (company) {
            return {
              company: {
                businessName: company.adh_razon_social,
                sector: company.adh_actividad,
                phone: company.adh_telefono,
                state: company.adh_provincia,
                zipCode: company.adh_codigo_postal,
                contactPerson: company.Persona_de_Contacto,
                employees: company.adh_plantilla_media,
                year: company.adh_ano_creacion,
                city: company.adh_poblacion,
                agreement: company.adh_convenio_col,
                ssAccount: company.adh_cuenta_cotizacion,
                email: company.Correo_electronico,
                address: company.adh_domicilio_social,
                since: company.adh_ano_creacion
              },
              representative: {
                name: company.adh_rep_nombre1,
                vatIdentifier: company.adh_DNI_representante_legal
              }
            };
          }

          return company;
        })
      );
  }

  checkStudentVat(vat: string): Observable<any> {
    const params = new HttpParams().set('vat', vat);

    return this.http
      .get(`${this.env.baseUrl}/entrust/student`, {
        params
      })
      .pipe(
        map((student: any) => {
          if (student) {
            return {
              email: student.fEmailAlu,
              phone: student.accion_formativa_movil,
              name: student.fNombreAlumno,
              subname: student.fPrimerApe,
              subname2: student.fSegundoApe,
              birthdate: this.dateFromString(student.fFechaNacAlu, '/'),
              ssNumber: student.fNumSSocialAlu,
              studies: student.fNivelEstudios,
              cotization: student.fGrupoCotiz,
              sex: student.fSexoAlumno
            };
          }

          return student;
        })
      );
  }

  pushOnlyEntrust(
    entrust: any,
    platform: CoursePlatform,
    agent?: string
  ): Observable<{ _id: string }> {
    const payload = this.getEntrustFormData(entrust, platform, agent);

    if (this.env.production) {
      return this.http.post<{ _id: string }>(
        `${this.env.baseUrl}/entrust/only`,
        payload
      );
    } else {
      console.log(payload);
      return of({ _id: '' });
    }
  }

  pushYesEntrust(
    entrust: any,
    platform: CoursePlatform,
    agent?: string
  ): Observable<Array<{ _id: string }>> {
    const payload = {
      students: entrust.students.map(student => ({
        ...this.platforms.definition(platform),
        fCIFNIF: entrust.company.vatIdentifier,
        fRazonSocial: entrust.company.businessName,
        fEmail: entrust.company.email,
        fNIFAlu: student.vatIdentifier,
        fNumSSocialAlu: student.ssNumber,
        fFechaNacAlu: this.dateToString(student.birthdate),
        fEmailAlu: student.email,
        accion_formativa_movil: String(student.phone || '').replace(
          /[^0-9]/g,
          ''
        ),
        ...this.parseAccountNumber(entrust.company.accountNumber),
        fNombreAlumno: student.name,
        fPrimerApe: student.subname,
        fSegundoApe: student.subname2,
        fNivelEstudios: student.studies,
        fGrupoCotiz: student.cotization,
        fCodCurso: '-',
        fDenomCurso: student.course,
        Observaciones: student.observations,
        frepresentante: entrust.representative.name,
        frepresentantecifnif: entrust.representative.vatIdentifier,
        Acepto_terminos: entrust.privacy ? 'true' : 'false',
        Confirmo_novedades: entrust.newsletter ? 'true' : 'false',
        Asesor: agent ? agent : '-',
        fdireccionempresa: entrust.company.address,
        fpoblacionempresa: entrust.company.city,
        fcodigopostalempresa: entrust.company.zipCode,
        fprovinciaempresa: entrust.company.state,
        Delegado_Sindical: entrust.company.delegate ? 'Si' : 'No',
        adh_plantilla_media: entrust.company.employees,
        accion_formativa_sexo: student.sex,
        fNacionalidad: student.nationality,
        ...this.parsePoll(student.poll)
      }))
    };
    if (this.env.production) {
      return this.http.post<Array<{ _id: string }>>(
        `${this.env.baseUrl}/entrust/yes`,
        payload
      );
    } else {
      console.log(payload);
      return of([{ _id: '' }]);
    }
  }

  pushNoEntrust(
    entrust: any,
    platform: CoursePlatform
  ): Observable<Array<{ _id: string }>> {
    const payload = {
      students: entrust.students.map(student => ({
        ...this.platforms.definition(platform),
        fCIFNIF: entrust.company.vatIdentifier,
        fRazonSocial: entrust.company.businessName,
        fEmail: entrust.company.email,
        fNumSSocial: entrust.company.address,
        Observaciones_Empresa: entrust.company.observations,
        fDNIAlu: student.vatIdentifier,
        fEmailAlu: student.email,
        accion_formativa_movil: String(student.phone || '').replace(
          /[^0-9]/g,
          ''
        ),
        fTelefonoEmpresa: String(entrust.company.phone || '').replace(
          /[^0-9]/g,
          ''
        ),
        fNombreAlumno: student.name,
        fApellidos: student.subname + ' ' + student.subname2,
        accion_formativa_sexo: student.sex,
        fNombreCurso: student.course,
        Observaciones_alumno: student.observations,
        frepresentante: entrust.representative.name,
        frepresentantecifnif: entrust.representative.vatIdentifier,
        Acepto_terminos_privacidad: entrust.privacy ? 'true' : 'false',
        Confirmo_recibir_info: entrust.newsletter ? 'true' : 'false',
        fNacionalidad: student.nationality,
        ...this.parsePoll(student.poll)
      }))
    };
    if (this.env.production) {
      return this.http.post<Array<{ _id: string }>>(
        `${this.env.baseUrl}/entrust/no`,
        payload
      );
    } else {
      console.log(entrust);
      console.log(payload);
      return of([{ _id: '' }]);
    }
  }

  private parseAccountNumber(
    accountNumber: string
  ):
    | {
        fIBAN: string;
        fEntidad: string;
        fSucursal: string;
        fDigito_Control: string;
        fNumeroCuenta: string;
      }
    | {} {
    if (!accountNumber || !this.accountNumberRE.test(accountNumber)) {
      return {};
    }

    const accountWithNoSymbols = String(accountNumber || '')
      .toLocaleUpperCase()
      .replace(/[^0-9ES]/g, '');

    return {
      fIBAN: accountWithNoSymbols.substr(0, 4),
      fEntidad: accountWithNoSymbols.substr(4, 4),
      fSucursal: accountWithNoSymbols.substr(8, 4),
      fDigito_Control: accountWithNoSymbols.substr(12, 2),
      fNumeroCuenta: accountWithNoSymbols.substr(14)
    };
  }

  parsePoll(poll){
    if(poll){
      return {
        titulos_poseidos: poll.title,
        graduacion: poll.graduation_year,
        primera_vez_universidad: poll.first_time,
        relacion_ucm: poll.ucm_link,
        situacion_laboral: poll.actual_job,
        tipo_trabajo: poll.job_type,
        ocupacion: poll.actual_occupation,
        sector_empresa: poll.sector,
        puesto: poll.job,
        motivacion: poll.motivation,
        motivacion_ucm: poll.ucm_motivation,
        motivacion_curso: poll.course_motivation
      }
    }else{
      return {};
    }
  }

  private getEntrustFormData(entrust, platform, agent = null) {
    return {
      ...this.platforms.definition(platform),
      comercial: agent ? agent : '-',
      adh_domicilio_social: entrust.company.address,
      adh_razon_social: entrust.company.businessName,
      adh_poblacion: entrust.company.city,
      Persona_de_Contacto: entrust.company.contactPerson,
      Correo_electronico: entrust.company.email,
      adh_plantilla_media: entrust.company.employees,
      adh_telefono: String(entrust.company.phone || '').replace(/[^0-9]/g, ''),
      adh_movil: String(entrust.company.phone2 || '').replace(/[^0-9]/g, ''),
      adh_convenio_col: entrust.company.agreement,
      adh_cuenta_cotizacion: entrust.company.ssAccount,
      adh_actividad: entrust.company.sector,
      adh_ano_creacion: isNaN(entrust.company.since)
        ? new Date().getFullYear()
        : entrust.company.since,
      adh_provincia: entrust.company.state,
      adh_CIF: entrust.company.vatIdentifier,
      adhesion_ano: this.checkYear(entrust.company.year),
      adh_codigo_postal: entrust.company.zipCode,
      adh_rep_nombre1: entrust.representative.name,
      adh_DNI_representante_legal: entrust.representative.vatIdentifier,
      Acepto_terminos_privacidad: entrust.privacy ? 'true' : 'false',
      Confirmo_recibir_info: entrust.newsletter ? 'true' : 'false'
    };
  }

  private checkYear(input: number): number {
    const today = new Date();
    const year = today.getFullYear();

    if (!input || input < year) {
      return year;
    }

    if (input > year + 1) {
      return year + 1;
    }

    return input;
  }

  dateToString(date: Date, divider = '/'): string {
    const day = date.getDate();
    const strDate = day < 10 ? `0${day}` : `${day}`;
    const month = date.getMonth() + 1;
    const strMonth = month < 10 ? `0${month}` : `${month}`;
    const year = date.getFullYear();

    return `${strDate}${divider}${strMonth}${divider}${year}`;
  }

  dateFromString(date: string, divider: string = '/'): Date {
    const dividerLength = divider ? divider.length : 0;

    const day = parseInt(date.substr(0, 2), 10);
    const month = parseInt(date.substr(2 + dividerLength, 2), 10) - 1;
    const year = parseInt(date.substr(4 + 2 * dividerLength, 4), 10);
    return new Date(year, month, day);
  }
}
