<label>
  Buscar
  <input type="file" ng2FileSelect [uploader]="uploader" />
</label>
<button
  type="button"
  class="btn btn-success btn-s"
  *ngIf="!manual"
  (click)="uploader.uploadAll()"
  [disabled]="!uploader || !uploader.getNotUploadedItems().length"
>
  <span class="glyphicon glyphicon-upload"></span> Upload all
</button>
<img
  [src]="display"
  alt="Preview"
  width="100px"
  height="100px"
  *ngIf="preview && display"
/>
