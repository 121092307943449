import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { filter, switchMap, first, map } from 'rxjs/operators';

import { FilePickerComponent } from '@portales/files';

import {
  Course,
  CoursesService,
  Family,
  FamiliesService,
  Tag,
  TagsService
} from '@cms-app/courses';
import { MessagingService, CKEDITOR_CONFIG } from '@cms-app/admin/shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'cms-admin-course-detail',
  templateUrl: './course-detail.component.html'
})
export class AdminCourseDetailComponent implements OnInit {
  @ViewChild(FilePickerComponent) public filePicker: FilePickerComponent;

  course: Course;
  courseForm: FormGroup;
  subfamiliesList: Array<Partial<Family>> = [];
  tagsList: Array<Partial<Tag>> = [];
  modalRef: BsModalRef;

  constructor(
    @Inject(CKEDITOR_CONFIG) public ckConfig: any,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private messaging: MessagingService,
    private courses: CoursesService,
    private familiesService: FamiliesService,
    private tagsService: TagsService
  ) {}

  ngOnInit() {
    this.setupCourse(this.route.snapshot.data.course);
    this.route.params
      .pipe(
        filter(params => params.id !== this.course._id || params.id === 'new'),
        switchMap(params => {
          return params.id === 'new'
            ? of(this.courses.empty())
            : this.courses.findOne(params.id);
        })
      )
      .subscribe(course => this.setupCourse(course));

    // FAMILIAS
    this.familiesService
      .find({ fields: 'name,source', per_page: 600 })
      .pipe(
        map(res => res.list),
      )
      .subscribe(list => {
        this.subfamiliesList = list;
      });

    // TAGS
    this.tagsService
      .find({ per_page: 100 })
      .pipe(map(res => res.list))
      .subscribe(list => {
        this.tagsList = list;
      });
    // const original = this.route.snapshot.data.family;

    // if (original) {
    //   this.original = original;
    // }

    // const subfamilies: any[] = this.original ? this.original.families : [];

    // this.family = this.fb.group({
    //   name: this.original ? this.original.name : '',
    //   families: this.fb.array(
    //     subfamilies.map((family: Family) => this.fb.control(family._id))
    //   )
    // });
  }

  get array() {
    return <FormArray>this.courseForm.get('metadata');
  }

  add() {
    (<FormArray>this.courseForm.get('metadata')).push(
      this.fb.group({
        key: 'campo',
        type: 'text',
        value: ''
      })
    );
  }

  remove(idx: number) {
    const metadatas = <FormArray>this.courseForm.get('metadata');

    metadatas.removeAt(idx);
  }

  removeCourse() {
    this.courses.remove(this.course._id).subscribe(
      () => {
        this.messaging.success('Curso eliminado correctamente', 'CMS ADMIN');
        this.router.navigate(['admin', 'iefp', 'courses']);
      },
      () => {
        this.messaging.error('Error eliminado el curso', 'CMS ADMIN');
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    this.modalRef.hide();
    this.removeCourse();
  }

  decline(): void {
    this.modalRef.hide();
  }

  save() {
    if (this.courseForm.valid) {
       if(!this.courseForm.value.modePV){
        this.courseForm.value.modePV = this.course.mode;
      }
      const request = this.filePicker.upload().pipe(
        switchMap(img => {
          const { _id, ...course } = this.course;
          const formValue = this.courseForm.value;
          formValue.families = formValue.families.filter(
            family => family.length > 0
          );
          formValue.tags = formValue.tags.filter(tag => tag.length > 0);
          return this.course._id === null
            ? this.courses.add({
                ...course,
                ...formValue,
                img
              })
            : this.courses.update(this.course._id, {
                ...this.courseForm.value,
                ...(img ? { img } : {})
              });
        }),
        first()
      );

      request.subscribe(
        (saved: Course) => {
          // ACTUALIZACION CORRECTA
          this.messaging.success(
            'Curso actualizado correctamente',
            'CMS ADMIN'
          );

          // redireccionar
          if (!!saved) {
            this.router.navigate(['admin', 'iefp', 'courses', saved._id]);
          }
        },
        () => {
          // ACTUALIZACION ERRONEA
          this.messaging.error('Error actualizando el curso', 'CMS ADMIN');
        }
      );
    }
  }

  upload(){
    this.router.navigate(['admin', 'iefp', 'courses','upload']);
  }

  //FAMILIES
  get families() {
    return <FormArray>this.courseForm.get('families');
  }

  addFamily(familyId = '') {
    this.families.push(this.fb.control(familyId));
  }

  removeFamily(idx: number) {
    this.families.removeAt(idx);
  }

  //TAGS
  get tags() {
    return <FormArray>this.courseForm.get('tags');
  }

  addTag(tagId = '') {
    this.tags.push(this.fb.control(tagId));
  }

  removeTag(idx: number) {
    this.tags.removeAt(idx);
  }

  // ROUTES
  goToCourses() {
    this.router.navigate(['admin', 'iefp', 'courses']);
  }

  goToCourse(related: Course) {
    this.router.navigate(['admin', 'iefp', 'courses', related._id]);
  }

  goToFamily(related: Family) {
    this.router.navigate(['admin', 'iefp', 'families', related._id]);
  }

  // OTHER
  copy(source: string, target: string) {
    this.courseForm.get(target).setValue(this.course[source]);
  }

  isGalicia() {
    return this.course.source == 'galicia';
  }

  // IMPLEMENTATION
  private mapToFormGroup(course: Course): FormGroup {
    const metadata = this.fb.array([]);

    course.metadata.forEach(row => {
      metadata.push(this.fb.group(row));
    });

    const families: any[] = this.course ? this.course.families : [];
    const tags: any[] = this.course ? this.course.tags : [];

    return this.fb.group({
      namePV: [course.namePV],
      descriptionPV: [course.descriptionPV],
      shortDescriptionPV: [course.shortDescriptionPV],
      modePV: [course.modePV],
      pricePV: [course.pricePV],
      durationPV: [course.durationPV],
      syllabusPV: [course.syllabusPV],
      referencePV: [course.referencePV],
      titulationPV: [course.titulationPV],
      orderBy: [course.orderBy],
      active: [course.active],
      metadata,
      families: this.fb.array(
        families.map((family: Family) => this.fb.control(family._id))
      ),
      tags: this.fb.array(tags.map((tag: Tag) => this.fb.control(tag._id)))
    });
  }

  private setupCourse(course: Course = this.courses.empty()) {
    this.course = course;
    this.courseForm = this.mapToFormGroup(this.course);
  }
}
