import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMSEnv, CMS_ENV } from '@cms-app/shared';

import { Course } from '../models/course.model';
import { ResponseLong } from '@cms-app/admin/scrapper/interfaces/response';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  private static defaultDuration = 52;
  private static basePicture =
    'https://portales-verticales.s3-eu-west-1.amazonaws.com/logo-iefp.png';
  private static nebrijaPicture = 'https://portales-verticales.s3-eu-west-1.amazonaws.com/logo-nebrija.png';
  endpoint = '/courses';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  find(
    options: any = { page: 1, per_page: 48, top: true }
  ): Observable<{
    list: Course[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 48;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<Course[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body.map(course =>
            this.adaptCourseForCatalogue(course)
          ),
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  search(
    options: any = { page: 1, per_page: 48, top: true }
  ): Observable<{
    list: Course[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 48;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<Course[]>(`${this.env.baseUrl}${this.endpoint}/search`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body.map(course =>
            this.adaptCourseForCatalogue(course)
          ),
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  excelFromSearch(options: any = { page: 1, per_page: 50, top: true }) {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 50;
    options.format = 'xls';
    options.expand = 'families.name,families.top';

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    const url = `${this.env.baseUrl}${
      this.endpoint
    }/search?${params.toString()}`;

    window.open(url, '_blank');
  }

  findOne(course: string): Observable<Course> {
    const params = new HttpParams().set(
      'expand',
      'related,families.name,tags.name'
    );

    return this.http
      .get<Course>(`${this.env.baseUrl}${this.endpoint}/${course}`, {
        params
      })
      .pipe(map(r => this.adaptCourseForCatalogue(r)));
  }

  excel(query: string) {
    window.open(`${this.env.baseUrl}/courses/excel?q=${query}`, '_blank');
  }

  add(payload: Partial<Course>) {
    return this.http.post<Course>(
      `${this.env.baseUrl}${this.endpoint}`,
      payload
    );
  }

  update(course: string, payload: Partial<Course>) {
    return this.http.patch<Course>(
      `${this.env.baseUrl}${this.endpoint}/${course}`,
      payload
    );
  }

  remove(courseId: string) {
    return this.http.delete<void>(
      `${this.env.baseUrl}${this.endpoint}/${courseId}`
    );
  }

  getSources(){
    return this.http.get<string[]>(`${this.env.baseUrl}${this.endpoint}/sources`);
  }

  uploadFile(file: any): Observable<ResponseLong>{
    return this.http.post<ResponseLong>(`${this.env.baseUrl}${this.endpoint}/upload`, file).pipe(map(res => res));
  }

  empty(): Course {
    return {
      _id: null,
      active: true,
      description: null,
      descriptionPV: '',
      duration: null,
      durationPV: 52,
      families: [],
      goals: [],
      tags: [],
      img: '',
      metadata: [],
      mode: 'online',
      name: null,
      namePV: '',
      price: null,
      pricePV: null,
      reference: '',
      referencePV: '',
      related: [],
      shortDescription: null,
      shortDescriptionPV: '',
      syllabus: null,
      syllabusPV: '',
      url: '',
      source: 'galicia',
      ucm: false
    };
  }

  getVariants(course){
    let duration = {
      first_duration: 0,
      second_duration: 0,
      third_duration: 0
    };
   if(course.duration){
      if(course.duration <= 30){
        duration.first_duration = 10;
        duration.second_duration = 20;
        duration.third_duration = 30;
      }else if(course.duration > 30 && course.duration <= 100){
        duration.first_duration = 52;
        duration.second_duration = 60;
        duration.third_duration = 75;
      }else if(course.duration > 100 && course.duration <= 190){
        duration.first_duration = 100;
        duration.second_duration = 120;
        duration.third_duration = 180;
      }else{
        duration.first_duration = 200;
        duration.second_duration = 300;
        duration.third_duration = 400;
      }
      let min = 100000;
      let Lkey = '';
      Object.keys(duration).forEach(key => {
        let resta = course.duration - duration[key];
        if(Math.abs(resta) < min ){
          min = Math.abs(resta);
          Lkey = key;
        }
      });
      duration[Lkey] = duration[Lkey] + '.';
    }
    if(course.durationPV){
      if(course.durationPV <= 30){
        duration.first_duration = 10;
        duration.second_duration = 20;
        duration.third_duration = 30;
      }else if(course.durationPV > 30 && course.duration <= 100){
        duration.first_duration = 52;
        duration.second_duration = 60;
        duration.third_duration = 75;
      }else if(course.durationPV > 100 && course.duration <= 190){
        duration.first_duration = 100;
        duration.second_duration = 120;
        duration.third_duration = 180;
      }else{
        duration.first_duration = 200;
        duration.second_duration = 300;
        duration.third_duration = 400;
      }
      let min = 100000;
      let Lkey = '';
      Object.keys(duration).forEach(key => {
        let resta = course.duration - duration[key];
        if(Math.abs(resta) < min ){
          min = Math.abs(resta);
          Lkey = key;
        }
      });
      duration[Lkey] = duration[Lkey] + '.';
    }
    return duration;
  }

  private adaptCourseForCatalogue(course: Course): Course {
    return {
      ...course,
      duration:
        course.durationPV || course.duration || CoursesService.defaultDuration,
      shortDescription:
        course.shortDescription && course.shortDescription.length > 0
          ? course.shortDescription
          : course.name,
      img: this.checkImageAndSetDefaultIfNotValid(course)
    };
  }

  private checkImageAndSetDefaultIfNotValid(course: Course) {
    if(course.credits){
      return CoursesService.nebrijaPicture;
    }
    if (!course.img) {
      return CoursesService.basePicture;
    }

    if (course.source === 'ieditorial') {
      const mappedImages = [
        'http://ieditorial.net/img/p/es-default-producto_ieditorial.jpg',
        'https://ieditorial.net/img/p/es-default-producto_ieditorial.jpg',
        'http://ieditorial.net.local/img/p/es-default-producto_ieditorial.jpg'
      ];
      if (!course.img || mappedImages.indexOf(course.img) >= 0) {
        return CoursesService.basePicture;
      }
    } else if (course.source === 'vertice') {
      const video = course.media && course.media[0] ? course.media[0] : null;

      if (!video) {
        return CoursesService.basePicture;
      }

      const match = video.match(/embed\/([A-Za-z0-9]*)\??/);

      return match && match[1]
        ? `https://i3.ytimg.com/vi/${match[1]}/hqdefault.jpg`
        : CoursesService.basePicture;
    } else if (course.source === 'galicia') {
      return course.img
        ? `${this.env.picturesUrl}/${course.img}`
        : CoursesService.basePicture;
    }

    return course.img;
  }
}
