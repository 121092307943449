<div [formGroup]="parent.get('company')">
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label for="">CIF / NIF</label>
        <div class="required">
          *
        </div>
        <cms-course-entrust-miniloader
          *ngIf="loading"
        ></cms-course-entrust-miniloader>
        <input
          type="text"
          class="form-control"
          (blur)="onVatBlur()"
          formControlName="vatIdentifier"
        />
        <div ngxErrors="vatIdentifier">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
          <div ngxError="maxlength" when="touched">
            Numero máximo de caracteres excedido
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Razón Social</label>
        <div class="required">
          *
        </div>
        <input
          type="text"
          class="form-control"
          formControlName="businessName"
        />
        <div ngxErrors="businessName">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Email</label>
        <div class="required">*</div>
        <input type="text" class="form-control" formControlName="email" />
        <div ngxErrors="email">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
        <div ngxErrors="email">
          <div ngxError="email" when="touched">
            El email no tiene el formato adecuado
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Teléfono</label>
        <div class="required">
          *
        </div>
        <input type="text" class="form-control" formControlName="phone" />
        <div ngxErrors="phone">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="">Dirección</label>
        <div class="required">*</div>
        <input type="text" class="form-control" formControlName="address" />
        <div ngxErrors="address">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="parent.get('bonus').value !== 'no'">
      <div class="form-group">
        <label for="">Código Postal</label>
        <div class="required">*</div>
        <input type="text" class="form-control" formControlName="zipCode" />
        <div ngxErrors="zipCode">
          <div ngxError="maxlength" when="touched">
            Numero máximo de caracteres excedido
          </div>
          <div ngxError="pattern" when="touched">
            Este campo debe tener un valor numerico
          </div>
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="parent.get('bonus').value !== 'no'">
      <div class="form-group">
        <label for="">Ciudad</label>
        <div class="required">*</div>
        <input type="text" class="form-control" formControlName="city" />
        <div ngxErrors="city">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="parent.get('bonus').value !== 'no'">
      <div class="form-group">
        <label for="">Provincia</label>
        <div class="required">*</div>
        <input type="text" class="form-control" formControlName="state" />
        <div ngxErrors="state">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="parent.get('bonus').value !== 'no'">
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Año de Creación de la Empresa</label>
        <div class="required">
          *
        </div>
        <input type="number" class="form-control" formControlName="since" />

        <div ngxErrors="since">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
          <div ngxError="pattern" when="touched">
            Este campo debe tener un valor numerico
          </div>
          <div ngxError="maxlength" when="touched">
            Numero máximo de caracteres excedido
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="">Convenio Colectivo</label>
        <div class="required">*</div>
        <input type="text" class="form-control" formControlName="agreement" />
        <div ngxErrors="agreement">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group">
        <label for="">Cuenta de cotización a la Seg.Social principal</label>
        <input type="text" class="form-control" formControlName="ssAccount" />
        <div ngxErrors="ssAccount">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="parent.get('bonus').value === 'yes'">
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Número de Cuenta Bancaria para pago</label>
        <input
          type="text"
          class="form-control"
          formControlName="accountNumber"
        />
        <div ngxErrors="accountNumber">
          <div ngxError="pattern" when="touched">
            Debe ser un IBAN válido
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Persona de Contacto</label>
        <div class="required">
          *
        </div>
        <input
          type="text"
          class="form-control"
          formControlName="contactPerson"
        />
        <div ngxErrors="contactPerson">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Plantilla media el Año Anterior</label>
        <div class="required">
          *
        </div>
        <input type="number" class="form-control" formControlName="employees" />
        <div ngxErrors="employees">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
          <div ngxError="pattern" when="touched">
            Este campo debe tener un valor numerico
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Sector</label>
        <div class="required">
          *
        </div>
        <input type="text" class="form-control" formControlName="sector" />
        <div ngxErrors="sector">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12"><div style="margin-top: 20px"></div></div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="">Observaciones</label>
        <textarea
          class="form-control"
          rows="5"
          formControlName="observations"
        ></textarea>
        <div ngxErrors="observations">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [formGroup]="parent.get('representative')">
  <div class="row">
    <div class="col-md-12">
      <h3>Datos del Representante Legal de la Empresa</h3>
      <hr />
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="">Nombre y Apellidos</label>
        <div class="required">
          *
        </div>
        <input type="text" class="form-control" formControlName="name" />
        <div ngxErrors="name">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="">DNI / NIE</label>
        <div class="required">
          *
        </div>
        <input
          type="text"
          class="form-control"
          formControlName="vatIdentifier"
        />
        <div ngxErrors="vatIdentifier">
          <div ngxError="required" when="touched">
            Este campo es obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" [formGroup]="parent">
  <div class="col-md-12">
    <div class="checkbox" [formGroup]="parent.get('company')">
      <label>
        <input type="checkbox" formControlName="delegate" /> ¿La empresa tiene
        delegado sindical?
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input type="checkbox" formControlName="privacy" />
        Acepto los términos de servicio y confirmo que he leído y comprendido la
        <a [href]="privacy" target="_blank">política de privacidad</a>
      </label>
      <div ngxErrors="privacy">
        <div ngxError="required" when="touched">
          Debe aceptar las condiciones de privacidad
        </div>
      </div>
    </div>
    <div class="checkbox">
      <label>
        <input type="checkbox" formControlName="newsletter" />
        Confirmo que quiero recibir novedades ocasionales por correo electrónico
      </label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12"><div style="margin-top: 20px"></div></div>
  <div class="col-md-12">
    <button class="btn btn-default" (click)="previous.emit()">ANTERIOR</button>
    <ng-content></ng-content>
  </div>
</div>
