import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import {
  GeoDestinoType,
  GeoDestino,
  GeoDestinoQuery,
} from '../models/geodestino.model';
import * as GeoDestinoLocations from './geodestinos-locations.constant';
import { GeoLocation } from '../models/geolocation.model';

@Injectable()
export class GeodestinosService {
  static DEFAULT_PER_PAGE = 10;
  static ACTIVE_SECTIONS =
    'hotel,rural,apartamento,camping, pension, salud, deportes, compras, albergue,comer, vivienda';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  fetch(
    section: GeoDestinoType = 'alojamiento',
    query?: Partial<GeoDestinoQuery & { _id: string }>,
    options: any = { page: 1, per_page: 10 }
  ): Observable<{
    list: GeoDestino[];
    count: number;
  }> {
    let params = new HttpParams();
    const checker = this.checkParam(query);

    if (query && query._id) {
      params = params.set('_id', query._id);
    } else {
      if (checker('q')) {
        params = params.set('$or>0>namePV>$like', query.q);
        params = params.set('$or>1>name>$like', query.q);
      }

      if (checker('location')) {
        params = params.set('location', query.location);
      }
      if (checker('promo')) {
        params = params.set('promo', 'true');
      }

      if (checker('location>$in')) {
        params = params.set('location>$in', query['location>$in']);
      }

      if (checker('zipCode')) {
        params = params.set('zipCode', query.code);
      }
    }

    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;
    params = params.set('page', options.page).set('per_page', options.per_page);
    return this.http
      .get<GeoDestino[]>(`${this.env.baseUrl}${this.endpoint(section)}`, {
        params: this.appendSectionFilters(section, params),
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          list: response.body.map((row) => ({
            ...row,
            picture: this.noPhotos(row.img)
              ? `${this.env.assetsUrl}/assets/nophoto.jpg`
              : row.img,
          })),
          count: parseInt(response.headers.get('X-Total-Count'), 10),
        }))
      );
  }

  async searchAllSections(
    query: Partial<GeoDestinoQuery & { _id: string }>,
    options: any = { page: 1, per_page: 10 }
  ) {
    const sections = [
      'alojamiento', 'comer', 'deporte', 'salud', 'compras'
    ];
    let allList:GeoDestino[]=[];
    let count=0;
    for(let section of sections){
      const sect= section as GeoDestinoType;
      const items = this.fetch(sect, query, options).toPromise();
      count = count + (await items).count;
      let list = (await items).list;
      for(let item of list){
        allList.push(item);
      }
    }
    return { list: allList, count: count};
  }

  async getPromos(location: string) {
    const sections = [
      'alojamiento', 'comer', 'deporte', 'salud', 'compras'
    ];
    const locations = await this.getGeoDestinoLocations().toPromise();
    const locList = locations.list.find(r => r.name == location);

    let promoList:GeoDestino[]=[];
      for (let section of sections) {
        const sect = section as GeoDestinoType;
        const list = await this.fetch(
          sect,
          { promo: 'true', 'location>$in': locList.locations.join()},
          { page: 1, per_page: 6 }
        ).toPromise();
        for(let item of list.list){
          promoList.push(item);
        }
        }
     return promoList;
  }

  findOne(section: GeoDestinoType, id: string): Observable<GeoDestino> {
    return this.http.get<GeoDestino>(
      `${this.env.baseUrl}${this.endpoint(section)}/${id}`
    );
  }

  empty(section: GeoDestinoType): GeoDestino {
    let geodestino: GeoDestino;
    switch (section) {
      case 'salud':
        geodestino = {
          _id: null,
          name: '',
          namePV: '',
          type: '',
          typePV: '',
          url: '',
          urlPV: '',
          state: '',
          statePV: '',
          location: '',
          locationPV: '',
          zipCode: '',
          zipCodePV: '',
          coordinates: '',
          coordinatesPV: '',
          img: [''],
          likes: 0,
          galicia: true,
          certCalidad: false,
          certCalidadLink: '',
          fidelity: false,
          fidelityLink: '',
          disable: false,
          description: '',
          address: '',
          addressPV: '',
          phone: [''],
          email: '',
          emailPV: '',
          actType: '',
          actTypePV: '',
          promo: false,
          promoUrl: '',
          promoDiscount: '',
        };
        break;
      case 'deporte':
        geodestino = {
          _id: null,
          name: '',
          namePV: '',
          type: '',
          typePV: '',
          url: '',
          urlPV: '',
          state: '',
          statePV: '',
          location: '',
          locationPV: '',
          zipCode: '',
          zipCodePV: '',
          coordinates: '',
          coordinatesPV: '',
          img: [''],
          likes: 0,
          galicia: true,
          certCalidad: false,
          certCalidadLink: '',
          fidelity: false,
          fidelityLink: '',
          disable: false,
          description: '',
          address: '',
          addressPV: '',
          phone: [''],
          email: '',
          emailPV: '',
          actType: '',
          actTypePV: '',
          promo: false,
          promoUrl: '',
          promoDiscount: '',
        };
        break;
      case 'compras':
        geodestino = {
          _id: null,
          name: '',
          namePV: '',
          type: '',
          typePV: '',
          url: '',
          urlPV: '',
          state: '',
          statePV: '',
          location: '',
          locationPV: '',
          zipCode: '',
          zipCodePV: '',
          coordinates: '',
          coordinatesPV: '',
          img: [''],
          likes: 0,
          galicia: true,
          certCalidad: false,
          certCalidadLink: '',
          fidelity: false,
          fidelityLink: '',
          disable: false,
          description: '',
          address: '',
          addressPV: '',
          phone: [''],
          email: '',
          emailPV: '',
          actType: '',
          actTypePV: '',
          promo: false,
          promoUrl: '',
          promoDiscount: '',
        };
        break;
      default:
        geodestino = {
          _id: null,
          name: '',
          namePV: '',
          type: '',
          typePV: '',
          url: '',
          urlPV: '',
          state: '',
          statePV: '',
          location: '',
          locationPV: '',
          zipCode: '',
          zipCodePV: '',
          coordinates: '',
          coordinatesPV: '',
          img: [''],
          likes: 0,
          galicia: true,
          certCalidad: false,
          certCalidadLink: '',
          fidelity: false,
          fidelityLink: '',
          disable: false,
          description: '',
          address: '',
          addressPV: '',
          phone: [''],
          email: '',
          emailPV: '',
          category: '',
          categoryPV: '',
          promo: false,
          promoUrl: '',
          promoDiscount: '',
        };
    }
    return geodestino;
  }
  patch(section: GeoDestinoType = 'alojamiento', id: string, payload: any) {
    return this.http.patch<GeoDestino>(
      `${this.env.baseUrl}${this.endpoint(section)}/${id}`,
      payload
    );
  }
  add(section: GeoDestinoType = 'alojamiento', payload: any) {
    return this.http.post<GeoDestino>(
      `${this.env.baseUrl}${this.endpoint(section)}`,
      payload
    );
  }

  appendSectionFilters(
    section: GeoDestinoType,
    params: HttpParams
  ): HttpParams {
    switch (section) {
      case 'albergue':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'Albergues');
      case 'pension':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'Pensiones');
      case 'hotel':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'Hoteles');
      case 'vivienda':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'Viviendas Turísticas');
      case 'camping':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'Campings');
      case 'apartamento':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type>$in', 'Apartamentos,Viviendas Turísticas');
      case 'rural':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'Turismo rural');
      case 'salud':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'salud');
      case 'deporte':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'deporte');
      case 'compras':
        return params
          .set('sort', this.appendSortParam(section))
          .set('type', 'compras');
      default:
        return params.set('sort', this.appendSortParam(section));
    }
  }

  isSectionActive(section: GeoDestinoType) {
    return (
      !GeodestinosService.ACTIVE_SECTIONS ||
      GeodestinosService.ACTIVE_SECTIONS.indexOf(section) >= 0
    );
  }

  isSectionEmpty(section: GeoDestinoType, location: string) {
    console.log(
      this.fetch(section, { location: location }, { page: 1, per_page: 10 })
    );
  }

  appendSortParam(section: GeoDestinoType): string {
    const base = '-galicia,-fidelity,-bestchoice';
    switch (section) {
      case 'hotel':
        return `${base},-stars,-name`;
      case 'apartamento':
        return `${base},-stars,-name`;
      case 'pension':
        return `${base},-stars,-name`;
      case 'camping':
        return `${base},-stars,-name`;
      default:
        return `${base}, -name`;
    }
  }

  endpoint(section: GeoDestinoType): string {
    switch (section) {
      case 'quedar':
        return '/bars';
      case 'comer':
        return '/restaurants';
      case 'deporte':
        return '/activities';
      case 'salud':
        return '/activities';
      case 'compras':
        return '/activities';
      case 'visitar':
        return '/activities';
      case 'alojamiento':
      default:
        return '/hotels';
    }
  }

  mapTypeToNumber(section: GeoDestinoType): number {
    return Math.max(
      [
        'alojamiento',
        'comer',
        'quedar',
        'visitar',
        'hacer',
        'informarse',
        'transporte',
        'servicios',
      ].indexOf(section),
      0
    );
  }

  getGeoDestinoLocations(options: any = { page: 1, per_page: 48}):Observable<{
    list: GeoLocation[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 48;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );
    return this.http
      .get<GeoLocation[]>(`${this.env.baseUrl}/locations`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body.map(row =>
            row
          ),
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  findOneGeoLocation(id: string): Observable<GeoLocation> {
    return this.http.get<GeoLocation>(
      `${this.env.baseUrl}/locations/${id}`
    );
  }

  geoLocationPatch(id: string, payload: any) {
    return this.http.patch<GeoLocation>(
      `${this.env.baseUrl}/locations/${id}`,
      payload
    );
  }
  geoLocationAdd(payload: any) {
    return this.http.post<GeoLocation>(
      `${this.env.baseUrl}/locations`,
      payload
    );
  }
  geoLocationRemove(id: string){
    return this.http.delete<GeoLocation>(
    `${this.env.baseUrl}/locations/${id}`);
  }

  getSectionDescription(section: GeoDestinoType): string {
    switch (section) {
      case 'hotel':
        return 'Hoteles';
      case 'albergue':
        return 'Albergues';
      case 'camping':
        return 'Campings';
      case 'pension':
        return 'Pensiones';
      case 'apartamento':
        return 'Apartamentos';
      case 'rural':
        return 'Casas Rurales';
      case 'alojamiento':
        return 'Alojamientos';
      case 'vivienda':
        return 'Viviendas Turísticas';
      case 'comer':
        return 'Restaurantes';
      case 'hacer':
        return 'Hacer';
      default:
        return 'Establecimientos';
    }
  }

  private checkParam(query: any) {
    return (param: string): boolean => {
      return query && query[param] && query[param].length > 0;
    };
  }

  noPhotos(imgs: [string]): boolean {
    if (!imgs || imgs.length < 1) {
      return true;
    }
  }
}
