import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { Family, FamiliesService } from '@cms-app/courses';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { filter, switchMap, first } from 'rxjs/operators';

import { MessagingService } from '@cms-app/admin/shared';

@Component({
  selector: 'cms-admin-families-detail',
  templateUrl: './families-detail.component.html'
})
export class AdminFamiliesDetailComponent implements OnInit {
  family: Family;
  familyForm: FormGroup;

  constructor(
    private familyService: FamiliesService,
    // private fb: FuilderBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private messaging: MessagingService
  ) {}

  ngOnInit() {
    this.setupFamily(this.route.snapshot.data.family);
    this.route.params
      .pipe(
        filter(params => params.id !== this.family._id || params.id === 'new'),
        switchMap(params => {
          return params.id === 'new'
            ? of(this.familyService.empty())
            : this.familyService.findOne(params.id);
        })
      )
      .subscribe(family => this.setupFamily(family));
  }

  saveFamily() {
    if (this.familyForm.valid) {
      const { _id, ...family } = this.family;
      const request =
        this.family._id === null
          ? this.familyService.add({
              ...family,
              ...this.familyForm.value
            })
          : this.familyService.update(this.family._id, this.familyForm.value);

      request.subscribe(
        (saved: Family) => {
          // ACTUALIZACION CORRECTA
          this.messaging.success(
            'Familia actualizada correctamente',
            'CMS ADMIN'
          );

          // redireccionar
          if (!!saved) {
            this.router.navigate(['admin', 'iefp', 'families', saved._id]);
          }
        },
        () => {
          // ACTUALIZACION ERRONEA
          this.messaging.error('Error actualizando la familia', 'CMS ADMIN');
        }
      );

      this.familyService.addFamily(this.family);
      console.log('guardando...', this.family);
    } else {
      console.log('inválido...', this.familyForm);
    }
  }

  setupFamily(family: Family = this.familyService.empty()) {
    this.family = family;
    this.familyForm = new FormGroup({ name: new FormControl(family.name) });
    // this.familyForm = this.fb.group({ name: [''] })
  }
}
