import { Component, Input, OnInit } from "@angular/core";
import { DomParser } from 'dom-parser';
import { ActivatedRoute } from "@angular/router";
import { RssNew } from "@cms-app/rss/models/rss-new.model";
import { RssNewService } from "@cms-app/rss/providers/rss-new.service";

@Component({
  selector: 'cms-new-render',
  templateUrl: './new-render.component.html',
})
export class NewRenderComponent implements OnInit{
  rssNew: RssNew = {
    title: '',
    link: '',
    content: '',
    source: '',
  };
  @Input() rssid: string = '5fd8a1a8e061c6c3dcfd2ab8';

  constructor(private rssNewService: RssNewService, private route: ActivatedRoute){}

  ngOnInit(): void {
      this.rssNewService.find({_id: this.rssid}).subscribe((rss) =>{
        this.rssNew = rss.list[0];
      });
  }

  getArticle(){

    const parser = new DOMParser();
    let dom = parser.parseFromString(this.rssNew.article, 'text/html');
    let paras = dom.querySelectorAll('p');
    let strPara:string[] = [];
    if(paras.length > 0){
    paras.forEach((p)=>{
      strPara.push(p.innerHTML);
    })
  }else{
    strPara.push(this.rssNew.article);
  }
    return strPara;
   }


}
