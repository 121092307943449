import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@cms-app/shared/shared.module';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { LoginComponent } from './components/login.component';


@NgModule({
  declarations:[
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AdminSharedModule,
    SharedModule,
  ],

})
export class AuthModule { }
