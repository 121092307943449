<cms-admin>
  <div class="row">
    <div class="col-sm-4">
      <div class="card card-body">
        <form (submit)="edit ? updateProvider() : submitProvider()">
          <div>
            <h3>General</h3>
            <div class="form-group">
              <label for="inputName">Nombre</label>
              <input
                type="text"
                name="name"
                id="inputName"
                [(ngModel)]="provider.name"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputURL">URL</label>
              <input
                type="text"
                name="url"
                id="inputURL"
                [(ngModel)]="provider.url"
                class="form-control"
                autofocus
              />
            </div>
          </div>
          <hr />
          <div>
            <h3>
              Familias
              <p>
                <small>
                  Estos elementos se obtendrán de la web indicada en
                  General</small
                >
              </p>
            </h3>

            <div class="form-group">
              <label for="inputFamilyList">Lista de Familias</label>
              <input
                type="text"
                name="familyList"
                id="inputFamilyList"
                [(ngModel)]="provider.familyList"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputSource">Fuente</label>
              <input
                type="text"
                name="source"
                id="inputSource"
                [(ngModel)]="provider.familySource"
                class="form-control"
              />
            </div>
          </div>
          <hr />
          <div>
            <h3>
              Resumen del Curso
              <p>
                <small>
                  Estos elementos se obtendrán de la web de cada familia</small
                >
              </p>
            </h3>
            <div class="form-group">
              <label for="inputCourseList">Lista de Cursos</label>
              <input
                type="text"
                name="courseList"
                id="inputCourseList"
                [(ngModel)]="provider.courseList"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputShortDescription">Descripcion Corta</label>
              <input
                type="text"
                name="shortDescription"
                id="inputShortDescription"
                [(ngModel)]="provider.shortDescription"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputImg">Imagen</label>
              <input
                type="text"
                name="img"
                id="inputImg"
                [(ngModel)]="provider.img"
                class="form-control"
              />
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="provider.pagination"
                id="defaultCheck1"
                name="pagination"
              />
              <label class="form-check-label" for="defaultCheck1">
                Paginacion
              </label>
            </div>
            <div *ngIf="provider.pagination">
              <div class="form-group">
                <label for="inputCoursePagination"
                  >Paginacion de los Cursos</label
                >
                <input
                  type="text"
                  name="coursePagination"
                  id="inputCoursePagination"
                  [(ngModel)]="provider.coursePagination"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="inputUrlPages">Separador de Pagina URL</label>
                <input
                  type="text"
                  name="urlPages"
                  id="inputUrlPages"
                  [(ngModel)]="provider.urlPages"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <hr />
          <div>
            <h3>
              Detalles del Curso
              <p>
                <small>
                  Estos elementos se obtendrán de la web de cada curso</small
                >
              </p>
            </h3>
            <div class="form-group">
              <label for="inputCourseName">Nombre</label>
              <input
                type="text"
                name="courseName"
                id="inputCourseName"
                [(ngModel)]="provider.courseName"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputDescription">Descripcion</label>
              <input
                type="text"
                name="description"
                id="inputDescription"
                [(ngModel)]="provider.description"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputDuration">Duracion</label>
              <input
                type="text"
                name="duration"
                id="inputDuration"
                [(ngModel)]="provider.duration"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputRef">Referencia</label>
              <input
                type="text"
                name="ref"
                id="inputRef"
                [(ngModel)]="provider.ref"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputTitulation">Titulacion</label>
              <input
                type="text"
                name="titulation"
                id="inputTitulation"
                [(ngModel)]="provider.titulation"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputMode">Modo</label>
              <input
                type="text"
                name="mode"
                id="inputMode"
                [(ngModel)]="provider.mode"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputSyllabus">Syllabus</label>
              <input
                type="text"
                name="syllabus"
                id="inputSyllabus"
                [(ngModel)]="provider.syllabus"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputPrice">Precio</label>
              <input
                type="text"
                name="price"
                id="inputPrice"
                [(ngModel)]="provider.price"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputTags">Tags</label>
              <input
                type="text"
                name="tags"
                id="inputTags"
                [(ngModel)]="provider.tags"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputMedia">Media</label>
              <input
                type="text"
                name="media"
                id="inputMEdia"
                [(ngModel)]="provider.media"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputRelated">Related</label>
              <input
                type="text"
                name="related"
                id="inputRelated"
                [(ngModel)]="provider.related"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="inputGoals">Metas</label>
              <input
                type="text"
                name="goals"
                id="inputGoals"
                [(ngModel)]="provider.goals"
                class="form-control"
              />
            </div>
          </div>
          <button class="btn btn-primary btn-block">Guardar</button>
        </form>
      </div>
    </div>
  </div>
</cms-admin>
