import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import {
  Family,
  PVFamily,
  PVFamiliesService,
  FamiliesService
} from '@cms-app/courses';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cms-admin-pv-family-detail',
  templateUrl: './pv-family-detail.component.html'
})
export class AdminPVFamilyDetailComponent implements OnInit {
  original: PVFamily;
  family: FormGroup;

  subfamiliesList: Array<Partial<Family>> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private families: FamiliesService,
    private pvFamilies: PVFamiliesService
  ) {}

  ngOnInit() {
    this.families
      .find({ fileds: 'name,source', per_page: 200 })
      .pipe(
        map(res => res.list),
        map(list =>
          list.map(row => ({
            ...row,
            display: `${row.source} - ${row.name}`
          }))
        )
      )
      .subscribe(list => {
        console.log(list);
        this.subfamiliesList = list;
      });
    const original = this.route.snapshot.data.family;

    if (original) {
      console.log(original);
      this.original = original;
    }

    const subfamilies: any[] = this.original ? this.original.families : [];

    this.family = this.fb.group({
      name: this.original ? this.original.name : '',
      families: this.fb.array(
        subfamilies.map((family: Family) => this.fb.control(family._id))
      )
    });
  }

  get subfamilies() {
    return <FormArray>this.family.get('families');
  }

  add() {
    (<FormArray>this.family.get('families')).push(this.fb.control(''));
  }

  remove(idx: number) {
    const families = <FormArray>this.family.get('families');

    families.removeAt(idx);
  }

  update() {
    this.pvFamilies
      .update(this.original ? this.original._id : null, this.family.value)
      .subscribe(() => this.goToFamilies());
  }

  // ROUTES
  goToFamilies() {
    this.router.navigate(['admin', 'iefp', 'pv-families']);
  }
}
