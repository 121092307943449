import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
var GeodestinosService = /** @class */ (function () {
    function GeodestinosService(env, http) {
        this.env = env;
        this.http = http;
    }
    GeodestinosService.prototype.fetch = function (section, query, options) {
        var _this = this;
        if (section === void 0) { section = 'alojamiento'; }
        if (options === void 0) { options = { page: 1, per_page: 10 }; }
        var params = new HttpParams();
        var checker = this.checkParam(query);
        if (query && query._id) {
            params = params.set('_id', query._id);
        }
        else {
            if (checker('q')) {
                params = params.set('$or>0>namePV>$like', query.q);
                params = params.set('$or>1>name>$like', query.q);
            }
            if (checker('location')) {
                params = params.set('location', query.location);
            }
            if (checker('promo')) {
                params = params.set('promo', 'true');
            }
            if (checker('location>$in')) {
                params = params.set('location>$in', query['location>$in']);
            }
            if (checker('zipCode')) {
                params = params.set('zipCode', query.code);
            }
        }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        params = params.set('page', options.page).set('per_page', options.per_page);
        return this.http
            .get("" + this.env.baseUrl + this.endpoint(section), {
            params: this.appendSectionFilters(section, params),
            observe: 'response',
        })
            .pipe(map(function (response) { return ({
            list: response.body.map(function (row) { return (tslib_1.__assign({}, row, { picture: _this.noPhotos(row.img)
                    ? _this.env.assetsUrl + "/assets/nophoto.jpg"
                    : row.img })); }),
            count: parseInt(response.headers.get('X-Total-Count'), 10),
        }); }));
    };
    GeodestinosService.prototype.searchAllSections = function (query, options) {
        if (options === void 0) { options = { page: 1, per_page: 10 }; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sections, allList, count, _i, sections_1, section, sect, items, _a, list, _b, list_1, item;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        sections = [
                            'alojamiento', 'comer', 'deporte', 'salud', 'compras'
                        ];
                        allList = [];
                        count = 0;
                        _i = 0, sections_1 = sections;
                        _c.label = 1;
                    case 1:
                        if (!(_i < sections_1.length)) return [3 /*break*/, 5];
                        section = sections_1[_i];
                        sect = section;
                        items = this.fetch(sect, query, options).toPromise();
                        _a = count;
                        return [4 /*yield*/, items];
                    case 2:
                        count = _a + (_c.sent()).count;
                        return [4 /*yield*/, items];
                    case 3:
                        list = (_c.sent()).list;
                        for (_b = 0, list_1 = list; _b < list_1.length; _b++) {
                            item = list_1[_b];
                            allList.push(item);
                        }
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 1];
                    case 5: return [2 /*return*/, { list: allList, count: count }];
                }
            });
        });
    };
    GeodestinosService.prototype.getPromos = function (location) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sections, locations, locList, promoList, _i, sections_2, section, sect, list, _a, _b, item;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        sections = [
                            'alojamiento', 'comer', 'deporte', 'salud', 'compras'
                        ];
                        return [4 /*yield*/, this.getGeoDestinoLocations().toPromise()];
                    case 1:
                        locations = _c.sent();
                        locList = locations.list.find(function (r) { return r.name == location; });
                        promoList = [];
                        _i = 0, sections_2 = sections;
                        _c.label = 2;
                    case 2:
                        if (!(_i < sections_2.length)) return [3 /*break*/, 5];
                        section = sections_2[_i];
                        sect = section;
                        return [4 /*yield*/, this.fetch(sect, { promo: 'true', 'location>$in': locList.locations.join() }, { page: 1, per_page: 6 }).toPromise()];
                    case 3:
                        list = _c.sent();
                        for (_a = 0, _b = list.list; _a < _b.length; _a++) {
                            item = _b[_a];
                            promoList.push(item);
                        }
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/, promoList];
                }
            });
        });
    };
    GeodestinosService.prototype.findOne = function (section, id) {
        return this.http.get("" + this.env.baseUrl + this.endpoint(section) + "/" + id);
    };
    GeodestinosService.prototype.empty = function (section) {
        var geodestino;
        switch (section) {
            case 'salud':
                geodestino = {
                    _id: null,
                    name: '',
                    namePV: '',
                    type: '',
                    typePV: '',
                    url: '',
                    urlPV: '',
                    state: '',
                    statePV: '',
                    location: '',
                    locationPV: '',
                    zipCode: '',
                    zipCodePV: '',
                    coordinates: '',
                    coordinatesPV: '',
                    img: [''],
                    likes: 0,
                    galicia: true,
                    certCalidad: false,
                    certCalidadLink: '',
                    fidelity: false,
                    fidelityLink: '',
                    disable: false,
                    description: '',
                    address: '',
                    addressPV: '',
                    phone: [''],
                    email: '',
                    emailPV: '',
                    actType: '',
                    actTypePV: '',
                    promo: false,
                    promoUrl: '',
                    promoDiscount: '',
                };
                break;
            case 'deporte':
                geodestino = {
                    _id: null,
                    name: '',
                    namePV: '',
                    type: '',
                    typePV: '',
                    url: '',
                    urlPV: '',
                    state: '',
                    statePV: '',
                    location: '',
                    locationPV: '',
                    zipCode: '',
                    zipCodePV: '',
                    coordinates: '',
                    coordinatesPV: '',
                    img: [''],
                    likes: 0,
                    galicia: true,
                    certCalidad: false,
                    certCalidadLink: '',
                    fidelity: false,
                    fidelityLink: '',
                    disable: false,
                    description: '',
                    address: '',
                    addressPV: '',
                    phone: [''],
                    email: '',
                    emailPV: '',
                    actType: '',
                    actTypePV: '',
                    promo: false,
                    promoUrl: '',
                    promoDiscount: '',
                };
                break;
            case 'compras':
                geodestino = {
                    _id: null,
                    name: '',
                    namePV: '',
                    type: '',
                    typePV: '',
                    url: '',
                    urlPV: '',
                    state: '',
                    statePV: '',
                    location: '',
                    locationPV: '',
                    zipCode: '',
                    zipCodePV: '',
                    coordinates: '',
                    coordinatesPV: '',
                    img: [''],
                    likes: 0,
                    galicia: true,
                    certCalidad: false,
                    certCalidadLink: '',
                    fidelity: false,
                    fidelityLink: '',
                    disable: false,
                    description: '',
                    address: '',
                    addressPV: '',
                    phone: [''],
                    email: '',
                    emailPV: '',
                    actType: '',
                    actTypePV: '',
                    promo: false,
                    promoUrl: '',
                    promoDiscount: '',
                };
                break;
            default:
                geodestino = {
                    _id: null,
                    name: '',
                    namePV: '',
                    type: '',
                    typePV: '',
                    url: '',
                    urlPV: '',
                    state: '',
                    statePV: '',
                    location: '',
                    locationPV: '',
                    zipCode: '',
                    zipCodePV: '',
                    coordinates: '',
                    coordinatesPV: '',
                    img: [''],
                    likes: 0,
                    galicia: true,
                    certCalidad: false,
                    certCalidadLink: '',
                    fidelity: false,
                    fidelityLink: '',
                    disable: false,
                    description: '',
                    address: '',
                    addressPV: '',
                    phone: [''],
                    email: '',
                    emailPV: '',
                    category: '',
                    categoryPV: '',
                    promo: false,
                    promoUrl: '',
                    promoDiscount: '',
                };
        }
        return geodestino;
    };
    GeodestinosService.prototype.patch = function (section, id, payload) {
        if (section === void 0) { section = 'alojamiento'; }
        return this.http.patch("" + this.env.baseUrl + this.endpoint(section) + "/" + id, payload);
    };
    GeodestinosService.prototype.add = function (section, payload) {
        if (section === void 0) { section = 'alojamiento'; }
        return this.http.post("" + this.env.baseUrl + this.endpoint(section), payload);
    };
    GeodestinosService.prototype.appendSectionFilters = function (section, params) {
        switch (section) {
            case 'albergue':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'Albergues');
            case 'pension':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'Pensiones');
            case 'hotel':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'Hoteles');
            case 'vivienda':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'Viviendas Turísticas');
            case 'camping':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'Campings');
            case 'apartamento':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type>$in', 'Apartamentos,Viviendas Turísticas');
            case 'rural':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'Turismo rural');
            case 'salud':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'salud');
            case 'deporte':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'deporte');
            case 'compras':
                return params
                    .set('sort', this.appendSortParam(section))
                    .set('type', 'compras');
            default:
                return params.set('sort', this.appendSortParam(section));
        }
    };
    GeodestinosService.prototype.isSectionActive = function (section) {
        return (!GeodestinosService.ACTIVE_SECTIONS ||
            GeodestinosService.ACTIVE_SECTIONS.indexOf(section) >= 0);
    };
    GeodestinosService.prototype.isSectionEmpty = function (section, location) {
        console.log(this.fetch(section, { location: location }, { page: 1, per_page: 10 }));
    };
    GeodestinosService.prototype.appendSortParam = function (section) {
        var base = '-galicia,-fidelity,-bestchoice';
        switch (section) {
            case 'hotel':
                return base + ",-stars,-name";
            case 'apartamento':
                return base + ",-stars,-name";
            case 'pension':
                return base + ",-stars,-name";
            case 'camping':
                return base + ",-stars,-name";
            default:
                return base + ", -name";
        }
    };
    GeodestinosService.prototype.endpoint = function (section) {
        switch (section) {
            case 'quedar':
                return '/bars';
            case 'comer':
                return '/restaurants';
            case 'deporte':
                return '/activities';
            case 'salud':
                return '/activities';
            case 'compras':
                return '/activities';
            case 'visitar':
                return '/activities';
            case 'alojamiento':
            default:
                return '/hotels';
        }
    };
    GeodestinosService.prototype.mapTypeToNumber = function (section) {
        return Math.max([
            'alojamiento',
            'comer',
            'quedar',
            'visitar',
            'hacer',
            'informarse',
            'transporte',
            'servicios',
        ].indexOf(section), 0);
    };
    GeodestinosService.prototype.getGeoDestinoLocations = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 48 }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 48;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get(this.env.baseUrl + "/locations", {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body.map(function (row) {
                return row;
            }),
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    GeodestinosService.prototype.findOneGeoLocation = function (id) {
        return this.http.get(this.env.baseUrl + "/locations/" + id);
    };
    GeodestinosService.prototype.geoLocationPatch = function (id, payload) {
        return this.http.patch(this.env.baseUrl + "/locations/" + id, payload);
    };
    GeodestinosService.prototype.geoLocationAdd = function (payload) {
        return this.http.post(this.env.baseUrl + "/locations", payload);
    };
    GeodestinosService.prototype.geoLocationRemove = function (id) {
        return this.http.delete(this.env.baseUrl + "/locations/" + id);
    };
    GeodestinosService.prototype.getSectionDescription = function (section) {
        switch (section) {
            case 'hotel':
                return 'Hoteles';
            case 'albergue':
                return 'Albergues';
            case 'camping':
                return 'Campings';
            case 'pension':
                return 'Pensiones';
            case 'apartamento':
                return 'Apartamentos';
            case 'rural':
                return 'Casas Rurales';
            case 'alojamiento':
                return 'Alojamientos';
            case 'vivienda':
                return 'Viviendas Turísticas';
            case 'comer':
                return 'Restaurantes';
            case 'hacer':
                return 'Hacer';
            default:
                return 'Establecimientos';
        }
    };
    GeodestinosService.prototype.checkParam = function (query) {
        return function (param) {
            return query && query[param] && query[param].length > 0;
        };
    };
    GeodestinosService.prototype.noPhotos = function (imgs) {
        if (!imgs || imgs.length < 1) {
            return true;
        }
    };
    GeodestinosService.DEFAULT_PER_PAGE = 10;
    GeodestinosService.ACTIVE_SECTIONS = 'hotel,rural,apartamento,camping, pension, salud, deportes, compras, albergue,comer, vivienda';
    return GeodestinosService;
}());
export { GeodestinosService };
