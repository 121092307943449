import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CoursesService } from '../../providers/courses.service';
import { ThemeService } from '../../providers/theme.service';
var CouresGridComponent = /** @class */ (function () {
    function CouresGridComponent(coursesService, theme) {
        this.coursesService = coursesService;
        this.theme = theme;
        this.color = 'red';
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.per_page = 48;
        this.query = '';
        this.loading = false;
        this.subfamilies = [];
        this.initialEmit = true;
    }
    Object.defineProperty(CouresGridComponent.prototype, "q", {
        set: function (query) {
            var _this = this;
            this.query = query;
            this.page = 1;
            setTimeout(function () { return _this.find(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CouresGridComponent.prototype, "families", {
        set: function (families) {
            var _this = this;
            this.query = this.initialEmit ? this.query : '';
            this.page = 1;
            this.subfamilies = families;
            this.initialEmit = false;
            setTimeout(function () { return _this.find(); });
        },
        enumerable: true,
        configurable: true
    });
    CouresGridComponent.prototype.ngOnInit = function () {
        this.find();
    };
    CouresGridComponent.prototype.find = function (append) {
        var _this = this;
        if (append === void 0) { append = false; }
        var options = {};
        if (this.lastCallParams &&
            this.page === this.lastCallParams.page &&
            this.query === this.lastCallParams.query &&
            this.subfamilies.join(',') === this.lastCallParams.subfamilies.join(',')) {
            return;
        }
        if (this.page) {
            options.page = this.page;
        }
        if (this.subfamilies && this.subfamilies.length > 0) {
            options['families>$in'] = this.subfamilies.join(',');
        }
        this.lastCallParams = {
            page: this.page,
            query: this.query,
            subfamilies: this.subfamilies
        };
        this.loading = true;
        this.coursesService
            .search(tslib_1.__assign({}, options, this.getQuery(), { per_page: this.per_page }))
            .subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = append ? _this.rows.concat(list) : list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    CouresGridComponent.prototype.excel = function () {
        var options = {};
        if (this.subfamilies && this.subfamilies.length > 0) {
            options['families>$in'] = this.subfamilies.join(',');
        }
        this.coursesService.excelFromSearch(tslib_1.__assign({}, options, this.getQuery()));
    };
    CouresGridComponent.prototype.getQuery = function () {
        var options = {};
        if (this.prefilter || this.query) {
            options['search>$like'] = this.prefilter || this.query;
            options['q'] = [this.prefilter, this.query].filter(Boolean).join(' ');
            options.sort = '-$score';
        }
        if (this.minHours && !isNaN(this.minHours)) {
            options['duration>$gte'] = this.minHours;
        }
        if (this.maxHours && !isNaN(this.maxHours)) {
            options['duration>$lte'] = this.maxHours;
        }
        return options;
    };
    Object.defineProperty(CouresGridComponent.prototype, "moreCourses", {
        get: function () {
            var nPages = Math.ceil(this.count / this.per_page);
            return this.page < nPages;
        },
        enumerable: true,
        configurable: true
    });
    CouresGridComponent.prototype.appendCourses = function () {
        this.page++;
        var nPages = Math.ceil(this.count / this.per_page);
        if (this.page <= nPages) {
            this.find(true);
        }
    };
    return CouresGridComponent;
}());
export { CouresGridComponent };
