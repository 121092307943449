import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GeoDestino } from '@cms-app/geodestinos';
import { GeodestinosService } from '@cms-app/geodestinos/providers/geodestinos.service';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';

@Component({
  selector: 'cms-geodestino-promo-list',
  templateUrl: './promo-list.component.html',
  styleUrls: ['./promo-list.component.css'],
})
export class PromoListComponent implements OnInit{
  @Input() geolocation: string;
  geodestinos: GeoDestino[];

    constructor(@Inject(CMS_ENV) private env: CMSEnv, private geodestinoService: GeodestinosService, private sanitizer: DomSanitizer){

  }
  ngOnInit(): void {
    this.geodestinoService.getPromos(this.geolocation).then( res => {this.geodestinos = res});
  }

  getBackGround(){
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.env.assetsUrl}/assets/fondo-tarjetafidelity.jpg)`)
  }


}
