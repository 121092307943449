import { Pipe, PipeTransform } from '@angular/core';

import { PVFamily } from '../models/pv-family.model';
import { PVFamiliesService } from '../providers/pv-families.service';

@Pipe({
  name: 'categoriesFilter'
})
export class CategoriesFilterPipe implements PipeTransform {
  constructor(private familiesService: PVFamiliesService) {}

  transform(families: PVFamily[], query: string = null): PVFamily[] {
    if (!this.validQuery(query)) {
      return families.filter(family => this.filterFamily(family, query));
    }
    return families.filter(family => this.filterFamily(family, query));
  }

  private filterFamily(family: PVFamily, query: string = null): boolean {
    const subfamilies = this.familiesService.subfamilies(family);

    return [family, ...subfamilies].some(family =>
      this.familiesService.match(family.name, query)
    );
  }

  private validQuery(query: string = null): boolean {
    return query && query.length > 3;
  }
}
