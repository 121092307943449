import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { PVFamily } from '../models/pv-family.model';

@Injectable({
  providedIn: 'root'
})
export class PVFamiliesService {
  endpoint = '/pv-families';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  find(
    options: any = { page: 1, per_page: 10, top: true }
  ): Observable<{
    list: PVFamily[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<PVFamily[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  groupByName(
    options: any = { page: 1, per_page: 10 }
  ): Observable<{
    list: PVFamily[];
    count: number;
  }> {
    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<PVFamily[]>(`${this.env.baseUrl}${this.endpoint}/group-by-name`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  findOne(family: string): Observable<PVFamily> {
    const params = new HttpParams().set('expand', 'tags.name,families.name');

    return this.http.get<PVFamily>(
      `${this.env.baseUrl}${this.endpoint}/${family}`,
      {
        params
      }
    );
  }

  update(
    familyId: string | null,
    family: Partial<PVFamily>
  ): Observable<PVFamily> {
    if (familyId) {
      return this.http.patch<PVFamily>(
        `${this.env.baseUrl}${this.endpoint}/${familyId}`,
        {
          ...family,
          families: (family.families as any[]).filter(row => row.length > 0)
        }
      );
    } else {
      return this.http.post<PVFamily>(
        `${this.env.baseUrl}${this.endpoint}`,
        family
      );
    }
  }

  subfamilies(family: PVFamily): Array<{ name: string; _id: string }> {
    return (family.families as any[]).reduce((acc, row) => {
      if (row.families.length < 1) {
        return [...acc, row];
      } else {
        const rows = row.families as any[];
        return [...acc, ...rows];
      }
    }, []);
  }

  match(one: string, other: string): boolean {
    if (!one || typeof other !== 'string' || other.length < 3) {
      return true;
    }
    // Remove special chars
    other = other.replace(
      new RegExp(
        '([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])',
        'g'
      ),
      '\\$1'
    );
    // Replace % and _ with equivalent regex
    other = other.replace(/%/g, '.*').replace(/_/g, '.');
    // Check matches
    const check = RegExp(other, 'gi').test(one);

    return check;
  }
}
