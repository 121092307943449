import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var TagsService = /** @class */ (function () {
    function TagsService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/tags';
    }
    TagsService.prototype.find = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 10, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        options.top = options.hasOwnProperty('top') ? options.top : true;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body.sort(function (a, b) { return a.name.localeCompare(b.name); }),
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    TagsService.prototype.add = function (candidate) {
        return this.http.post("" + this.env.baseUrl + this.endpoint, candidate);
    };
    TagsService.prototype.patch = function (candidate, id) {
        return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + id, candidate);
    };
    TagsService.prototype.remove = function (id) {
        return this.http.delete("" + this.env.baseUrl + this.endpoint + "/" + id);
    };
    TagsService.ngInjectableDef = i0.defineInjectable({ factory: function TagsService_Factory() { return new TagsService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: TagsService, providedIn: "root" });
    return TagsService;
}());
export { TagsService };
