import { Component, Inject, Input, OnInit } from "@angular/core";
import { RssNew } from "@cms-app/rss/models/rss-new.model";
import { RssNewService } from "@cms-app/rss/providers/rss-new.service";
import { CMSEnv, CMS_ENV } from "@cms-app/shared";

@Component({
  selector: 'cms-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit{
  @Input() rssNew: RssNew;
  @Input() postLink: string;

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv, private rssNewService: RssNewService){}

  ngOnInit(): void {
  }

  getImage(){
    if (this.rssNew.img) {
      return this.rssNew.img;
    } else {
      return `${this.env.assetsUrl}/assets/nophoto.png`;
    }
  }

  getDate(){
    return new Date(this.rssNew.isoDate);
  }

  handleClick(){

  }

  getLink(){
    return this.postLink+'?id='+this.rssNew._id;
  }

  cutText(text: string){
    if(text.length > 53){
      return text.replace(/^(.{50}[^\s]*).*/, "$1")+"...";
    }else{
      return text;
    }

  }
}
