import { of } from 'rxjs';
import { PVFamiliesService } from './pv-families.service';
import * as i0 from "@angular/core";
import * as i1 from "./pv-families.service";
var PVFamilyResolver = /** @class */ (function () {
    function PVFamilyResolver(families) {
        this.families = families;
    }
    PVFamilyResolver.prototype.resolve = function (route) {
        return route.params.id === '0'
            ? of(null)
            : this.families.findOne(route.params.id);
    };
    PVFamilyResolver.ngInjectableDef = i0.defineInjectable({ factory: function PVFamilyResolver_Factory() { return new PVFamilyResolver(i0.inject(i1.PVFamiliesService)); }, token: PVFamilyResolver, providedIn: "root" });
    return PVFamilyResolver;
}());
export { PVFamilyResolver };
