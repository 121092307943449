import { Component, OnInit } from '@angular/core';

import { EntrustService, Entrust } from '@cms-app/courses';
import { Router } from '@angular/router';

type EntrustView = Entrust & {
  status: string;
  source: string;
  description1: string;
  description2: string;
};

@Component({
  selector: 'cms-admin-entrusts',
  templateUrl: './entrusts.component.html'
})
export class AdminEntrustsComponent implements OnInit {
  rows: EntrustView[] = [];
  count = 0;
  page = 1;
  query = '';
  loading = false;

  constructor(
    private router: Router,
    private entrustsService: EntrustService
  ) {}

  ngOnInit() {
    this.find();
  }

  update(row: any) {
    this.router.navigate(['admin', 'iefp', 'entrusts', row._id]);
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    const options: any = {
      sort: '-createdAt'
    };

    if (this.page) {
      options.page = this.page;
    }

    this.loading = true;
    this.entrustsService.find(options).subscribe(
      ({ list, count }) => {
        this.rows = list.map(row => ({
          ...row,
          status: this.extractStatusResponse(row.response),
          source: this.extractSource(row),
          createdAt: new Date(row.createdAt),
          description1: this.extractDescription1(row),
          description2: this.extractDescription2(row)
        }));
        this.count = count;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  private extractDescription1(entrust: Entrust): string {
    if (entrust.kind === 'only') {
      return `${entrust.formData.adh_razon_social} ${
        entrust.formData.Correo_electronico
      }`;
    }

    return `${entrust.formData.students[0].fRazonSocial} ${
      entrust.formData.students[0].fEmail
    }`;
  }

  private extractDescription2(entrust: Entrust): string {
    if (entrust.kind === 'only') {
      return `${entrust.formData.Persona_de_Contacto} ${
        entrust.formData.adh_telefono
      }`;
    }

    return `${entrust.formData.students[0].fNombreAlumno} ${
      entrust.formData.students[0].accion_formativa_movil
    }`;
  }

  private extractSource(entrust: Entrust): string {
    return entrust.kind === 'only'
      ? entrust.formData.web_info
      : entrust.formData.students[0].web_info;
  }

  private extractStatusResponse(response: any) {
    if (!response) {
      return null;
    }
    if(response.result){
      if(response.result[0].message && response.result[0].message === 'Data Added Successfully'){
        return 'Success';
      }else{
        return response.result[0].message;
      }
    }else{
      if(response.code == 1030){
        return response.description;
      }else{
        const match = response.match(/\"status\":\"(.*)\"/);
        return match ? match[1] : null;
      }
    }
  }
}
