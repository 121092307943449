/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../promo-item/promo-item.component.ngfactory";
import * as i3 from "../promo-item/promo-item.component";
import * as i4 from "../../../shared/models/environment";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./promo-list.component";
import * as i8 from "../../providers/geodestinos.service";
var styles_PromoListComponent = [i0.styles];
var RenderType_PromoListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoListComponent, data: {} });
export { RenderType_PromoListComponent as RenderType_PromoListComponent };
function View_PromoListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-4 col-sm-6 geopromo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cms-geodestino-promo-item", [], null, null, null, i2.View_PromoItemComponent_0, i2.RenderType_PromoItemComponent)), i1.ɵdid(2, 49152, null, 0, i3.PromoItemComponent, [i4.CMS_ENV, i5.DomSanitizer], { geodestino: [0, "geodestino"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PromoListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row geopromo-row"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-12 col-md-12 col-sm-8 geopromo-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoListComponent_1)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.geodestinos; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getBackGround(); _ck(_v, 0, 0, currVal_0); }); }
export function View_PromoListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-geodestino-promo-list", [], null, null, null, View_PromoListComponent_0, RenderType_PromoListComponent)), i1.ɵdid(1, 114688, null, 0, i7.PromoListComponent, [i4.CMS_ENV, i8.GeodestinosService, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromoListComponentNgFactory = i1.ɵccf("cms-geodestino-promo-list", i7.PromoListComponent, View_PromoListComponent_Host_0, { geolocation: "geolocation" }, {}, []);
export { PromoListComponentNgFactory as PromoListComponentNgFactory };
