import { OnInit } from '@angular/core';
import { GeodestinosService } from '../../providers/geodestinos.service';
import { CMSEnv, QueryStringService } from '@cms-app/shared';
import { FormBuilder } from '@angular/forms';
var GeoDestinoSectionComponent = /** @class */ (function () {
    function GeoDestinoSectionComponent(env, geodestinos, fb, queryService) {
        this.env = env;
        this.geodestinos = geodestinos;
        this.fb = fb;
        this.queryService = queryService;
        this.section = 'alojamiento';
        this.page = 1;
        this.per_page = GeodestinosService.DEFAULT_PER_PAGE;
        this.rows = [];
    }
    GeoDestinoSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fetch().subscribe(function (_a) {
            var count = _a.count, list = _a.list;
            return _this.handleRows(count, list);
        });
        var params = this.queryService.parse(window.location.search);
        this.searchForm = this.fb.group({
            query: [params.q ? decodeURIComponent(params.q) : ''],
        });
        if (params && params.q && params.q.length > 0) {
            this.doSearch();
        }
    };
    Object.defineProperty(GeoDestinoSectionComponent.prototype, "loaded", {
        get: function () {
            return this.count !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    GeoDestinoSectionComponent.prototype.fetch = function (page) {
        if (page === void 0) { page = 1; }
        return this.geodestinos.fetch(this.section, {
            q: this.query,
            code: this.code,
            location: this.city
        }, {
            page: page,
            per_page: this.per_page
        });
    };
    GeoDestinoSectionComponent.prototype.doSearch = function () {
        var _this = this;
        var q = this.searchForm.get('query').value;
        this.query = q ? q.trim() : '';
        this.fetch(1).subscribe(function (_a) {
            var count = _a.count, list = _a.list;
            return _this.handleRows(count, list);
        });
        this.query = '';
    };
    GeoDestinoSectionComponent.prototype.pageChanged = function (event) {
        var _this = this;
        if (this.page !== event.page) {
            this.page = event.page;
            this.fetch(event.page).subscribe(function (_a) {
                var count = _a.count, list = _a.list;
                return _this.handleRows(count, list);
            });
        }
    };
    GeoDestinoSectionComponent.prototype.preppendAssetsUrl = function (url) {
        return "" + this.env.assetsUrl + url;
    };
    GeoDestinoSectionComponent.prototype.handleRows = function (count, list) {
        this.rows = list;
        this.count = count;
    };
    return GeoDestinoSectionComponent;
}());
export { GeoDestinoSectionComponent };
