import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MediaService } from '@cms-app/shared';
import { ThemeService } from '../../providers/theme.service';
import { PVFamiliesService } from '../../providers/pv-families.service';
var CategoriesListComponent = /** @class */ (function () {
    function CategoriesListComponent(mediaService, theme, familiesService) {
        this.mediaService = mediaService;
        this.theme = theme;
        this.familiesService = familiesService;
        this.color = 'red';
        this.families = new EventEmitter();
        this.all = new EventEmitter();
        this.rows = [];
        this.count = 0;
        this.loading = false;
        this.query = '';
        this.currentFilter = null;
        this.currentSubFilter = null;
    }
    Object.defineProperty(CategoriesListComponent.prototype, "family", {
        set: function (family) {
            if (this._family && !family) {
                this.currentFilter = null;
                this.currentSubFilter = null;
                this.emitFamiliesFilter();
            }
            this._family = family;
            this.find();
        },
        enumerable: true,
        configurable: true
    });
    CategoriesListComponent.prototype.ngOnInit = function () {
        this.find();
        this.size$ = this.mediaService.register();
    };
    CategoriesListComponent.prototype.find = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var PVfamilyId = '5dd4fa099e90e004c432a083';
        if (!this.loading) {
            this.loading = true;
            this.familiesService
                .find(tslib_1.__assign({}, options, { per_page: 100, expand: 'families.name,families.families,families.source,families>families.name' }))
                .subscribe(function (_a) {
                // console.log({ list, count });
                var list = _a.list, count = _a.count;
                _this.rows = _this._family
                    ? list.filter(function (row) { return row._id === _this._family; })
                    : list;
                _this.rows.sort(function (a, b) {
                    if (a._id === PVfamilyId) {
                        return -1;
                    }
                    if (b._id === PVfamilyId) {
                        return 1;
                    }
                    return 0;
                });
                _this.count = count;
                _this.loading = false;
                if (_this._family) {
                    _this.setFamilyFilter(_this.rows[0]);
                }
            }, function () {
                _this.loading = false;
            });
        }
    };
    CategoriesListComponent.prototype.onSelectChange = function (ev) {
        var value = ev.target ? ev.target.value : '';
        if (value && value.length > 0) {
            var family = this.rows.find(function (row) { return row._id === value; });
            if (family) {
                this.setFamilyFilter(family);
            }
            else {
                this.families.emit(null);
                this.currentFilter = null;
            }
        }
        else {
            this.families.emit(null);
            this.currentFilter = null;
        }
    };
    CategoriesListComponent.prototype.setFamilyFilter = function (family) {
        if (family._id === this.currentFilter && !this._family) {
            this.currentFilter = null;
            this.currentSubFilter = null;
        }
        else {
            this.currentFilter = family._id;
        }
        this.emitFamiliesFilter();
    };
    CategoriesListComponent.prototype.setSelectColor = function (subfamily) {
        if (this.currentSubFilter && subfamily.families[0] === this.currentSubFilter[0]) {
            return this.theme.color;
        }
        else {
            return "'#333'";
        }
    };
    CategoriesListComponent.prototype.setSubfamilyFilter = function (subfamily) {
        if (this.currentSubFilter &&
            subfamily.families[0] === this.currentSubFilter[0]) {
            this.currentSubFilter = null;
        }
        else {
            this.currentSubFilter = subfamily.families;
        }
        this.emitFamiliesFilter();
    };
    CategoriesListComponent.prototype.getSubfamilies = function (family) {
        var _this = this;
        var subFamiliesList = [];
        var subfamilies = this.familiesService
            .subfamilies(family)
            .filter(function (family) { return _this.familiesService.match(family.name, _this.query); });
        for (var _i = 0, subfamilies_1 = subfamilies; _i < subfamilies_1.length; _i++) {
            var subFamily = subfamilies_1[_i];
            var exists = false;
            var sf = {
                name: '',
                families: [],
            };
            if (subFamiliesList.length > 0) {
                for (var _a = 0, subFamiliesList_1 = subFamiliesList; _a < subFamiliesList_1.length; _a++) {
                    var object = subFamiliesList_1[_a];
                    if (object.name.toLowerCase() === subFamily.name.toLowerCase()) {
                        exists = true;
                    }
                }
            }
            if (exists) {
                for (var _b = 0, subFamiliesList_2 = subFamiliesList; _b < subFamiliesList_2.length; _b++) {
                    var so = subFamiliesList_2[_b];
                    if (so.name.toLowerCase() === subFamily.name.toLowerCase()) {
                        so.families.push(subFamily._id);
                    }
                }
            }
            else {
                sf.name = subFamily.name;
                sf.families.push(subFamily._id);
                subFamiliesList.push(sf);
            }
        }
        return subFamiliesList;
    };
    CategoriesListComponent.prototype.emitFamiliesFilter = function () {
        var _this = this;
        if (!this.currentFilter) {
            this.families.emit(null);
        }
        else {
            var family = this.rows.find(function (row) { return row._id === _this.currentFilter; });
            console.log(family);
            var subfamilies = family.families
                .reduce(function (acc, subfamily) {
                if (subfamily.families.length < 1) {
                    return acc.concat([subfamily._id]);
                }
                else {
                    var rows = subfamily.families.map(function (row) { return row._id; });
                    return acc.concat(rows);
                }
            }, []);
            var sFArray = [];
            if (this.currentSubFilter) {
                for (var _i = 0, subfamilies_2 = subfamilies; _i < subfamilies_2.length; _i++) {
                    var subfamily = subfamilies_2[_i];
                    for (var _a = 0, _b = this.currentSubFilter; _a < _b.length; _a++) {
                        var sf = _b[_a];
                        if (subfamily === sf) {
                            sFArray.push(subfamily);
                        }
                    }
                }
            }
            else {
                for (var _c = 0, subfamilies_3 = subfamilies; _c < subfamilies_3.length; _c++) {
                    var subfamily = subfamilies_3[_c];
                    sFArray.push(subfamily);
                }
            }
            console.log(sFArray);
            this.families.emit({
                name: family.name,
                families: sFArray,
            });
        }
    };
    return CategoriesListComponent;
}());
export { CategoriesListComponent };
