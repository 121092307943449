import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import {
  GeodestinosService,
} from '@cms-app/geodestinos';
import { MessagingService } from '@cms-app/admin/shared';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GeoLocation } from '@cms-app/geodestinos/models/geolocation.model';
import { isObject } from 'util';

@Component({
  selector: 'cms-location-detail',
  templateUrl: './location-detail.component.html'
})
export class AdminLocationDetailComponent implements OnInit {
  location: GeoLocation;
  addedLocation: string='';
  geodestinoForm: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private geodestinosService: GeodestinosService,
    private messaging: MessagingService
  ) {}

  ngOnInit() {
    this.setupGeoDestino(this.route.snapshot.data.geodestino);
    this.route.params
      .pipe(
        filter(params => params.id !== this.location._id || params.id === 'new'),
        switchMap(params => {
          return params.id === 'new'
            ? of(this.empty())
            : this.geodestinosService.findOneGeoLocation(this.route.snapshot.params.id);
        })
      )
      .subscribe(geodestino => this.setupGeoDestino(geodestino));

  }

  save() {
      if (this.geodestinoForm.valid) {
        let request;
        if(this.location._id === null){
          request = this.geodestinosService.geoLocationAdd(this.geodestinoForm.value);
        }else{
           request = this.geodestinosService.geoLocationPatch(this.location._id, this.geodestinoForm.value);
        }
        console.log(this.geodestinoForm.value)

        request.subscribe(
          (saved: Geolocation) => {
            // ACTUALIZACION CORRECTA
            this.messaging.success(
              'Region actualizada correctamente',
              'CMS ADMIN'
            );

            // redireccionar
          },
          () => {
            // ACTUALIZACION ERRONEA
            this.messaging.error('Error actualizando la region', 'CMS ADMIN');
          }
        );
  }
}

getLocations(){
  return this.sortLocations(this.location.locations);
}

remove(item:string){
  for(let i=0; i< this.location.locations.length; i++){
    if( this.location.locations[i] === item){
      this.location.locations.splice(i,1);
    }
  }
}

addLocation(){
  if(this.addedLocation !== ''){
    let exists= false;
    for(let item of this.location.locations){
      if(item === this.addedLocation){
        exists = true;
      }
    }
    if(!exists){
      this.location.locations.push(this.addedLocation);
    }
    this.addedLocation='';
  }

}

setItem(value){
  this.addedLocation= value;
}

  //IMPLEMENTATION
  private mapToFormGroup(location: GeoLocation): FormGroup {
    let form = this.fb.group({
      name: [location.name],
      fullName: [location.fullName],
      locations: [location.locations]
    });
    return form;
  }

  private setupGeoDestino(geodestino: GeoLocation = this.empty()) {
    this.location = geodestino;
    this.geodestinoForm = this.mapToFormGroup(this.location);
  }

  private empty(){
    return {
      name:'',
      fullName:'',
      locations: ['']
    }
  }

  private sortLocations(locations: string[]){
    return locations.sort((a,b) => (a > b ? 1: -1));
  }
}
