import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[cmsBirthdate]'
})
export class BirthdateDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = <HTMLInputElement>event.target;
    // A date can only have numbers and slashes
    let clean = input.value.replace(/[^0-9\/]/g, '');
    const groups = clean.split('/');

    const numberOfSlashes = groups.length - 1;

    // A date can only contain two slashes
    if (numberOfSlashes > 2) {
      clean = clean.slice(0, clean.length - 1);
    }

    if (numberOfSlashes === 2) {
      let year = groups[2].substr(0, 4);
      clean = [groups[0], groups[1], year].join('/');
    }
    input.value = clean;
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const input = <HTMLInputElement>event.target;
    let clean = input.value;
    const groups = clean.split('/');

    if (event.key !== 'Backspace') {
      if (groups.length === 1 && groups[0].length === 2) {
        clean += '/';
      } else if (groups.length === 2 && groups[1].length === 2) {
        clean += '/';
      }
    }

    input.value = clean;
  }
}
