import { fromEvent } from 'rxjs';
import { debounceTime, map, startWith, distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
var MediaService = /** @class */ (function () {
    function MediaService() {
        this.resize$ = fromEvent(window, 'resize').pipe(debounceTime(250));
    }
    MediaService.prototype.register = function () {
        var _this = this;
        return this.resize$.pipe(startWith(this.getMedia(window.innerWidth)), map(function () { return _this.getMedia(window.innerWidth); }), distinctUntilChanged());
    };
    MediaService.prototype.getMedia = function (width) {
        if (width <= 767) {
            return 'xs';
        }
        else if (width <= 992) {
            return 'sm';
        }
        else if (width <= 1200) {
            return 'md';
        }
        else {
            return 'lg';
        }
    };
    MediaService.ngInjectableDef = i0.defineInjectable({ factory: function MediaService_Factory() { return new MediaService(); }, token: MediaService, providedIn: "root" });
    return MediaService;
}());
export { MediaService };
