import { InjectionToken } from '@angular/core';

export const CKEDITOR_CONFIG = new InjectionToken<string>('CKEDITOR_CONFIG');

export const ckEditorConfig = {
  extraPlugins: 'divarea',
  height: 1200,
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    '/',
    { name: 'basicstyles', groups: ['basicstyles'] },
    { name: 'colors' },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'align']
    },
    { name: 'links' },
    { name: 'insert' }
  ]
};
