<cms-admin *ngIf="!!geodestino" [formGroup]="geodestinoForm">
  <ol class="breadcrumb">
    <li>
      <a (click)="goToSection()" style="cursor: pointer">{{ sectionName() }}</a>
    </li>
    <li class="active">{{ geodestino.name }}</li>
  </ol>
  <cms-section-title [showAdd]="false" [showQuery]="false">{{
    geodestino.name
  }}</cms-section-title>
  <div
    class="row"
    style="margin-left: 0px; margin-right: 0px"
    [formGroup]="geodestinoForm"
    *ngIf="!!geodestino"
  >
    <div class="col-md-3">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="galicia" />
          Agrupacion Galicia
        </label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="certCalidad" />
          Sello de Calidad
        </label>
        <input
          type="text"
          formControlName="certCalidadLink"
          placeholder="Url"
        />
      </div>
    </div>
    <div class="col-md-3">
      <div class="checkbox">
        <label>
          <input type="checkbox" formControlName="fidelity" />
          Tarjeta Fidelity
        </label>
        <input type="text" formControlName="fidelityLink" placeholder="Url" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="promo" />
            Promocion
          </label>
          <input type="text" formControlName="promoDiscount" placeholder="Descuento" />
          <input type="text" formControlName="promoUrl" placeholder="Url" />
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th style="width: 50%">
            <h2><span>Fuente: </span>Scrapper</h2>
          </th>
          <th style="width: 50%">
            <h2><span>Fuente: Galicia</span></h2>
          </th>
        </tr>
      </thead>
      <tr>
        <td>
          <span style="font-weight: bold"> Nombre: </span
          ><span>{{ geodestino.name }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="namePV"
              placeholder="Nombre"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Tipo: </span
          ><span>{{ geodestino.type }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="type"
              placeholder="Tipo"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Url: </span
          ><span>{{ geodestino.url }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="urlPV"
              placeholder="url"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Direccion: </span
          ><span>{{ geodestino.address }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="addressPV"
              placeholder="Direccion"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Provincia: </span
          ><span>{{ geodestino.state }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="statePV"
              placeholder="Provincia"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Localidad: </span
          ><span>{{ geodestino.location }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="locationPV"
              placeholder="Localidad"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Codigo Postal: </span
          ><span>{{ geodestino.zipCode }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="zipCodePV"
              placeholder="Codigo Postal"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Coordenadas: </span
          ><span>{{ geodestino.coordinates }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="coordinatesPV"
              placeholder="Coordenadas"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> Descripcion: </span
          ><span>{{ geodestino.description }}</span
          ><br />
        </td>
        <td>
          <div>
            <textarea
              class="form-control"
              placeholder="Descripción"
              formControlName="descriptionPV"
              cols="50"
              rows="5"
            ></textarea>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold"> e-mail: </span
          ><span>{{ geodestino.email }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="emailPV"
              placeholder="e-mail"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr *ngIf="!isActivity()">
        <td>
          <span style="font-weight: bold"> Categoría: </span
          ><span>{{ geodestino.category }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="categoryPV"
              placeholder="Categoría"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr *ngIf="isActivity()">
        <td>
          <span style="font-weight: bold"> Tipo de Actividad: </span
          ><span>{{ geodestino.actType }}</span
          ><br />
        </td>
        <td>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="actTypePV"
              placeholder="Tipo de Actividad"
              size="150"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <label for="activo"
            >Deshabilitado
            <input
              type="checkbox"
              class="form-control"
              formControlName="disable"
              id="activo"
            />
          </label>
        </td>
      </tr>
    </table>
    <div formArrayName="phone">
      <label for="">Telefonos: </label>
      <div
        style="padding: 5px"
        *ngFor="let item of phone.controls; let i = index"
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <div style="display: flex; flex-direction: row">
          <input type="text" class="form-control" [formControl]="item" />
          <button type="button" (click)="removePhone(i)" class="btn btn-danger">
            X
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" style="padding: 0px 20px">
          <button class="btn btn-success" (click)="addPhone()">AÑADIR</button>
        </div>
      </div>
    </div>
    <br />
    <div formArrayName="img">
      <label for="">Imagenes: </label>
      <div
        style="padding: 5px"
        *ngFor="let item of img.controls; let i = index"
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <div style="display: flex; flex-direction: row">
          <input type="text" class="form-control" [formControl]="item" />
          <a [href]="item.value" target="_blank"></a
          ><img [src]="item.value" width="100" height="100" />
          <button type="button" (click)="removeImg(i)" class="btn btn-danger">
            X
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" style="padding: 0px 20px">
          <button class="btn btn-success" (click)="addImg()">AÑADIR</button>
        </div>
      </div>
    </div>
    <br />
    <div class="row" style="margin-left: 0px; margin-right: 0px">
      <div class="col-md-12 pull-right">
        <button class="btn btn-success" (click)="save()">GUARDAR</button>
      </div>
    </div>
  </div>
</cms-admin>
