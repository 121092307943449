import { Component, Inject, OnInit } from '@angular/core';
import { RssNew } from '@cms-app/rss/models/rss-new.model';
import { RssSrc } from '@cms-app/rss/models/rss-src.model';
import { RssSrcService } from '@cms-app/rss/providers/rss-src.service';
import { RssService } from '@cms-app/rss/providers/rss.service';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';
import { GeodestinosService } from '@cms-app/geodestinos';
import { GeoLocation } from '@cms-app/geodestinos/models/geolocation.model';

@Component({
  selector: 'admin-rss-chooser',
  templateUrl: 'rss-chooser.component.html',
  styleUrls: ['./rss-chooser.component.css'],
})

export class AdminRssChooser implements OnInit{
sources: RssSrc[];
source: RssSrc = null;
rows: RssNew[];
currentFilter;
regions: GeoLocation[];
loaded: boolean = false;
articleSelector: string;


  constructor( @Inject(CMS_ENV) public env: CMSEnv,private rssService: RssService, private rssSrcService: RssSrcService, private geoDestinosService: GeodestinosService){}

  ngOnInit(): void {
    this.rssSrcService.find().subscribe((sources)=> { this.sources = sources.list});
    this.geoDestinosService.getGeoDestinoLocations().subscribe((regions) => {this.regions = regions.list});
  }

  getFeeds(){
    if(this.source){
    this.rows = [];
    this.articleSelector = this.source.articleSelector;
    this.rssService.getRss(this.source.url, this.source.blogGalicia).subscribe((feeds) => {
      for(let feed of feeds){
        this.rows.push(this.handleFeed(feed));
      }
    });
  }
  }

  setSource(source:RssSrc){
    if(!this.source){
      this.source=source;
      this.currentFilter=source._id;
      this.loaded=true;
    }else{
      if(this.source._id === source._id){
        this.source = null;
        this.currentFilter = null;
        this.loaded=false;
      }else{
        this.source = source;
        this.currentFilter=source._id;
        this.loaded=true;
      }
    }
    this.getFeeds();
  }



  private isoDateToDate(isodate){
    return new Date(isodate);
  }

  private handleFeed(feed){
    let img: string;
    if(feed.enclosure && feed.enclosure.url){
      img = feed.enclosure.url;
    }else{
       img= feed.img ? feed.img : '';
    }

    let date = feed.isoDate ? feed.isoDate : feed.date;

    return {
      date: this.isoDateToDate(date),
      title: feed.title,
      img: img,
      link: feed.link,
      content: feed.content,
      source: this.source.name,
    }
  }



}
