/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tabs.component";
var styles_TabsComponent = [i0.styles];
var RenderType_TabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsComponent, data: {} });
export { RenderType_TabsComponent as RenderType_TabsComponent };
function View_TabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "tab-header-picture"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.picture; _ck(_v, 0, 0, currVal_0); }); }
function View_TabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTab(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_1 = !!_v.context.$implicit.picture; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.selected ? "tab-header-element tab-selected" : "tab-header-element"); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
export function View_TabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tabs; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.block ? "tabs-header-container tabs-header-block" : "tabs-header-container"); _ck(_v, 0, 0, currVal_0); }); }
export function View_TabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "cms-tabs", [], null, null, null, View_TabsComponent_0, RenderType_TabsComponent)), i1.ɵdid(1, 1097728, null, 1, i3.TabsComponent, [], null, null), i1.ɵqud(603979776, 1, { tabs: 1 })], null, null); }
var TabsComponentNgFactory = i1.ɵccf("cms-tabs", i3.TabsComponent, View_TabsComponent_Host_0, { block: "block", initial: "initial" }, {}, ["*"]);
export { TabsComponentNgFactory as TabsComponentNgFactory };
