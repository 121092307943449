/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./course-entrust-miniloader.component";
var styles_CourseEntrustMiniLoader = [".lds-circle[_ngcontent-%COMP%] {\n        display: inline-block;\n        transform: translateZ(1px);\n      }\n      .lds-circle[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%] {\n        display: inline-block;\n        width: 12px;\n        height: 12px;\n        margin-left: 4px;\n        border-radius: 50%;\n        background: #888;\n        animation: lds-circle 4.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n      }\n      @keyframes lds-circle {\n        0%,\n        100% {\n          animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);\n        }\n        0% {\n          transform: rotateY(0deg);\n        }\n        50% {\n          transform: rotateY(1800deg);\n          animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);\n        }\n        100% {\n          transform: rotateY(3600deg);\n        }\n      }"];
var RenderType_CourseEntrustMiniLoader = i0.ɵcrt({ encapsulation: 0, styles: styles_CourseEntrustMiniLoader, data: {} });
export { RenderType_CourseEntrustMiniLoader as RenderType_CourseEntrustMiniLoader };
export function View_CourseEntrustMiniLoader_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "lds-circle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CourseEntrustMiniLoader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-course-entrust-miniloader", [], null, null, null, View_CourseEntrustMiniLoader_0, RenderType_CourseEntrustMiniLoader)), i0.ɵdid(1, 49152, null, 0, i1.CourseEntrustMiniLoader, [], null, null)], null, null); }
var CourseEntrustMiniLoaderNgFactory = i0.ɵccf("cms-course-entrust-miniloader", i1.CourseEntrustMiniLoader, View_CourseEntrustMiniLoader_Host_0, {}, {}, []);
export { CourseEntrustMiniLoaderNgFactory as CourseEntrustMiniLoaderNgFactory };
