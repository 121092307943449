import { Component, Inject } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';

@Component({
  selector: 'cms-geodestino-contact-item',
  templateUrl: './geodestino-contact-item.component.html',
  styleUrls: ['./geodestino-contact-item.component.css']
})
export class GeoDestinoContactItemComponent {

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv,private sanitizer: DomSanitizer) {}

  getContactLink(){
    const baseUrl = window.location.origin
    return baseUrl+"/incorporar-negocio.html"
  }

  getLogo(){
    let img ='';
    switch(window.location.origin){
      case 'https://www.riasbaixas.info':
        img='riasbaixasinfo-web.png';
        break;
      case 'https://www.acostadamorte.info' :
        img='Costadammorte-web.png';
        break;
      case 'https://www.ourense.info'   :
        img='ourense-web.png';
        break;
      case 'https://www.riasaltas.info':
        img='riasaltasinfo-web.png'
        break;
      case 'https://www.aribeirasacra.info':
        img='riebirasacra-web.png';
        break;
      case 'https://www.santiago.info':
        img='santiagoinfo-web.png'
        break;
      default:
        img='galiciainfo-web.png';
    }
    return this.preppendAssetsUrl('/assets/'+img);
  }

  getBackGround(){
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.env.assetsUrl}/assets/fondo-geodestino.jpg)`)
  }

  preppendAssetsUrl(url: string): string {
    return `${this.env.assetsUrl}${url}`;
  }
}
