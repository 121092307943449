import { of } from 'rxjs';
import { CoursesService } from './courses.service';
import * as i0 from "@angular/core";
import * as i1 from "./courses.service";
var CourseResolver = /** @class */ (function () {
    function CourseResolver(courses) {
        this.courses = courses;
    }
    CourseResolver.prototype.resolve = function (route) {
        return route.params.id === 'new'
            ? of(this.courses.empty())
            : this.courses.findOne(route.params.id);
    };
    CourseResolver.ngInjectableDef = i0.defineInjectable({ factory: function CourseResolver_Factory() { return new CourseResolver(i0.inject(i1.CoursesService)); }, token: CourseResolver, providedIn: "root" });
    return CourseResolver;
}());
export { CourseResolver };
