import { Component, OnInit } from "@angular/core";
import { RssNew } from "@cms-app/rss/models/rss-new.model";
import { RssQuery } from "@cms-app/rss/models/rss-query.model";
import { RssNewService } from "@cms-app/rss/providers/rss-new.service";

@Component({
  selector: 'admin-rss-news',
  templateUrl: 'rss-news.component.html',
})
export class RssNewsComponent implements OnInit{
  page = 1;
  loading = false;
  rows: RssNew[] = [];
  count = 0;
  query = '';



  constructor(private rss: RssNewService) {}
  ngOnInit(): void {
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  find() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }

    let rssQ: RssQuery = {
      type: 'title',
      query: ''
    }
    if(this.query){
      rssQ.query = this.query;
    }

    this.loading = true;
    this.rss.find(options, rssQ).subscribe(
      ({ list, count }) => {
        this.rows = list;
        this.count = count;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  delete(row:RssNew){
    this.rss.delete(row._id).subscribe(res => {
      this.rows = this.rows.filter((r) => {
        if(r._id === row._id){
          return false;
        }else{
          return true;
        }
      })
    });
  }

}
