<cms-admin>
  <div class="container">
    <div class="col align-self-center">
      <table class="table">
        <tr>
          <td>
            Esto eliminará la copia existente y la sustituirá por los datos
            actuales:
          </td>
          <td  class="columnFix">
            <button class="btn-primary btn-block" (click)="backup()">
              Copia de Seguridad
            </button>
          </td>
        </tr>
        <tr>
          <td>
            Esto eliminará todo los datos actuales y los sustituirá por los
            guardados en la copia:
          </td>
          <td class="columnFix">
            <button class="btn-primary btn-block" (click)="restore()">
              Restaurar Copia
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <tr>
              <td style="padding:0 15px 0 0;">
                Programar lanzamiento de scrapper:
              </td>
              <td style="padding:0 15px 0 0;">
                <input
                [owlDateTime]="dt2"
                placeholder="Elija fecha y hora"
                [(ngModel)]="date"
              />
              <span [owlDateTimeTrigger]="dt2"
                ><i class="fa fa-calendar"></i
              ></span>
              <owl-date-time #dt2></owl-date-time>
              </td>
              <td style="padding:0 15px 0 0;">
                <ng-multiselect-dropdown
                [placeholder]="'Seleccionar Proveedores'"
                [data]="sourceList"
                [(ngModel)]="sources"
                [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
              >
              </ng-multiselect-dropdown>
              </td>
            </tr>
          </td>
          <td  class="columnFix">
            <button class="btn-primary btn-block" (click)="launch()">
              Lanzar
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</cms-admin>
