<cms-admin>
  <ngx-datatable cmsDatatable [rows]="rows" [loadingIndicator]="loading" [count]="count || 0">
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fecha
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.date | date:'dd-MM-yyyy HH:mm'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Mensaje
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.msg }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Tipo
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.msgType }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
