<cms-admin>
  <cms-section-title (query)="onQuery($event)" (add)="onAdd()">Regiones</cms-section-title>
  <ngx-datatable cmsDatatable [rows]="rows" [loadingIndicator]="loading" [count]="count || 0" [limit]="per_page"
    [externalPaging]="true" (page)="onPage($event)">
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Codigo
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.fullName }}
      </ng-template>
    </ngx-datatable-column>
        <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right actions">
          <button class="btn btn-primary" (click)="update(row)" tooltip="Ver / Editar" placement="top">
            E
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
