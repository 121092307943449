import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CMS_ENV, CMSEnv, QueryStringService } from '@cms-app/shared';

import { Color } from '../../models/theme.model';

import { ThemeService } from '../../providers/theme.service';
import { EntrustService } from '../../providers/entrust.service';
import { CoursePlatformsService } from '../../providers/platforms.service';

@Component({
  selector: 'cms-course-entrust-ok',
  templateUrl: './course-entrust-ok.component.html'
})
export class CourseEntrustOkComponent implements OnInit {
  @Input() color: Color = 'red';

  entrust: any;
  email: string;

  constructor(
    @Inject(CMS_ENV) public env: CMSEnv,
    private query: QueryStringService,
    public theme: ThemeService,
    private route: ActivatedRoute,
    private entrustService: EntrustService,
    private platforms: CoursePlatformsService
  ) {}

  ngOnInit() {
    this.email = this.platforms.definition(this.platforms.guess()).web_email;
    this.theme.schema = this.color;
    if (this.env.elements) {
      const params: any = this.query.parse(window.location.search);

      if (params && params.entrust) {
        this.entrustService.findOne(params.entrust).subscribe(entrust => {
          this.entrust = entrust;
        });
      }
    } else {
      const entrustId = this.route.snapshot.params.entrust;

      if (entrustId) {
        this.entrustService.findOne(entrustId).subscribe(entrust => {
          this.entrust = entrust;
        });
      }
    }
  }
}
