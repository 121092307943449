import { Inject, Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user.interface'
import { CMSEnv, CMS_ENV } from '@cms-app/shared/models/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endpoint= '/users'
  constructor(@Inject(CMS_ENV) private env: CMSEnv,private http: HttpClient) { }

  getUsers(): Observable<User[]>{
    return this.http.get<User[]>(`${this.env.baseUrl}${this.endpoint}`);
  }

  getUserById(id: string):Observable<User>{
    return this.http.get<User>(`${this.env.baseUrl}${this.endpoint}/${id}`);
  }

  createUser(user: User): Observable<User>{
    return this.http.post<User>(`${this.env.baseUrl}${this.endpoint}`,user);
  }

  deleteUser(id: string): Observable<User>{
    return this.http.delete<User>(`${this.env.baseUrl}${this.endpoint}/${id}`);
  }

  updateUser(id : string,user: User): Observable<User>{
    return this.http.patch<User>(`${this.env.baseUrl}${this.endpoint}/${id}`, user);
  }
}
