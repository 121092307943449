import { Directive, OnInit } from '@angular/core';

import { PaginationComponent } from 'ngx-bootstrap/pagination';

@Directive({
  selector: '[geoDestinoPager]'
})
export class GeoDestinoPager implements OnInit {
  constructor(private pager: PaginationComponent) {}

  ngOnInit() {
    this.pager.previousText = '&lsaquo;';
    this.pager.nextText = '&rsaquo;';
    this.pager.firstText = '&laquo;';
    this.pager.lastText = '&raquo;';

    this.pager.maxSize = 3;
    this.pager.boundaryLinks = true;
  }
}
