<div [ngClass]="{ container: !env.elements }" *ngIf="!!course">
  <div class="row">
    <div class="col-xs-12">
      <ol class="breadcrumb">
        <li><a (click)="goToCourses()" style="cursor: pointer">Cursos</a></li>
        <li *ngIf="course.families.length > 0">
          {{ getFamily(course) }}
        </li>
        <li class="active">
          {{ course.namePV || course.name | removeReference }}
        </li>
      </ol>
    </div>
    <div class="col-sm-9">
      <div class="row">
        <div class="col-xs-12">
          <h1 style="font-size: 32px">
            {{ course.namePV || course.name | removeReference }}
          </h1>
          <div [ngSwitch]="course.source">
            <div *ngSwitchCase="'ieditorial'" class="course-source-badge">
              I
            </div>
            <div *ngSwitchCase="'vertice'" class="course-source-badge">
              V
            </div>
            <div *ngSwitchCase="'espacio'" class="course-source-badge">
              E
            </div>
            <div *ngSwitchCase="'galicia'" class="course-source-badge">
              G
            </div>
            <div *ngSwitchCase="'fcontenido'" class="course-source-badge">
              F
            </div>
          </div>
          <img
            [src]="course.img"
            [alt]="course.name"
            width="100%"
            *ngIf="
              (course.source === 'ieditorial' && course.img) ||
              (course.source === 'vertice' &&
                (!course.media || !course.media[0]))
            "
            style="max-height: 300px; object-fit: cover;"
          />
          <img
            [src]="course.img"
            [alt]="course.name"
            width="100%"
            *ngIf="course.source === 'galicia' && course.img"
            style="max-height: 300px; object-fit: cover;"
          />
          <iframe
            width="100%"
            height="340"
            [src]="sanitizer.bypassSecurityTrustResourceUrl(course.media[0])"
            frameborder="0"
            allowfullscreen
            *ngIf="
              course.source === 'vertice' && course.media && course.media[0]
            "
          ></iframe>
        </div>
        <div class="col-xs-6">
          <div class="box">
            <p class="title">Modalidades (Horas)</p>
            <p class="content">
              <span class="cms-duration">{{ courses.getVariants(course).first_duration }}</span>
              <span class="cms-duration">{{ courses.getVariants(course).second_duration }}</span>
              <span class="cms-duration">{{ courses.getVariants(course).third_duration }}</span>
              <span *ngIf="!course.duration">A Consultar</span>
            </p>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="box">
            <p class="title">Precio</p>
            <!-- <p class="content">A consultar</p> -->
            <!-- Se muestra para los cursos sin precio o los de fuente distinta a galicia -->
            <p
              class="content"
              *ngIf="
                course.source !== 'galicia' ||
                (course.source === 'galicia' && !course.pricePV)
              "
            >
              A consultar
            </p>
            <p
              class="content"
              *ngIf="course.source === 'galicia' && course.pricePV"
            >
              {{ course.pricePV }}€
            </p>
          </div>
        </div>
        <div class="col-xs-12">
          <tabset>
            <tab heading="Objetivos">
              <div style="margin-top: 10px"></div>
              <div
                [innerHTML]="course.descriptionPV"
                style="padding: 10px"
                *ngIf="course.source === 'galicia'"
              ></div>
              <div
                [innerHTML]="course.description"
                style="padding: 10px"
                *ngIf="course.source === 'ieditorial'"
              ></div>
              <div
                [innerHTML]="course.goals[0]"
                style="padding: 10px"
                *ngIf="course.source === 'espacio'"
              ></div>
              <div
                [innerHTML]="downloadGoals(course.reference)"
                style="padding: 10px"
                *ngIf="course.source === 'fcontenido'"
              ></div>
              <div *ngIf="course.source === 'vertice' && course.goals">
                <ul>
                  <li *ngFor="let goal of course.goals">{{ goal }}</li>
                </ul>
              </div>
            </tab>
            <tab heading="Programa">
              <div
                [innerHTML]="course.syllabusPV"
                style="padding: 10px"
                *ngIf="course.source == 'galicia'"
              ></div>
              <div
                [innerHTML]="course.syllabus"
                style="padding: 10px"
                *ngIf="
                  course.source !== 'galicia' && course.source !== 'fcontenido'
                "
              ></div>
              <div
                [innerHTML]="downloadSyllabus(course.reference)"
                style="padding: 10px"
                *ngIf="course.source === 'fcontenido'"
              ></div>
            </tab>
            <tab heading="Metodología">
              <div style="padding: 10px">
                <p style="text-align:justify">
                  La <strong>METODOLOGÍA ONLINE</strong> propuesta se ajusta a
                  las características y necesidades de cada alumno/a, combinando
                  las metodologías de <strong>enseñanza programada</strong> y de
                  <strong>trabajo autónomo</strong> del alumnado con el
                  <strong
                    >asesoramiento de un/a formador/a especializado</strong
                  >
                  y mediante el uso de las nuevas tecnologías de la información
                  y comunicación, creando un entorno de aprendizaje activo,
                  próximo y colaborativo en el <strong>Campus Virtual</strong>.
                </p>
                <!-- <div style="margin: 20px;">
                  <a
                    href="https://formacionycontenido.com/index.php?controller=attachment&id_attachment=541"
                    target="_blank"
                    class="badge"
                    >Descargar Programa</a
                  >
                </div> -->
              </div>
            </tab>
          </tabset>
        </div>
        <div
          class="col-xs-12"
          *ngIf="course.related && course.related.length > 0"
        >
          <hr />
          <h3>Cursos Relacionados</h3>
        </div>
        <div class="col-xs-12">
          <div class="row" style="margin-left: 0px">
            <div
              class="col-md-4 col-sm-6 cms-card-container"
              *ngFor="let related of course.related"
            >
              <cms-course-detail
                [catalog]="getCatalog()"
                [color]="color"
                [course]="related"
                [register]="register"
              >
              </cms-course-detail>
            </div>
          </div>
          <div style="margin-top: 10px"></div>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="row">
        <div class="col-xs-12">
          <h3>SOLICITA INFORMACIÓN</h3>
        </div>
        <div class="col-xs-12">
          <h4>Por Whatsapp:</h4>
          <a href="https://wa.link/s5hov2"  target="_blank"><img src="https://www.cursosonlinebonificados.es/files/theme/telefonosBonificados.png"></a>
        </div>
        <div class="col-xs-12">
          <h4>Por Email:</h4>
          <cms-course-request-info [course]="course"></cms-course-request-info>
        </div>
      </div>
    </div>
  </div>
</div>
