import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ToastrModule } from 'ngx-toastr';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalModule } from 'ngx-bootstrap/modal';

import { FilesModule } from '@portales/files';

import { environment as env } from '../../../environments/environment';

import { CKEDITOR_CONFIG, ckEditorConfig } from './models/ckeditor.model';

@NgModule({
  exports: [
    FlexLayoutModule,
    CKEditorModule,
    ToastrModule,
    FilesModule,
    NgSelectModule
  ],
  imports: [
    BrowserAnimationsModule,
    FlexLayoutModule,
    CKEditorModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 3,
      autoDismiss: true,
      newestOnTop: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
      onActivateTick: true
    }),
    FilesModule.forRoot('', `${env.filesUrl}`),
    NgSelectModule
  ]
})
export class AdminSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AdminSharedModule,
      providers: [
        {
          provide: CKEDITOR_CONFIG,
          useValue: ckEditorConfig
        }
      ]
    };
  }
}
