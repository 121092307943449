/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./courses-grid.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../course-detail/course-detail.component.ngfactory";
import * as i3 from "../course-detail/course-detail.component";
import * as i4 from "../../../shared/models/environment";
import * as i5 from "../../providers/theme.service";
import * as i6 from "@angular/router";
import * as i7 from "../../providers/courses.service";
import * as i8 from "../../../shared/pipes/color.pipe";
import * as i9 from "@angular/common";
import * as i10 from "ngx-infinite-scroll";
import * as i11 from "./courses-grid.component";
var styles_CouresGridComponent = [i0.styles];
var RenderType_CouresGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouresGridComponent, data: {} });
export { RenderType_CouresGridComponent as RenderType_CouresGridComponent };
function View_CouresGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-4 col-sm-6 cms-card-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cms-course-detail", [], null, null, null, i2.View_CouresDetailComponent_0, i2.RenderType_CouresDetailComponent)), i1.ɵdid(2, 49152, null, 0, i3.CouresDetailComponent, [i4.CMS_ENV, i5.ThemeService, i6.Router, i7.CoursesService], { course: [0, "course"], color: [1, "color"], register: [2, "register"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.color; var currVal_2 = _co.register; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CouresGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-md-12"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn cms-card-body-font"], ["style", "padding: 12px; color: white; margin-top: 48px"], ["type", "button"]], [[4, "backgroundColor", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appendCourses() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵted(-1, null, [" CARGAR M\u00C1S CURSOS "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.theme.color)); _ck(_v, 2, 0, currVal_0); }); }
export function View_CouresGridComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.ColorPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouresGridComponent_1)), i1.ɵdid(3, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouresGridComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "search-results"], ["infiniteScroll", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.appendCourses() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 4866048, null, 0, i10.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollThrottle: [1, "infiniteScrollThrottle"] }, { scrolled: "scrolled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rows; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.moreCourses; _ck(_v, 5, 0, currVal_1); var currVal_2 = 1; var currVal_3 = 150; _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_CouresGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-courses-grid", [], null, null, null, View_CouresGridComponent_0, RenderType_CouresGridComponent)), i1.ɵdid(1, 114688, null, 0, i11.CouresGridComponent, [i7.CoursesService, i5.ThemeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CouresGridComponentNgFactory = i1.ɵccf("cms-courses-grid", i11.CouresGridComponent, View_CouresGridComponent_Host_0, { q: "q", families: "families", prefilter: "prefilter", color: "color", minHours: "min-hours", maxHours: "max-hours", register: "register" }, {}, []);
export { CouresGridComponentNgFactory as CouresGridComponentNgFactory };
