/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./new-render.component";
import * as i3 from "../../providers/rss-new.service";
import * as i4 from "@angular/router";
var styles_NewRenderComponent = [];
var RenderType_NewRenderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewRenderComponent, data: {} });
export { RenderType_NewRenderComponent as RenderType_NewRenderComponent };
function View_NewRenderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rssNew.img; _ck(_v, 1, 0, currVal_0); }); }
function View_NewRenderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
export function View_NewRenderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewRenderComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["FUENTE: ", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewRenderComponent_2)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.rssNew.img; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.getArticle(); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rssNew.title; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.rssNew.source; _ck(_v, 9, 0, currVal_2); }); }
export function View_NewRenderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-new-render", [], null, null, null, View_NewRenderComponent_0, RenderType_NewRenderComponent)), i0.ɵdid(1, 114688, null, 0, i2.NewRenderComponent, [i3.RssNewService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewRenderComponentNgFactory = i0.ɵccf("cms-new-render", i2.NewRenderComponent, View_NewRenderComponent_Host_0, { rssid: "rssid" }, {}, []);
export { NewRenderComponentNgFactory as NewRenderComponentNgFactory };
