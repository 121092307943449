/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/geoguide/geoguide.component.ngfactory";
import * as i2 from "../../components/geoguide/geoguide.component";
import * as i3 from "../../../shared/models/environment";
import * as i4 from "../../../shared/providers/query-string.service";
import * as i5 from "../../providers/geodestinos.service";
import * as i6 from "@nicky-lenaers/ngx-scroll-to";
import * as i7 from "../../../shared/providers/media.service";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/router";
import * as i10 from "./geoguide-lab.component";
var styles_GeoGuideLabComponent = [];
var RenderType_GeoGuideLabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GeoGuideLabComponent, data: {} });
export { RenderType_GeoGuideLabComponent as RenderType_GeoGuideLabComponent };
export function View_GeoGuideLabComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-geoguide", [], null, null, null, i1.View_GeoGuideComponent_0, i1.RenderType_GeoGuideComponent)), i0.ɵdid(1, 114688, null, 0, i2.GeoGuideComponent, [i3.CMS_ENV, i4.QueryStringService, i5.GeodestinosService, i6.ScrollToService, i7.MediaService, i8.FormBuilder, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GeoGuideLabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-geoguide-lab", [], null, null, null, View_GeoGuideLabComponent_0, RenderType_GeoGuideLabComponent)), i0.ɵdid(1, 49152, null, 0, i10.GeoGuideLabComponent, [], null, null)], null, null); }
var GeoGuideLabComponentNgFactory = i0.ɵccf("cms-geoguide-lab", i10.GeoGuideLabComponent, View_GeoGuideLabComponent_Host_0, {}, {}, []);
export { GeoGuideLabComponentNgFactory as GeoGuideLabComponentNgFactory };
