import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AdminSharedModule } from '../shared/admin-shared.module';
import { SharedModule } from '../../shared/shared.module';

import { SourceComponent } from './components/source/source.component';
import { TagModalComponent } from './components/tag-modal/tag-modal.component';

import { AdminCoursesComponent } from './containers/courses/courses.component';
import { AdminCourseDetailComponent } from './containers/course-detail/course-detial.component';
import { AdminFamiliesComponent } from './containers/families/families.component';
import { AdminTagsComponent } from './containers/tags/tags.component';
import { AdminPVFamiliesComponent } from './containers/pv-families/pv-families.component';
import { AdminPVFamilyDetailComponent } from './containers/pv-family-detail/pv-family-detail.component';
import { AdminEntrustsComponent } from './containers/entrusts/entrusts.component';

import { ROUTES } from './admin-iefp.routing';
import { AdminFamiliesDetailComponent } from './containers/families-detail/families-detail.component';
import { AuthModule } from '../auth/auth.module';
import { AdminCourseUploadExcelComponent } from './containers/course-upload-excel/course-upload-excel.component';

const components = [TagModalComponent, SourceComponent];

const containers = [
  AdminCoursesComponent,
  AdminCourseDetailComponent,
  AdminEntrustsComponent,
  AdminFamiliesComponent,
  AdminFamiliesDetailComponent,
  AdminPVFamiliesComponent,
  AdminPVFamilyDetailComponent,
  AdminTagsComponent,
  AdminCourseUploadExcelComponent
];

@NgModule({
  declarations: [...components, ...containers],
  imports: [
    NgxDatatableModule,
    AdminSharedModule,
    SharedModule,
    RouterModule.forChild(ROUTES),
    DragDropModule,
    AuthModule
  ]
})
export class AdminIEFPModule {}
