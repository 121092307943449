/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../navbar/navbar.component.ngfactory";
import * as i2 from "../navbar/navbar.component";
import * as i3 from "./admin.component";
var styles_AdminComponent = [];
var RenderType_AdminComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-navbar", [], null, null, null, i1.View_NavBarComponent_0, i1.RenderType_NavBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.NavBarComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["style", "padding: 20px"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_AdminComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i0.ɵdid(1, 49152, null, 0, i3.AdminComponent, [], null, null)], null, null); }
var AdminComponentNgFactory = i0.ɵccf("cms-admin", i3.AdminComponent, View_AdminComponent_Host_0, {}, {}, ["*"]);
export { AdminComponentNgFactory as AdminComponentNgFactory };
