import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SharedModule } from '@cms-app/shared/shared.module';

import { AdminCopyblogsComponent } from './containers/articles/articles.component';
import { ROUTES } from './admin-copyblogs.routing';
import { AdminCopyblogsDetailComponent } from './containers/article-detail/article-detail.component';

const containers = [AdminCopyblogsComponent, AdminCopyblogsDetailComponent];

@NgModule({
  declarations: [...containers],
  exports: [...containers],
  imports: [NgxDatatableModule, SharedModule, RouterModule.forChild(ROUTES)]
})
export class AdminCopyblogsModule {}
