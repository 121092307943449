import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { GeodestinosService } from '@cms-app/geodestinos';
import { MessagingService } from '@cms-app/admin/shared';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
var AdminGeoDestinoDetailComponent = /** @class */ (function () {
    function AdminGeoDestinoDetailComponent(router, route, fb, geodestinosService, messaging) {
        this.router = router;
        this.route = route;
        this.fb = fb;
        this.geodestinosService = geodestinosService;
        this.messaging = messaging;
    }
    AdminGeoDestinoDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.type = this.route.snapshot.params.type;
        this.setupGeoDestino(this.route.snapshot.data.geodestino);
        this.route.params
            .pipe(filter(function (params) { return params.id !== _this.geodestino._id || params.id === 'new'; }), switchMap(function (params) {
            return params.id === 'new'
                ? of(_this.geodestinosService.empty(_this.route.snapshot.params.type))
                : _this.geodestinosService.findOne(_this.route.snapshot.params.type, params.id);
        }))
            .subscribe(function (geodestino) { return _this.setupGeoDestino(geodestino); });
    };
    AdminGeoDestinoDetailComponent.prototype.goToSection = function () {
        switch (this.type) {
            case 'quedar':
                this.router.navigate(['admin', 'geodestinos', 'bars']);
                break;
            case 'comer':
                this.router.navigate(['admin', 'geodestinos', 'restaurants']);
                break;
            case 'visitar':
                this.router.navigate(['admin', 'geodestinos', 'activities']);
                break;
            case 'alojamiento':
            default:
                this.router.navigate(['admin', 'geodestinos', 'hotels']);
        }
    };
    AdminGeoDestinoDetailComponent.prototype.sectionName = function () {
        switch (this.type) {
            case 'quedar':
                return 'Bares';
            case 'visitar':
                return 'Actividades';
            case 'comer':
                return 'Restaurantes';
            case 'alojamiento':
            default:
                return 'Hoteles';
        }
    };
    AdminGeoDestinoDetailComponent.prototype.isActivity = function () {
        return this.type === 'visitar';
    };
    AdminGeoDestinoDetailComponent.prototype.save = function () {
        var _this = this;
        if (this.geodestinoForm.valid) {
            var request = void 0;
            if (this.geodestino._id === null) {
                var data = tslib_1.__assign({}, this.geodestinoForm.value, { name: this.geodestinoForm.get('namePV').value, url: this.geodestinoForm.get('urlPV').value, location: this.geodestinoForm.get('locationPV').value, zipCode: this.geodestinoForm.get('zipCodePV').value, state: this.geodestinoForm.get('statePV').value, description: this.geodestinoForm.get('descriptionPV').value, address: this.geodestinoForm.get('addressPV').value, email: this.geodestinoForm.get('emailPV').value, category: this.geodestinoForm.get('categoryPV').value });
                request = this.geodestinosService.add(this.type, data);
            }
            else {
                request = this.geodestinosService.patch(this.type, this.geodestino._id, this.geodestinoForm.value);
            }
            request.subscribe(function (saved) {
                // ACTUALIZACION CORRECTA
                _this.messaging.success('Geodestino actualizado correctamente', 'CMS ADMIN');
                // redireccionar
                if (!!saved) {
                    _this.goToSection();
                }
            }, function () {
                // ACTUALIZACION ERRONEA
                _this.messaging.error('Error actualizando el geodestino', 'CMS ADMIN');
            });
        }
    };
    Object.defineProperty(AdminGeoDestinoDetailComponent.prototype, "img", {
        get: function () {
            return this.geodestinoForm.get('img');
        },
        enumerable: true,
        configurable: true
    });
    AdminGeoDestinoDetailComponent.prototype.addImg = function (stringImg) {
        if (stringImg === void 0) { stringImg = ''; }
        this.img.push(this.fb.control(stringImg));
    };
    AdminGeoDestinoDetailComponent.prototype.removeImg = function (idx) {
        this.img.removeAt(idx);
    };
    Object.defineProperty(AdminGeoDestinoDetailComponent.prototype, "phone", {
        get: function () {
            return this.geodestinoForm.get('phone');
        },
        enumerable: true,
        configurable: true
    });
    AdminGeoDestinoDetailComponent.prototype.addPhone = function (stringImg) {
        if (stringImg === void 0) { stringImg = ''; }
        this.phone.push(this.fb.control(stringImg));
    };
    AdminGeoDestinoDetailComponent.prototype.removePhone = function (idx) {
        this.phone.removeAt(idx);
    };
    //IMPLEMENTATION
    AdminGeoDestinoDetailComponent.prototype.mapToFormGroup = function (geodestino) {
        var _this = this;
        var img = this.geodestino ? this.geodestino.img : [];
        var phone = this.geodestino ? this.geodestino.phone : [];
        var form;
        switch (this.type) {
            case 'visitar':
                form = this.fb.group({
                    namePV: [geodestino.namePV],
                    type: [geodestino.type],
                    urlPV: [geodestino.urlPV],
                    statePV: [geodestino.statePV],
                    locationPV: [geodestino.locationPV],
                    zipCodePV: [geodestino.zipCodePV],
                    coordinatesPV: [geodestino.coordinatesPV],
                    img: this.fb.array(img.map(function (src) { return _this.fb.control(src); })),
                    addressPV: [geodestino.addressPV],
                    phone: this.fb.array(phone.map(function (phone) { return _this.fb.control(phone); })),
                    emailPV: [geodestino.emailPV],
                    descriptionPV: [geodestino.descriptionPV],
                    disable: [geodestino.disable],
                    certCalidad: [geodestino.certCalidad],
                    certCalidadLink: [geodestino.certCalidadLink],
                    fidelity: [geodestino.fidelity],
                    fidelityLink: [geodestino.fidelityLink],
                    galicia: [geodestino.galicia],
                    actTypePV: [geodestino.actTypePV],
                    promo: [geodestino.promo],
                    promoUrl: [geodestino.promoUrl],
                    promoDiscount: [geodestino.promoDiscount]
                });
                break;
            default:
                form = this.fb.group({
                    namePV: [geodestino.namePV],
                    type: [geodestino.type],
                    urlPV: [geodestino.urlPV],
                    statePV: [geodestino.statePV],
                    locationPV: [geodestino.locationPV],
                    zipCodePV: [geodestino.zipCodePV],
                    coordinatesPV: [geodestino.coordinatesPV],
                    img: this.fb.array(img.map(function (src) { return _this.fb.control(src); })),
                    addressPV: [geodestino.addressPV],
                    phone: this.fb.array(phone.map(function (phone) { return _this.fb.control(phone); })),
                    emailPV: [geodestino.emailPV],
                    categoryPV: [geodestino.categoryPV],
                    descriptionPV: [geodestino.descriptionPV],
                    disable: [geodestino.disable],
                    certCalidad: [geodestino.certCalidad],
                    certCalidadLink: [geodestino.certCalidadLink],
                    fidelity: [geodestino.fidelity],
                    fidelityLink: [geodestino.fidelityLink],
                    galicia: [geodestino.galicia],
                    promo: [geodestino.promo],
                    promoUrl: [geodestino.promoUrl],
                    promoDiscount: [geodestino.promoDiscount]
                });
        }
        return form;
    };
    AdminGeoDestinoDetailComponent.prototype.setupGeoDestino = function (geodestino) {
        if (geodestino === void 0) { geodestino = this.geodestinosService.empty(this.type); }
        this.geodestino = geodestino;
        this.geodestinoForm = this.mapToFormGroup(this.geodestino);
    };
    return AdminGeoDestinoDetailComponent;
}());
export { AdminGeoDestinoDetailComponent };
