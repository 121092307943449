import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  FamiliesService,
  Family,
  PVFamiliesService,
  PVFamily
} from '@cms-app/courses';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cms-admin-families',
  templateUrl: './families.component.html'
})
export class AdminFamiliesComponent implements OnInit {
  rows: Array<Family & { reference: string[] }> = [];
  count = 0;
  page = 1;
  query = '';
  loading = false;

  pvFamilies: Array<Partial<PVFamily>>;

  constructor(
    private familiesService: FamiliesService,
    private pvFamiliesService: PVFamiliesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.pvFamiliesService
      .find({
        fields: 'name,families',
        per_page: 200
      })
      .pipe(map(data => data.list))
      .subscribe(rows => {
        this.pvFamilies = rows;
      });
    this.find();
  }

  checkLink(row: Family) {
    window.open(row.url, '_blank');
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  onAdd() {
    this.router.navigate(['admin', 'iefp', 'families', 'new']);
  }

  update(row: Family) {
    this.router.navigate(['admin', 'iefp', 'families', row._id]);
  }

  find() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }
    if (this.query) {
      options['name>$like'] = this.query;
    }

    this.loading = true;
    this.familiesService
      .find({
        ...options,
        per_page: 200
      })
      .subscribe(
        ({ list, count }) => {
          this.rows = list.map(row => {
            const used = this.pvFamilies.filter(
              reference => (reference.families as any[]).indexOf(row._id) >= 0
            );

            return {
              ...row,
              reference: used.map(row => row.name)
            };
          });
          this.count = count;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  getRowHeight() {
    return row => (!!row ? 25 + 22 * Math.max(row.reference.length, 1) : 50);
  }
}
