<div *ngIf="entrust" style="margin: auto; max-width: 800px; padding: 5%">
  <div style="text-align: center">
    <img
      src="https://creator.zoho.com/appbuilder/direccion3/cursostablet/downLoadImage?imgFileId=4156068000000060122&imgFileName=1421953727670_1127368.png"
      alt=""
      style="text-align: center"
    />
  </div>

  <h3 style="text-align: center">MENSAJE ENVIADO CORRECTAMENTE</h3>

  <p style="text-align: center">
    Recibirá, en el correo que nos ha facilitado, el formulario que acaba de
    cubrir. Siga las siguientes instrucciones que aparecen el el correo:
  </p>
  <ol>
    <li>Imprima el formulario</li>
    <li>Fírmelo</li>
    <li>Séllelo</li>
    <li>Envíelo por e-mail a: {{ email }}</li>
  </ol>
  <p style="text-align: center">
    Una vez aprobada su adhesión, le informaremos del crédito formativo y podrá
    continuar con el siguiente paso. Muchas gracias.
  </p>
</div>
