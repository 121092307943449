import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SharedModule } from '@cms-app/shared/shared.module';

import { AdminUsersComponent } from './containers/users/users.component';
import { ROUTES } from './admin-users.routing';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../auth/services/authconfig.interceptor';
import { UserDetailComponent } from './containers/user-detail/UserDetailComponent';
import { FormsModule } from '@angular/forms';

const containers = [AdminUsersComponent, UserDetailComponent];

@NgModule({
  declarations: [...containers],
  exports: [...containers],
  imports: [NgxDatatableModule, SharedModule, RouterModule.forChild(ROUTES), FormsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
})
export class AdminUsersModule {}
