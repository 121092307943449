import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { filter, switchMap, startWith } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { CMSEnv, QueryStringService } from '@cms-app/shared';
import { ThemeService } from '../../providers/theme.service';
import { CoursesService } from '../../providers/courses.service';
import { EntrustService } from '../../providers/entrust.service';
import { CoursePlatformsService } from '../../providers/platforms.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
var CourseEntrustComponent = /** @class */ (function () {
    function CourseEntrustComponent(env, sanitizer, query, localeService, router, theme, route, entrust, courses, platforms, fb) {
        this.env = env;
        this.sanitizer = sanitizer;
        this.query = query;
        this.localeService = localeService;
        this.router = router;
        this.theme = theme;
        this.route = route;
        this.entrust = entrust;
        this.courses = courses;
        this.platforms = platforms;
        this.fb = fb;
        this.okurl = 'inscripcion-ok.html';
        this.color = 'red';
        this.onlycredit = 'false';
        this.platform = this.platforms.guess();
        this.step = 1;
        this.sending = false;
    }
    Object.defineProperty(CourseEntrustComponent.prototype, "privacy", {
        get: function () {
            return this.platforms.privacy(this.platform);
        },
        enumerable: true,
        configurable: true
    });
    CourseEntrustComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.onlycredit == 'true') {
            this.step = 2;
        }
        this.localeService.use('es');
        this.theme.schema = this.color;
        var obs;
        if (this.env.elements) {
            var params = this.query.parse(window.location.search);
            if (params && params.id) {
                obs = this.courses.findOne(params.id);
            }
        }
        else {
            this.course = this.route.snapshot.data.course;
            if (this.course) {
                obs = of(this.course);
                this.route.params
                    .pipe(filter(function (params) { return params.id !== _this.course._id; }), switchMap(function (params) { return _this.courses.findOne(params.id); }))
                    .subscribe(function (course) {
                    _this.course = course;
                });
            }
        }
        (obs || of(null)).subscribe(function (course) {
            _this.course = course;
            _this.inscriptionForm = _this.fb.group({
                bonus: ['yes'],
                agent: [''],
                company: _this.fb.group({
                    businessName: [''],
                    vatIdentifier: [''],
                    address: [''],
                    zipCode: [''],
                    city: [''],
                    state: [''],
                    since: [
                        new Date().getFullYear(),
                        [Validators.required, Validators.pattern(/[0-9]*/)],
                    ],
                    agreement: [''],
                    ssAccount: [0],
                    email: ['', [Validators.required, Validators.email]],
                    phone: [''],
                    phone2: [''],
                    accountNumber: [
                        '',
                        [Validators.pattern(_this.entrust.accountNumberRE)],
                    ],
                    delegate: [false],
                    contactPerson: [''],
                    employees: [1],
                    sector: [''],
                    year: [new Date().getFullYear(), [Validators.pattern(/[0-9]*/)]],
                    observations: [''],
                }),
                privacy: [false, [Validators.requiredTrue]],
                newsletter: [false],
                representative: _this.fb.group({
                    name: [''],
                    vatIdentifier: [''],
                }),
                students: _this.fb.array([]),
            });
            _this.addStudent();
            _this.inscriptionForm
                .get('bonus')
                .valueChanges.pipe(startWith(_this.inscriptionForm.get('bonus').value))
                .subscribe(function (value) {
                var only = value === 'entrust';
                var yes = value === 'yes';
                [
                    'businessName',
                    'vatIdentifier',
                    'phone',
                    'since',
                    'employees',
                    'year',
                ].forEach(function (field) {
                    _this.inscriptionForm
                        .get('company')
                        .get(field)
                        .setValidators(yes || only ? [Validators.required] : null);
                });
                ['sector'].forEach(function (field) {
                    _this.inscriptionForm
                        .get('company')
                        .get(field)
                        .setValidators(yes ? [Validators.required] : null);
                });
                ['name', 'vatIdentifier'].forEach(function (field) {
                    _this.inscriptionForm
                        .get('representative')
                        .get(field)
                        .setValidators(yes || only ? [Validators.required] : null);
                });
                if (only) {
                    var students = _this.inscriptionForm.get('students');
                    students.controls.forEach(function (student) {
                        Object.keys(student.controls).forEach(function (key) {
                            student.get(key).setValidators(null);
                        });
                    });
                }
                else {
                    var students = _this.inscriptionForm.get('students');
                    students.controls.forEach(function (student) {
                        ['name', 'subname', 'subname2', 'email', 'course', 'sex'].forEach(function (key) {
                            student.get(key).setValidators([Validators.required]);
                        });
                        [
                            'phone',
                            'vatIdentifier',
                            'ssNumber',
                            'birthdate',
                            'studies',
                            'cotization',
                        ].forEach(function (key) {
                            student
                                .get(key)
                                .setValidators(yes ? [Validators.required] : null);
                        });
                    });
                }
            });
        });
    };
    CourseEntrustComponent.prototype.addStudent = function () {
        var students = this.inscriptionForm.get('students');
        var bonus = this.inscriptionForm.get('bonus').value;
        var student;
        if (bonus === 'no') {
            student = this.fb.group({
                name: ['', [Validators.required]],
                subname: ['', [Validators.required]],
                subname2: [''],
                email: ['', [Validators.required, Validators.email]],
                phone: ['', [Validators.required]],
                course: [this.course ? this.course.name : '', [Validators.required]],
                vatIdentifier: ['', [Validators.required]],
                sex: ['', [Validators.required]],
                ssNumber: [''],
                birthdate: [''],
                studies: [''],
                cotization: [''],
                observations: ['']
            });
        }
        else {
            student = this.fb.group({
                name: ['', [Validators.required]],
                subname: ['', [Validators.required]],
                subname2: [''],
                email: ['', [Validators.required, Validators.email]],
                phone: ['', bonus ? [Validators.required] : []],
                course: [this.course ? this.course.name : '', [Validators.required]],
                vatIdentifier: ['', bonus ? [Validators.required] : []],
                ssNumber: ['', bonus ? [Validators.required] : []],
                sex: ['', [Validators.required]],
                birthdate: [
                    '',
                    bonus ? [Validators.required] : [],
                ],
                studies: ['', bonus ? [Validators.required] : []],
                cotization: ['', bonus ? [Validators.required] : []],
                observations: ['']
            });
        }
        if (this.course && this.course.ucm) {
            student.addControl('poll', this.fb.group({
                title: ['', [Validators.required]],
                graduation_year: ['', [Validators.required]],
                first_time: ['', [Validators.required]],
                ucm_link: ['', [Validators.required]],
                actual_job: ['', [Validators.required]],
                job_type: ['', [Validators.required]],
                actual_occupation: ['', [Validators.required]],
                sector: ['', [Validators.required]],
                job: ['', [Validators.required]],
                motivation: ['', [Validators.required]],
                ucm_motivation: ['', [Validators.required]],
                course_motivation: ['', [Validators.required]]
            }));
        }
        students.push(student);
    };
    CourseEntrustComponent.prototype.removeStudent = function (idx) {
        var students = this.inscriptionForm.get('students');
        students.removeAt(idx);
    };
    CourseEntrustComponent.prototype.send = function () {
        var _this = this;
        if (!this.sending && this.inscriptionForm.valid) {
            var formData = this.inscriptionForm.value;
            var agentElement = this.inscriptionForm.get('agent');
            if (agentElement) {
                this.agent = agentElement.value;
            }
            else {
                this.agent = "-";
            }
            this.inscriptionForm.removeControl('agent');
            if (formData.bonus === 'entrust') {
                this.sending = true;
                this.entrust
                    .pushOnlyEntrust(formData, this.platform, this.agent)
                    .subscribe(function (entrust) {
                    if (_this.env.elements) {
                        window.open(window.location.origin + "/" + _this.okurl + "?entrust=" + entrust._id, '_self');
                    }
                    else {
                        _this.router.navigate(['entrust', 'ok', entrust._id]);
                    }
                    _this.sending = false;
                }, function () {
                    _this.sending = false;
                });
            }
            if (formData.bonus === 'yes') {
                this.sending = true;
                combineLatest(this.entrust.pushOnlyEntrust(formData, this.platform, this.agent), this.entrust.pushYesEntrust(formData, this.platform, this.agent)).subscribe(function (_a) {
                    var _ = _a[0], entrusts = _a[1];
                    if (entrusts[0]) {
                        if (_this.env.elements) {
                            window.open(window.location.origin + "/" + _this.okurl + "?entrust=" + entrusts[0]._id, '_self');
                        }
                        else {
                            _this.router.navigate(['entrust', 'ok', entrusts[0]._id]);
                        }
                    }
                    _this.sending = false;
                }, function () {
                    _this.sending = false;
                });
            }
            if (formData.bonus === 'no') {
                this.sending = true;
                this.entrust.pushNoEntrust(formData, this.platform).subscribe(function (entrusts) {
                    if (entrusts[0]) {
                        if (_this.env.elements) {
                            window.open(window.location.origin + "/" + _this.okurl + "?entrust=" + entrusts[0]._id, '_self');
                        }
                        else {
                            _this.router.navigate(['entrust', 'ok', entrusts[0]._id]);
                        }
                    }
                    _this.sending = false;
                }, function () {
                    _this.sending = false;
                });
            }
        }
        else {
            this.handleError(this.inscriptionForm);
        }
    };
    CourseEntrustComponent.prototype.handleError = function (form) {
        var _this = this;
        if (window.console) {
            console.log(this.inscriptionForm);
        }
        var privacy = form.get('privacy');
        if (privacy.invalid) {
            privacy.markAsTouched();
            this.step = 2;
            return;
        }
        var studentsArray = form.get('students');
        if (studentsArray.invalid) {
            this.step = 1;
            studentsArray.controls.forEach(function (student) {
                if (student.invalid) {
                    _this.handleErrorFormGroup(student);
                }
            });
            return;
        }
        var companyForm = form.get('company');
        if (companyForm.invalid) {
            this.step = 2;
            this.handleErrorFormGroup(companyForm);
        }
    };
    CourseEntrustComponent.prototype.handleErrorFormGroup = function (group) {
        Object.keys(group.controls).forEach(function (key) {
            group.controls[key].markAsTouched();
        });
    };
    // ROUTES
    CourseEntrustComponent.prototype.goToCourses = function () {
        if (this.env.elements) {
            var params = this.query.parse(window.location.search);
            window.open(this.catalog || params.back, '_self');
        }
        else {
            this.router.navigate(['courses', 'catalogue']);
        }
    };
    CourseEntrustComponent.prototype.onStepChange = function (step) {
        console.log(step);
        this.step = step;
    };
    CourseEntrustComponent.prototype.getCatalog = function () {
        var params = this.query.parse(window.location.search);
        return this.catalog || params.back;
    };
    CourseEntrustComponent.prototype.goToCourse = function (related) {
        this.router.navigate(['admin', 'courses', related._id]);
    };
    CourseEntrustComponent.prototype.goToFamily = function (related) {
        this.router.navigate(['admin', 'families', related._id]);
    };
    return CourseEntrustComponent;
}());
export { CourseEntrustComponent };
