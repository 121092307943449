import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { startWith, switchMap, takeWhile } from 'rxjs/operators';
import { ERROR, FINISHED, WORKING } from '../../interfaces/response';
import { ProviderService } from '../../services/provider.service';

@Component({
  selector: 'app-comparator',
  templateUrl: './comparator.component.html',
  styleUrls: ['./comparator.componet.scss']
})
export class ComparatorComponent implements OnInit {
  compareFamiliesString: string;
  compareCoursesString: string;
  updateFamiliesString: string;
  updateCoursesString: string;
  disableUpdateFamiliesButton: boolean;
  disableUpdateCoursesButton: boolean;
  compareFamiliesError: boolean;
  compareCoursesError: boolean;
  updateFamiliesError: boolean;
  updateCoursesError: boolean;


  constructor(private providerService: ProviderService) { }

  ngOnInit(): void {
    this.disableUpdateFamiliesButton=true;
    this.disableUpdateCoursesButton=true;
  }

  compareFamilies(){
    this.compareFamiliesError=false;
    this.compareFamiliesString="Working...";
    let subs = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.providerService.compareFamilies()),
       )
      .subscribe(res => {
        if(res.status === FINISHED ||  res.status === ERROR ){
          subs.unsubscribe();
          this.compareFamiliesString=res.msg;
          this.disableUpdateFamiliesButton = false;
        }
      });
  }

  updateFamilies(){
    this.updateFamiliesError=false;
    this.updateFamiliesString="Working...";
    let subs = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.providerService.updateFamilies()),
       )
      .subscribe(res => {
        if(res.status === FINISHED ||  res.status === ERROR ){
          subs.unsubscribe();
          this.updateFamiliesString=res.msg;
        }
      });
  }

  compareCourses(){
    this.compareCoursesError=false;
    this.compareCoursesString="Working...";
    let subs = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.providerService.compareCourses()),
       )
      .subscribe(res => {
        if(res.status === FINISHED ||  res.status === ERROR ){
          subs.unsubscribe();
          this.compareCoursesString=res.msg;
          this.disableUpdateCoursesButton=false;
        }
      });
  }

  updateCourses(){
    this.updateCoursesError=false;
    this.updateCoursesString="Working...";
    let subs = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.providerService.updateCourses()),
       )
      .subscribe(res => {
        if(res.status === FINISHED ||  res.status === ERROR ){
          subs.unsubscribe();
          this.updateCoursesString=res.msg;
        }
      });
  }


}
