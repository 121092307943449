import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CoursePlatform, CoursePlatformsService, EntrustService } from "@cms-app/courses";
import { CMSEnv, CMS_ENV } from "@cms-app/shared";

@Component({
  selector: 'cms-course-entrust-credit',
  templateUrl: './course-entrust-credit.component.html',
})
export class CourseEntrustCreditComponent implements OnInit {

  inscriptionForm: FormGroup;

  @Input() privacy: string;
  @Input() okurl: string = 'inscripcion-ok.html';
  @Input() platform: CoursePlatform = this.platforms.guess();

  sending = false;
  agent: string;
  loading = false;

  constructor(@Inject(CMS_ENV) public env: CMSEnv,
    private fb: FormBuilder,
    private entrust: EntrustService,
    private platforms: CoursePlatformsService,
    private router: Router,){}

  ngOnInit(): void {
    this.inscriptionForm = this.fb.group({
      agent: [''],
      company: this.fb.group({
        businessName: [''],
        vatIdentifier: [''],
        address: [''],
        zipCode: [''],
        city: [''],
        state: [''],
        since: [
          new Date().getFullYear(),
          [Validators.required, Validators.pattern(/[0-9]*/)],
        ],
        agreement: [''],
        ssAccount: [0],
        email: ['', [Validators.required,  Validators.email]],
        phone: [''],
        phone2: [''],
        accountNumber: [
          '',
          [Validators.pattern(this.entrust.accountNumberRE)],
        ],
        delegate: [false],
        contactPerson: [''],
        employees: [1],
        sector: [''],
        year: [new Date().getFullYear(), [Validators.pattern(/[0-9]*/)]],
        observations: [''],
      }),
      privacy: [false, [Validators.requiredTrue]],
      newsletter: [false],
      representative: this.fb.group({
        name: [''],
        vatIdentifier: [''],
      }),
      students: this.fb.array([]),
    });
  }

  onVatBlur(){
    if (!this.loading) {
      const vat = this.inscriptionForm.get('company').get('vatIdentifier').value;

      if (vat && vat.length > 0) {
        this.loading = true;
        this.entrust.checkCompanyVat(vat).subscribe(
          company => {
            if (company && company.company) {
              this.inscriptionForm.get('company').patchValue(company.company);
              this.inscriptionForm
                .get('representative')
                .patchValue(company.representative);
            }

            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  send(){
    if (!this.sending && this.inscriptionForm.valid) {
      const formData = this.inscriptionForm.value;

      let agentElement = this.inscriptionForm.get('agent');
      if(agentElement){
        this.agent = agentElement.value;
      }else{
        this.agent = "-";
      }
      this.inscriptionForm.removeControl('agent');
      this.sending = true;
        this.entrust
          .pushOnlyEntrust(formData, this.platform, this.agent)
          .subscribe(
            (entrust: { _id: string }) => {
              if (this.env.elements) {
                window.open(
                  `${window.location.origin}/${this.okurl}?entrust=${entrust._id}`,
                  '_self'
                );
              } else {
                this.router.navigate(['entrust', 'ok', entrust._id]);
              }
              this.sending = false;
            },
            () => {
              this.sending = false;
            }
          );
    }else{
      this.handleError(this.inscriptionForm);
    }
  }

  handleError(form: FormGroup) {
    if (window.console) {
      console.log(this.inscriptionForm);
    }

    const privacy = form.get('privacy');
    if (privacy.invalid) {
      privacy.markAsTouched();
      return;
    }
  }

  handleErrorFormGroup(group: FormGroup) {
    Object.keys(group.controls).forEach((key) => {
      group.controls[key].markAsTouched();
    });
  }

}
