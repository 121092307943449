import { Component, Input } from '@angular/core';
import { Source } from '@cms-app/courses';

@Component({
  selector: 'cms-source',
  template: `
    <span class="label label-info" *ngIf="row.source === 'ieditorial'"
      >IEDITORIAL</span
    >
    <span class="label label-primary" *ngIf="row.source === 'vertice'"
      >VERTICE</span
    >
    <span class="label label-warning" *ngIf="row.source === 'galicia'"
      >GALICIA</span
    >
    <span class="label label-success" *ngIf="row.source === 'espacio'"
      >ESPACIO</span
    >
    <span class="label label-info" *ngIf="row.source === 'fcontenido'"
      >FCONTENIDO</span
    >
  `
})
export class SourceComponent {
  @Input() row: { source: Source };
}
