import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { PVFamily } from '../models/pv-family.model';
import { PVFamiliesService } from './pv-families.service';

@Injectable({
  providedIn: 'root'
})
export class PVFamilyResolver implements Resolve<PVFamily> {
  constructor(private families: PVFamiliesService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PVFamily> {
    return route.params.id === '0'
      ? of(null)
      : this.families.findOne(route.params.id);
  }
}
