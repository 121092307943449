import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var FamiliesService = /** @class */ (function () {
    function FamiliesService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/families';
    }
    FamiliesService.prototype.find = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 10, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        //options.top = options.hasOwnProperty('top') ? options.top : true;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    FamiliesService.prototype.groupByName = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 10, top: true }; }
        options.top = options.hasOwnProperty('top') ? options.top : true;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint + "/group-by-name", {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    FamiliesService.prototype.addFamily = function (family) {
        return this.http.post("" + this.env.baseUrl + this.endpoint, {
            name: family.name,
            families: family.families || [],
            url: family.url || '',
            top: family.top !== false ? true : false,
            source: family.source || 'galicia'
        });
    };
    FamiliesService.prototype.empty = function () {
        return {
            _id: null,
            name: '',
            url: null,
            top: true,
            source: 'galicia',
            families: []
        };
    };
    FamiliesService.prototype.findOne = function (family) {
        var params = new HttpParams().set('expand', 'related,families.name');
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/" + family, {
            params: params
        });
    };
    FamiliesService.prototype.add = function (payload) {
        return this.http.post("" + this.env.baseUrl + this.endpoint, payload);
    };
    FamiliesService.prototype.update = function (family, payload) {
        return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + family, payload);
    };
    FamiliesService.ngInjectableDef = i0.defineInjectable({ factory: function FamiliesService_Factory() { return new FamiliesService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: FamiliesService, providedIn: "root" });
    return FamiliesService;
}());
export { FamiliesService };
