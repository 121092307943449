import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { SharedModule } from "@cms-app/shared/shared.module";
import { BlogNewsListComponent } from "./components/blog-new-list/blognews-list.component";
import { BlogNewComponent } from "./components/blog-new/blognew.component";
import { BlogNewRenderComponent } from "./components/blognew-render/blognew-render.component";
import { NewRenderComponent } from "./components/new-render/new-render.component";
import { NewComponent } from "./components/new/new.component";
import { NewsListComponent } from "./components/news-list/news-list.component";
import { RssNewService } from "./providers/rss-new.service";
import { RssSrcService } from "./providers/rss-src.service";

const components = [
  NewComponent,
  NewsListComponent,
  NewRenderComponent,
  BlogNewsListComponent,
  BlogNewComponent,
  BlogNewRenderComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
  ],
  providers: [
    RssNewService,
    RssSrcService,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    }
  ]
})
export class RssModule {}
