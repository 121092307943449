import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EntrustService } from '../../providers/entrust.service';

@Component({
  selector: 'cms-course-entrust-company',
  templateUrl: './course-entrust-company.component.html'
})
export class CourseEntrustCompanyComponent {
  @Input() parent: FormGroup;
  @Input() agent: string;
  @Input() privacy: string;

  loading = false;

  constructor(private entrust: EntrustService) {}

  @Output() previous = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  onVatBlur() {
    if (!this.loading && this.parent.get('bonus').value !== 'no') {
      const vat = this.parent.get('company').get('vatIdentifier').value;

      if (vat && vat.length > 0) {
        this.loading = true;
        this.entrust.checkCompanyVat(vat).subscribe(
          company => {
            if (company && company.company) {
              this.parent.get('company').patchValue(company.company);
              this.parent
                .get('representative')
                .patchValue(company.representative);
            }

            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    }
  }
}
