import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { of } from 'rxjs';
import { filter, switchMap, first, map } from 'rxjs/operators';
import { FilePickerComponent } from '@portales/files';
import { CoursesService, FamiliesService, TagsService } from '@cms-app/courses';
import { MessagingService } from '@cms-app/admin/shared';
import { BsModalService } from 'ngx-bootstrap/modal';
var AdminCourseDetailComponent = /** @class */ (function () {
    function AdminCourseDetailComponent(ckConfig, modalService, fb, router, route, messaging, courses, familiesService, tagsService) {
        this.ckConfig = ckConfig;
        this.modalService = modalService;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.messaging = messaging;
        this.courses = courses;
        this.familiesService = familiesService;
        this.tagsService = tagsService;
        this.subfamiliesList = [];
        this.tagsList = [];
    }
    AdminCourseDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setupCourse(this.route.snapshot.data.course);
        this.route.params
            .pipe(filter(function (params) { return params.id !== _this.course._id || params.id === 'new'; }), switchMap(function (params) {
            return params.id === 'new'
                ? of(_this.courses.empty())
                : _this.courses.findOne(params.id);
        }))
            .subscribe(function (course) { return _this.setupCourse(course); });
        // FAMILIAS
        this.familiesService
            .find({ fields: 'name,source', per_page: 600 })
            .pipe(map(function (res) { return res.list; }))
            .subscribe(function (list) {
            _this.subfamiliesList = list;
        });
        // TAGS
        this.tagsService
            .find({ per_page: 100 })
            .pipe(map(function (res) { return res.list; }))
            .subscribe(function (list) {
            _this.tagsList = list;
        });
        // const original = this.route.snapshot.data.family;
        // if (original) {
        //   this.original = original;
        // }
        // const subfamilies: any[] = this.original ? this.original.families : [];
        // this.family = this.fb.group({
        //   name: this.original ? this.original.name : '',
        //   families: this.fb.array(
        //     subfamilies.map((family: Family) => this.fb.control(family._id))
        //   )
        // });
    };
    Object.defineProperty(AdminCourseDetailComponent.prototype, "array", {
        get: function () {
            return this.courseForm.get('metadata');
        },
        enumerable: true,
        configurable: true
    });
    AdminCourseDetailComponent.prototype.add = function () {
        this.courseForm.get('metadata').push(this.fb.group({
            key: 'campo',
            type: 'text',
            value: ''
        }));
    };
    AdminCourseDetailComponent.prototype.remove = function (idx) {
        var metadatas = this.courseForm.get('metadata');
        metadatas.removeAt(idx);
    };
    AdminCourseDetailComponent.prototype.removeCourse = function () {
        var _this = this;
        this.courses.remove(this.course._id).subscribe(function () {
            _this.messaging.success('Curso eliminado correctamente', 'CMS ADMIN');
            _this.router.navigate(['admin', 'iefp', 'courses']);
        }, function () {
            _this.messaging.error('Error eliminado el curso', 'CMS ADMIN');
        });
    };
    AdminCourseDetailComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    };
    AdminCourseDetailComponent.prototype.confirm = function () {
        this.modalRef.hide();
        this.removeCourse();
    };
    AdminCourseDetailComponent.prototype.decline = function () {
        this.modalRef.hide();
    };
    AdminCourseDetailComponent.prototype.save = function () {
        var _this = this;
        if (this.courseForm.valid) {
            if (!this.courseForm.value.modePV) {
                this.courseForm.value.modePV = this.course.mode;
            }
            var request = this.filePicker.upload().pipe(switchMap(function (img) {
                var _a = _this.course, _id = _a._id, course = tslib_1.__rest(_a, ["_id"]);
                var formValue = _this.courseForm.value;
                formValue.families = formValue.families.filter(function (family) { return family.length > 0; });
                formValue.tags = formValue.tags.filter(function (tag) { return tag.length > 0; });
                return _this.course._id === null
                    ? _this.courses.add(tslib_1.__assign({}, course, formValue, { img: img }))
                    : _this.courses.update(_this.course._id, tslib_1.__assign({}, _this.courseForm.value, (img ? { img: img } : {})));
            }), first());
            request.subscribe(function (saved) {
                // ACTUALIZACION CORRECTA
                _this.messaging.success('Curso actualizado correctamente', 'CMS ADMIN');
                // redireccionar
                if (!!saved) {
                    _this.router.navigate(['admin', 'iefp', 'courses', saved._id]);
                }
            }, function () {
                // ACTUALIZACION ERRONEA
                _this.messaging.error('Error actualizando el curso', 'CMS ADMIN');
            });
        }
    };
    AdminCourseDetailComponent.prototype.upload = function () {
        this.router.navigate(['admin', 'iefp', 'courses', 'upload']);
    };
    Object.defineProperty(AdminCourseDetailComponent.prototype, "families", {
        //FAMILIES
        get: function () {
            return this.courseForm.get('families');
        },
        enumerable: true,
        configurable: true
    });
    AdminCourseDetailComponent.prototype.addFamily = function (familyId) {
        if (familyId === void 0) { familyId = ''; }
        this.families.push(this.fb.control(familyId));
    };
    AdminCourseDetailComponent.prototype.removeFamily = function (idx) {
        this.families.removeAt(idx);
    };
    Object.defineProperty(AdminCourseDetailComponent.prototype, "tags", {
        //TAGS
        get: function () {
            return this.courseForm.get('tags');
        },
        enumerable: true,
        configurable: true
    });
    AdminCourseDetailComponent.prototype.addTag = function (tagId) {
        if (tagId === void 0) { tagId = ''; }
        this.tags.push(this.fb.control(tagId));
    };
    AdminCourseDetailComponent.prototype.removeTag = function (idx) {
        this.tags.removeAt(idx);
    };
    // ROUTES
    AdminCourseDetailComponent.prototype.goToCourses = function () {
        this.router.navigate(['admin', 'iefp', 'courses']);
    };
    AdminCourseDetailComponent.prototype.goToCourse = function (related) {
        this.router.navigate(['admin', 'iefp', 'courses', related._id]);
    };
    AdminCourseDetailComponent.prototype.goToFamily = function (related) {
        this.router.navigate(['admin', 'iefp', 'families', related._id]);
    };
    // OTHER
    AdminCourseDetailComponent.prototype.copy = function (source, target) {
        this.courseForm.get(target).setValue(this.course[source]);
    };
    AdminCourseDetailComponent.prototype.isGalicia = function () {
        return this.course.source == 'galicia';
    };
    // IMPLEMENTATION
    AdminCourseDetailComponent.prototype.mapToFormGroup = function (course) {
        var _this = this;
        var metadata = this.fb.array([]);
        course.metadata.forEach(function (row) {
            metadata.push(_this.fb.group(row));
        });
        var families = this.course ? this.course.families : [];
        var tags = this.course ? this.course.tags : [];
        return this.fb.group({
            namePV: [course.namePV],
            descriptionPV: [course.descriptionPV],
            shortDescriptionPV: [course.shortDescriptionPV],
            modePV: [course.modePV],
            pricePV: [course.pricePV],
            durationPV: [course.durationPV],
            syllabusPV: [course.syllabusPV],
            referencePV: [course.referencePV],
            titulationPV: [course.titulationPV],
            orderBy: [course.orderBy],
            active: [course.active],
            metadata: metadata,
            families: this.fb.array(families.map(function (family) { return _this.fb.control(family._id); })),
            tags: this.fb.array(tags.map(function (tag) { return _this.fb.control(tag._id); }))
        });
    };
    AdminCourseDetailComponent.prototype.setupCourse = function (course) {
        if (course === void 0) { course = this.courses.empty(); }
        this.course = course;
        this.courseForm = this.mapToFormGroup(this.course);
    };
    return AdminCourseDetailComponent;
}());
export { AdminCourseDetailComponent };
