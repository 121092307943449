
    <div class="content-title" [formGroup]="searchForm">
      <button
        *ngIf="showAdd"
        class="btn btn-primary"
        style="margin: -8px 10px 0px 0px"
        (click)="searching = !searching"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
      <div class="form-inline" *ngIf="searching" style="display: inline-block">
        <input
          type="text"
          class="form-control"
          style="margin-top: -8px"
          placeholder="Buscar por Nombre"
          formControlName="query"
        />
        <select
        *ngIf="searching && showSearchBySource"
        [id]='source'
        formControlName='source'
        style="color: #000000"
        >
        <option value=""></option>
        <option *ngFor="let source of sources" [value]="source" style="color: #000000">{{source}}</option>
        </select>
        <button
          *ngIf="searching && showExcel"
          class="btn btn-success"
          style="margin: -8px 10px 0px 0px"
          (click)="doExport()"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </button>
      </div>
      <ng-content *ngIf="!searching"></ng-content>
      <button
        *ngIf="showAdd"
        class="btn btn-success pull-right"
        style="margin: 5px 10px 0px 0px"
        (click)="add.emit()"
      >
        +
      </button>
    </div>
  