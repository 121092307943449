<cms-admin>
  <cms-section-title (query)="onQuery($event)" (add)="onAdd()">Restaurantes</cms-section-title>
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
    [limit]="per_page"
    [externalPaging]="true"
    (page)="onPage($event)"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.namePV || row.name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Email
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.emailPV || row.email }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Direccion
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.addressPV || row.address }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Localidad
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.locationPV || row.location }} ({{ row.statePV || row.state }})
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right actions">
          <button
            class="btn btn-primary"
            (click)="update(row)"
            tooltip="Ver / Editar"
            placement="top"
          >
            E
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
