import { EventEmitter } from '@angular/core';
var CourseEntrustStepper = /** @class */ (function () {
    function CourseEntrustStepper() {
        this.color = 'red';
        this.stepChange = new EventEmitter();
    }
    Object.defineProperty(CourseEntrustStepper.prototype, "type", {
        set: function (type) {
            var _this = this;
            this._type = type;
            setTimeout(function () {
                if (type === 'entrust' && _this.step === 1) {
                    _this.stepChange.emit(2);
                }
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    return CourseEntrustStepper;
}());
export { CourseEntrustStepper };
