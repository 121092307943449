/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/geodestino/geodestino.component.ngfactory";
import * as i2 from "../../components/geodestino/geodestino.component";
import * as i3 from "../../providers/geodestinos.service";
import * as i4 from "./geodestinos-lab.component";
var styles_GeoDestinosLabComponent = [];
var RenderType_GeoDestinosLabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GeoDestinosLabComponent, data: {} });
export { RenderType_GeoDestinosLabComponent as RenderType_GeoDestinosLabComponent };
export function View_GeoDestinosLabComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-geodestino", [["city", "Vigo"], ["initial", "comer"]], null, null, null, i1.View_GeoDestinoComponent_0, i1.RenderType_GeoDestinoComponent)), i0.ɵdid(1, 49152, null, 0, i2.GeoDestinoComponent, [i3.GeodestinosService], { city: [0, "city"], initial: [1, "initial"] }, null)], function (_ck, _v) { var currVal_0 = "Vigo"; var currVal_1 = "comer"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GeoDestinosLabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-geodestinos-lab", [], null, null, null, View_GeoDestinosLabComponent_0, RenderType_GeoDestinosLabComponent)), i0.ɵdid(1, 49152, null, 0, i4.GeoDestinosLabComponent, [], null, null)], null, null); }
var GeoDestinosLabComponentNgFactory = i0.ɵccf("cms-geodestinos-lab", i4.GeoDestinosLabComponent, View_GeoDestinosLabComponent_Host_0, {}, {}, []);
export { GeoDestinosLabComponentNgFactory as GeoDestinosLabComponentNgFactory };
