import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var CoursesService = /** @class */ (function () {
    function CoursesService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/courses';
    }
    CoursesService.prototype.find = function (options) {
        var _this = this;
        if (options === void 0) { options = { page: 1, per_page: 48, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 48;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body.map(function (course) {
                return _this.adaptCourseForCatalogue(course);
            }),
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    CoursesService.prototype.search = function (options) {
        var _this = this;
        if (options === void 0) { options = { page: 1, per_page: 48, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 48;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint + "/search", {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body.map(function (course) {
                return _this.adaptCourseForCatalogue(course);
            }),
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    CoursesService.prototype.excelFromSearch = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 50, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 50;
        options.format = 'xls';
        options.expand = 'families.name,families.top';
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        var url = "" + this.env.baseUrl + this.endpoint + "/search?" + params.toString();
        window.open(url, '_blank');
    };
    CoursesService.prototype.findOne = function (course) {
        var _this = this;
        var params = new HttpParams().set('expand', 'related,families.name,tags.name');
        return this.http
            .get("" + this.env.baseUrl + this.endpoint + "/" + course, {
            params: params
        })
            .pipe(map(function (r) { return _this.adaptCourseForCatalogue(r); }));
    };
    CoursesService.prototype.excel = function (query) {
        window.open(this.env.baseUrl + "/courses/excel?q=" + query, '_blank');
    };
    CoursesService.prototype.add = function (payload) {
        return this.http.post("" + this.env.baseUrl + this.endpoint, payload);
    };
    CoursesService.prototype.update = function (course, payload) {
        return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + course, payload);
    };
    CoursesService.prototype.remove = function (courseId) {
        return this.http.delete("" + this.env.baseUrl + this.endpoint + "/" + courseId);
    };
    CoursesService.prototype.getSources = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/sources");
    };
    CoursesService.prototype.uploadFile = function (file) {
        return this.http.post("" + this.env.baseUrl + this.endpoint + "/upload", file).pipe(map(function (res) { return res; }));
    };
    CoursesService.prototype.empty = function () {
        return {
            _id: null,
            active: true,
            description: null,
            descriptionPV: '',
            duration: null,
            durationPV: 52,
            families: [],
            goals: [],
            tags: [],
            img: '',
            metadata: [],
            mode: 'online',
            name: null,
            namePV: '',
            price: null,
            pricePV: null,
            reference: '',
            referencePV: '',
            related: [],
            shortDescription: null,
            shortDescriptionPV: '',
            syllabus: null,
            syllabusPV: '',
            url: '',
            source: 'galicia',
            ucm: false
        };
    };
    CoursesService.prototype.getVariants = function (course) {
        var duration = {
            first_duration: 0,
            second_duration: 0,
            third_duration: 0
        };
        if (course.duration) {
            if (course.duration <= 30) {
                duration.first_duration = 10;
                duration.second_duration = 20;
                duration.third_duration = 30;
            }
            else if (course.duration > 30 && course.duration <= 100) {
                duration.first_duration = 52;
                duration.second_duration = 60;
                duration.third_duration = 75;
            }
            else if (course.duration > 100 && course.duration <= 190) {
                duration.first_duration = 100;
                duration.second_duration = 120;
                duration.third_duration = 180;
            }
            else {
                duration.first_duration = 200;
                duration.second_duration = 300;
                duration.third_duration = 400;
            }
            var min_1 = 100000;
            var Lkey_1 = '';
            Object.keys(duration).forEach(function (key) {
                var resta = course.duration - duration[key];
                if (Math.abs(resta) < min_1) {
                    min_1 = Math.abs(resta);
                    Lkey_1 = key;
                }
            });
            duration[Lkey_1] = duration[Lkey_1] + '.';
        }
        if (course.durationPV) {
            if (course.durationPV <= 30) {
                duration.first_duration = 10;
                duration.second_duration = 20;
                duration.third_duration = 30;
            }
            else if (course.durationPV > 30 && course.duration <= 100) {
                duration.first_duration = 52;
                duration.second_duration = 60;
                duration.third_duration = 75;
            }
            else if (course.durationPV > 100 && course.duration <= 190) {
                duration.first_duration = 100;
                duration.second_duration = 120;
                duration.third_duration = 180;
            }
            else {
                duration.first_duration = 200;
                duration.second_duration = 300;
                duration.third_duration = 400;
            }
            var min_2 = 100000;
            var Lkey_2 = '';
            Object.keys(duration).forEach(function (key) {
                var resta = course.duration - duration[key];
                if (Math.abs(resta) < min_2) {
                    min_2 = Math.abs(resta);
                    Lkey_2 = key;
                }
            });
            duration[Lkey_2] = duration[Lkey_2] + '.';
        }
        return duration;
    };
    CoursesService.prototype.adaptCourseForCatalogue = function (course) {
        return tslib_1.__assign({}, course, { duration: course.durationPV || course.duration || CoursesService.defaultDuration, shortDescription: course.shortDescription && course.shortDescription.length > 0
                ? course.shortDescription
                : course.name, img: this.checkImageAndSetDefaultIfNotValid(course) });
    };
    CoursesService.prototype.checkImageAndSetDefaultIfNotValid = function (course) {
        if (course.credits) {
            return CoursesService.nebrijaPicture;
        }
        if (!course.img) {
            return CoursesService.basePicture;
        }
        if (course.source === 'ieditorial') {
            var mappedImages = [
                'http://ieditorial.net/img/p/es-default-producto_ieditorial.jpg',
                'https://ieditorial.net/img/p/es-default-producto_ieditorial.jpg',
                'http://ieditorial.net.local/img/p/es-default-producto_ieditorial.jpg'
            ];
            if (!course.img || mappedImages.indexOf(course.img) >= 0) {
                return CoursesService.basePicture;
            }
        }
        else if (course.source === 'vertice') {
            var video = course.media && course.media[0] ? course.media[0] : null;
            if (!video) {
                return CoursesService.basePicture;
            }
            var match = video.match(/embed\/([A-Za-z0-9]*)\??/);
            return match && match[1]
                ? "https://i3.ytimg.com/vi/" + match[1] + "/hqdefault.jpg"
                : CoursesService.basePicture;
        }
        else if (course.source === 'galicia') {
            return course.img
                ? this.env.picturesUrl + "/" + course.img
                : CoursesService.basePicture;
        }
        return course.img;
    };
    CoursesService.defaultDuration = 52;
    CoursesService.basePicture = 'https://portales-verticales.s3-eu-west-1.amazonaws.com/logo-iefp.png';
    CoursesService.nebrijaPicture = 'https://portales-verticales.s3-eu-west-1.amazonaws.com/logo-nebrija.png';
    CoursesService.ngInjectableDef = i0.defineInjectable({ factory: function CoursesService_Factory() { return new CoursesService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: CoursesService, providedIn: "root" });
    return CoursesService;
}());
export { CoursesService };
