/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tab.component";
var styles_TabComponent = [".tab-block[_ngcontent-%COMP%] {\n        display: block;\n      }\n      .tab-hidden[_ngcontent-%COMP%] {\n        display: none;\n      }"];
var RenderType_TabComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_TabComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TabComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._selected; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i0.ɵdid(1, 49152, null, 0, i2.TabComponent, [], null, null)], null, null); }
var TabComponentNgFactory = i0.ɵccf("cms-tab", i2.TabComponent, View_TabComponent_Host_0, { name: "name", picture: "picture", selected: "selected" }, { select: "select" }, ["*"]);
export { TabComponentNgFactory as TabComponentNgFactory };
