<div [classList]="'md-stepper-horizontal ' + color">
  <div
    class="md-step done"
    [class.active]="step == 1"
    (click)="stepChange.emit(1)"
    *ngIf="_type !== 'entrust'"
  >
    <div class="md-step-circle"><span>1</span></div>
    <div class="md-step-title">Alumnos</div>
    <div class="md-step-bar-left"></div>
    <div class="md-step-bar-right"></div>
  </div>
  <div
    class="md-step active editable"
    [class.active]="step == 2"
    (click)="stepChange.emit(2)"
  >
    <div class="md-step-circle"><span>2</span></div>
    <div class="md-step-title">Empresa</div>
    <div class="md-step-bar-left"></div>
    <div class="md-step-bar-right"></div>
  </div>
</div>
