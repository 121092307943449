import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileUploadModule } from 'ng2-file-upload';
import { Ng2ImgMaxModule } from 'ng2-img-max';

import { TOKEN, BASE_URL } from './models/files.interface';

import { FilePickerComponent } from './components/filepicker/filepicker.component';
import { ImagePickerComponent } from './components/imagepicker/imagepicker.component';

import { AjaxDownloadService } from './services/ajax-download.service';
import { FilesService } from './services/files.service';
import { VisionService } from './services/vision.service';
import { ImagesService } from './services/images.service';

const components = [FilePickerComponent, ImagePickerComponent];
const containers = [];
const directives = [];
const modules = [CommonModule, FileUploadModule, Ng2ImgMaxModule];
const pipes = [];
const guards = [];
const services = [
  AjaxDownloadService,
  ImagesService,
  FilesService,
  VisionService
];

@NgModule({
  declarations: [...components, ...containers, ...directives, ...pipes],
  exports: [...components, ...containers, ...directives, ...modules, ...pipes],
  imports: [...modules]
})
export class FilesModule {
  static forRoot(token: string, baseUrl?: string): ModuleWithProviders {
    return {
      ngModule: FilesModule,
      providers: [
        ...guards,
        ...services,
        {
          provide: TOKEN,
          useValue: token
        },
        {
          provide: BASE_URL,
          useValue: baseUrl
        }
      ]
    };
  }
}
