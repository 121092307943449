import { Title, Meta } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var SeoService = /** @class */ (function () {
    function SeoService(titleService, metaService) {
        this.titleService = titleService;
        this.metaService = metaService;
        this._baseTitle = 'Contenidos E-Learning';
    }
    Object.defineProperty(SeoService.prototype, "baseTitle", {
        set: function (title) {
            this._baseTitle = title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeoService.prototype, "title", {
        set: function (title) {
            this.titleService.setTitle(title || this._baseTitle);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeoService.prototype, "description", {
        set: function (description) {
            var currentMetaDescription = this.metaService.getTag('name=description');
            if (currentMetaDescription) {
                this.metaService.updateTag({
                    name: 'description',
                    content: description
                });
            }
            else {
                this.metaService.addTag({
                    name: 'description',
                    content: description
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeoService.prototype, "keywords", {
        set: function (keywords) {
            var currentMetaKeywords = this.metaService.getTag('name=keywords');
            var currentKeywords = currentMetaKeywords
                ? currentMetaKeywords.content.split(',')
                : [];
            var newKeywords = keywords
                ? keywords.split(',')
                : SeoService.keywords.split(',');
            var nextKeywords = newKeywords.reduce(function (acc, key) { return (acc.indexOf(key) >= 0 ? acc : acc.concat([key])); }, currentKeywords);
            if (currentMetaKeywords) {
                this.metaService.updateTag({
                    name: 'keywords',
                    content: nextKeywords.join(',')
                });
            }
            else {
                this.metaService.addTag({
                    name: 'keywords',
                    content: nextKeywords.join(',')
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    SeoService.prototype.setPage = function (page) {
        if (page === void 0) { page = {}; }
        // TITLE
        this.title = page.title || this._baseTitle;
        // KEYWORDS
        if (page.replaceKeywords) {
            this.cleanKeywords();
        }
        this.keywords = page.keywords || SeoService.keywords;
        // DESCRIPTION
        this.description = page.description || SeoService.description;
    };
    SeoService.prototype.cleanKeywords = function () {
        var currentMetaKeywords = this.metaService.getTag('name=keywords');
        if (currentMetaKeywords) {
            this.metaService.updateTag({
                name: 'keywords',
                content: ''
            });
        }
    };
    SeoService.keywords = 'e-learning,cursos,bonificados,formacion,empresas,fundae,online,scorm,educacion,online,contenidos';
    SeoService.description = 'Comercialización de contenidos e-learming en formato SCORM 1.2. La mayor oferta nacional de contenidos didácticos online';
    SeoService.ngInjectableDef = i0.defineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.inject(i1.Title), i0.inject(i1.Meta)); }, token: SeoService, providedIn: "root" });
    return SeoService;
}());
export { SeoService };
