import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { environment } from '../environments/environment';

import { CMS_ENV } from './shared';

import { AdminSharedModule } from './admin/shared/admin-shared.module';
import { AdminCopyblogsModule } from './admin/copyblogs/admin-copyblogs.module';
import { CoursesModule } from './courses/courses.module';
import { GeoDestinosModule } from './geodestinos/geodestinos.module';
import { AdminUsersModule } from './admin/users/admin-users.module';

import { AdminIEFPModule } from './admin/iefp/admin-iefp.module';
import { AdminGeodestinosModule } from './admin/geodestinos/admin-geodestinos.module';

import { AppComponent } from './app.component';
import { ROUTES } from './app.routing';
import { ScrapperModule } from './admin/scrapper/scrapper.module';
import { AdminRssModule } from './admin/rss/admin-rss.module';
import { RssModule } from './rss/rss.module';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES),
    // CORE MDOULES
    ScrollToModule.forRoot(),
    // FEATURES MODULE
    CoursesModule,
    GeoDestinosModule,
    RssModule,
    // ADMIN MODULES,
    AdminSharedModule.forRoot(),
    AdminCopyblogsModule,
    AdminIEFPModule,
    AdminGeodestinosModule,
    AdminUsersModule,
    ScrapperModule,
    AdminRssModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es',
    },
    {
      provide: CMS_ENV,
      useValue: environment,
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
