import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FamiliesService, PVFamiliesService } from '@cms-app/courses';
import { map } from 'rxjs/operators';
var AdminFamiliesComponent = /** @class */ (function () {
    function AdminFamiliesComponent(familiesService, pvFamiliesService, router) {
        this.familiesService = familiesService;
        this.pvFamiliesService = pvFamiliesService;
        this.router = router;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.query = '';
        this.loading = false;
    }
    AdminFamiliesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pvFamiliesService
            .find({
            fields: 'name,families',
            per_page: 200
        })
            .pipe(map(function (data) { return data.list; }))
            .subscribe(function (rows) {
            _this.pvFamilies = rows;
        });
        this.find();
    };
    AdminFamiliesComponent.prototype.checkLink = function (row) {
        window.open(row.url, '_blank');
    };
    AdminFamiliesComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminFamiliesComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminFamiliesComponent.prototype.onAdd = function () {
        this.router.navigate(['admin', 'iefp', 'families', 'new']);
    };
    AdminFamiliesComponent.prototype.update = function (row) {
        this.router.navigate(['admin', 'iefp', 'families', row._id]);
    };
    AdminFamiliesComponent.prototype.find = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        if (this.query) {
            options['name>$like'] = this.query;
        }
        this.loading = true;
        this.familiesService
            .find(tslib_1.__assign({}, options, { per_page: 200 }))
            .subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list.map(function (row) {
                var used = _this.pvFamilies.filter(function (reference) { return reference.families.indexOf(row._id) >= 0; });
                return tslib_1.__assign({}, row, { reference: used.map(function (row) { return row.name; }) });
            });
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    AdminFamiliesComponent.prototype.getRowHeight = function () {
        return function (row) { return (!!row ? 25 + 22 * Math.max(row.reference.length, 1) : 50); };
    };
    return AdminFamiliesComponent;
}());
export { AdminFamiliesComponent };
