import {
  Component,
  Input,
  ContentChildren,
  QueryList,
  AfterContentInit
} from '@angular/core';

import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'cms-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs = new QueryList<TabComponent>();
  @Input() block: boolean = false;
  @Input() initial: number;

  ngAfterContentInit() {
    setTimeout(() => this.selectTab(this.initial || 0));
  }

  private selectTab(idx: number) {
    this.tabs.forEach((tab, i) => {
      tab.selected = i === idx;
    });
  }
}
