<div class="row contact-card"  [style.background-image]="getBackGround()">
  <div class="col-sm-4">
    <div class="contact-picture">
      <img [src]='getLogo()'/>
    </div>
  </div>
  <div class="col-sm-6">
    <h4> ¿QUIERE APARECER EN ESTA LISTA?</h4>
    <div>
      <p style="margin-left: 15px;">
        ¿Tiene un negocio y quiere aparecer en esta lista?<br/>
        Póngase en contacto con nosotros en el siguiente enlace
        <br/>
      </p>
    </div>
    <div class='text-center'>
      <a [href]="getContactLink()" class="btn btn-primary" target="_blank">Contacto</a>
    </div>
  </div>
</div>
