import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var CopyBlogsService = /** @class */ (function () {
    function CopyBlogsService(env, http) {
        this.env = env;
        this.http = http;
    }
    CopyBlogsService.prototype.fetch = function (query, options) {
        if (options === void 0) { options = { page: 1, per_page: 10 }; }
        var params = new HttpParams();
        if (query && query.q && query.q.length > 0) {
            params = params.set('q', query.q);
        }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        params = params.set('page', options.page).set('per_page', options.per_page);
        return this.http
            .get(this.env.copyblogUrl + "/admin", {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    CopyBlogsService.prototype.patch = function (id, update) {
        var params = new HttpParams();
        params = params.set('post', id);
        return this.http.patch(this.env.copyblogUrl + "/admin", update, {
            params: params
        });
    };
    CopyBlogsService.DEFAULT_PER_PAGE = 10;
    CopyBlogsService.ngInjectableDef = i0.defineInjectable({ factory: function CopyBlogsService_Factory() { return new CopyBlogsService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: CopyBlogsService, providedIn: "root" });
    return CopyBlogsService;
}());
export { CopyBlogsService };
