import { Component, OnInit, Input } from '@angular/core';

import { Color } from '../../models/theme.model';
import { Course } from '../../models/course.model';

import { CoursesService } from '../../providers/courses.service';
import { ThemeService } from '../../providers/theme.service';

@Component({
  selector: 'cms-courses-grid',
  templateUrl: './courses-grid.component.html',
  styleUrls: ['./courses-grid.component.css']
})
export class CouresGridComponent implements OnInit {
  @Input() set q(query: string) {
    this.query = query;
    this.page = 1;

    setTimeout(() => this.find());
  }
  @Input() set families(families: string[]) {
    this.query = this.initialEmit ? this.query : '';
    this.page = 1;
    this.subfamilies = families;

    this.initialEmit = false;

    setTimeout(() => this.find());
  }
  @Input() prefilter: string;
  @Input() color: Color = 'red';

  @Input('min-hours') minHours: number;
  @Input('max-hours') maxHours: number;
  @Input() register: string;
  rows: Course[] = [];
  count = 0;
  page = 1;
  per_page = 48;
  query = '';
  loading = false;
  subfamilies = [];

  initialEmit = true;
  lastCallParams: any;

  constructor(
    private coursesService: CoursesService,
    public theme: ThemeService
  ) {}

  ngOnInit() {
    this.find();
  }

  find(append = false) {
    const options: any = {};

    if (
      this.lastCallParams &&
      this.page === this.lastCallParams.page &&
      this.query === this.lastCallParams.query &&
      this.subfamilies.join(',') === this.lastCallParams.subfamilies.join(',')
    ) {
      return;
    }

    if (this.page) {
      options.page = this.page;
    }
    if (this.subfamilies && this.subfamilies.length > 0) {
      options['families>$in'] = this.subfamilies.join(',');
    }

    this.lastCallParams = {
      page: this.page,
      query: this.query,
      subfamilies: this.subfamilies
    };

    this.loading = true;
    this.coursesService
      .search({
        ...options,
        ...this.getQuery(),
        per_page: this.per_page
      })
      .subscribe(
        ({ list, count }) => {
          this.rows = append ? [...this.rows, ...list] : list;
          this.count = count;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  excel() {
    const options: any = {};

    if (this.subfamilies && this.subfamilies.length > 0) {
      options['families>$in'] = this.subfamilies.join(',');
    }

    this.coursesService.excelFromSearch({
      ...options,
      ...this.getQuery()
    });
  }

  getQuery() {
    const options: any = {};
    if (this.prefilter || this.query) {
      options['search>$like'] = this.prefilter || this.query;
      options['q'] = [this.prefilter, this.query].filter(Boolean).join(' ');
      options.sort = '-$score';
    }

    if (this.minHours && !isNaN(this.minHours)) {
      options['duration>$gte'] = this.minHours;
    }

    if (this.maxHours && !isNaN(this.maxHours)) {
      options['duration>$lte'] = this.maxHours;
    }
    return options;
  }

  get moreCourses() {
    const nPages = Math.ceil(this.count / this.per_page);

    return this.page < nPages;
  }

  appendCourses() {
    this.page++;

    const nPages = Math.ceil(this.count / this.per_page);
    if (this.page <= nPages) {
      this.find(true);
    }
  }
}
