<div [ngClass]="{ container: !env.elements }">
  <div class="row">
    <div
      class="col-lg-4 col-md-3 col-sm-4 no-padding"
      style="padding-left: 0px; padding-right: 0px"
    >
      <cms-categories-list
        [family]="family"
        [color]="color"
        (all)="onViewAll()"
        (families)="onFamilyFilter($event)"
      ></cms-categories-list>
    </div>
    <div class="col-lg-8 col-md-9 col-sm-8 no-padding">
      <div
        class="form-inline"
        style="margin-left: -10px"
        [formGroup]="searchForm"
      >
        <div class="form-group cms-search">
          <label for="">{{ 'Busca tu curso' | uppercase }}</label
          >&nbsp;
          <input
            type="text"
            class="form-control cms-search"
            (keyup.enter)="doSearch()"
            formControlName="query"
          />
          &nbsp;
          <button
            class="btn"
            (click)="doSearch()"
            [style.backgroundColor]="theme.color | color"
            style="color: white"
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
          &nbsp;
          <button class="btn btn-success" (click)="exportExcel()">
            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
          </button>
          &nbsp;
          <button
            class="btn btn-info"
            (click)="doCopy()"
            *ngIf="query && query.length > 0"
          >
            <i class="fa fa-clipboard" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <cms-courses-grid
        [q]="query"
        [prefilter]="prefilter"
        [min-hours]="minHours"
        [max-hours]="maxHours"
        [families]="families"
        [register]="register"
        [color]="color"
      ></cms-courses-grid>
    </div>
  </div>
</div>
