import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cms-tab',
  template: `
    <div *ngIf="_selected"><ng-content></ng-content></div>
  `,
  styles: [
    `
      .tab-block {
        display: block;
      }
      .tab-hidden {
        display: none;
      }
    `
  ]
})
export class TabComponent {
  @Input() name = '';
  @Input() picture: string;
  @Input() set selected(value) {
    if (value) {
      this.select.emit();
    }

    this._selected = value;
  }

  get selected() {
    return this._selected;
  }

  @Output() select = new EventEmitter<void>();

  _selected = false;
}
