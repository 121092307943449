import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  userName = '';
  password = '';
  loggedIn: boolean = false;
  router: Router;

  constructor(private authService: AuthService, private route: Router) {
    this.router=route;
  }
  Login() {
    this.authService.login(this.userName, this.password);
  }

  ngOnInit() {
  }
}
