/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blognews-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../blog-new/blognew.component.ngfactory";
import * as i3 from "../blog-new/blognew.component";
import * as i4 from "@angular/common";
import * as i5 from "./blognews-list.component";
import * as i6 from "../../providers/rss.service";
var styles_BlogNewsListComponent = [i0.styles];
var RenderType_BlogNewsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogNewsListComponent, data: {} });
export { RenderType_BlogNewsListComponent as RenderType_BlogNewsListComponent };
function View_BlogNewsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-blog-new", [], null, null, null, i2.View_BlogNewComponent_0, i2.RenderType_BlogNewComponent)), i1.ɵdid(1, 114688, null, 0, i3.BlogNewComponent, [], { new: [0, "new"], postLink: [1, "postLink"], location: [2, "location"], columns: [3, "columns"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.postlink; var currVal_2 = _co.location; var currVal_3 = _co.columns; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_BlogNewsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogNewsListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.news; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClassName(); _ck(_v, 0, 0, currVal_0); }); }
export function View_BlogNewsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-blog-news-list", [], null, null, null, View_BlogNewsListComponent_0, RenderType_BlogNewsListComponent)), i1.ɵdid(1, 114688, null, 0, i5.BlogNewsListComponent, [i6.RssService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlogNewsListComponentNgFactory = i1.ɵccf("cms-blog-news-list", i5.BlogNewsListComponent, View_BlogNewsListComponent_Host_0, { columns: "columns", location: "location", postlink: "postlink", events: "events" }, {}, []);
export { BlogNewsListComponentNgFactory as BlogNewsListComponentNgFactory };
