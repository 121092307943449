<div class="row cms-card">
  <div class="col-sm-4">
    <div class="rssnew-picture">
      <img [src]="getPicture()"/>
    </div>
  </div>
  <div class="col-sm-6">
    <h3><a [href]="rssNew.link" target='_blank'>{{ rssNew.title }}</a></h3>
  </div>
  <div class="col-sm-6">
    <p>
      {{rssNew.date | date: 'dd/MM/yyyy'}}
    </p>
    <p [innerHTML]='rssNew.content'>
    </p>
  </div>

  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-6">
        <label for="region">Región: </label>
        <select class="form-control" type="text" [(ngModel)]="region" (change)="onRegionSelec($event)">
          <option *ngFor="let item of regions" [value]="item.name">{{item.fullName}}</option>
        </select>
      </div>
      <div class="col-sm-6">
        <label for="localidad">Localidad: </label>
      <select class="form-control" type="text" [(ngModel)]="location">
        <option *ngFor="let item of locations" [value]="item">{{item}}</option>
      </select>
      </div>


    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="active"/>
           Activo
          </label>
        </div>
      </div>
      <div class="col-sm-6">
        <button class="btn btn-success" (click)="save()">GUARDAR</button>
      </div>
    </div>

  </div>
</div>
