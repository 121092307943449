import { Component, Input, OnInit } from "@angular/core";
import { Blognew } from "@cms-app/rss/models/blog-new.model";
import { RssService } from "@cms-app/rss/providers/rss.service";

@Component({
  selector: 'cms-blog-news-list',
  templateUrl: './blognews-list.component.html',
  styleUrls: ['./blognews-list.component.css'],
})
export class BlogNewsListComponent implements OnInit{
  @Input() columns: number= 5;
  @Input() location: string = 'Galicia';
  @Input() postlink: string;
  @Input() events: boolean = false;

  news: Blognew[];

  constructor(private rss: RssService){}

  ngOnInit(): void {
    this.rss.getBlogNews(this.location, this.events).subscribe(r => {
      this.news = [];
      for(let i=0; i< this.columns; i++){
        this.news.push(r[i]);
      }
    })
  }
  getClassName() {
    if (this.columns >= 3) {
     return `news summary col-3`;
   } else if (this.columns === 2) {
     return `news summary col-2`;
   } else {
     return `news summary col-1`;
   }
 }
}
