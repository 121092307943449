import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GeodestinosService } from '@cms-app/geodestinos';
import { GeoLocation } from '@cms-app/geodestinos/models/geolocation.model';

@Component({
  selector: 'cms-admin-locations',
  templateUrl: './locations.component.html'
})
export class AdminLocationsComponent implements OnInit {
  rows: GeoLocation[] = [];
  count = 0;
  page = 1;
  per_page = 20;
  query = '';
  loading = false;

  constructor(
    private router: Router,
    private geodestinosService: GeodestinosService
  ) {}

  ngOnInit() {
    this.find();
  }

  update(row: GeoLocation) {
    this.router.navigate(
      ['admin', 'geodestinos', 'location-detail', row._id],
      {
        state: {
          data: row,
        }
      }
    );
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    this.loading = true;
    this.geodestinosService.getGeoDestinoLocations({}).subscribe(({count, list}) => {
        this.rows= list;
        this.count= count;
        this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  onAdd() {
    this.router.navigate(['admin', 'geodestinos', 'location-detail', 'new']);
  }
}
