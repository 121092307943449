<div class='row promo-card' [style.background-image]="getBackGround()" (click)="goLink()">
    <div class='row sello-container'>
    <div class='sello' [style.background-image]="getBackGroundSello()">
        <span class='sello-text'>
          {{geodestino.promoDiscount}}%
        </span>
   </div>
    </div>
    <div class="buffer-row">

    </div>
    <div class='row geoname-row'>
      <div class="geoname-container">
        <span class="geoname-span">
          <span class="geoname">{{geodestino.namePV || geodestino.name}}</span>
        </span>
      </div>
   </div>

</div>
