import { Component, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CoursesService } from '@cms-app/courses';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { Course } from '../../models/course.model';
import { Color } from '../../models/theme.model';

import { ThemeService } from '../../providers/theme.service';

@Component({
  selector: 'cms-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CouresDetailComponent {
  @Input() catalog: string;
  @Input() course: Course;
  @Input() color: Color;
  @Input() register: string;

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv,
    public theme: ThemeService,
    private router: Router,
    public courses: CoursesService
  ) {}

  info() {
    if (this.env.elements) {
      window.open(
        `/info-curso.html?id=${this.course._id}&back=${encodeURIComponent(
          this.catalog || window.location.href
        )}`,
        '_blank'
      );
    } else {
      this.router.navigate(['courses', this.course._id]);
    }
  }

  book() {
    // window.open('https://www.cursosonlinebonificados.es/inscribirse.html');
    const page = this.register || 'inscribirse.html';
    if (this.env.elements) {
      window.open(
        `/${page}?id=${this.course._id}&back=${encodeURIComponent(
          this.catalog || window.location.href
        )}`,
        '_blank'
      );
    } else {
      this.router.navigate(['entrust', this.course._id]);
    }
  }
}
