/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../course-entrust-student/course-entrust-student.component.ngfactory";
import * as i2 from "../course-entrust-student/course-entrust-student.component";
import * as i3 from "../../../shared/models/datepicker.token";
import * as i4 from "../../providers/entrust.service";
import * as i5 from "../course-entrust-company/course-entrust-company.component.ngfactory";
import * as i6 from "../course-entrust-company/course-entrust-company.component";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "../course-entrust-stepper/course-entrust-stepper.component.ngfactory";
import * as i10 from "../course-entrust-stepper/course-entrust-stepper.component";
import * as i11 from "./course-entrust-bonif.component";
import * as i12 from "../../../shared/models/environment";
import * as i13 from "../../providers/platforms.service";
import * as i14 from "@angular/router";
var styles_CourseEntrustBonifComponent = [];
var RenderType_CourseEntrustBonifComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CourseEntrustBonifComponent, data: {} });
export { RenderType_CourseEntrustBonifComponent as RenderType_CourseEntrustBonifComponent };
function View_CourseEntrustBonifComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-course-entrust-student", [], null, [[null, "student"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("student" === en)) {
        var pd_0 = (_co.addStudent() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = ((_co.step = 2) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CourseEntrustStudentComponent_0, i1.RenderType_CourseEntrustStudentComponent)), i0.ɵdid(1, 49152, null, 0, i2.CourseEntrustStudentComponent, [i3.CMS_DATEPICKER_CONFIG, i4.EntrustService], { parent: [0, "parent"], preselectedCourse: [1, "preselectedCourse"], agent: [2, "agent"], ucm: [3, "ucm"] }, { student: "student", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inscriptionForm; var currVal_1 = !!_co.course; var currVal_2 = _co.agent; var currVal_3 = _co.ucm; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_CourseEntrustBonifComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ENVIAR"]))], null, null); }
function View_CourseEntrustBonifComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ENVIANDO ..."]))], null, null); }
function View_CourseEntrustBonifComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "cms-course-entrust-company", [], null, [[null, "previous"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("previous" === en)) {
        var pd_0 = ((_co.step = 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CourseEntrustCompanyComponent_0, i5.RenderType_CourseEntrustCompanyComponent)), i0.ɵdid(1, 49152, null, 0, i6.CourseEntrustCompanyComponent, [i4.EntrustService], { parent: [0, "parent"], agent: [1, "agent"], privacy: [2, "privacy"] }, { previous: "previous" }), (_l()(), i0.ɵeld(2, 0, null, 0, 10, "div", [["class", "col-xs-3 pull-right"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 540672, null, 0, i7.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i7.ControlContainer, null, [i7.FormGroupDirective]), i0.ɵdid(5, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "center-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "input", [["class", "form-control"], ["formControlName", "agent"], ["id", "agent"], ["placeholder", "Agente (Persona que le ha atendido)"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i7.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i0.ɵdid(10, 671744, null, 0, i7.FormControlName, [[3, i7.ControlContainer], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR], [2, i7.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i7.NgControl, null, [i7.FormControlName]), i0.ɵdid(12, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i0.ɵeld(13, 0, null, 0, 4, "button", [["class", "btn btn-info pull-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.send() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CourseEntrustBonifComponent_3)), i0.ɵdid(15, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CourseEntrustBonifComponent_4)), i0.ɵdid(17, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inscriptionForm; var currVal_1 = _co.agent; var currVal_2 = _co.privacy; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.inscriptionForm; _ck(_v, 3, 0, currVal_10); var currVal_18 = "agent"; _ck(_v, 10, 0, currVal_18); var currVal_20 = !_co.sending; _ck(_v, 15, 0, currVal_20); var currVal_21 = _co.sending; _ck(_v, 17, 0, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 5).ngClassValid; var currVal_8 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = i0.ɵnov(_v, 12).ngClassUntouched; var currVal_12 = i0.ɵnov(_v, 12).ngClassTouched; var currVal_13 = i0.ɵnov(_v, 12).ngClassPristine; var currVal_14 = i0.ɵnov(_v, 12).ngClassDirty; var currVal_15 = i0.ɵnov(_v, 12).ngClassValid; var currVal_16 = i0.ɵnov(_v, 12).ngClassInvalid; var currVal_17 = i0.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_19 = _co.sending; _ck(_v, 13, 0, currVal_19); }); }
export function View_CourseEntrustBonifComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-course-entrust-stepper", [], null, [[null, "stepChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("stepChange" === en)) {
        var pd_0 = (_co.onStepChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_CourseEntrustStepper_0, i9.RenderType_CourseEntrustStepper)), i0.ɵdid(1, 49152, [["stepper", 4]], 0, i10.CourseEntrustStepper, [], { step: [0, "step"], type: [1, "type"] }, { stepChange: "stepChange" }), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["style", "margin-top: 20px"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CourseEntrustBonifComponent_1)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CourseEntrustBonifComponent_2)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; var currVal_1 = "yes"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.step === 1); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.step === 2); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_CourseEntrustBonifComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-course-entrust-bonif", [], null, null, null, View_CourseEntrustBonifComponent_0, RenderType_CourseEntrustBonifComponent)), i0.ɵdid(1, 114688, null, 0, i11.CourseEntrustBonifComponent, [i12.CMS_ENV, i7.FormBuilder, i4.EntrustService, i13.CoursePlatformsService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CourseEntrustBonifComponentNgFactory = i0.ɵccf("cms-course-entrust-bonif", i11.CourseEntrustBonifComponent, View_CourseEntrustBonifComponent_Host_0, { privacy: "privacy", okurl: "okurl", platform: "platform", course: "course" }, {}, []);
export { CourseEntrustBonifComponentNgFactory as CourseEntrustBonifComponentNgFactory };
