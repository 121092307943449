import { OnInit, Component } from '@angular/core';
import { ProviderService } from '../../services/provider.service';
import { Log } from '../../interfaces/log';


@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
})
export class LogListComponent implements OnInit {
  rows: Log[] = [];
  count = 0;
  page = 1;
  query = '';
  loading = false;

  ngOnInit(): void {
    this.getLogs();
  }
  constructor(private providerService: ProviderService) {}

  getLogs(){
    this.providerService.getLogs().subscribe(res => {
      this.rows = res;
    },
       err => console.log(err));
  }

  getDate(millis: number):string{
    const date = new Date(millis);
    return date.toLocaleDateString()+"  "+date.toLocaleTimeString();
  }

}
