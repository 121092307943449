<div
  [ngClass]="{ container: !env.elements }"
  [formGroup]="inscriptionForm"
  *ngIf="!!inscriptionForm"
>
  <div
    class="row"
    style="padding-left: 15px; padding-right: 15px"
    *ngIf="!!course"
  >
    <div class="col-md-12">
      <cms-course-cart [course]="course" [color]="color"></cms-course-cart>
    </div>
  </div>
  <div class="row" *ngIf="onlycredit != 'true'">
    <div class="col-md-12">
      <div style="margin-top: 20px"></div>
      <div class="form-group" style="text-align: center">
        <!--<div class="btn-group" btnRadioGroup formControlName="bonus">
          <label
            btnRadio="yes"
            class="btn btn-danger btn-type"
            style="margin-right: 50px; border-top-right-radius: 1; border-bottom-right-radius: 1;"
            tabindex="0"
            role="button"
            (click)="step = 1"
            >FORMACIÓN <br />
            BONIFICADA</label>
          <label
            btnRadio="no"
            class="btn btn-danger btn-type"
            tabindex="0"
            role="button"
            (click)="step = 3"
            >FORMACION NO <br />
            BONIFICADA</label
          >
        </div>-->
        <div class="col-md-6">
          <button class="btn btn-danger btn-type" [ngClass]="step == 1 ? 'active' : ''" style="min-width: 230px; min-height: 35px;" (click)="step = 1">FORMACION BONIFICADA</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-danger btn-type" [ngClass]="step == 3 ? 'active' : ''"style="min-width: 230px; min-height: 35px;" (click)="step = 3">FORMACION NO BONIFICADA</button>
        </div>
      </div>
      </div>
    <!--<cms-course-entrust-stepper
      [step]="step"
      [type]="inscriptionForm.get('bonus').value"
      (stepChange)="onStepChange($event)"
      *ngIf="inscriptionForm.get('bonus').value !== 'entrust'"
    ></cms-course-entrust-stepper>-->
    <div style="margin-top: 20px"></div>
    <cms-course-entrust-credit [privacy]="privacy" *ngIf="step==2"></cms-course-entrust-credit>
    <cms-course-entrust-bonif [privacy]="privacy" *ngIf="step==1" [course]="course"><</cms-course-entrust-bonif>
    <cms-course-entrust-nobonif [privacy]="privacy" *ngIf="step==3" [course]="course"><</cms-course-entrust-nobonif>
    <!--<cms-course-entrust-student
      [parent]="inscriptionForm"
      [preselectedCourse]="!!course"
      [agent]="agent"
      *ngIf="step === 1"
      (student)="addStudent()"
      (next)="step = 2"
    ></cms-course-entrust-student>
    <cms-course-entrust-company
      [parent]="inscriptionForm"
      [agent]="agent"
      [privacy]="privacy"
      *ngIf="step === 2"
      (previous)="step = 1"
    >
    <div class="col-xs-3 pull-right">
      <div class="center-block">
      <input id="agent" type="text" formControlName="agent" class="form-control" placeholder="Agente (Persona que le ha atendido)"/>
      </div>
    </div>
      <button
        class="btn btn-info pull-right"
        [disabled]="sending"
        (click)="send()"
      >
        <span *ngIf="!sending">ENVIAR</span>
        <span *ngIf="sending">ENVIANDO ...</span>
      </button>
    </cms-course-entrust-company>-->
  </div>
  <div class="row" *ngIf="onlycredit == 'true'">
    <div class="col-md-12;">
      <div style="margin-top: 20px"></div>
      <h1 style="color: red; text-align: center;">Consulta Crédito Formativo</h1>
      </div>
      <div style="margin-top: 40px"></div>
    <cms-course-entrust-credit [privacy]="privacy" *ngIf="step==2"></cms-course-entrust-credit>
  </div>
  <div style="margin-top: 20px"></div>
</div>
