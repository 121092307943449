import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  endpoint = '/tags';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  find(
    options: any = { page: 1, per_page: 10, top: true }
  ): Observable<{
    list: Tag[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;
    options.top = options.hasOwnProperty('top') ? options.top : true;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<Tag[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body.sort((a, b) => a.name.localeCompare(b.name)),
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  add(candidate: Partial<Tag>): Observable<Tag> {
    return this.http.post<Tag>(
      `${this.env.baseUrl}${this.endpoint}`,
      candidate
    );
  }

  patch(candidate: Partial<Tag>, id: string) {
    return this.http.patch<Tag>(
      `${this.env.baseUrl}${this.endpoint}/${id}`,
      candidate
    );
  }

  remove(id: string) {
    return this.http.delete<Tag>(`${this.env.baseUrl}${this.endpoint}/${id}`);
  }
}
