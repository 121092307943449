import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ERROR, FINISHED, WORKING, } from '@cms-app/admin/scrapper/interfaces/response';
import { CoursesService } from '@cms-app/courses';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
var AdminCourseUploadExcelComponent = /** @class */ (function () {
    function AdminCourseUploadExcelComponent(formBuilder, coursesService) {
        this.formBuilder = formBuilder;
        this.coursesService = coursesService;
        this.working = false;
    }
    AdminCourseUploadExcelComponent.prototype.ngOnInit = function () {
        this.fileUploadForm = this.formBuilder.group({
            file: [''],
            source: [''],
            clear: [false],
        });
    };
    AdminCourseUploadExcelComponent.prototype.onFileSelect = function (event) {
        var af = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ];
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            var isValid = false;
            for (var _i = 0, af_1 = af; _i < af_1.length; _i++) {
                var a = af_1[_i];
                if (file.type === a) {
                    isValid = true;
                }
            }
            if (!isValid) {
                alert('Solo documentos de excel permitidos!');
            }
            else {
                this.fileUploadForm.get('file').setValue(file);
            }
        }
    };
    AdminCourseUploadExcelComponent.prototype.onFormSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData, subs;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.fileUploadForm.get('file').value) {
                    alert('Por favor introduzca valores válidos');
                    return [2 /*return*/, false];
                }
                formData = new FormData();
                formData.append('file', this.fileUploadForm.get('file').value);
                formData.append('source', this.fileUploadForm.get('source').value);
                formData.append('clear', this.fileUploadForm.get('clear').value);
                subs = interval(5000)
                    .pipe(startWith(0), switchMap(function () { return _this.coursesService.uploadFile(formData); }))
                    .subscribe(function (res) {
                    if (res.status === WORKING) {
                        _this.working = true;
                    }
                    if (res.status === FINISHED || res.status === ERROR) {
                        subs.unsubscribe();
                        _this.working = false;
                        alert(res.msg);
                        _this.uploadFileInput.nativeElement.value = '';
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return AdminCourseUploadExcelComponent;
}());
export { AdminCourseUploadExcelComponent };
