import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';
import { Blognew } from '../models/blog-new.model';

@Injectable({
  providedIn: 'root'
})
export class RssService{
  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  getRss(source: string, blogGalicia: boolean){
    return this.http.post<any>(`${this.env.scrapperUrl}/rss`, {source: source, blogGalicia: blogGalicia});
  }

  getDom(url: string, selector: string){
    return this.http.post<any>(`${this.env.scrapperUrl}/rss/getDom`, {url: url, selector: selector});
  }

  getBlogNews(src, events){
    return this.http.post<any>(`${this.env.baseUrl}/blogspot/getNews`, {source: src, events: events});
  }

  getBlogHtml(link, columns, location){
    return this.http.post<any>(`${this.env.baseUrl}/blogspot/getHtml`, {link: link, columns: columns, location: location});
  }
}
