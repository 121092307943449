/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./article-detail.component";
import * as i2 from "@angular/router";
var styles_AdminCopyblogsDetailComponent = [];
var RenderType_AdminCopyblogsDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminCopyblogsDetailComponent, data: {} });
export { RenderType_AdminCopyblogsDetailComponent as RenderType_AdminCopyblogsDetailComponent };
export function View_AdminCopyblogsDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataFromArticle; _ck(_v, 2, 0, currVal_0); }); }
export function View_AdminCopyblogsDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-admin-copyblogs-article-detail", [], null, null, null, View_AdminCopyblogsDetailComponent_0, RenderType_AdminCopyblogsDetailComponent)), i0.ɵdid(1, 114688, null, 0, i1.AdminCopyblogsDetailComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminCopyblogsDetailComponentNgFactory = i0.ɵccf("cms-admin-copyblogs-article-detail", i1.AdminCopyblogsDetailComponent, View_AdminCopyblogsDetailComponent_Host_0, {}, {}, []);
export { AdminCopyblogsDetailComponentNgFactory as AdminCopyblogsDetailComponentNgFactory };
