import { Component, Input, Inject, OnInit } from '@angular/core';
import { GeodestinosService } from '@cms-app/geodestinos';
import { GeoLocation } from '@cms-app/geodestinos/models/geolocation.model';
import { RssNew } from '@cms-app/rss/models/rss-new.model';
import { RssNewService } from '@cms-app/rss/providers/rss-new.service';
import { RssService } from '@cms-app/rss/providers/rss.service';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';

@Component({
  selector: 'admin-rss-chooser-item',
  templateUrl: 'rss-chooser-item.component.html',
  styleUrls: ['./rss-chooser-item.component.css'],
})
export class RssChooserItem implements OnInit{
  @Input() rssNew: RssNew;
  @Input() regions: GeoLocation[];
  @Input() articleSelector: string;

  region: string;
  locations: string[]= [];
  location: string;
  active: boolean;
  edit: boolean=false;

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv, private rssNewService: RssNewService, private rssService: RssService) {}

    ngOnInit(): void {
    this.rssNewService.find({content: this.rssNew.content}).subscribe((data) => {
      if(data.count == 1){
        this.rssNew =data.list[0];
        this.location = this.rssNew.location;
        this.active = this.rssNew.active;
        this.region = this.rssNew.region;
        this.edit = true;
        this.selectRegion(this.region);
      }
    });
  }


  getPicture() {
    if (this.rssNew.img) {
      return this.rssNew.img;
    } else {
      return `${this.env.assetsUrl}/assets/nophoto.png`;
    }
  }

  wrapText(text:string){
    if(text.length > 150){
      return text.slice(0,145)+' ...'
    }else{
      return text;
    }
  }

  onRegionSelec(event){
    this.region=event.target.value;
    this.selectRegion(this.region);
  }

  selectRegion(region:string){
    for(let item of this.regions){
      if(item.name === this.region){
        this.locations = item.locations;
      }
    }
  }

  save(){
    let rssContent: RssNew = {
      isoDate: this.rssNew.date.toISOString(),
      title: this.rssNew.title,
      img:  this.rssNew.img ?  this.rssNew.img : '',
      link:  this.rssNew.link,
      content:  this.rssNew.content,
      article: '',
      source: this.rssNew.source,
      location: this.location,
      region: this.region,
      active: this.active
    }
    this.rssService.getDom(this.rssNew.link, this.articleSelector).subscribe((dom) => {
      rssContent.article = dom;
      if(this.edit){
        this.rssNewService.update(this.rssNew._id, rssContent);
      }else{
        this.rssNewService.add(rssContent).subscribe((res) => {
          console.log(res)
        });
      }

    });

  }
}
