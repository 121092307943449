/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/admin/admin.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../shared/components/admin/admin.component";
import * as i4 from "../../../../shared/components/section-title/section-title.componen.ngfactory";
import * as i5 from "../../../../shared/components/section-title/section-title.componen";
import * as i6 from "../../../../courses/providers/courses.service";
import * as i7 from "ngx-bootstrap/tooltip";
import * as i8 from "ngx-bootstrap/component-loader";
import * as i9 from "./families-detail.component";
import * as i10 from "../../../../courses/providers/families.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../shared/providers/messaging.service";
var styles_AdminFamiliesDetailComponent = [];
var RenderType_AdminFamiliesDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminFamiliesDetailComponent, data: {} });
export { RenderType_AdminFamiliesDetailComponent as RenderType_AdminFamiliesDetailComponent };
export function View_AdminFamiliesDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "cms-admin", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_AdminComponent_0, i1.RenderType_AdminComponent)), i0.ɵdid(1, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i0.ɵdid(3, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), i0.ɵdid(4, 49152, null, 0, i3.AdminComponent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "cms-section-title", [], null, null, null, i4.View_SectionTitleComponent_0, i4.RenderType_SectionTitleComponent)), i0.ɵdid(6, 114688, null, 0, i5.SectionTitleComponent, [i2.FormBuilder, i6.CoursesService], { showAdd: [0, "showAdd"], showQuery: [1, "showQuery"] }, null), (_l()(), i0.ɵted(-1, 0, ["Familia - Galicia"])), (_l()(), i0.ɵeld(8, 0, null, 0, 11, "div", [["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["for", "name"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nombre familia: "])), (_l()(), i0.ɵeld(11, 0, null, null, 7, "input", [["class", "form-control"], ["formControlName", "name"], ["placeholder", "Nombre de la familia"], ["required", ""], ["type", "text"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(13, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i0.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(16, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i0.ɵdid(18, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, 0, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 16777216, null, null, 2, "button", [["class", "btn btn-info"], ["tooltip", "Guardar familia"]], [[1, "aria-describedby", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveFamily() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(22, 212992, null, 0, i7.TooltipDirective, [i0.ViewContainerRef, i0.Renderer2, i0.ElementRef, i8.ComponentLoaderFactory, i7.TooltipConfig], { tooltip: [0, "tooltip"] }, null), (_l()(), i0.ɵted(-1, null, [" Guardar "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.familyForm; _ck(_v, 1, 0, currVal_7); var currVal_8 = false; var currVal_9 = false; _ck(_v, 6, 0, currVal_8, currVal_9); var currVal_18 = ""; _ck(_v, 13, 0, currVal_18); var currVal_19 = "name"; _ck(_v, 16, 0, currVal_19); var currVal_21 = "Guardar familia"; _ck(_v, 22, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 3).ngClassValid; var currVal_5 = i0.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = (i0.ɵnov(_v, 13).required ? "" : null); var currVal_11 = i0.ɵnov(_v, 18).ngClassUntouched; var currVal_12 = i0.ɵnov(_v, 18).ngClassTouched; var currVal_13 = i0.ɵnov(_v, 18).ngClassPristine; var currVal_14 = i0.ɵnov(_v, 18).ngClassDirty; var currVal_15 = i0.ɵnov(_v, 18).ngClassValid; var currVal_16 = i0.ɵnov(_v, 18).ngClassInvalid; var currVal_17 = i0.ɵnov(_v, 18).ngClassPending; _ck(_v, 11, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_20 = i0.ɵnov(_v, 22).ariaDescribedby; _ck(_v, 21, 0, currVal_20); }); }
export function View_AdminFamiliesDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-admin-families-detail", [], null, null, null, View_AdminFamiliesDetailComponent_0, RenderType_AdminFamiliesDetailComponent)), i0.ɵdid(1, 114688, null, 0, i9.AdminFamiliesDetailComponent, [i10.FamiliesService, i11.Router, i11.ActivatedRoute, i12.MessagingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminFamiliesDetailComponentNgFactory = i0.ɵccf("cms-admin-families-detail", i9.AdminFamiliesDetailComponent, View_AdminFamiliesDetailComponent_Host_0, {}, {}, []);
export { AdminFamiliesDetailComponentNgFactory as AdminFamiliesDetailComponentNgFactory };
