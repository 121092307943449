<cms-admin>
  <div class="row">
    <div class="col-sm-4" style="float: none; margin: 0 auto">
      <div class="card card-body">
        <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <div>
                <h6 class="head-title margin-top-8"><span>UPLOAD</span></h6>
              </div>
            </div>

            <div class="col-sm-6 text-center">
              <div class="source">
                <input
                  type="text"
                  class="form-control"
                  formControlName="source"
                  placeholder="Fuente de datos"
                />
              </div>
              <p></p>
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  name="myfile"
                  (change)="onFileSelect($event)"
                  #UploadFileInput
                />
              </div>
              <p></p>
              <label for="check">
                <input
                  id="check"
                  type="checkbox"
                  class="form-control"
                  formControlName="clear"
                />
                ¿Borrar registros anteriores?
              </label>
              <p></p>

            </div>
            <div class="col-sm-6 text-center">
              <button class="btn btn-primary" type="submit">Upload</button>
            </div>
          </div>
          <div *ngIf="working" class="col-sm-4 text-center">
            <span class="loader"> &nbsp;&nbsp;&nbsp; </span>
          </div>
        </form>
      </div>
      <div>
        El Archivo de datos deberá contener en su primera fila el tipo de datos de cada columna, el resto de filas deben contener exclusivamente datos,
         los tipos de datos reconocidos son:
        <p></p>
        <li>
          Codigo
        </li>
        <li>
          Nombre
        </li>
        <li>
          Horas
        </li>
        <li>
          Creditos ECTS
        </li>
        <li>
          Baremable Nebrija
        </li>
        <li>
          Precio
        </li>
        <li>
          Familia
        </li>
        <li>
          ContenidosHTML (obtiene el contenido con etiquetas HTML para su formato)
        </li>
        <li>
          ObjetivosHTLM (obtiene los objetivos con etiquetas HTML(li) para su captura)
        </li>
      </div>
    </div>
  </div>
</cms-admin>
