import { OnInit, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { CMSEnv, QueryStringService, MediaService, } from '@cms-app/shared';
import { GeodestinosService } from '../../providers/geodestinos.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
var GeoGuideComponent = /** @class */ (function () {
    function GeoGuideComponent(env, queryService, geodestinos, scrollToService, media, fb, route) {
        this.env = env;
        this.queryService = queryService;
        this.geodestinos = geodestinos;
        this.scrollToService = scrollToService;
        this.media = media;
        this.fb = fb;
        this.route = route;
        this.geolocation = 'riasaltas';
        this.queryinput = "";
        this.section = 'hotel';
        this.sections = [];
        this.sectionMobile = 'hotel';
        this.selectedValue = '';
        this.location = '';
        this.locationMobile = '';
        this.locations = [];
        this.isMobile = true;
        this.page = 1;
        this.per_page = GeodestinosService.DEFAULT_PER_PAGE;
        this.rows = [];
        this.titleEst = false;
    }
    GeoGuideComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.media
            .register()
            .pipe(map(function (size) { return size === 'xs' || size === 'sm'; }))
            .subscribe(function (mobile) {
            _this.isMobile = mobile;
        });
        var params = this.queryService.parse(window.location.search);
        this.searchForm = this.fb.group({
            query: [params.q ? decodeURIComponent(params.q) : ''],
        });
        this.geodestinos.getGeoDestinoLocations({ name: this.geolocation }).subscribe(function (_a) {
            var count = _a.count, list = _a.list;
            if (list[0]) {
                _this.geoLoc = list[0];
                _this.locations = list[0].locations;
                if (_this.queryinput) {
                    _this.searchForm.setValue({
                        query: _this.queryinput,
                    });
                    _this.doSearch();
                }
                else {
                    _this.fetch(1).subscribe(function (_a) {
                        var count = _a.count, list = _a.list;
                        return _this.handleRows(count, list);
                    });
                }
            }
        });
        for (var _i = 0, _a = this.getSectionsDetail(); _i < _a.length; _i++) {
            var section = _a[_i];
            this.sections.push(section.display);
        }
    };
    GeoGuideComponent.prototype.scrollTo = function (index, location) {
        var _this = this;
        var el = document.getElementById('accordion' + index);
        el.scrollIntoView();
        this.location = location;
        var filter = { location: this.location, q: this.query };
        this.geodestinos.searchAllSections(filter, {
            page: 1,
            per_page: this.per_page,
        }).then(function (res) {
            _this.titleEst = true;
            _this.handleRows(res.count, res.list);
        });
        this.searchForm.get('query').setValue('');
    };
    GeoGuideComponent.prototype.doSearch = function () {
        var _this = this;
        /*this.location=null;
        const q = this.searchForm.get('query').value;
        this.query = q ? q.trim() : '';
        this.fetch(1).subscribe(({ count, list }) => this.handleRows(count, list));*/
        var q = this.searchForm.get('query').value;
        this.query = q ? q.trim() : '';
        var filter = { 'location>$in': this.locations.join(','), q: this.query };
        this.query = '';
        this.geodestinos.searchAllSections(filter, {
            page: 1,
            per_page: this.per_page,
        }).then(function (res) {
            _this.titleEst = true;
            _this.handleRows(res.count, res.list);
        });
    };
    Object.defineProperty(GeoGuideComponent.prototype, "title", {
        get: function () {
            if (this.titleEst) {
                if (this.location) {
                    return "Establecimientos en " + this.location;
                }
                else {
                    return "Establecimientos en " + this.geoLoc.fullName;
                }
            }
            if (this.location && this.location.length > 0) {
                return this.geodestinos.getSectionDescription(this.section) + " en " + this.location;
            }
            else {
                return this.geodestinos.getSectionDescription(this.section) + " en " + this.geoLoc.fullName;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeoGuideComponent.prototype, "loaded", {
        get: function () {
            return this.count !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    GeoGuideComponent.prototype.filter = function (section, locat) {
        var _this = this;
        if (this.section !== section || this.location !== locat) {
            this.section = section;
            this.location = locat;
            this.fetch(1).subscribe(function (_a) {
                var count = _a.count, list = _a.list;
                return _this.handleRows(count, list);
            });
            this.searchForm.get('query').setValue('');
            this.titleEst = false;
        }
    };
    GeoGuideComponent.prototype.fetch = function (page) {
        if (page === void 0) { page = 1; }
        var filter = this.location
            ? { location: this.location, q: this.query }
            : { 'location>$in': this.locations.join(','), q: this.query };
        this.query = '';
        return this.geodestinos.fetch(this.section, filter, {
            page: page,
            per_page: this.per_page,
        });
    };
    GeoGuideComponent.prototype.pageChanged = function (event) {
        var _this = this;
        if (this.page !== event.page) {
            this.page = event.page;
            this.fetch(event.page).subscribe(function (_a) {
                var count = _a.count, list = _a.list;
                return _this.handleRows(count, list);
            });
        }
    };
    GeoGuideComponent.prototype.preppendAssetsUrl = function (url) {
        return "" + this.env.assetsUrl + url;
    };
    GeoGuideComponent.prototype.isSectionActive = function (type) {
        return this.geodestinos.isSectionActive(type);
    };
    GeoGuideComponent.prototype.getSectionsDetail = function () {
        var _this = this;
        return [
            { name: 'hotel', display: 'Servicios Hoteleros' },
            { name: 'apartamento', display: 'Viviendas / Apartamentos' },
            { name: 'rural', display: 'Casas Rurales' },
            { name: 'family', display: 'Familia' },
            { name: 'albergue', display: 'Albergues' },
            { name: 'camping', display: 'Campings' },
            { name: 'pension', display: 'Pensiones' },
            { name: 'comer', display: 'Restaurantes' },
            { name: 'hacer', display: 'Hacer' },
            { name: 'informarse', display: 'Informarse' },
            { name: 'transporte', display: 'Transporte' },
            { name: 'servicios', display: 'Servicios' },
            { name: 'salud', display: 'Bienestar y Salud' },
            { name: 'deporte', display: 'Actividades Deportivas' },
            { name: 'compras', display: 'Compras' },
        ].filter(function (row) {
            return _this.isSectionActive(row.name);
        });
    };
    GeoGuideComponent.prototype.handleRows = function (count, list) {
        if (this.count != undefined) {
            this.scrollToService.scrollTo({
                offset: this.isMobile ? 0 : 0,
                target: 'cms-geoguide-container',
                duration: 100,
            });
        }
        this.rows = list;
        this.count = count;
    };
    GeoGuideComponent.prototype.onSelectChangeLocation = function (ev) {
        var value = ev.target ? ev.target.value : '';
        this.locationMobile = value;
        if (this.locationMobile !== '') {
            this.filter(this.sectionMobile, this.locationMobile);
        }
    };
    GeoGuideComponent.prototype.onSelectChangeSection = function (ev) {
        var value = ev.target ? ev.target.value : '';
        if (value !== '') {
            for (var _i = 0, _a = this.getSectionsDetail(); _i < _a.length; _i++) {
                var section = _a[_i];
                if (section.display === value) {
                    this.sectionMobile = section.name;
                    break;
                }
            }
        }
        else {
            this.sectionMobile = 'hotel';
        }
        if (this.locationMobile !== '') {
            this.filter(this.sectionMobile, this.locationMobile);
        }
    };
    return GeoGuideComponent;
}());
export { GeoGuideComponent };
