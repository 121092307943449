/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./geodestino-contact-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./geodestino-contact-item.component";
import * as i3 from "../../../shared/models/environment";
import * as i4 from "@angular/platform-browser";
var styles_GeoDestinoContactItemComponent = [i0.styles];
var RenderType_GeoDestinoContactItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeoDestinoContactItemComponent, data: {} });
export { RenderType_GeoDestinoContactItemComponent as RenderType_GeoDestinoContactItemComponent };
export function View_GeoDestinoContactItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "row contact-card"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "contact-picture"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00BFQUIERE APARECER EN ESTA LISTA?"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [["style", "margin-left: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00BFTiene un negocio y quiere aparecer en esta lista?"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" P\u00F3ngase en contacto con nosotros en el siguiente enlace "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["class", "btn btn-primary"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contacto"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getBackGround(); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getLogo(); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.getContactLink(); _ck(_v, 14, 0, currVal_2); }); }
export function View_GeoDestinoContactItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-geodestino-contact-item", [], null, null, null, View_GeoDestinoContactItemComponent_0, RenderType_GeoDestinoContactItemComponent)), i1.ɵdid(1, 49152, null, 0, i2.GeoDestinoContactItemComponent, [i3.CMS_ENV, i4.DomSanitizer], null, null)], null, null); }
var GeoDestinoContactItemComponentNgFactory = i1.ɵccf("cms-geodestino-contact-item", i2.GeoDestinoContactItemComponent, View_GeoDestinoContactItemComponent_Host_0, {}, {}, []);
export { GeoDestinoContactItemComponentNgFactory as GeoDestinoContactItemComponentNgFactory };
