import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core';

import { TabComponent } from '@cms-app/shared';

@Directive({
  selector: '[sectionLazy]'
})
export class SectionLazyDirective implements OnInit {
  rendered = false;

  constructor(
    private template: TemplateRef<any>,
    private container: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private tab: TabComponent
  ) {}

  ngOnInit() {
    this.container.clear();
    this.tab.select.subscribe(() => this.render());
  }

  render() {
    if (!this.rendered) {
      this.rendered = true;
      this.container.createEmbeddedView(this.template);

      setTimeout(() => this.cd.detectChanges());
    }
  }
}
