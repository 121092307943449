import { HttpClient, HttpParams } from '@angular/common/http';
import { CMSEnv } from '@cms-app/shared';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var RssNewService = /** @class */ (function () {
    function RssNewService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/rssnew';
    }
    RssNewService.prototype.find = function (options, query) {
        if (options === void 0) { options = { page: 1, per_page: 10 }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        if (query) {
            switch (query.type) {
                case 'region':
                    params = params.set('region', query.query);
                    break;
                case 'location':
                    params = params.set('location', query.query);
                    break;
                case 'title':
                    params = params.set('title>$like', query.query);
                    break;
            }
        }
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response',
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10),
        }); }));
    };
    RssNewService.prototype.add = function (payload) {
        console.log("" + this.env.baseUrl + this.endpoint);
        console.log(payload);
        return this.http.post("" + this.env.baseUrl + this.endpoint, payload);
    };
    RssNewService.prototype.update = function (rssNew, payload) {
        return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + rssNew, payload);
    };
    RssNewService.prototype.delete = function (id) {
        return this.http.delete("" + this.env.baseUrl + this.endpoint + "/" + id);
    };
    RssNewService.prototype.empty = function () {
        return {
            _id: null,
            date: null,
            title: '',
            img: '',
            link: '',
            content: '',
            source: '',
            region: '',
            location: '',
            active: false,
        };
    };
    RssNewService.ngInjectableDef = i0.defineInjectable({ factory: function RssNewService_Factory() { return new RssNewService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: RssNewService, providedIn: "root" });
    return RssNewService;
}());
export { RssNewService };
