<div class="row">
  <div class="col-md-4 col-sm-6 cms-card-container" *ngFor="let course of rows">
    <cms-course-detail
      [course]="course"
      [color]="color"
      [register]="register"
    ></cms-course-detail>
  </div>
</div>
<div class="row" *ngIf="moreCourses">
  <div class="col-md-12" style="text-align: center">
    <button
      type="button"
      class="btn cms-card-body-font"
      (click)="appendCourses()"
      [style.backgroundColor]="theme.color | color"
      style="padding: 12px; color: white; margin-top: 48px"
    >
      CARGAR MÁS CURSOS
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="150"
      (scrolled)="appendCourses()"
    ></div>
  </div>
</div>
