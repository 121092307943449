<cms-admin>
  <ol class="breadcrumb">
    <li>
      <a (click)="goBack()" style="cursor: pointer">Fuentes RSS</a>
    </li>
    <li class="active">{{ rssSrc.name }}</li>
  </ol>
  <cms-section-title [showAdd]="false" [showQuery]="false">{{
    rssSrc.name
  }}</cms-section-title>
  <div class="row">
    <div class="col-sm-4">
      <div class="card card-body">
        <form (submit)="edit ? updateSource() : submitSource()">
          <div>
            <div class="form-group">
              <label for="inputName">Nombre</label>
              <input
                type="text"
                name="name"
                id="inputName"
                [(ngModel)]="rssSrc.name"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputURL">URL</label>
              <input
                type="text"
                name="url"
                id="inputURL"
                [(ngModel)]="rssSrc.url"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputSelector">Selector Articulos</label>
              <input
                type="text"
                name="articleSelector"
                id="inputSelector"
                [(ngModel)]="rssSrc.articleSelector"
                class="form-control"
                autofocus
              />
            </div>
            <div class="form-group">
              <label for="inputSelector">Blog Galicia</label>
              <input
                type="checkbox"
                name="blogGalicia"
                id="inputBlogGalicia"
                [(ngModel)]="rssSrc.blogGalicia"
                class="form-control"
                autofocus
              />
            </div>
          </div>
          <button class="btn btn-primary btn-block">Guardar</button>
        </form>
      </div>
    </div>
  </div>
</cms-admin>
