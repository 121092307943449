import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';

@Injectable({
  providedIn: 'root',
})
export class ScrapperService {

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  launch(millis: Number, sources: String[]) {
    let params = {
      millis: millis,
      sources: sources,
    }
    return this.http.post(`${this.env.scrapperUrl}/launch`, params);
  }

  backup(sources: String[]) {
    return this.http.post(`${this.env.scrapperUrl}/backup`, sources);
  }

  restore(sources: String[]) {
    return this.http.post(`${this.env.scrapperUrl}/restore`, sources);
  }
}
