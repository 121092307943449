import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var MessagingService = /** @class */ (function () {
    function MessagingService(toastr) {
        this.toastr = toastr;
    }
    MessagingService.prototype.success = function (message, title) {
        this.toastr.success(message, title);
    };
    MessagingService.prototype.error = function (message, title) {
        this.toastr.error(message, title);
    };
    MessagingService.ngInjectableDef = i0.defineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.inject(i1.ToastrService)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
