import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GeoDestino, GeodestinosService } from '@cms-app/geodestinos';

@Component({
  selector: 'cms-admin-restaurants',
  templateUrl: './restaurants.component.html'
})
export class AdminRestaurantsComponent implements OnInit {
  rows: GeoDestino[] = [];
  count = 0;
  page = 1;
  per_page = 20;
  query = '';
  loading = false;

  constructor(
    private router: Router,
    private geodestinosService: GeodestinosService
  ) {}

  ngOnInit() {
    this.find();
  }

  checkLink(row: GeoDestino) {
    window.open(row.url, '_blank');
  }

  update(row: GeoDestino) {
    this.router.navigate(
      ['admin', 'geodestinos', 'geodestino-detail', 'comer', row._id],
      {
        state: {
          data: row,
          type: 'comer'
        }
      }
    );
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    this.loading = true;
    this.geodestinosService
      .fetch(
        'comer',
        this.query && this.query.length > 0 ? { q: this.query } : {},
        { page: this.page || 1, per_page: this.per_page }
      )
      .subscribe(
        ({ list, count }) => {
          this.rows = list;
          this.count = count;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }
  onAdd(){
    this.router.navigate(['admin', 'geodestinos', 'geodestino-detail', 'comer', 'new']);
  }
}
