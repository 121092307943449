import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { PVFamiliesService, FamiliesService } from '@cms-app/courses';
import { map } from 'rxjs/operators';
var AdminPVFamilyDetailComponent = /** @class */ (function () {
    function AdminPVFamilyDetailComponent(fb, router, route, families, pvFamilies) {
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.families = families;
        this.pvFamilies = pvFamilies;
        this.subfamiliesList = [];
    }
    AdminPVFamilyDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.families
            .find({ fileds: 'name,source', per_page: 200 })
            .pipe(map(function (res) { return res.list; }), map(function (list) {
            return list.map(function (row) { return (tslib_1.__assign({}, row, { display: row.source + " - " + row.name })); });
        }))
            .subscribe(function (list) {
            console.log(list);
            _this.subfamiliesList = list;
        });
        var original = this.route.snapshot.data.family;
        if (original) {
            console.log(original);
            this.original = original;
        }
        var subfamilies = this.original ? this.original.families : [];
        this.family = this.fb.group({
            name: this.original ? this.original.name : '',
            families: this.fb.array(subfamilies.map(function (family) { return _this.fb.control(family._id); }))
        });
    };
    Object.defineProperty(AdminPVFamilyDetailComponent.prototype, "subfamilies", {
        get: function () {
            return this.family.get('families');
        },
        enumerable: true,
        configurable: true
    });
    AdminPVFamilyDetailComponent.prototype.add = function () {
        this.family.get('families').push(this.fb.control(''));
    };
    AdminPVFamilyDetailComponent.prototype.remove = function (idx) {
        var families = this.family.get('families');
        families.removeAt(idx);
    };
    AdminPVFamilyDetailComponent.prototype.update = function () {
        var _this = this;
        this.pvFamilies
            .update(this.original ? this.original._id : null, this.family.value)
            .subscribe(function () { return _this.goToFamilies(); });
    };
    // ROUTES
    AdminPVFamilyDetailComponent.prototype.goToFamilies = function () {
        this.router.navigate(['admin', 'iefp', 'pv-families']);
    };
    return AdminPVFamilyDetailComponent;
}());
export { AdminPVFamilyDetailComponent };
