import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

import { NgxMistakesModule } from 'ngx-mistakes';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule, ModalOptions } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { AdminComponent } from './components/admin/admin.component';
import { NavBarComponent } from './components/navbar/navbar.component';
import { SectionTitleComponent } from './components/section-title/section-title.componen';
import { MessagesComponent } from './components/messages/messages.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';

import { RemoveReferencePipe } from './pipes/remove-reference.pipe';
import { ColorPipe } from './pipes/color.pipe';

import { BirthdateDirective } from './directives/birthdate.directive';
import { DataTableDirective } from './directives/table.directive';
import { ImagePreloadDirective } from './directives/image.directive';

import { MODAL_OPTIONS } from './models/modal-options.token';
import { CMS_DATEPICKER_CONFIG } from './models/datepicker.token';

export const modalOptions: Partial<ModalOptions> = {
  backdrop: 'static',
  ignoreBackdropClick: true
};

export const bsConfig: Partial<BsDatepickerConfig> = {
  containerClass: 'theme-dark-blue',
  showWeekNumbers: false
};
registerLocaleData(localeEs, 'es');
defineLocale('es', esLocale);

const components = [
  AdminComponent,
  NavBarComponent,
  MessagesComponent,
  SectionTitleComponent,
  TabsComponent,
  TabComponent
];
const directives = [
  BirthdateDirective,
  DataTableDirective,
  ImagePreloadDirective
];
const services = [Title, Meta];
const pipes = [RemoveReferencePipe, ColorPipe];
const ngxBootstrap = [
  TooltipModule,
  BsDatepickerModule,
  BsDropdownModule,
  ModalModule,
  TabsModule,
  ButtonsModule,
  PaginationModule
];

@NgModule({
  declarations: [...components, ...pipes, ...directives],
  exports: [
    ...components,
    ...directives,
    ...pipes,
    CommonModule,
    ReactiveFormsModule,
    NgxMistakesModule,
    ...ngxBootstrap
  ],
  providers: [
    ...services,
    {
      provide: MODAL_OPTIONS,
      useValue: modalOptions
    },
    {
      provide: CMS_DATEPICKER_CONFIG,
      useValue: bsConfig
    }
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMistakesModule,
    RouterModule,
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot()
  ]
})
export class SharedModule {}
