import { OnInit } from '@angular/core';
import { CoursesService } from '@cms-app/courses';
import { Router } from '@angular/router';
var AdminCoursesComponent = /** @class */ (function () {
    function AdminCoursesComponent(router, coursesService) {
        this.router = router;
        this.coursesService = coursesService;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.query = '';
        this.queryBySource = '';
        this.loading = false;
    }
    AdminCoursesComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminCoursesComponent.prototype.checkLink = function (row) {
        window.open(row.url, '_blank');
    };
    AdminCoursesComponent.prototype.onAdd = function () {
        this.router.navigate(['admin', 'iefp', 'courses', 'new']);
    };
    AdminCoursesComponent.prototype.update = function (row) {
        this.router.navigate(['admin', 'iefp', 'courses', row._id]);
    };
    AdminCoursesComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminCoursesComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminCoursesComponent.prototype.onQueryBySource = function (query) {
        this.queryBySource = query;
        this.findBySource();
    };
    AdminCoursesComponent.prototype.onExport = function (query) {
        this.coursesService.excel(query);
    };
    AdminCoursesComponent.prototype.find = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        if (this.query) {
            options['namePV>$like'] = this.query;
            options['source>$like'] = this.queryBySource;
        }
        this.loading = true;
        this.coursesService.find(options).subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    AdminCoursesComponent.prototype.findBySource = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        if (this.queryBySource) {
            options['source>$like'] = this.queryBySource;
        }
        this.loading = true;
        this.coursesService.find(options).subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    return AdminCoursesComponent;
}());
export { AdminCoursesComponent };
