export const environment = {
  production: true,
  elements: false,
  baseUrl: 'https://api.iefp.galicia.info/api',
  assetsUrl: 'https://iefp.galicia.info',
  copyblogUrl: 'https://api.galicia.info/copyblog/v2/cache',
  picturesUrl: 'https://s3-eu-west-1.amazonaws.com/mruta-assets',
  filesUrl: 'https://api.galicia.info/images/upload',
  scrapperUrl: 'https://scrapper.galicia.info'
};
