<div class="container">
  <div class="row">
    <div class="card">
      <article class="card-body">
        <h4 class="card-title text-center mb-4 mt-1">Log In</h4>
        <hr>
        <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-user"></i> </span>
           </div>
          <input name="" class="form-control" placeholder="Nombre" type="text" [(ngModel)]="userName">
        </div> <!-- input-group.// -->
        </div> <!-- form-group// -->
        <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
           </div>
            <input class="form-control" placeholder="******" type="password" [(ngModel)]="password" (keyup.enter)="Login()">
        </div> <!-- input-group.// -->
        </div> <!-- form-group// -->
        <div class="form-group">
        <button type="submit" (click)="Login()" class="btn btn-primary"> Login  </button>
        </div> <!-- form-group// -->
      </article>
      </div> <!-- card.// -->
  </div>
</div>
