<!--<div>
  <img class="copyblog-img"
    [src]="getImage()"
  >
</div>
<div class="new image new-summary">
  <div class="new-header" (click)="handleClick()">
    <h2 class="new-title">
     {{ rssNew.title}}
    </h2>
    <p class="new-date">
      <span class="new-text">
        {{ getDate() | date: 'dd/MM/yyyy' }}
      </span>
    </p>
  </div>
  <div class="new-source">{{rssNew.source}}</div>
  <div class="blog-separator">&nbsp;</div>

  <div class="new-bottom">
    <a
      class="wsite-button wsite-button-large wsite-button-highlight"
      target="_blank"
      [href]="postLink"
    >
      <span class="wsite-button-inner">Leer más</span>
    </a>
  </div>-->
  <div class="new image new-summary" id={this.id}>
    <div class="new-header" (click)="handleClick()">
      <h2 class="new-title">
        <a class="blog-title-link blog-link" [href]='getLink()' [innerHTML]="cutText(rssNew.title)"></a>
      </h2>
      <p class="new-date">
        <span class="new-text">
          {{ getDate() | date: 'dd/MM/yyyy' }}
        </span>
      </p>
    </div>
    <div class="new-source">{{rssNew.source}}</div>
    <div class="new-separator">&nbsp;</div>
    <div class="new-content" (click)="handleClick()">
      <div>
        <img class="copyblog-img"
          [src]="getImage()"/>
      </div>
      <div class="new-bottom">
        <a
          class="wsite-button wsite-button-large wsite-button-highlight"
          target="_blank"
          [href]="getLink()"
        >
          <span class="wsite-button-inner">Leer más</span>
        </a>
      </div>
    </div>
  </div>


