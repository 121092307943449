import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var PVFamiliesService = /** @class */ (function () {
    function PVFamiliesService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/pv-families';
    }
    PVFamiliesService.prototype.find = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 10, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    PVFamiliesService.prototype.groupByName = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 10 }; }
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint + "/group-by-name", {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    PVFamiliesService.prototype.findOne = function (family) {
        var params = new HttpParams().set('expand', 'tags.name,families.name');
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/" + family, {
            params: params
        });
    };
    PVFamiliesService.prototype.update = function (familyId, family) {
        if (familyId) {
            return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + familyId, tslib_1.__assign({}, family, { families: family.families.filter(function (row) { return row.length > 0; }) }));
        }
        else {
            return this.http.post("" + this.env.baseUrl + this.endpoint, family);
        }
    };
    PVFamiliesService.prototype.subfamilies = function (family) {
        return family.families.reduce(function (acc, row) {
            if (row.families.length < 1) {
                return acc.concat([row]);
            }
            else {
                var rows = row.families;
                return acc.concat(rows);
            }
        }, []);
    };
    PVFamiliesService.prototype.match = function (one, other) {
        if (!one || typeof other !== 'string' || other.length < 3) {
            return true;
        }
        // Remove special chars
        other = other.replace(new RegExp('([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])', 'g'), '\\$1');
        // Replace % and _ with equivalent regex
        other = other.replace(/%/g, '.*').replace(/_/g, '.');
        // Check matches
        var check = RegExp(other, 'gi').test(one);
        return check;
    };
    PVFamiliesService.ngInjectableDef = i0.defineInjectable({ factory: function PVFamiliesService_Factory() { return new PVFamiliesService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: PVFamiliesService, providedIn: "root" });
    return PVFamiliesService;
}());
export { PVFamiliesService };
