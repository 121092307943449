import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RssNew } from '../models/rss-new.model';
import { RssQuery } from '../models/rss-query.model';

@Injectable({
  providedIn: 'root',
})
export class RssNewService {
  endpoint = '/rssnew';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  find(
    options: any = { page: 1, per_page: 10 },
    query?: RssQuery
  ): Observable<{
    list: RssNew[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;

    let params = Object.keys(options).reduce((acc: HttpParams, key: string) => {
      return acc.set(key, String(options[key]));
    }, new HttpParams());

    if (query) {
      switch (query.type) {
        case 'region':
          params = params.set('region', query.query);
          break;
        case 'location':
          params = params.set('location', query.query);
          break;
        case 'title' :
          params = params.set('title>$like', query.query);
          break;
      }
    }

    return this.http
      .get<RssNew[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10),
        }))
      );
  }

  add(payload: Partial<RssNew>) {
    console.log(`${this.env.baseUrl}${this.endpoint}`);
    console.log(payload);
    return this.http.post<RssNew>(
      `${this.env.baseUrl}${this.endpoint}`,
      payload
    );
  }

  update(rssNew: string, payload: Partial<RssNew>) {
    return this.http.patch<RssNew>(
      `${this.env.baseUrl}${this.endpoint}/${rssNew}`,
      payload
    );
  }

  delete(id: string) {
    return this.http.delete<RssNew>(
      `${this.env.baseUrl}${this.endpoint}/${id}`
    );
  }

  empty(): RssNew {
    return {
      _id: null,
      date: null,
      title: '',
      img: '',
      link: '',
      content: '',
      source: '',
      region: '',
      location: '',
      active: false,
    };
  }
}
