
export interface ResponseLong {
  status:number;
  msg:string;

}

export const WORKING: number = 0;
export const FINISHED: number = 1;
export const ERROR: number = 3;
