<div style="text-align: center">
  <h1>{{ rssNew.title }}</h1>
</div>
<br />
<div *ngIf="rssNew.img" style="text-align: center">
  <img [src]="rssNew.img" />
</div>
<br/>
<div>
  <p>FUENTE: {{rssNew.source}}</p>
</div>
<br />
<div>
  <p *ngFor="let p of getArticle()" [innerHTML]='p'></p>
</div>
