<div [formGroup]="parent">
  <div formArrayName="students">
    <div
      [formGroupName]="i"
      *ngFor="let student of students.controls; let i = index"
    >
      <div class="row" *ngIf="students.controls.length > 1">
        <div class="col-md-12">
          <h4 style="display: inline">Datos del Alumno ({{ i + 1 }})</h4>
          <button
            (click)="remove(i)"
            style="display: inline-block; margin-left: 10px"
            class="btn btn-danger"
          >
            <i class="fa fa-ban" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">DNI/NIE</label>
            <div class="required" *ngIf="bonus">*</div>
            <cms-course-entrust-miniloader
              *ngIf="loading"
            ></cms-course-entrust-miniloader>
            <input
              type="text"
              class="form-control"
              (blur)="onVatBlur(i)"
              formControlName="vatIdentifier"
            />
            <div ngxErrors="vatIdentifier" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
              <div ngxError="maxLength" when="touched">
                La longitud de este campo no puede superar los 9 caracteres
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Nombre</label>
            <div class="required">*</div>
            <input
              type="text"
              class="form-control"
              (blur)="onNameBlur(i)"
              formControlName="name"
            />
            <div ngxErrors="name" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Primer Apellido</label>
            <div class="required">*</div>
            <input
              type="text"
              class="form-control"
              (blur)="onNameBlur(i)"
              formControlName="subname"
            />
            <div ngxErrors="subname" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Segundo Apellido</label>
            <input
              type="text"
              class="form-control"
              (blur)="onNameBlur(i)"
              formControlName="subname2"
            />
            <div ngxErrors="subname2" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Email</label>
            <div class="required">*</div>
            <input
              type="text"
              class="form-control"
              (blur)="onEmailBlur(i)"
              formControlName="email"
            />
            <div ngxErrors="email" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
            <div ngxErrors="email" arr="students" [idx]="i">
              <div ngxError="email" when="touched">
                El email no tiene el formato adecuado
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Teléfono</label>
            <div class="required" *ngIf="bonus">*</div>
            <input
              type="text"
              class="form-control"
              (blur)="onPhoneBlur(i)"
              formControlName="phone"
            />
            <div ngxErrors="phone" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Sexo</label>
            <div class="required">*</div>
            <select class="form-control" formControlName="sex">
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
            </select>
            <div ngxErrors="sex" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Nacionalidad</label>
            <input
            type="text"
            class="form-control"
            formControlName="nationality"
          />
            <div ngxErrors="sex" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom" *ngIf="!preselectedCourse">
          <div class="form-group">
            <label for="">Denominación del Curso</label>
            <div class="required">*</div>
            <input type="text" class="form-control" formControlName="course" />
            <div ngxErrors="course" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="parent.get('bonus').value === 'yes'">
        <div class="col-md-12">
          <div style="margin-top: 20px"></div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Número de la seguridad social</label>
            <div class="required" *ngIf="parent.get('bonus').value === 'yes'">
              *
            </div>
            <input
              type="text"
              class="form-control"
              formControlName="ssNumber"
            />
            <div ngxErrors="ssNumber" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label>Fecha Nacimiento:</label>
            <div class="form-inline">
              <input
                class="form-control"
                cmsBirthdate
                bsDatepicker
                placeholder="01/01/2002"
                formControlName="birthdate"
                triggers="none"
                placement="bottom"
                [bsConfig]="bsConfig"
              />
              <button class="btn btn-default" (click)="triggerDatepicker(i)">
                <i class="fa fa-calendar-plus-o" aria-hidden="true"></i>
              </button>
            </div>
            <div
              ngxErrors="birthdate"
              style="color: red"
              arr="students"
              [idx]="i"
            >
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
              <div ngxError="pattern" when="touched">
                La fecha no tiene el formato adecuado: 01/01/2002
              </div>
              <div ngxError="dateBeforeToday" when="touched">
                La fecha no puede ser posterior a hoy
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <label for=""></label>
            <div class="required" *ngIf="parent.get('bonus').value === 'yes'">
              *
            </div>
            <input type="text" bsDatepicker readonly="true" placement="bottom left" [bsConfig]="bsConfig"
              class="form-control" formControlName="birthdate" />
            <div ngxErrors="birthdate" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div> -->
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Nivel Estudios Finalizados</label>
            <div class="required" *ngIf="parent.get('bonus').value === 'yes'">
              *
            </div>
            <select class="form-control" formControlName="studies">
              <option value="1. Sin Estudios">Sin Estudios</option>
              <option
                value="2. Primaria"
              >
                Primaria</option
              >
              <option value="3.ESO-EGB">ESO / EGB</option>
              <option value="4.BUP-FP-BACH"
                >BUP / BACH / FP</option
              >
              <option value="5.Certificado Nivel 3">Certificado Nivel 3</option>
              <option value="6.Tecnico Superior">Tecnico Superior</option>
              <option value="7.Diplomado">Diplomado</option>
              <option value="8.Licenciado">Licenciado</option>
              <option value="9.Doctor">Doctor</option>
              <option value="10.Otros">Otros</option>
            </select>
            <div ngxErrors="studies" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 field-custom">
          <div class="form-group">
            <label for="">Grupo de cotización</label>
            <div class="required" *ngIf="parent.get('bonus').value === 'yes'">
              *
            </div>
            <select class="form-control" formControlName="cotization">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <div ngxErrors="cotization" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="ucm" formGroupName="poll">
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Titulos universitarios que posee</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="title">
              <option value="0 No posee titulo de enseñanza superior">No posee titulo de enseñanza superior</option>
              <option
                value="1 Posee título de estudios superiores no universitarios"
              >
              Posee título de estudios superiores no universitarios</option
              >
              <option value="2 Posee título universitario de ciclo corto (diplomatura)">Posee título universitario de ciclo corto (diplomatura)</option>
              <option value="3 Posee título universitario de ciclo largo (licenciatura-grado)"
                >Posee título universitario de ciclo largo (licenciatura-grado)</option
              >
              <option value="4 Posee máster universitario oficial">Posee máster universitario oficial</option>
              <option value="5 Posee título de doctor">Posee título de doctor</option>
            </select>
            <div ngxErrors="poll.title" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Año de Graduación de la última titulación</label>
            <div class="required">*</div>
            <input
              type="text"
              class="form-control"
              formControlName="graduation_year"
            />
            <div ngxErrors="poll.graduation_year" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">¿Accede por primera vez al sist. univ. español?</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="first_time">
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            <div ngxErrors="poll.first_time" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Relacion con la UCM</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="ucm_link">
              <option value="0 No está vinculado">No está vinculado</option>
              <option value="1 Es PDI">Es PDI</option>
              <option value="2 Es PAS">Es PAS</option>
              <option value="3 Es titulado de la UCM">Es titulado de la UCM</option>
            </select>
            <div ngxErrors="poll.ucm_link" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Situación laboral actual</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="actual_job">
              <option value="0 Estoy trabajando">Estoy trabajando</option>
              <option value="1 He trabajado antes y estoy buscando trabajo">He trabajado antes y estoy buscando trabajo</option>
              <option value="2 Nunca he trabajado y estoy buscando mi primer empleo">Nunca he trabajado y estoy buscando mi primer empleo</option>
              <option value="3 No tengo trabajo y no estoy buscando empleo">No tengo trabajo y no estoy buscando empleo</option>
            </select>
            <div ngxErrors="poll.actual_job" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Tipo de trabajo</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="job_type">
              <option value="0 No realizo ningún trabajo ni actividad remunerada">No realizo ningún trabajo ni actividad remunerada</option>
              <option value="1 Trabajo esporádico o eventual">Trabajo esporádico o eventual</option>
              <option value="2 Trabajo continuado a tiempo parcial">Trabajo continuado a tiempo parcial</option>
              <option value="3 Trabajo continuado a tiempo completo">Trabajo continuado a tiempo completo</option>
            </select>
            <div ngxErrors="poll.job_type" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Ocupación Actual</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="actual_occupation">
              <option value="0 Ocupaciones militares">Ocupaciones militares</option>
              <option value="1 Directores y gerentes">Directores y gerentes</option>
              <option value="2 Técnicos y profesionales científicos e intelectuales">Técnicos y profesionales científicos e intelectuales</option>
              <option value="3 Técnicos y profesionales de apoyo">Técnicos y profesionales de apoyo</option>
              <option value="4 Empleados de tipo contable y administrativo">Empleados de tipo contable y administrativo</option>
              <option value="5 Trabajadores de servicios de restauración..">Trabajadores de servicios de restauración</option>
              <option value="6 Trabajadores cualificados en la agricultura…">Trabajadores cualificados en la agricultura</option>
              <option value="7 Artesanos y trabajadores cualificados en las industrias">Artesanos y trabajadores cualificados en las industrias</option>
              <option value="8 Operadores de instalaciones y maquinaria">Operadores de instalaciones y maquinaria</option>
              <option value="9 Trabajadores no cualificados">Trabajadores no cualificados</option>
              <option value="10 Parado o parada">Parado o parada</option>
              <option value="11 Jubilados o jubiladas">Jubilados o jubiladas</option>
              <option value="88 No aplica">No aplica</option>
              <option value="12 Amas de casa">12 Amas de casa</option>
              <option value="13 Incapacitados para trabajar">Incapacitados para trabajar</option>
              <option value="14 Otra situación (rentistas…)">Otra situación (rentistas…)</option>
            </select>
            <div ngxErrors="poll.actual_occupation" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Sector empresa</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="sector">
              <option value="1 Agricultura, ganadería y pesca">Agricultura, ganadería y pesca</option>
              <option value="2 Industrias manufactureras y extractivas">Industrias manufactureras y extractivas</option>
              <option value="3 Energía eléctrica, gas y agua">Energía eléctrica, gas y agua</option>
              <option value="4 Construcción">Construcción</option>
              <option value="5 Comercio, distribución y reparaciones">Comercio, distribución y reparaciones</option>
              <option value="6 Hostelería">Hostelería</option>
              <option value="7 Transporte y almacenamiento">Transporte y almacenamiento</option>
              <option value="8 Comunicaciones">Comunicaciones</option>
              <option value="9 Intermediación financiera (bancos, cajas de ahorro, etc)">Intermediación financiera (bancos, cajas de ahorro, etc)</option>
              <option value="10 Actividades inmobiliarias y de alquiler">Actividades inmobiliarias y de alquiler</option>
              <option value="11 Servicios empresariales: consultoría legal, contable, etc">Servicios empresariales: consultoría legal, contable, etc</option>
              <option value="12 Administración pública, defensa y seguridad social">Administración pública, defensa y seguridad social</option>
              <option value="13 Enseñanza">Enseñanza</option>
              <option value="14 Actividades sanitarias y veterinarias">Actividades sanitarias y veterinarias</option>
              <option value="15 Servicios sociales, deportivos o culturales">Servicios sociales, deportivos o culturales</option>
              <option value="16 Otros">Otros</option>
            </select>
            <div ngxErrors="poll.sector" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Puesto en la empresa</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="job">
              <option value="1 Administrativo">Administrativo</option>
              <option value="2 Asistente, apoyo al técnico especializado o al responsable">Asistente, apoyo al técnico especializado o al responsable</option>
              <option value="3 Técnico">Técnico</option>
              <option value="4 Comercial (técnico comercial, delegado de ventas)">Comercial (técnico comercial, delegado de ventas)</option>
              <option value="5 Responsable de área (departamento, unidad, división)">Responsable de área (departamento, unidad, división)</option>
              <option value="6 Directivo">Directivo</option>
              <option value="7 Autónomo/desempeño de una actividad profesional">Autónomo/desempeño de una actividad profesional</option>
              <option value="8 Otro puesto">Otro puesto</option>
            </select>
            <div ngxErrors="poll.job" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Motivo para cursar un curso de formación continua</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="motivation">
              <option value="0 Para encontrar trabajo">Para encontrar trabajo</option>
              <option value="1 Para mejorar mis posibilidades de promoción">Para mejorar mis posibilidades de promoción</option>
              <option value="2 Para ampliar conocimientos">Para ampliar conocimientos</option>
              <option value="3 Para reciclarme">Para reciclarme</option>
              <option value="4 Por hobby">Por hobby</option>
              <option value="5 Otros motivos">Otros motivos</option>
            </select>
            <div ngxErrors="poll.motivation" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Motivo por el que ha elegido la UCM</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="ucm_motivation">
              <option value="0 Por el prestigio de la UCM">Por el prestigio de la UCM</option>
              <option value="1 Por el prestigio del título">Por el prestigio del título</option>
              <option value="2 Por disponer de bolsa de trabajo">Por disponer de bolsa de trabajo</option>
              <option value="3 Por sus salidas profesionales">Por sus salidas profesionales</option>
              <option value="4 Porque no se oferta en otra universidad o centro">Porque no se oferta en otra universidad o centro</option>
              <option value="5 Otros motivos">Otros motivos</option>
            </select>
            <div ngxErrors="poll.ucm_motivation" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 field-custom">
          <div class="form-group">
            <label for="">Motivo por el que se decidió a cursar este título</label>
            <div class="required">
              *
            </div>
            <select class="form-control" formControlName="course_motivation">
              <option value="0 Por el prestigio del centro donde se imparte">Por el prestigio del centro donde se imparte</option>
              <option value="1 Por el prestigio del propio título">Por el prestigio del propio título</option>
              <option value="2 Por disponer de prácticas o bolsa de trabajo">Por disponer de prácticas o bolsa de trabajo</option>
              <option value="3 Por sus salidas profesionales">Por sus salidas profesionales</option>
              <option value="4 Por su precio">Por su precio</option>
            </select>
            <div ngxErrors="poll.course_motivation" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div style="margin-top: 20px"></div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Observaciones</label>
            <textarea
              class="form-control"
              rows="5"
              formControlName="observations"
            ></textarea>
            <div ngxErrors="observations" arr="students" [idx]="i">
              <div ngxError="required" when="touched">
                Este campo es obligatorio
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div style="margin-top: 20px"></div>
    </div>
    <div class="col-md-12">
      <button class="btn btn-success" (click)="student.emit()">
        AÑADIR ALUMNO
      </button>
      <button class="btn btn-danger pull-right" (click)="next.emit()">
        SIGUIENTE
      </button>
    </div>
  </div>
</div>
