import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Course,
  CoursePlatform,
  CoursePlatformsService,
  EntrustService,
} from '@cms-app/courses';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'cms-course-entrust-nobonif',
  templateUrl: './course-entrust-nobonif.component.html',
})
export class CourseEntrustNoBonifComponent implements OnInit {
  inscriptionForm: FormGroup;

  @Input() privacy: string;
  @Input() okurl: string = 'inscripcion-ok.html';
  @Input() platform: CoursePlatform = this.platforms.guess();
  @Input() course: Course;

  sending = false;
  agent: string;
  loading = false;
  ucm:boolean = false;
  step = 1;

  constructor(
    @Inject(CMS_ENV) public env: CMSEnv,
    private fb: FormBuilder,
    private entrust: EntrustService,
    private platforms: CoursePlatformsService,
    private router: Router
  ) {}

  ngOnInit(): void {

    this.inscriptionForm = this.fb.group({
      agent: [''],
      bonus: ['no'],
      company: this.fb.group({
        businessName: [''],
        vatIdentifier: [''],
        address: [''],
        zipCode: [''],
        city: [''],
        state: [''],
        since: [
          new Date().getFullYear(),
          [Validators.required, Validators.pattern(/[0-9]*/)],
        ],
        agreement: [''],
        ssAccount: [0],
        email: ['', [Validators.required, Validators.email]],
        phone: [''],
        phone2: [''],
        accountNumber: ['', [Validators.pattern(this.entrust.accountNumberRE)]],
        delegate: [false],
        contactPerson: [''],
        employees: [1],
        sector: [''],
        year: [new Date().getFullYear(), [Validators.pattern(/[0-9]*/)]],
        observations: [''],
      }),
      privacy: [false, [Validators.requiredTrue]],
      newsletter: [false],
      representative: this.fb.group({
        name: [''],
        vatIdentifier: [''],
      }),
      students: this.fb.array([]),
    });
    this.addStudent();
  }

  addStudent() {
    const students = <FormArray>this.inscriptionForm.get('students');
    const bonus = 'no';
    let student = this.fb.group({
      name: ['', [Validators.required]],
      sex: ['', [Validators.required]],
      subname: ['', [Validators.required]],
      subname2: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      course: [this.course ? this.course.name : '', [Validators.required]],
      vatIdentifier: ['', [Validators.required]],
      ssNumber: [''],
      birthdate: [''],// Validación fecha para datapicker
      studies: [''],
      cotization: [''],
      observations: ['']});
      if(this.course && this.course.ucm){
        student.addControl('poll', this.fb.group({
          title: ['', [Validators.required]],
            graduation_year: ['', [Validators.required]],
            first_time: ['', [Validators.required]],
            ucm_link: ['', [Validators.required]],
            actual_job: ['', [Validators.required]],
            job_type: ['', [Validators.required]],
            actual_occupation: ['', [Validators.required]],
            sector: ['', [Validators.required]],
            job:['', [Validators.required]],
            motivation: ['', [Validators.required]],
            ucm_motivation: ['', [Validators.required]],
            course_motivation: ['', [Validators.required]]
        }));
      }
    students.push(student);
  }
  onStepChange(step: number) {
    this.step = step;
  }

  send() {
    if (!this.sending && this.inscriptionForm.valid) {
      const formData = this.inscriptionForm.value;

      let agentElement = this.inscriptionForm.get('agent');
      if (agentElement) {
        this.agent = agentElement.value;
      } else {
        this.agent = '-';
      }
      this.inscriptionForm.removeControl('agent');
      this.sending = true;
        this.entrust.pushNoEntrust(formData, this.platform).subscribe(
          (entrusts: Array<{ _id: string }>) => {
            if (entrusts[0]) {
              if (this.env.elements) {
                window.open(
                  `${window.location.origin}/${this.okurl}?entrust=${entrusts[0]._id}`,
                  '_self'
                );
              } else {
                this.router.navigate(['entrust', 'ok', entrusts[0]._id]);
              }
            }

            this.sending = false;
          },
          () => {
            this.sending = false;
          }
        );
    } else {
      this.handleError(this.inscriptionForm);
    }
  }

  handleError(form: FormGroup) {
    if (window.console) {
      console.log(this.inscriptionForm);
    }

    const privacy = form.get('privacy');
    if (privacy.invalid) {
      privacy.markAsTouched();
      this.step = 2;
      return;
    }

    const studentsArray = form.get('students') as FormArray;
    if (studentsArray.invalid) {
      this.step = 1;

      studentsArray.controls.forEach((student: FormGroup) => {
        if (student.invalid) {
          this.handleErrorFormGroup(student);
        }
      });

      return;
    }
    const companyForm = form.get('company') as FormGroup;

    if (companyForm.invalid) {
      this.step = 2;
      this.handleErrorFormGroup(companyForm);
    }
  }

  handleErrorFormGroup(group: FormGroup) {
    Object.keys(group.controls).forEach((key) => {
      group.controls[key].markAsTouched();
    });
  }
}
