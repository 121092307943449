/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./new.component";
import * as i4 from "../../../shared/models/environment";
import * as i5 from "../../providers/rss-new.service";
var styles_NewComponent = [i0.styles];
var RenderType_NewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewComponent, data: {} });
export { RenderType_NewComponent as RenderType_NewComponent };
export function View_NewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "new image new-summary"], ["id", "{this.id}"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "new-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "new-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "a", [["class", "blog-title-link blog-link"]], [[8, "href", 4], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "new-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "new-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "new-source"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "new-separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "new-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["class", "copyblog-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "new-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "a", [["class", "wsite-button wsite-button-large wsite-button-highlight"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "wsite-button-inner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leer m\u00E1s"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLink(); var currVal_1 = _co.cutText(_co.rssNew.title); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.getDate(), "dd/MM/yyyy")); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.rssNew.source; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.getImage(); _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.getLink(); _ck(_v, 17, 0, currVal_5); }); }
export function View_NewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-new", [], null, null, null, View_NewComponent_0, RenderType_NewComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewComponent, [i4.CMS_ENV, i5.RssNewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewComponentNgFactory = i1.ɵccf("cms-new", i3.NewComponent, View_NewComponent_Host_0, { rssNew: "rssNew", postLink: "postLink" }, {}, []);
export { NewComponentNgFactory as NewComponentNgFactory };
