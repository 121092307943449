import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EntrustService } from '@cms-app/courses';
import { Router } from '@angular/router';
var AdminEntrustsComponent = /** @class */ (function () {
    function AdminEntrustsComponent(router, entrustsService) {
        this.router = router;
        this.entrustsService = entrustsService;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.query = '';
        this.loading = false;
    }
    AdminEntrustsComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminEntrustsComponent.prototype.update = function (row) {
        this.router.navigate(['admin', 'iefp', 'entrusts', row._id]);
    };
    AdminEntrustsComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminEntrustsComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminEntrustsComponent.prototype.find = function () {
        var _this = this;
        var options = {
            sort: '-createdAt'
        };
        if (this.page) {
            options.page = this.page;
        }
        this.loading = true;
        this.entrustsService.find(options).subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list.map(function (row) { return (tslib_1.__assign({}, row, { status: _this.extractStatusResponse(row.response), source: _this.extractSource(row), createdAt: new Date(row.createdAt), description1: _this.extractDescription1(row), description2: _this.extractDescription2(row) })); });
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    AdminEntrustsComponent.prototype.extractDescription1 = function (entrust) {
        if (entrust.kind === 'only') {
            return entrust.formData.adh_razon_social + " " + entrust.formData.Correo_electronico;
        }
        return entrust.formData.students[0].fRazonSocial + " " + entrust.formData.students[0].fEmail;
    };
    AdminEntrustsComponent.prototype.extractDescription2 = function (entrust) {
        if (entrust.kind === 'only') {
            return entrust.formData.Persona_de_Contacto + " " + entrust.formData.adh_telefono;
        }
        return entrust.formData.students[0].fNombreAlumno + " " + entrust.formData.students[0].accion_formativa_movil;
    };
    AdminEntrustsComponent.prototype.extractSource = function (entrust) {
        return entrust.kind === 'only'
            ? entrust.formData.web_info
            : entrust.formData.students[0].web_info;
    };
    AdminEntrustsComponent.prototype.extractStatusResponse = function (response) {
        if (!response) {
            return null;
        }
        if (response.result) {
            if (response.result[0].message && response.result[0].message === 'Data Added Successfully') {
                return 'Success';
            }
            else {
                return response.result[0].message;
            }
        }
        else {
            if (response.code == 1030) {
                return response.description;
            }
            else {
                var match = response.match(/\"status\":\"(.*)\"/);
                return match ? match[1] : null;
            }
        }
    };
    return AdminEntrustsComponent;
}());
export { AdminEntrustsComponent };
