import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import {
  CMS_ENV,
  CMSEnv,
  QueryStringService,
  SeoService
} from '@cms-app/shared';

import { Color } from '../../models/theme.model';

import { CopierService } from '../../providers/copier.service';
import { ThemeService } from '../../providers/theme.service';

import { CouresGridComponent } from '../../components/courses-grid/courses-grid.component';

@Component({
  selector: 'cms-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent implements OnInit {
  @Input() family: string;
  @Input() prefilter: string;
  @Input() title: string;
  @Input() color: Color = 'red';
  @Input('min-hours') minHours: number;
  @Input('max-hours') maxHours: number;
  @Input() register: string;

  @ViewChild(CouresGridComponent) grid: CouresGridComponent;

  defaultTitle =
    'La mejor formación programada para trabajadores - Cursos gratuitos para trabajadores.';
  searchForm: FormGroup;
  query: string;
  families: string[] = [];

  constructor(
    @Inject(CMS_ENV) public env: CMSEnv,
    public theme: ThemeService,
    private queryService: QueryStringService,
    private copierService: CopierService,
    private fb: FormBuilder,
    private seo: SeoService
  ) {}

  ngOnInit() {
    this.seo.setPage({
      title: this.title || this.defaultTitle
    });
    this.theme.schema = this.color;
    const params: any = this.queryService.parse(window.location.search);

    this.searchForm = this.fb.group({
      query: [params.q ? decodeURIComponent(params.q) : '']
    });
    if (params && params.q && params.q.length > 0) {
      this.doSearch();
    }
  }

  doSearch() {
    const q = this.searchForm.get('query').value;
    this.query = q ? q.trim() : '';
  }

  doCopy() {
    const search = encodeURIComponent(this.query);
    const content = `${window.location.origin}${
      window.location.pathname
    }?q=${search}`;

    this.copierService.copyText(content);
  }

  exportExcel() {
    if (this.grid) {
      this.grid.excel();
    }
  }

  onFamilyFilter(params: { name: string; families: string[] }) {
    if (!this.title) {
      this.seo.title =
        params && params.name ? `${params.name} - ${this.title}` : this.title;
    }

    this.searchForm.get('query').setValue('');
    this.query = '';
    this.families = params && params.families ? params.families : [];
  }

  onViewAll() {
    this.family = undefined;
  }
}
