import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../interfaces/user.interface';
import { UserService } from '../../services/users.service';


@Component({
  selector: 'cms-user-detail',
  templateUrl: './userDetail.component.html',
})
export class UserDetailComponent implements OnInit {
  user: User = {
    user: '',
    password: ''
  };
  pass:string = '';
  passCheck: string = '';
  edit: boolean = false;
  warning: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.params;
    if (params) {
      this.userService.getUserById(params.id).subscribe(
        (res) => {
          this.user = res;
          this.edit = true;
        },
        (err) => console.log(err)
      );
    }
  }

  submitUser() {
    if(this.checkPasswords()){
    this.userService.createUser(this.user).subscribe(
      (res) => {
        this.router.navigate(['/admin/users']);
      },
      (err) => console.log(err)
    );
    }else{
      this.warning = "Las contraseñas no coinciden";
    }
  }

  updateUser() {
    if(this.checkPasswords()){
    this.userService
      .updateUser(this.user._id, this.user)
      .subscribe(
        (res) => {
          this.router.navigate(['/admin/users']);
        },
        (err) => console.log(err)
      );
    }else{
      this.warning = "Las contraseñas no coinciden";
    }
  }

  checkPasswords(): boolean{
     if(this.pass === this.passCheck){
      this.user.password = this.pass;
      return true;
    }else{
      return false;
    }
  }
}
