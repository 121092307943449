import * as i0 from "@angular/core";
var QueryStringService = /** @class */ (function () {
    function QueryStringService() {
    }
    QueryStringService.prototype.parse = function (queryString) {
        var query = {};
        var pairs = (queryString[0] === '?'
            ? queryString.substr(1)
            : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    };
    QueryStringService.ngInjectableDef = i0.defineInjectable({ factory: function QueryStringService_Factory() { return new QueryStringService(); }, token: QueryStringService, providedIn: "root" });
    return QueryStringService;
}());
export { QueryStringService };
