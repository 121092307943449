import { Injectable } from '@angular/core';
import {
  CoursePlatformParams,
  CoursePlatform
} from '../models/platform.course';

@Injectable({
  providedIn: 'root'
})
export class CoursePlatformsService {
  definition(platform: CoursePlatform) {
    switch (platform) {
      case 'galicia':
        return this.mapToZoho({
          email: 'formacion@galicia.info',
          logo:
            'https://www.agrupaciongalicia.com/uploads/1/4/5/6/14568052/published/1465557536.png',
          title: 'Agrupación Galicia',
          phone: '693271302',
          code: 4,
          origin: 'https://www.agrupaciongalicia.com',
          path: 'https://www.agrupaciongalicia.com/inscribirse.html'
        });
      case 'hosteleria':
        return this.mapToZoho({
          email: 'info@escueladehosteleriadevigo.com',
          logo:
            'https://www.escueladehosteleriadevigo.com/uploads/1/8/4/8/18489470/published/2923151.png',
          title: 'Escuela de Hosteleria de Vigo',
          phone: '693271302',
          code: 3,
          origin: 'https://www.escueladehosteleriadevigo.com',
          path: 'https://www.escueladehosteleriadevigo.com/inscribirse.html'
        });
      case 'tablet':
        return this.mapToZoho({
          email: 'info@cursostablet.com',
          logo:
            'https://www.cursostablet.com/uploads/1/8/4/0/18409439/3220113.png',
          title: 'Cursos Tablet',
          phone: '693271302',
          code: 1,
          origin: 'https://www.cursostablet.com',
          path: 'https://www.cursostablet.com/inscribirse.html'
        });
      case 'online':
      default:
        return this.mapToZoho({
          email: 'info@cursosonlinebonificados.es',
          logo:
            'https://www.cursosonlinebonificados.es/uploads/1/8/4/0/18409439/2784575.png',
          title: 'Cursos Online Bonificados',
          phone: '693271302',
          code: 2,
          origin: 'https://www.cursosonlinebonificados.es',
          path: 'https://www.cursosonlinebonificados.es/inscribirse.html'
        });
    }
  }

  guess(): CoursePlatform {
    const origin = window.location.origin;
    switch (origin) {
      case 'https://www.agrupaciongalicia.com':
        return 'galicia';
      case 'https://www.escueladehosteleriadevigo.com':
        return 'hosteleria';
      case 'https://www.cursostablet.com':
        return 'tablet';
      case 'https://www.cursosonlinebonificados.es':
      default:
        return 'online';
    }
  }

  privacy(platform: CoursePlatform): string {
    switch (platform) {
      case 'galicia':
        return 'https://www.agrupaciongalicia.com/politica-de-privacidad.html';
      case 'hosteleria':
        return 'https://www.escueladehosteleriadevigo.com/politica-de-privacidad.html';
      case 'tablet':
        return 'https://www.cursostablet.com/politica-de-privacidad.html';
      case 'online':
      default:
        return 'https://www.cursosonlinebonificados.es/politica-de-privacidad.html';
    }
  }

  mapToZoho(platform: CoursePlatformParams) {
    return {
      web_email: platform.email,
      web_logo: platform.logo,
      web_info: platform.title,
      web_phone: platform.phone,
      plataform: platform.code,
      web_origen: platform.origin,
      web_posicion: platform.path
    };
  }
}
