/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
import * as i2 from "ngx-bootstrap/component-loader";
import * as i3 from "@angular/forms";
import * as i4 from "ngx-mistakes";
import * as i5 from "./tag-modal.component";
import * as i6 from "../../../../shared/models/modal-options.token";
import * as i7 from "../../../../courses/providers/tags.service";
var styles_TagModalComponent = [];
var RenderType_TagModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TagModalComponent, data: {} });
export { RenderType_TagModalComponent as RenderType_TagModalComponent };
export function View_TagModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { childModal: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 35, "div", [["aria-hidden", "true"], ["aria-labelledby", "mySmallModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, [[1, 4], ["childModal", 4]], 0, i1.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i2.ComponentLoaderFactory], { config: [0, "config"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 33, "div", [["class", "modal-dialog modal-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 32, "div", [["class", "modal-content"], ["style", "margin-top: 150px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(11, 0, null, null, 25, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 13).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i3.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(13, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i0.ɵdid(15, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵeld(16, 0, null, null, 20, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 14, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nombre"])), (_l()(), i0.ɵeld(22, 0, null, null, 5, "input", [["class", "form-control"], ["formControlName", "name"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 23).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 23)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 23)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(25, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i0.ɵdid(27, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(28, 0, null, null, 4, "div", [["ngxErrors", "name"], ["style", "color: red"]], null, null, null, null, null)), i0.ɵdid(29, 4866048, null, 0, i4.NgxMistakesDirective, [i3.FormGroupDirective], { controlName: [0, "controlName"] }, null), (_l()(), i0.ɵeld(30, 0, null, null, 2, "div", [["ngxError", "required"], ["when", "touched"]], [[8, "hidden", 0]], null, null, null, null)), i0.ɵdid(31, 475136, null, 0, i4.NgxMistakeDirective, [i4.NgxMistakesDirective], { ngxError: [0, "ngxError"], when: [1, "when"] }, null), (_l()(), i0.ɵted(-1, null, [" Este campo es obligatorio "])), (_l()(), i0.ɵeld(33, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(34, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save($event, _co.tagForm) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" GUARDAR "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalOptions; _ck(_v, 2, 0, currVal_0); var currVal_9 = _co.tagForm; _ck(_v, 13, 0, currVal_9); var currVal_17 = "name"; _ck(_v, 25, 0, currVal_17); var currVal_18 = "name"; _ck(_v, 29, 0, currVal_18); var currVal_20 = "required"; var currVal_21 = "touched"; _ck(_v, 31, 0, currVal_20, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 15).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 15).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 15).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 15).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 15).ngClassValid; var currVal_7 = i0.ɵnov(_v, 15).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 15).ngClassPending; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = i0.ɵnov(_v, 27).ngClassUntouched; var currVal_11 = i0.ɵnov(_v, 27).ngClassTouched; var currVal_12 = i0.ɵnov(_v, 27).ngClassPristine; var currVal_13 = i0.ɵnov(_v, 27).ngClassDirty; var currVal_14 = i0.ɵnov(_v, 27).ngClassValid; var currVal_15 = i0.ɵnov(_v, 27).ngClassInvalid; var currVal_16 = i0.ɵnov(_v, 27).ngClassPending; _ck(_v, 22, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_19 = i0.ɵnov(_v, 31).hidden; _ck(_v, 30, 0, currVal_19); }); }
export function View_TagModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-tag-modal", [], null, null, null, View_TagModalComponent_0, RenderType_TagModalComponent)), i0.ɵdid(1, 114688, null, 0, i5.TagModalComponent, [i6.MODAL_OPTIONS, i3.FormBuilder, i7.TagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagModalComponentNgFactory = i0.ɵccf("cms-tag-modal", i5.TagModalComponent, View_TagModalComponent_Host_0, {}, { update: "update" }, []);
export { TagModalComponentNgFactory as TagModalComponentNgFactory };
