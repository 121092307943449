import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { CopyBlogsService, BlogPostV2 } from '@cms-app/copyblogs';

@Component({
  selector: 'cms-admin-copyblogs-article-detail',
  templateUrl: './article-detail.component.html'
})
export class AdminCopyblogsDetailComponent implements OnInit {
  dataFromArticle: any;
  // TODO:
  constructor(private router: Router) {
    this.dataFromArticle = history;
    console.log(this.dataFromArticle);
  }

  ngOnInit() {}
}
