import { Injectable } from '@angular/core';

import { Color } from '../models/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private theme: Color = 'red';

  set schema(theme: Color) {
    this.theme = theme;
  }

  get schema(): Color {
    return this.theme;
  }

  get color() {
    switch (this.theme) {
      case 'blue':
        return '#309fe0';
      case 'darkblue':
        return '#0c27ad';
      case 'green':
        return '#2dad37';
      case 'orange':
        return '#ff6332';
      case 'red':
      default:
        return '#c03';
    }
  }

  get translucent() {
    switch (this.theme) {
      case 'blue':
        return '#309fe0a1';
      case 'darkblue':
        return '#0c27ada1';
      case 'green':
        return '#2dad37a1';
      case 'orange':
        return '#ff6332a1';
      case 'red':
      default:
        return '#cc0033a1';
    }
  }
}
