import { Component, OnInit } from '@angular/core';
import { MessagingService } from '@cms-app/admin/shared';
import { CoursesService } from '@cms-app/courses';
import { ScrapperService } from '../../services/scrapper.service';

@Component({
  selector: 'app-scrapper-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  date: Date;
  sources: String[];
  sourceList: String[];
  dropdownSettings = {};

  constructor(
    private courseService: CoursesService,
    private scrapperService: ScrapperService,
    private messaging: MessagingService
  ) {}

  ngOnInit(): void {
    this.courseService
      .getSources()
      .subscribe((list) => (this.sourceList = list));
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };
  }

  backup() {
    let response: any;
    this.scrapperService
      .backup(this.sources)
      .subscribe((res) => (response = res));
    this.messaging.success(response, 'CMS-ADMIN');
  }

  restore() {
    let response: any;
    this.scrapperService
      .restore(this.sources)
      .subscribe((res) => (response = res));
    this.messaging.success(response, 'CMS-ADMIN');
  }

  launch() {
    if (!this.date) {
      this.messaging.error('Debe introducir una fecha', 'CMS-ADMIN');
    }
    if (!this.sources || this.sources.length < 1) {
      this.messaging.error(
        'Debe introducir por lo menos un proveedor',
        'CMS-ADMIN'
      );
    }
    if (this.date && this.sources && this.sources.length > 0) {
      let response: any;
      const millis = this.date.getTime();
      this.scrapperService
        .launch(millis, this.sources)
        .subscribe((res) => (response = res));
      this.messaging.success(response, 'CMS-ADMIN');
    }
  }

  onItemSelect(item: any) {}
  onSelectAll(items: any) {}
}
