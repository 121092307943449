import { Component, Inject, Input, OnInit } from "@angular/core";
import { RssNew } from "@cms-app/rss/models/rss-new.model";
import { RssQuery } from "@cms-app/rss/models/rss-query.model";
import { RssNewService } from "@cms-app/rss/providers/rss-new.service";
import { CMSEnv, CMS_ENV } from "@cms-app/shared";

@Component({
  selector: 'cms-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css'],
})
export class NewsListComponent implements OnInit{

  @Input() columns: number= 2;
  @Input() region: string;
  @Input() location: string;
  @Input() postlink: string;

  list: RssNew[]=[];

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv, private rssNewService: RssNewService){}

  ngOnInit(): void {
    let query: RssQuery;
  if(this.region){
    query={
      type: 'region',
      query: this.region
  }
}
  if(this.location){
    query={
      type: 'location',
      query: this.location,
    }
  }

  this.rssNewService.find({}, query).subscribe((data) => {
      this.rssNewService.find({per_page: data.count}, query).subscribe((dataT) => {
        let listtmp = [];
        for(let item of dataT.list){
          if(item.active){
            listtmp.push(item);
          }
      }
      listtmp.sort((a,b) => {
        const aDate = new Date(a.isoDate);
        const bDate = new Date(b.isoDate);
        if ( aDate < bDate ){
          return 1;
        }
        if ( aDate > bDate ){
          return -1;
        }
        return 0;
      });
      let count = 0;
      for(let it of listtmp){
        if(count < this.columns){
          this.list.push(it);
          count++;
        }
        if(count == this.columns){
          break;
        }
      }
      });
    });
  }

  getClassName() {
     if (this.columns >= 3) {
      return `news summary col-3`;
    } else if (this.columns === 2) {
      return `news summary col-2`;
    } else {
      return `news summary col-1`;
    }
  }
}
