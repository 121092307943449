import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Course } from '../models/course.model';
import { CoursesService } from './courses.service';

@Injectable({
  providedIn: 'root'
})
export class CourseResolver implements Resolve<Course> {
  constructor(private courses: CoursesService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Course> {
    return route.params.id === 'new'
      ? of(this.courses.empty())
      : this.courses.findOne(route.params.id);
  }
}
