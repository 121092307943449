<cms-admin>
  <cms-section-title (query)="onQuery($event)"
    >Articulos en Caché</cms-section-title
  >
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
    [limit]="per_page"
    [externalPaging]="true"
    (page)="onPage($event)"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Titular
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.header }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Web
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.site }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fuente
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.origin }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right actions">
          <button
            class="btn btn-primary"
            (click)="update(row)"
            tooltip="Ver / Editar"
            placement="top"
          >
            E
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
