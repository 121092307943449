import { Injectable } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Injectable()
export class ImagesService {
  constructor(private imgMax: Ng2ImgMaxService) {}

  resizeImage(image: File, maxWidth: number, maxHeight: number) {
    return this.imgMax.resizeImage(image, maxWidth, maxHeight);
  }

  getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  getImageSize(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      this.getBase64(file)
        .then(result => {
          const img = new Image();
          img.onload = () =>
            resolve({
              width: img.width,
              height: img.height
            });
          img.onerror = err => reject(err);
          img.src = result;
        })
        .catch(err => reject(err));
    });
  }
}
