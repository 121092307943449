import { HttpClient } from '@angular/common/http';
import { CMSEnv } from '@cms-app/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var RssService = /** @class */ (function () {
    function RssService(env, http) {
        this.env = env;
        this.http = http;
    }
    RssService.prototype.getRss = function (source, blogGalicia) {
        return this.http.post(this.env.scrapperUrl + "/rss", { source: source, blogGalicia: blogGalicia });
    };
    RssService.prototype.getDom = function (url, selector) {
        return this.http.post(this.env.scrapperUrl + "/rss/getDom", { url: url, selector: selector });
    };
    RssService.prototype.getBlogNews = function (src, events) {
        return this.http.post(this.env.baseUrl + "/blogspot/getNews", { source: src, events: events });
    };
    RssService.prototype.getBlogHtml = function (link, columns, location) {
        return this.http.post(this.env.baseUrl + "/blogspot/getHtml", { link: link, columns: columns, location: location });
    };
    RssService.ngInjectableDef = i0.defineInjectable({ factory: function RssService_Factory() { return new RssService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: RssService, providedIn: "root" });
    return RssService;
}());
export { RssService };
