import { Component } from '@angular/core';

@Component({
  selector: 'cms-admin',
  template: `
    <cms-navbar></cms-navbar>
    <div style="padding: 20px">
      <ng-content></ng-content>
    </div>
  `
})
export class AdminComponent {}
