/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filepicker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-file-upload";
import * as i3 from "@angular/common";
import * as i4 from "./filepicker.component";
import * as i5 from "../../services/files.service";
var styles_FilePickerComponent = [i0.styles];
var RenderType_FilePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilePickerComponent, data: {} });
export { RenderType_FilePickerComponent as RenderType_FilePickerComponent };
function View_FilePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-success btn-s"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploader.uploadAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-upload"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Upload all\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.uploader || !_co.uploader.getNotUploadedItems().length); _ck(_v, 0, 0, currVal_0); }); }
function View_FilePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Preview"], ["height", "100px"], ["width", "100px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display; _ck(_v, 0, 0, currVal_0); }); }
export function View_FilePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Buscar "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "input", [["ng2FileSelect", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.FileSelectDirective, [i1.ElementRef], { uploader: [0, "uploader"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePickerComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePickerComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploader; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.manual; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.preview && _co.display); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_FilePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pv-filepicker", [], null, null, null, View_FilePickerComponent_0, RenderType_FilePickerComponent)), i1.ɵdid(1, 114688, null, 0, i4.FilePickerComponent, [i5.FilesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilePickerComponentNgFactory = i1.ɵccf("pv-filepicker", i4.FilePickerComponent, View_FilePickerComponent_Host_0, { width: "width", height: "height", extent: "extent", transformations: "transformations", preview: "preview", manual: "manual", required: "required", url: "url" }, { uploaded: "uploaded" }, []);
export { FilePickerComponentNgFactory as FilePickerComponentNgFactory };
