import { Component } from '@angular/core';

@Component({
  selector: 'cms-course-entrust-miniloader',
  template: `
    <div class="lds-circle"><div></div></div>
  `,
  styles: [
    `
      .lds-circle {
        display: inline-block;
        transform: translateZ(1px);
      }
      .lds-circle > div {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 4px;
        border-radius: 50%;
        background: #888;
        animation: lds-circle 4.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      @keyframes lds-circle {
        0%,
        100% {
          animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
        }
        0% {
          transform: rotateY(0deg);
        }
        50% {
          transform: rotateY(1800deg);
          animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
        }
        100% {
          transform: rotateY(3600deg);
        }
      }
    `
  ]
})
export class CourseEntrustMiniLoader {}
