import * as i0 from "@angular/core";
var ThemeService = /** @class */ (function () {
    function ThemeService() {
        this.theme = 'red';
    }
    Object.defineProperty(ThemeService.prototype, "schema", {
        get: function () {
            return this.theme;
        },
        set: function (theme) {
            this.theme = theme;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "color", {
        get: function () {
            switch (this.theme) {
                case 'blue':
                    return '#309fe0';
                case 'darkblue':
                    return '#0c27ad';
                case 'green':
                    return '#2dad37';
                case 'orange':
                    return '#ff6332';
                case 'red':
                default:
                    return '#c03';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThemeService.prototype, "translucent", {
        get: function () {
            switch (this.theme) {
                case 'blue':
                    return '#309fe0a1';
                case 'darkblue':
                    return '#0c27ada1';
                case 'green':
                    return '#2dad37a1';
                case 'orange':
                    return '#ff6332a1';
                case 'red':
                default:
                    return '#cc0033a1';
            }
        },
        enumerable: true,
        configurable: true
    });
    ThemeService.ngInjectableDef = i0.defineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
