import { OnInit } from "@angular/core";
import { RssNewService } from "@cms-app/rss/providers/rss-new.service";
var RssNewsComponent = /** @class */ (function () {
    function RssNewsComponent(rss) {
        this.rss = rss;
        this.page = 1;
        this.loading = false;
        this.rows = [];
        this.count = 0;
        this.query = '';
    }
    RssNewsComponent.prototype.ngOnInit = function () {
        this.find();
    };
    RssNewsComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    RssNewsComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    RssNewsComponent.prototype.find = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        var rssQ = {
            type: 'title',
            query: ''
        };
        if (this.query) {
            rssQ.query = this.query;
        }
        this.loading = true;
        this.rss.find(options, rssQ).subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    RssNewsComponent.prototype.delete = function (row) {
        var _this = this;
        this.rss.delete(row._id).subscribe(function (res) {
            _this.rows = _this.rows.filter(function (r) {
                if (r._id === row._id) {
                    return false;
                }
                else {
                    return true;
                }
            });
        });
    };
    return RssNewsComponent;
}());
export { RssNewsComponent };
