import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CMSEnv } from '../models/environment';
import * as i0 from "@angular/core";
import * as i1 from "../models/environment";
import * as i2 from "@angular/common/http";
var ContactService = /** @class */ (function () {
    function ContactService(env, http) {
        this.env = env;
        this.http = http;
    }
    ContactService.prototype.send = function (data) {
        var site = this.getHost();
        var to = "info@" + site;
        var bod = tslib_1.__assign({}, data, { site: site,
            to: to });
        console.log(bod);
        return this.http.post(this.env.baseUrl + "/send-email", tslib_1.__assign({}, data, { site: site,
            to: to }));
    };
    ContactService.prototype.getHost = function () {
        var host = window.location.host.split('.');
        return host.slice(host.length - 2).join('.');
    };
    ContactService.ngInjectableDef = i0.defineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };
