import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { BlogPostV2 } from '../models/blog-post.model';

@Injectable({
  providedIn: 'root'
})
export class CopyBlogsService {
  static DEFAULT_PER_PAGE = 10;

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  fetch(
    query?: any,
    options: any = { page: 1, per_page: 10 }
  ): Observable<{
    list: BlogPostV2[];
    count: number;
  }> {
    let params = new HttpParams();

    if (query && query.q && query.q.length > 0) {
      params = params.set('q', query.q);
    }

    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;

    params = params.set('page', options.page).set('per_page', options.per_page);

    return this.http
      .get<BlogPostV2[]>(`${this.env.copyblogUrl}/admin`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  patch(id: string, update: any) {
    let params = new HttpParams();
    params = params.set('post', id);

    return this.http.patch<BlogPostV2[]>(
      `${this.env.copyblogUrl}/admin`,
      update,
      {
        params
      }
    );
  }
}
