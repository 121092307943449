import { Component } from '@angular/core';

import { Alert, alertType, AlertOptions } from './messages.interface';

@Component({
  selector: 'cms-messages',
  template: `
    <div class="row" *ngIf="alerts.length > 0">
      <div class="col-md-12" *ngFor="let alert of alerts">
        <alert
          *ngIf="!alert.options.timeout"
          [type]="alert.type"
          [dismissible]="alert.options.dismissible || false"
        >
          <div [innerHTML]="alert.message"></div>
        </alert>
        <alert
          *ngIf="alert.options.timeout"
          [type]="alert.type"
          [dismissOnTimeout]="alert.options.timeout"
        >
          <div [innerHTML]="alert.message"></div>
        </alert>
      </div>
    </div>
  `
})
export class MessagesComponent {
  public alerts: Alert[] = [];

  public addMessage(
    type: alertType,
    message: string,
    options: AlertOptions = {}
  ) {
    this.alerts.push({ type, message, options });
  }

  public clear() {
    this.alerts = [];
  }

  public addError(message: string) {
    this.addMessage('danger', message, { dismissible: true });
  }
}
