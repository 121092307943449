import { HttpClient, HttpParams } from '@angular/common/http';
import { CMSEnv } from '@cms-app/shared';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "@angular/common/http";
var RssSrcService = /** @class */ (function () {
    function RssSrcService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/rsssrc';
    }
    RssSrcService.prototype.find = function (options, query) {
        if (options === void 0) { options = { page: 1, per_page: 10 }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        if (query) {
            params = params.set('name>$like', query);
        }
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    RssSrcService.prototype.getRssSrcById = function (id) {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/" + id);
    };
    RssSrcService.prototype.add = function (payload) {
        return this.http.post("" + this.env.baseUrl + this.endpoint, payload);
    };
    RssSrcService.prototype.update = function (rssSrc, payload) {
        return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + rssSrc, payload);
    };
    RssSrcService.prototype.delete = function (id) {
        return this.http.delete("" + this.env.baseUrl + this.endpoint + "/" + id).subscribe(function () { return console.log('borrado'); });
    };
    RssSrcService.ngInjectableDef = i0.defineInjectable({ factory: function RssSrcService_Factory() { return new RssSrcService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: RssSrcService, providedIn: "root" });
    return RssSrcService;
}());
export { RssSrcService };
