<cms-admin>
  <div class="row">
    <div class="col-sm-4">
      <div class="card card-body">
        <form (submit)="edit ? updateUser() : submitUser()">
          <div class="form-group">
            <label for="inputNAme">Nombre</label>
            <input
              type="text"
              name="name"
              id="inputName"
              [(ngModel)]="user.user"
              class="form-control"
              autofocus
            />
          </div>
          <div class="form-group">
            <label for="inputPassword">Contraseña</label>
            <input
              type="password"
              name="password"
              id="inputPassword"
              [(ngModel)]="pass"
              class="form-control"
              autofocus
            />
            <label for="checkPassword">Repita Contraseña</label>
            <input
              type="password"
              name="passwordCheck"
              id="checkPassword"
              [(ngModel)]="passCheck"
              class="form-control"
              autofocus
            />
            </div>
          <button class="btn btn-primary btn-block">Guardar</button>
        </form>
        {{warning}}
      </div>
    </div>
  </div>
</cms-admin>
