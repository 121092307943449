import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CMSEnv } from '@cms-app/shared';
import { CoursePlatformsService } from './platforms.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/environment";
import * as i2 from "./platforms.service";
import * as i3 from "@angular/common/http";
var EntrustService = /** @class */ (function () {
    function EntrustService(env, platforms, http) {
        this.env = env;
        this.platforms = platforms;
        this.http = http;
        this.accountNumberRE = /ES\d{2}[\s-]{1}\d{4}[\s-]\d{4}[\s-]\d{4}[\s-]\d{4}[\s-]\d{4}|ES\d{22}|ES\d{2}[\s-]\d{4}[\s-]\d{4}[\s-]\d{2}[\s-]\d{10}/;
        this.endpoint = '/entrusts';
    }
    EntrustService.prototype.find = function (options) {
        if (options === void 0) { options = { page: 1, per_page: 10, top: true }; }
        options.page = options.hasOwnProperty('page') ? options.page : 1;
        options.per_page = options.hasOwnProperty('per_page')
            ? options.per_page
            : 10;
        var params = Object.keys(options).reduce(function (acc, key) {
            return acc.set(key, String(options[key]));
        }, new HttpParams());
        return this.http
            .get("" + this.env.baseUrl + this.endpoint, {
            params: params,
            observe: 'response'
        })
            .pipe(map(function (response) { return ({
            list: response.body,
            count: parseInt(response.headers.get('X-Total-Count'), 10)
        }); }));
    };
    EntrustService.prototype.findOne = function (entrustId) {
        return this.http.get(this.env.baseUrl + "/entrust/ok/" + entrustId);
    };
    EntrustService.prototype.checkCompanyVat = function (vat) {
        var params = new HttpParams().set('vat', vat);
        return this.http
            .get(this.env.baseUrl + "/entrust/company", {
            params: params
        })
            .pipe(map(function (company) {
            if (company) {
                return {
                    company: {
                        businessName: company.adh_razon_social,
                        sector: company.adh_actividad,
                        phone: company.adh_telefono,
                        state: company.adh_provincia,
                        zipCode: company.adh_codigo_postal,
                        contactPerson: company.Persona_de_Contacto,
                        employees: company.adh_plantilla_media,
                        year: company.adh_ano_creacion,
                        city: company.adh_poblacion,
                        agreement: company.adh_convenio_col,
                        ssAccount: company.adh_cuenta_cotizacion,
                        email: company.Correo_electronico,
                        address: company.adh_domicilio_social,
                        since: company.adh_ano_creacion
                    },
                    representative: {
                        name: company.adh_rep_nombre1,
                        vatIdentifier: company.adh_DNI_representante_legal
                    }
                };
            }
            return company;
        }));
    };
    EntrustService.prototype.checkStudentVat = function (vat) {
        var _this = this;
        var params = new HttpParams().set('vat', vat);
        return this.http
            .get(this.env.baseUrl + "/entrust/student", {
            params: params
        })
            .pipe(map(function (student) {
            if (student) {
                return {
                    email: student.fEmailAlu,
                    phone: student.accion_formativa_movil,
                    name: student.fNombreAlumno,
                    subname: student.fPrimerApe,
                    subname2: student.fSegundoApe,
                    birthdate: _this.dateFromString(student.fFechaNacAlu, '/'),
                    ssNumber: student.fNumSSocialAlu,
                    studies: student.fNivelEstudios,
                    cotization: student.fGrupoCotiz,
                    sex: student.fSexoAlumno
                };
            }
            return student;
        }));
    };
    EntrustService.prototype.pushOnlyEntrust = function (entrust, platform, agent) {
        var payload = this.getEntrustFormData(entrust, platform, agent);
        if (this.env.production) {
            return this.http.post(this.env.baseUrl + "/entrust/only", payload);
        }
        else {
            console.log(payload);
            return of({ _id: '' });
        }
    };
    EntrustService.prototype.pushYesEntrust = function (entrust, platform, agent) {
        var _this = this;
        var payload = {
            students: entrust.students.map(function (student) { return (tslib_1.__assign({}, _this.platforms.definition(platform), { fCIFNIF: entrust.company.vatIdentifier, fRazonSocial: entrust.company.businessName, fEmail: entrust.company.email, fNIFAlu: student.vatIdentifier, fNumSSocialAlu: student.ssNumber, fFechaNacAlu: _this.dateToString(student.birthdate), fEmailAlu: student.email, accion_formativa_movil: String(student.phone || '').replace(/[^0-9]/g, '') }, _this.parseAccountNumber(entrust.company.accountNumber), { fNombreAlumno: student.name, fPrimerApe: student.subname, fSegundoApe: student.subname2, fNivelEstudios: student.studies, fGrupoCotiz: student.cotization, fCodCurso: '-', fDenomCurso: student.course, Observaciones: student.observations, frepresentante: entrust.representative.name, frepresentantecifnif: entrust.representative.vatIdentifier, Acepto_terminos: entrust.privacy ? 'true' : 'false', Confirmo_novedades: entrust.newsletter ? 'true' : 'false', Asesor: agent ? agent : '-', fdireccionempresa: entrust.company.address, fpoblacionempresa: entrust.company.city, fcodigopostalempresa: entrust.company.zipCode, fprovinciaempresa: entrust.company.state, Delegado_Sindical: entrust.company.delegate ? 'Si' : 'No', adh_plantilla_media: entrust.company.employees, accion_formativa_sexo: student.sex, fNacionalidad: student.nationality }, _this.parsePoll(student.poll))); })
        };
        if (this.env.production) {
            return this.http.post(this.env.baseUrl + "/entrust/yes", payload);
        }
        else {
            console.log(payload);
            return of([{ _id: '' }]);
        }
    };
    EntrustService.prototype.pushNoEntrust = function (entrust, platform) {
        var _this = this;
        var payload = {
            students: entrust.students.map(function (student) { return (tslib_1.__assign({}, _this.platforms.definition(platform), { fCIFNIF: entrust.company.vatIdentifier, fRazonSocial: entrust.company.businessName, fEmail: entrust.company.email, fNumSSocial: entrust.company.address, Observaciones_Empresa: entrust.company.observations, fDNIAlu: student.vatIdentifier, fEmailAlu: student.email, accion_formativa_movil: String(student.phone || '').replace(/[^0-9]/g, ''), fTelefonoEmpresa: String(entrust.company.phone || '').replace(/[^0-9]/g, ''), fNombreAlumno: student.name, fApellidos: student.subname + ' ' + student.subname2, accion_formativa_sexo: student.sex, fNombreCurso: student.course, Observaciones_alumno: student.observations, frepresentante: entrust.representative.name, frepresentantecifnif: entrust.representative.vatIdentifier, Acepto_terminos_privacidad: entrust.privacy ? 'true' : 'false', Confirmo_recibir_info: entrust.newsletter ? 'true' : 'false', fNacionalidad: student.nationality }, _this.parsePoll(student.poll))); })
        };
        if (this.env.production) {
            return this.http.post(this.env.baseUrl + "/entrust/no", payload);
        }
        else {
            console.log(entrust);
            console.log(payload);
            return of([{ _id: '' }]);
        }
    };
    EntrustService.prototype.parseAccountNumber = function (accountNumber) {
        if (!accountNumber || !this.accountNumberRE.test(accountNumber)) {
            return {};
        }
        var accountWithNoSymbols = String(accountNumber || '')
            .toLocaleUpperCase()
            .replace(/[^0-9ES]/g, '');
        return {
            fIBAN: accountWithNoSymbols.substr(0, 4),
            fEntidad: accountWithNoSymbols.substr(4, 4),
            fSucursal: accountWithNoSymbols.substr(8, 4),
            fDigito_Control: accountWithNoSymbols.substr(12, 2),
            fNumeroCuenta: accountWithNoSymbols.substr(14)
        };
    };
    EntrustService.prototype.parsePoll = function (poll) {
        if (poll) {
            return {
                titulos_poseidos: poll.title,
                graduacion: poll.graduation_year,
                primera_vez_universidad: poll.first_time,
                relacion_ucm: poll.ucm_link,
                situacion_laboral: poll.actual_job,
                tipo_trabajo: poll.job_type,
                ocupacion: poll.actual_occupation,
                sector_empresa: poll.sector,
                puesto: poll.job,
                motivacion: poll.motivation,
                motivacion_ucm: poll.ucm_motivation,
                motivacion_curso: poll.course_motivation
            };
        }
        else {
            return {};
        }
    };
    EntrustService.prototype.getEntrustFormData = function (entrust, platform, agent) {
        if (agent === void 0) { agent = null; }
        return tslib_1.__assign({}, this.platforms.definition(platform), { comercial: agent ? agent : '-', adh_domicilio_social: entrust.company.address, adh_razon_social: entrust.company.businessName, adh_poblacion: entrust.company.city, Persona_de_Contacto: entrust.company.contactPerson, Correo_electronico: entrust.company.email, adh_plantilla_media: entrust.company.employees, adh_telefono: String(entrust.company.phone || '').replace(/[^0-9]/g, ''), adh_movil: String(entrust.company.phone2 || '').replace(/[^0-9]/g, ''), adh_convenio_col: entrust.company.agreement, adh_cuenta_cotizacion: entrust.company.ssAccount, adh_actividad: entrust.company.sector, adh_ano_creacion: isNaN(entrust.company.since)
                ? new Date().getFullYear()
                : entrust.company.since, adh_provincia: entrust.company.state, adh_CIF: entrust.company.vatIdentifier, adhesion_ano: this.checkYear(entrust.company.year), adh_codigo_postal: entrust.company.zipCode, adh_rep_nombre1: entrust.representative.name, adh_DNI_representante_legal: entrust.representative.vatIdentifier, Acepto_terminos_privacidad: entrust.privacy ? 'true' : 'false', Confirmo_recibir_info: entrust.newsletter ? 'true' : 'false' });
    };
    EntrustService.prototype.checkYear = function (input) {
        var today = new Date();
        var year = today.getFullYear();
        if (!input || input < year) {
            return year;
        }
        if (input > year + 1) {
            return year + 1;
        }
        return input;
    };
    EntrustService.prototype.dateToString = function (date, divider) {
        if (divider === void 0) { divider = '/'; }
        var day = date.getDate();
        var strDate = day < 10 ? "0" + day : "" + day;
        var month = date.getMonth() + 1;
        var strMonth = month < 10 ? "0" + month : "" + month;
        var year = date.getFullYear();
        return "" + strDate + divider + strMonth + divider + year;
    };
    EntrustService.prototype.dateFromString = function (date, divider) {
        if (divider === void 0) { divider = '/'; }
        var dividerLength = divider ? divider.length : 0;
        var day = parseInt(date.substr(0, 2), 10);
        var month = parseInt(date.substr(2 + dividerLength, 2), 10) - 1;
        var year = parseInt(date.substr(4 + 2 * dividerLength, 4), 10);
        return new Date(year, month, day);
    };
    EntrustService.ngInjectableDef = i0.defineInjectable({ factory: function EntrustService_Factory() { return new EntrustService(i0.inject(i1.CMS_ENV), i0.inject(i2.CoursePlatformsService), i0.inject(i3.HttpClient)); }, token: EntrustService, providedIn: "root" });
    return EntrustService;
}());
export { EntrustService };
