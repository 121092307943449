import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { RssService } from "@cms-app/rss/providers/rss.service";

@Component({
  selector: 'cms-blog-render',
  templateUrl: './blognew-render.component.html',
  styleUrls: ['./blognew-render.component.css']
})
export class BlogNewRenderComponent implements OnInit{
  @Input() params: string = 'aHR0cHM6Ly9ub3RpY2lhc2RlZ2FsaWNpYWdlb2Rlc3Rpbm9zLmJsb2dzcG90LmNvbS8yMDIyLzA0L2RpZ2l0YWxpemFjaW9uLXktZ2FtaWZpY2FjaW9uLWxhLW51ZXZhLmh0bWw%3D';
  @Input() columns = 5;
  @Input() location: string = "Formacion";
  link;
  new_body;
  title = '';
  date;
  latestNews = [];

  constructor(private rss: RssService, private sanitizer:DomSanitizer){}
  ngOnInit(): void {
    this.link = atob(decodeURIComponent(this.params));
    if(this.link){
      this.rss.getBlogHtml(this.link, this.columns, this.location).subscribe(r => {
        if(r.html){
          this.new_body = this.replaceEscaped(r.html);
        }
        if(r.title){
          let titlesplit = r.title.split('*');
          if(titlesplit[0]){
            this.title = titlesplit[0];
          }
        }
        if(r.date){
          this.date = r.date;
        }
        if(r.latestNews && r.latestNews instanceof Array){
          for(let i = 0; i < (r.latestNews.length - 1); i++){
            this.latestNews.push(r.latestNews[i]);
          }
        }
      });
    }
  }

  replaceEscaped(string){
    let s =string.replace(/\\"/g, '"');
    return this.sanitizer.bypassSecurityTrustHtml(s);
  }

  getLink(newData){
    if(newData && newData.link){
        let encodedLink = encodeURIComponent(btoa(newData.link));
        return window.location.pathname+'?params='+encodedLink+'&columns='+this.columns+'&location='+this.location;
    }
  }


}
