import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PVFamily, PVFamiliesService } from '@cms-app/courses';

@Component({
  selector: 'cms-admin-pv-families',
  templateUrl: './pv-families.component.html'
})
export class AdminPVFamiliesComponent implements OnInit {
  rows: PVFamily[] = [];
  count = 0;
  page = 1;
  query = '';
  loading = false;

  constructor(
    private router: Router,
    private familiesService: PVFamiliesService
  ) {}

  ngOnInit() {
    this.find();
  }

  onAdd() {
    this.router.navigate(['admin', 'iefp', 'pv-families', '0']);
  }

  update(row: PVFamily) {
    this.router.navigate(['admin', 'iefp', 'pv-families', row._id]);
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }
    if (this.query) {
      options['name>$like'] = this.query;
    }

    this.loading = true;
    this.familiesService
      .find({
        ...options,
        expand: 'families.name',
        sort: 'order',
        per_page: 25
      })
      .subscribe(
        ({ list, count }) => {
          this.rows = list;
          this.count = count;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  getRowHeight() {
    return row => (!!row ? 25 + 22 * row.families.length : 50);
  }
}
