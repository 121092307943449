import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContactService, MessagesComponent } from '@cms-app/shared';

import { Course } from '../../models/course.model';

@Component({
  selector: 'cms-course-request-info',
  templateUrl: './course-request-info.component.html'
})
export class CourseRequestInfoComponent implements OnInit {
  @ViewChild(MessagesComponent) messages: MessagesComponent;
  @Input() course: Course;

  request: FormGroup;
  sending = false;

  constructor(private fb: FormBuilder, private contact: ContactService) {}

  ngOnInit() {
    this.request = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      comments: ['']
    });
  }

  send() {
    if (this.request.valid && !this.sending) {
      this.sending = true;
      this.contact
        .send({
          ...this.request.value,
          reference: this.course.referencePV || this.course.reference,
          course: this.course.namePV || this.course.name
        })
        .subscribe(
          () => {
            this.sending = false;
            this.request.setValue({
              name: '',
              email: '',
              phone: '',
              comments: ''
            });
            this.messages.addMessage(
              'success',
              'Se ha enviado tu consulta con éxito. En breve nos pondremos en contacto contigo',
              {
                dismissible: true
              }
            );
          },
          () => {
            this.sending = false;
            this.messages.addError(
              'No se ha podido enviar tu solicitud, por favor, intentalo de nuevo más tarde'
            );
          }
        );
    } else {
      let claves = Object.keys(this.request.controls);
      claves.forEach(element => {
        this.request.get(element).markAsTouched();
      });
    }
  }
}
