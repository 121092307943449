import { Component, OnInit, ViewChild } from '@angular/core';

import { Tag, TagsService } from '@cms-app/courses';

import { TagModalComponent } from '../../components/tag-modal/tag-modal.component';

@Component({
  selector: 'cms-admin-tags',
  templateUrl: './tags.component.html'
})
export class AdminTagsComponent implements OnInit {
  @ViewChild(TagModalComponent) tagModal: TagModalComponent;

  rows: Tag[];
  count = 0;
  page = 1;
  query = '';
  loading = false;

  constructor(private tagsService: TagsService) {}

  ngOnInit() {
    this.find();
  }

  add() {
    this.tagModal.add();
  }

  update(row: Tag) {
    this.tagModal.edit(row);
  }

  remove(row: Tag) {
    this.tagsService.remove(row._id).subscribe(() => this.find());
  }

  onUpdate() {
    this.find();
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }
    if (this.query) {
      options['name>$like'] = this.query;
    }

    this.loading = true;
    this.tagsService.find(options).subscribe(
      ({ list, count }) => {
        this.rows = list;
        this.count = count;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
