import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../interfaces/user.interface';
import { UserService } from '../../services/users.service';

@Component({
  selector: 'cms-admin-users',
  templateUrl: './users.component.html',
})
export class AdminUsersComponent implements OnInit{
  rows: User[] = [];
  count = 0;
  page = 1;
  query = '';
  loading = false;


  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(){
    this.userService.getUsers().subscribe(res => {
      this.rows = res;
    },
       err => console.log(err));
  }

  onAdd() {
    this.router.navigate(['admin', 'users', 'create']);
  }

  update(row: User) {
    this.router.navigate(['admin', 'users',  row._id]);
  }

  delete(row: User) {
    this.userService.deleteUser(row._id).subscribe(res => {
      this.getUsers();
    }, err => console.log(err));
  }
}
