import { HttpClient } from '@angular/common/http';
import { CMSEnv } from '@cms-app/shared/models/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/models/environment";
import * as i2 from "@angular/common/http";
var ProviderService = /** @class */ (function () {
    function ProviderService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/scrapper';
    }
    ProviderService.prototype.getProviders = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/providers");
    };
    ProviderService.prototype.getProviderById = function (id) {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/providers/" + id);
    };
    ProviderService.prototype.getProviderBySource = function (source) {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/providers/source?providerSource=" + source);
    };
    ProviderService.prototype.createProvider = function (provider) {
        return this.http.post("" + this.env.baseUrl + this.endpoint + "/providers", provider);
    };
    ProviderService.prototype.deleteProvider = function (id) {
        return this.http.delete("" + this.env.baseUrl + this.endpoint + "/providers/" + id);
    };
    ProviderService.prototype.updateProvider = function (id, provider) {
        return this.http.put("" + this.env.baseUrl + this.endpoint + "/providers/" + id, provider);
    };
    //comparator
    ProviderService.prototype.compareFamilies = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/comparator/compareFamilies").pipe(map(function (res) { return res; }));
    };
    ProviderService.prototype.compareCourses = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/comparator/compareCourses").pipe(map(function (res) { return res; }));
    };
    ProviderService.prototype.updateFamilies = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/comparator/updateFamilies").pipe(map(function (res) { return res; }));
    };
    ProviderService.prototype.updateCourses = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/comparator/updateCourses").pipe(map(function (res) { return res; }));
    };
    //Logger
    ProviderService.prototype.getLogs = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/logs");
    };
    ProviderService.prototype.getLogById = function (id) {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/logs/" + id);
    };
    ProviderService.ngInjectableDef = i0.defineInjectable({ factory: function ProviderService_Factory() { return new ProviderService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: ProviderService, providedIn: "root" });
    return ProviderService;
}());
export { ProviderService };
