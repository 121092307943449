import { Component, Input, OnInit } from '@angular/core';
import { CoursesService } from '@cms-app/courses';

import { Course } from '../../models/course.model';
import { Color } from '../../models/theme.model';

import { ThemeService } from '../../providers/theme.service';

@Component({
  selector: 'cms-course-cart',
  templateUrl: './course-cart.component.html',
  styleUrls: ['./course-cart.component.css']
})
export class CourseCartComponent implements OnInit {
  @Input() course: Course;
  @Input() color: Color = 'red';

  constructor(public theme: ThemeService, public courses: CoursesService) {}

  ngOnInit() {
    this.theme.schema = this.color;
  }
}
