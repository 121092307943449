import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeReference',
  pure: true
})
export class RemoveReferencePipe implements PipeTransform {
  transform(name: string): string {
    return name ? name.replace(/[A-Za-z]{4}[0-9]{3}[A-Za-z]{2}/g, '') : '';
  }
}
