import { HttpClient } from '@angular/common/http';
import { CMSEnv } from '@cms-app/shared/models/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/models/environment";
import * as i2 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(env, http) {
        this.env = env;
        this.http = http;
        this.endpoint = '/users';
    }
    UserService.prototype.getUsers = function () {
        return this.http.get("" + this.env.baseUrl + this.endpoint);
    };
    UserService.prototype.getUserById = function (id) {
        return this.http.get("" + this.env.baseUrl + this.endpoint + "/" + id);
    };
    UserService.prototype.createUser = function (user) {
        return this.http.post("" + this.env.baseUrl + this.endpoint, user);
    };
    UserService.prototype.deleteUser = function (id) {
        return this.http.delete("" + this.env.baseUrl + this.endpoint + "/" + id);
    };
    UserService.prototype.updateUser = function (id, user) {
        return this.http.patch("" + this.env.baseUrl + this.endpoint + "/" + id, user);
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.CMS_ENV), i0.inject(i2.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
