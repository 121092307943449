<div class="row cms-card">
  <div class="col-md-3 col-sm-6">
    <img
      class="cms-card-picture"
      [src]="course.img"
      [alt]="course.name"
      width="100%"
      *ngIf="course.img"
    />
  </div>
  <div class="col-md-9 col-sm-6">
    <h4>{{ course.namePV || course.name | removeReference }}</h4>
    <p>{{ course.shortDescriptionPV || course.shortDescription }}</p>

    <div class="row">
      <div class="col-xs-6">
        <div class="box">
          <p class="title">Modalidades:</p>
          <p class="content">
            <span *ngIf="course.duration"
              ><span class="cms-duration">{{ courses.getVariants(course).first_duration }}</span>
              <span class="cms-duration">{{ courses.getVariants(course).second_duration }}</span>
              <span class="cms-duration">{{ courses.getVariants(course).third_duration }}</span></span
            >
            <span *ngIf="!course.duration">A Consultar</span>
          </p>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="box">
          <p class="title">Precio:</p>
          <p class="content">A consultar</p>
        </div>
      </div>
    </div>
  </div>
</div>
