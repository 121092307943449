<cms-admin [formGroup]="familyForm">
  <cms-section-title [showAdd]="false" [showQuery]="false"
    >Familia - Galicia</cms-section-title
  >
  <div style="margin-top: 10px;">
    <label for="name">Nombre familia: </label>
    <input
      type="text"
      class="form-control"
      formControlName="name"
      placeholder="Nombre de la familia"
      required
    /><br />
  </div>
  <div>
    <button
      class="btn btn-info"
      tooltip="Guardar familia"
      (click)="saveFamily()"
    >
      Guardar
    </button>
  </div>
</cms-admin>
