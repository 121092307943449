import { EventEmitter, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { EntrustService } from '../../providers/entrust.service';
var CourseEntrustStudentComponent = /** @class */ (function () {
    function CourseEntrustStudentComponent(bsConfig, entrust) {
        this.bsConfig = bsConfig;
        this.entrust = entrust;
        this.pickers = new QueryList();
        this.preselectedCourse = false;
        this.ucm = false;
        this.student = new EventEmitter();
        this.next = new EventEmitter();
        this.loading = false;
    }
    Object.defineProperty(CourseEntrustStudentComponent.prototype, "bonus", {
        get: function () {
            var value = this.parent.get('bonus').value;
            return value === 'yes' || value === 'entrust';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CourseEntrustStudentComponent.prototype, "students", {
        get: function () {
            return this.parent.get('students');
        },
        enumerable: true,
        configurable: true
    });
    CourseEntrustStudentComponent.prototype.remove = function (idx) {
        var students = this.parent.get('students');
        students.removeAt(idx);
    };
    CourseEntrustStudentComponent.prototype.onVatBlur = function (idx) {
        var _this = this;
        if (!this.loading && this.parent.get('bonus').value === 'yes') {
            var students = this.parent.get('students');
            var student_1 = students.at(idx);
            var vat = student_1.get('vatIdentifier').value;
            if (vat && vat.length > 0) {
                this.loading = true;
                this.entrust.checkStudentVat(vat).subscribe(function (data) {
                    if (data) {
                        student_1.patchValue(data);
                    }
                    _this.loading = false;
                }, function () {
                    _this.loading = false;
                });
            }
        }
        var bonus = this.parent.get('bonus').value;
        if (bonus === 'no') {
            var companyVatIdentifier = this.parent.get('company').value
                .vatIdentifier;
            var representativeVatIdentifier = this.parent.get('representative')
                .value.vatIdentifier;
            var student = this.getStudent(idx);
            if (!companyVatIdentifier ||
                (companyVatIdentifier.length < 1 && student.value)) {
                this.parent.get('company').patchValue({
                    vatIdentifier: student.value.vatIdentifier
                });
            }
            if (!representativeVatIdentifier ||
                (representativeVatIdentifier.length < 1 && student.value)) {
                this.parent.get('representative').patchValue({
                    vatIdentifier: student.value.vatIdentifier
                });
            }
        }
    };
    CourseEntrustStudentComponent.prototype.onNameBlur = function (idx) {
        var bonus = this.parent.get('bonus').value;
        if (bonus === 'no') {
            var student = this.getStudent(idx);
            if (student.value) {
                this.parent.get('company').patchValue({
                    businessName: [
                        student.value.name,
                        student.value.subname,
                        student.value.subname2
                    ]
                        .filter(Boolean)
                        .join(' ')
                });
            }
            if (student.value) {
                this.parent.get('representative').patchValue({
                    name: [
                        student.value.name,
                        student.value.subname,
                        student.value.subname2
                    ]
                        .filter(Boolean)
                        .join(' ')
                });
            }
        }
    };
    CourseEntrustStudentComponent.prototype.onEmailBlur = function (idx) {
        var bonus = this.parent.get('bonus').value;
        if (bonus === 'no') {
            var companyEmail = this.parent.get('company').value.email;
            var student = this.getStudent(idx);
            if (!companyEmail || (companyEmail.length < 1 && student.value)) {
                this.parent.get('company').patchValue({
                    email: student.value.email
                });
            }
        }
    };
    CourseEntrustStudentComponent.prototype.onPhoneBlur = function (idx) {
        var bonus = this.parent.get('bonus').value;
        if (bonus === 'no') {
            var companyPhone = this.parent.get('company').value.phone;
            var student = this.getStudent(idx);
            if (!companyPhone || (companyPhone.length < 1 && student.value)) {
                this.parent.get('company').patchValue({
                    phone: student.value.phone
                });
            }
        }
    };
    CourseEntrustStudentComponent.prototype.triggerDatepicker = function (idx) {
        console.log({
            pickers: this.pickers,
            idx: idx
        });
        this.pickers.find(function (_, i) { return i == idx; }).toggle();
    };
    CourseEntrustStudentComponent.prototype.getStudent = function (idx) {
        var students = this.parent.get('students');
        return students.at(idx);
    };
    return CourseEntrustStudentComponent;
}());
export { CourseEntrustStudentComponent };
