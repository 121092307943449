import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CMSEnv, CMS_ENV } from '@cms-app/shared/models/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  endpoint= '/login'
  token;

  constructor(@Inject(CMS_ENV) private env: CMSEnv,private http: HttpClient, private router: Router) {}

  login(name: string, pass: string){
    this.http.post(`${this.env.baseUrl}${this.endpoint}`, {name: name, pass: pass}).subscribe((res: any) => {
      this.router.navigate(['admin/users']);
      localStorage.setItem('auth_token', res.access_token);
    })
  }

  getToken(){
    return localStorage.getItem('auth_token')
  }

  logout() {
    localStorage.removeItem('token');
  }

  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

}
