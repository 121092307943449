<div class="row cms-card" [style.background-image]="getBackGround()">
  <div class="col-sm-4">
    <div class="geodestino-picture">
      <img [src]="getPicture()" [alt]="'Foto ' + geodestino.name" />
    </div>
  </div>
  <div class="col-sm-6">
    <p class="subname" *ngIf="!isActivity()" style="line-height: 2px !important;">{{ geodestino.typePV || geodestino.type }} {{geodestino.categoryPV || geodestino.category || ""}}</p>
    <p class="subname" *ngIf="isActivity()" style="line-height: 2px !important;">{{geodestino.actTypePV || geodestino.actType}}</p>
    <h3>{{ selectPV(geodestino, 'name') }}</h3>
    <p style="line-height: 2px !important;" class="nowrap">
      <i class="fa fa-map-marker" aria-hidden="true"></i>{{ selectPV(geodestino, 'address') }}
      {{ selectPV(geodestino, 'zipCode') }}
      {{ selectPV(geodestino, 'location') }}
      {{ selectPV(geodestino, 'state') }}
    </p>
    <p  *ngIf="checkCoordinates()" style="line-height: 2px !important;">
      <i class="fa fa-map"></i>
      <a [href]="getMapsLink()" target="_blank">ver en mapa</a>
    </p>
    <div class="contact-info">
      <p *ngIf="this.geodestino.phone || this.geodestino.phonePV" style="line-height: 2px !important;">
        <a [href]="telTo(ht)" *ngFor="let ht of geodestino.phone; let i=index">
          <i class="fa fa-phone" aria-hidden="true"></i>{{ ht }}
        </a>
      </p>
      <p *ngIf="this.geodestino.email || this.geodestino.emailPV" style="line-height: 2px !important;">
        <a [href]="mailTo()">
          <i class="fa fa-envelope-o" aria-hidden="true"></i>{{ selectPV(geodestino, 'email') }}
        </a>
      </p>
      <p *ngIf="this.geodestino.url || this.geodestino.urlPV" style="line-height: 2px !important;">
        <a [href]="visit()" target="_blank">
          <i class="fa fa-globe" aria-hidden="true"></i>{{ selectPV(geodestino, 'url') }}
        </a>
      </p>
    </div>
  </div>
  <div class="col-sm-2">
    <div class="seals-spacer"></div>
    <!-- <p class="likes-container seal">
      <i class="fa fa-heart" aria-hidden="true"></i>{{ geodestino.likes }}
    </p> -->
    <div class="row seals">
      <div class="col-sm-12 col-xs-4">
        <div class="seal" *ngIf="!!geodestino.galicia">
          <img [src]="preppendAssetsUrl('/assets/logo-nuevo-ag-web.png')" alt="Socio Agrupación Galicia" />
        </div>
      </div>
      <div class="col-sm-12 col-xs-4">
        <div class="seal" *ngIf="!!geodestino.fidelity">
          <a [href]="geodestino.fidelityLink" target="_blank" *ngIf="!!geodestino.fidelityLink">
            <img [src]="preppendAssetsUrl('/assets/tarjeta-fidelity.png')" alt="Descuentos con la Tarjeta Fidelity" />
          </a>
          <img [src]="preppendAssetsUrl('/assets/tarjeta-fidelity.png')" alt="Descuentos con la Tarjeta Fidelity" *ngIf="!geodestino.fidelityLink"/>
        </div>
      </div>
      <div class="col-sm-12 col-xs-4">
        <div class="seal best-choice" *ngIf="!!geodestino.certCalidad">
          <a [href]="geodestino.certCalidadLink" target="_blank" *ngIf="!!geodestino.certCalidadLink">
            <img [src]="preppendAssetsUrl('/assets/best-choice.png')" alt="Certificado Best Choice" />
        </a>
        <img [src]="preppendAssetsUrl('/assets/best-choice.png')" alt="Descuentos con la Tarjeta Fidelity" *ngIf="!geodestino.certCalidadLink"/>
        </div>
      </div>
    </div>
  </div>
</div>
