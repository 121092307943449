<div class="container">
  <div class="row">
    <div class="form-inline text-center" [formGroup]="searchForm" style="margin-top: 15px;">
      <div class="input-group">
        <input
          type="search"
          class="form-control cms-search"
          (keyup.enter)="doSearch()"
          formControlName="query"
          placeholder="Buscar"
        />
        <span class="input-group-btn">
          <button class="btn btn-default" (click)="doSearch()">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="loaded">
    <div class="col-md-12" style="text-align: right">
      <p style="margin-top: 15px; margin-bottom: 0px">
        <strong>{{ count }}</strong> resultados encontrados
      </p>
      <pagination
        geoDestinoPager
        [totalItems]="count"
        [(ngModel)]="page"
        (pageChanged)="pageChanged($event)"
        *ngIf="count > per_page"
      ></pagination>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" *ngFor="let geodestino of rows">
      <cms-geodestino-section-item
        [geodestino]="geodestino"
      ></cms-geodestino-section-item>
    </div>
    <div class="col-md-12">
      <cms-geodestino-contact-item></cms-geodestino-contact-item>
    </div>
  </div>
  <div class="row" *ngIf="loaded && count > 5">
    <div class="col-md-12" style="text-align: right">
      <p style="margin-top: 15px; margin-bottom: 0px">
        <strong>{{ count }}</strong> resultados encontrados
      </p>
      <pagination
        geoDestinoPager
        [totalItems]="count"
        [(ngModel)]="page"
        (pageChanged)="pageChanged($event)"
        *ngIf="count > per_page"
      ></pagination>
    </div>
  </div>
</div>
