import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FileUploadTransformation,
  FileUploader,
  FileUpload
} from '../../models/files.interface';
import { FilesService } from '../../services/files.service';

@Component({
  selector: 'pv-filepicker',
  templateUrl: './filepicker.component.html',
  styleUrls: ['./filepicker.component.scss']
})
export class FilePickerComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() extent: boolean;
  @Input() transformations: FileUploadTransformation[] | string;
  @Input() preview = false;
  @Input() manual = false;
  @Input() required = false;
  @Input() url: string = null;
  @Output() uploaded = new EventEmitter<FileUpload>();
  display: string;
  uploader: FileUploader;

  constructor(private filesService: FilesService) {}

  ngOnInit() {
    const transformations = this.optionsBuilder();
    this.filesService
      .getUploader({ transformations }, this.url)
      .subscribe(uploader => {
        this.uploader = uploader;
        if (this.preview) {
          this.uploader.onAfterAddingFile = file => {
            const reader = new FileReader();

            reader.onload = e => {
              this.display = (<any>e.target).result;
            };

            reader.readAsDataURL(file._file);
            while (this.uploader.queue.length > 1) {
              this.uploader.removeFromQueue(this.uploader.queue[0]);
            }
          };
        }
        this.uploader.events.subscribe(({ path, status }) => {
          this.uploader.clearQueue();
          this.uploaded.emit({ path, status });
          this.display = null;
        });
      });
  }

  public upload(): Observable<string> {
    return new Observable(observer => {
      if (this.uploader.getNotUploadedItems().length > 0) {
        this.uploader.uploadAll();
        this.uploaded.subscribe(({ path, status }) => {
          observer.next(path);
        });
      } else {
        observer.next(null);
      }
    });
  }

  private optionsBuilder(): string {
    if (this.transformations) {
      if (this.transformations instanceof Array) {
        return this.transformations
          .map(
            row =>
              `${row.width},${row.height},${row.name},${row.extent ? 1 : 0}`
          )
          .join('|');
      } else {
        return this.transformations;
      }
    } else if (this.width || this.height) {
      return `${this.width},${this.height},,${
        Boolean(this.width && this.height) ? 1 : 0
      }`;
    } else {
      return null;
    }
  }
}
