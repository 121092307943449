
    <div class="row" *ngIf="alerts.length > 0">
      <div class="col-md-12" *ngFor="let alert of alerts">
        <alert
          *ngIf="!alert.options.timeout"
          [type]="alert.type"
          [dismissible]="alert.options.dismissible || false"
        >
          <div [innerHTML]="alert.message"></div>
        </alert>
        <alert
          *ngIf="alert.options.timeout"
          [type]="alert.type"
          [dismissOnTimeout]="alert.options.timeout"
        >
          <div [innerHTML]="alert.message"></div>
        </alert>
      </div>
    </div>
  