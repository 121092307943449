import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RssSrcService } from '@cms-app/rss/providers/rss-src.service';
var AdminRssSrcComponent = /** @class */ (function () {
    function AdminRssSrcComponent(router, rssSrcService) {
        this.router = router;
        this.rssSrcService = rssSrcService;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.per_page = 20;
        this.query = '';
        this.loading = false;
    }
    AdminRssSrcComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminRssSrcComponent.prototype.checkLink = function (row) {
        window.open(row.url, '_blank');
    };
    AdminRssSrcComponent.prototype.update = function (row) {
        this.router.navigate(['admin', 'rss', 'rss-src', row._id], {
            state: {
                data: row,
            }
        });
    };
    AdminRssSrcComponent.prototype.delete = function (row) {
        this.rssSrcService.delete(row._id);
        this.find();
    };
    AdminRssSrcComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminRssSrcComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminRssSrcComponent.prototype.find = function () {
        var _this = this;
        this.loading = true;
        this.rssSrcService
            .find({ page: this.page || 1, per_page: this.per_page }, this.query)
            .subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    AdminRssSrcComponent.prototype.onAdd = function () {
        this.router.navigate(['admin', 'rss', 'rss-src', 'new']);
    };
    return AdminRssSrcComponent;
}());
export { AdminRssSrcComponent };
