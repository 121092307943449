import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import {
  debounceTime,
  map,
  startWith,
  distinctUntilChanged
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  resize$ = fromEvent(window, 'resize').pipe(debounceTime(250));

  register(): Observable<'xs' | 'sm' | 'md' | 'lg'> {
    return this.resize$.pipe(
      startWith(this.getMedia(window.innerWidth)),
      map(() => this.getMedia(window.innerWidth)),
      distinctUntilChanged()
    );
  }

  getMedia(width: number): 'xs' | 'sm' | 'md' | 'lg' {
    if (width <= 767) {
      return 'xs';
    } else if (width <= 992) {
      return 'sm';
    } else if (width <= 1200) {
      return 'md';
    } else {
      return 'lg';
    }
  }
}
