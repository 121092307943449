import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { CopyBlogsService, BlogPostV2 } from '@cms-app/copyblogs';

@Component({
  selector: 'cms-admin-copyblogs',
  templateUrl: './articles.component.html'
})
export class AdminCopyblogsComponent implements OnInit {
  rows: BlogPostV2[] = [];
  count = 0;
  page = 1;
  per_page = 20;
  query = '';
  loading = false;

  constructor(
    private router: Router,
    private copyblogsService: CopyBlogsService
  ) {}

  ngOnInit() {
    this.find();
  }

  checkLink(row: BlogPostV2) {
    window.open(row.url, '_blank');
  }

  update(row: BlogPostV2) {
    this.router.navigate([
      'admin',
      'copyblogs',
      'articles',
      row._id,
      { state: { data: row } }
    ]);
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    const options: any = {};

    if (this.page) {
      options.page = this.page;
    }
    if (this.query) {
      options['header>$like'] = this.query;
    }

    this.loading = true;
    this.copyblogsService
      .fetch(options, { page: 1, per_page: this.per_page })
      .subscribe(
        ({ list, count }) => {
          this.rows = list;
          this.count = count;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }
}
