import { OnInit } from '@angular/core';
import { TagsService } from '@cms-app/courses';
import { TagModalComponent } from '../../components/tag-modal/tag-modal.component';
var AdminTagsComponent = /** @class */ (function () {
    function AdminTagsComponent(tagsService) {
        this.tagsService = tagsService;
        this.count = 0;
        this.page = 1;
        this.query = '';
        this.loading = false;
    }
    AdminTagsComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminTagsComponent.prototype.add = function () {
        this.tagModal.add();
    };
    AdminTagsComponent.prototype.update = function (row) {
        this.tagModal.edit(row);
    };
    AdminTagsComponent.prototype.remove = function (row) {
        var _this = this;
        this.tagsService.remove(row._id).subscribe(function () { return _this.find(); });
    };
    AdminTagsComponent.prototype.onUpdate = function () {
        this.find();
    };
    AdminTagsComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminTagsComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminTagsComponent.prototype.find = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        if (this.query) {
            options['name>$like'] = this.query;
        }
        this.loading = true;
        this.tagsService.find(options).subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    return AdminTagsComponent;
}());
export { AdminTagsComponent };
