import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from '@cms-app/admin/shared';
import { RssSrc } from '@cms-app/rss/models/rss-src.model';
import { RssSrcService } from '@cms-app/rss/providers/rss-src.service';

@Component({
  selector: 'cms-provider-detail',
  templateUrl: './rss-src-detail.component.html',
})
export class RssSrcDetailComponent implements OnInit {
  rssSrc: RssSrc = {
    name: '',
    url: '',
    articleSelector: '',
    blogGalicia: false,
  };
  edit: boolean = false;

  constructor(
    private rssSrcService: RssSrcService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messaging: MessagingService
  ) {}

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.params;
     if (params) {
      this.rssSrcService.getRssSrcById(params.id).subscribe(
        (res) => {
          this.rssSrc = res;
          this.edit = true;
        },
        (err) => console.log(err)
      );
    }
  }

  goBack(){
    this.router.navigate(['/admin/rss/rss-src']);
  }

  submitSource() {
    this.rssSrcService.add(this.rssSrc).subscribe(
      (res) => {
        this.messaging.success(
          'FuenteRSS Creada correctamente',
          'CMS ADMIN'
        );
      },
      (err) => {
        this.messaging.error(
          'Error al Crear la Fuente RSS',
          'CMS ADMIN'
        )
        console.log(err)}
    );
  }

  updateSource() {
    this.rssSrcService
      .update(this.rssSrc._id, this.rssSrc)
      .subscribe(
        (res) => {
          this.messaging.success(
            'FuenteRSS Modificada correctamente',
            'CMS ADMIN'
          );
        },
        (err) =>  {
          this.messaging.error(
            'Error al Crear la Fuente RSS',
            'CMS ADMIN'
          )
          console.log(err)}
      );
  }
}
