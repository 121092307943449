import { Component, Input, Inject } from '@angular/core';
import { patchComponentDefWithScope } from '@angular/core/src/render3/jit/module';
import { listenToElementOutputs } from '@angular/core/src/view/element';
import { DomSanitizer} from '@angular/platform-browser';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { GeoDestino } from '../../models/geodestino.model';

@Component({
  selector: 'cms-geodestino-section-item',
  templateUrl: './geodestino-section-item.component.html',
  styleUrls: ['./geodestino-section-item.component.css'],
})
export class GeoDestinoSectionItemComponent {
  @Input() geodestino: GeoDestino;

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private sanitizer: DomSanitizer) {}

  //Return a tel: link for autocall on compatible devices
  telTo(phone: string) {
    return `tel:${phone.replace(/\+/, '00').replace(/\s/g, '').trim()}`;
  }

  //returns a mailto: link to open the email app
  mailTo() {
    return `mailto:${this.geodestino.email}`;
  }

  visit() {
    return this.geodestino.url;
  }

  //if the item have images gets the first if not returns a generic image
  getPicture() {
    return this.geodestino.img && this.geodestino.img.length > 0
      ? this.geodestino.img[0]
      : `${this.env.assetsUrl}/assets/nophoto.jpg`;
  }

  getBackGround(){
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.env.assetsUrl}/assets/fondo-geodestino.jpg)`)
  }

  //returns a link to the assets url for use in elements on other websites
  preppendAssetsUrl(url: string): string {
    return `${this.env.assetsUrl}${url}`;
  }

  isActivity() {
    switch (this.geodestino.type) {
      case 'salud':
        return true;
      case 'deporte':
        return true;
      case 'compras':
        return true;
      default:
        return false;
    }
  }

  //check if the coordinates are correct
  checkCoordinates(){
    const coord = this.geodestino.coordinatesPV || this.geodestino.coordinates;
    let valid = false;
    if(coord){
      if(coord.split('-').length < 2){
        if(coord.split(',').length < 2){
          valid = false;
        }else{
          valid = true;
        }
      }else{
        valid = true;
      }
    }
    return valid;
  }

  //returns a google maps link to the coordinates
  getMapsLink(): string{
    const coord = this.geodestino.coordinatesPV || this.geodestino.coordinates;
    const mapsUrl = 'https://www.google.es/maps/?ll=';
    const split = coord.split(',');
      if(split.length > 1){
      return mapsUrl+split[0].trim()+","+split[1].trim();
    }else{
      const coordDecimal = this.convertCoordinates(coord);
      return mapsUrl+coordDecimal.latitude+","+coordDecimal.longitude;
    }
  }

  //this 2 methods transforms the coordinates string in degrees minutes seconds to decimal coordinates
  private convertCoordinates(coordinates: string) {
    if (coordinates) {
      const coordinatesSplit = coordinates.split('-');
      const lat = this.getNumbers(coordinatesSplit[0].trim());
      const long = this.getNumbers(coordinatesSplit[1].trim());
      let latDecimal = lat.degrees + lat.minutes / 60 + lat.seconds / 3600;
      let longDecimal = long.degrees + long.minutes / 60 + long.seconds / 3600;
      if (lat.direction === 'S') {
        latDecimal = latDecimal * -1;
      }
      if (long.direction === 'W') {
        longDecimal = longDecimal * -1;
      }
      return {
        latitude: latDecimal,
        longitude: longDecimal,
      };
    } else {
      return null;
    }
  }

  private getNumbers(string: string) {
    let result = { degrees: 0, minutes: 0, seconds: 0, direction: '' };
    const split = string.split(' ');
    result.degrees = parseInt(split[0], 10);
    result.minutes = parseInt(split[1], 10);
    result.seconds = parseFloat(split[2]);
    result.direction = split[3];
    return result;
  }


  selectPV(geoDestino: GeoDestino,key: string){
    const keyPV= key.concat('PV');
    if(geoDestino[keyPV] && geoDestino[keyPV].length > 0){
      return geoDestino[keyPV];
    }else{
      return geoDestino[key];
    }
  }
}
