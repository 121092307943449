import { EventEmitter } from '@angular/core';
var TabComponent = /** @class */ (function () {
    function TabComponent() {
        this.name = '';
        this.select = new EventEmitter();
        this._selected = false;
    }
    Object.defineProperty(TabComponent.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (value) {
                this.select.emit();
            }
            this._selected = value;
        },
        enumerable: true,
        configurable: true
    });
    return TabComponent;
}());
export { TabComponent };
