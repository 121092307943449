<div class="row" [formGroup]="request" *ngIf="!!request">
  <div class="col-xs-12">
    <div class="form-group">
      <label for="">Nombre</label>
      <input type="text" class="form-control" formControlName="name" />
      <div ngxErrors="name">
        <div ngxError="required" when="touched">El nombre es obligatorio</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="form-group">
      <label for="">Email</label>
      <input type="text" class="form-control" formControlName="email" />
      <div ngxErrors="email">
        <div ngxError="required" when="touched">El email es obligatorio</div>
        <div ngxError="email" when="touched">
          El formato del email no es válido
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="form-group">
      <label for="">Teléfono</label>
      <input type="text" class="form-control" formControlName="phone" />
      <div ngxErrors="phone">
        <div ngxError="required" when="touched">El teléfono es obligatorio</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="form-group">
      <label for="">Comentarios</label>
      <textarea
        class="form-control"
        rows="10"
        formControlName="comments"
      ></textarea>
    </div>
  </div>
  <div class="col-xs-12">
    <button
      class="btn btn-default btn-block"
      [disabled]="sending"
      (click)="send()"
      style="background-color: black; color: white;"
    >
      <span *ngIf="!sending">ENVIAR</span>
      <span *ngIf="sending">ENVIANDO ...</span>
    </button>
  </div>
  <div style="padding: 0px 15px">
    <div style="margin-top: 5px;"></div>
    <cms-messages></cms-messages>
  </div>
</div>
