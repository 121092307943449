import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { SharedModule } from '@cms-app/shared/shared.module';

import { GeoDestinoComponent } from './components/geodestino/geodestino.component';
import { GeoDestinoSectionComponent } from './components/geodestino-section/geodestino-section.component';
import { GeoDestinoSectionItemComponent } from './components/geodestino-section-item/geodestino-section-item.component';
import { GeoGuideComponent } from './components/geoguide/geoguide.component';

import { GeoDestinosLabComponent } from './containers/geodestinos-lab/geodestinos-lab.component';
import { GeoGuideLabComponent } from './containers/geoguide-lab/geoguide-lab.component';

import { SectionLazyDirective } from './directives/section-lazy.directive';
import { GeoDestinoPager } from './directives/paginator.directive';
import { AccordionGroupChevronDirective } from './directives/accordion-group-chevron.directive';

import { GeodestinosService } from './providers/geodestinos.service';
import { GeoDestinoContactItemComponent } from './components/geodestino-contact-item/geodestino-contact-item.component';
import { PromoItemComponent } from './components/promo-item/promo-item.component';
import { PromoListComponent } from './components/promo-list/promo-list.component';

const components = [
  PromoItemComponent,
  PromoListComponent,
  GeoDestinoComponent,
  GeoDestinoSectionComponent,
  GeoDestinoSectionItemComponent,
  GeoGuideComponent,
  GeoDestinoContactItemComponent,
];
const containers = [GeoDestinosLabComponent, GeoGuideLabComponent];
const directives = [
  GeoDestinoPager,
  SectionLazyDirective,
  AccordionGroupChevronDirective
];

@NgModule({
  declarations: [...components, ...containers, ...directives],
  exports: [...containers, ...components],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    SharedModule
  ],
  providers: [GeodestinosService]
})
export class GeoDestinosModule {}
