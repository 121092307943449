import {
  Component,
  Inject,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import {
  CMS_ENV,
  CMSEnv,
  QueryStringService,
  MediaService,
} from '@cms-app/shared';

import {
  GeoDestino,
  GeoDestinoType,
} from '../../models/geodestino.model';
import { GeodestinosService } from '../../providers/geodestinos.service';
import { map } from 'rxjs/operators';
import { GeoLocation } from '@cms-app/geodestinos/models/geolocation.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cms-geoguide',
  templateUrl: './geoguide.component.html',
  styleUrls: ['./geoguide.component.css'],
})
export class GeoGuideComponent implements OnInit {
  @Input() geolocation: String = 'riasaltas';
  @Input() queryinput: String = "";
  searchForm: FormGroup;
  query: string;

  geoLoc: GeoLocation;
  section: GeoDestinoType = 'hotel';
  sections: any[] = [];
  sectionMobile: GeoDestinoType = 'hotel';
  selectedValue: string = '';
  location: string = '';
  locationMobile: string = '';
  locations: string[] = [];
  isMobile = true;

  page: number = 1;
  per_page: number = GeodestinosService.DEFAULT_PER_PAGE;
  count: number;
  rows: GeoDestino[] = [];
  titleEst: boolean = false;

  element: HTMLElement;

  constructor(
    @Inject(CMS_ENV) public env: CMSEnv,
    private queryService: QueryStringService,
    private geodestinos: GeodestinosService,
    private scrollToService: ScrollToService,
    private media: MediaService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.media
      .register()
      .pipe(map((size) => size === 'xs' || size === 'sm'))
      .subscribe((mobile) => {
        this.isMobile = mobile;
      });
      const params: any = this.queryService.parse(window.location.search);
      this.searchForm = this.fb.group({
        query: [params.q ? decodeURIComponent(params.q) : ''],
      });
    this.geodestinos.getGeoDestinoLocations({name: this.geolocation}).subscribe(({count, list}) => {
      if(list[0]){
        this.geoLoc= list[0];
        this.locations = list[0].locations;
        if(this.queryinput){
          this.searchForm.setValue({
            query: this.queryinput,
          });
          this.doSearch();
        }else{
          this.fetch(1).subscribe(({ count, list }) => this.handleRows(count, list));
        }
      }
    });

    for (let section of this.getSectionsDetail()) {
      this.sections.push(section.display);
    }

  }

  scrollTo(index, location) {
    let el = document.getElementById('accordion' + index);
    el.scrollIntoView();
    this.location = location;
    const filter = { location: this.location, q: this.query };
    this.geodestinos.searchAllSections(filter, {
      page: 1,
      per_page: this.per_page,
    }).then( (res) =>{
      this.titleEst = true;
      this.handleRows(res.count, res.list);
    });
    this.searchForm.get('query').setValue('');
  }

  doSearch() {
    /*this.location=null;
    const q = this.searchForm.get('query').value;
    this.query = q ? q.trim() : '';
    this.fetch(1).subscribe(({ count, list }) => this.handleRows(count, list));*/
    const q = this.searchForm.get('query').value;
    this.query = q ? q.trim() : '';
    const filter = { 'location>$in': this.locations.join(','), q: this.query };
    this.query = '';
    this.geodestinos.searchAllSections(filter, {
      page: 1,
      per_page: this.per_page,
    }).then( (res) =>{
      this.titleEst = true;
      this.handleRows(res.count, res.list);
    });
  }

  get title() {
    if(this.titleEst){
      if(this.location){
        return `Establecimientos en ${ this.location}`;
      }else{
        return `Establecimientos en ${ this.geoLoc.fullName}`;
      }
    }
    if (this.location && this.location.length > 0) {
      return `${this.geodestinos.getSectionDescription(this.section)} en ${
        this.location
      }`;
    } else {
      return `${this.geodestinos.getSectionDescription(
        this.section
      )} en ${this.geoLoc.fullName}`;
    }
  }

  get loaded() {
    return this.count !== undefined;
  }

  filter(section: GeoDestinoType, locat: string) {
    if (this.section !== section || this.location !== locat) {
      this.section = section;
      this.location = locat;
      this.fetch(1).subscribe(({ count, list }) =>
        this.handleRows(count, list)
      );
      this.searchForm.get('query').setValue('');
      this.titleEst=false;
    }
  }

  fetch(page = 1) {
    const filter = this.location
      ? { location: this.location, q: this.query }
      : { 'location>$in': this.locations.join(','), q: this.query };
    this.query = '';
    return this.geodestinos.fetch(this.section, filter, {
      page,
      per_page: this.per_page,
    });
  }

  pageChanged(event: any): void {
    if (this.page !== event.page) {
      this.page = event.page;

      this.fetch(event.page).subscribe(({ count, list }) =>
        this.handleRows(count, list)
      );
    }
  }

  preppendAssetsUrl(url: string): string {
    return `${this.env.assetsUrl}${url}`;
  }

  isSectionActive(type: GeoDestinoType): boolean {
    return this.geodestinos.isSectionActive(type);
  }

  getSectionsDetail(): Array<{ name: GeoDestinoType; display: string }> {
    return ([
      { name: 'hotel', display: 'Servicios Hoteleros' },
      { name: 'apartamento', display: 'Viviendas / Apartamentos' },
      { name: 'rural', display: 'Casas Rurales' },
      { name: 'family', display: 'Familia' },
      { name: 'albergue', display: 'Albergues' },
      { name: 'camping', display: 'Campings' },
      { name: 'pension', display: 'Pensiones' },
      { name: 'comer', display: 'Restaurantes' },
      { name: 'hacer', display: 'Hacer' },
      { name: 'informarse', display: 'Informarse' },
      { name: 'transporte', display: 'Transporte' },
      { name: 'servicios', display: 'Servicios' },
      { name: 'salud', display: 'Bienestar y Salud' },
      { name: 'deporte', display: 'Actividades Deportivas' },
      { name: 'compras', display: 'Compras' },
    ] as Array<{ name: GeoDestinoType; display: string }>).filter((row) =>
      this.isSectionActive(row.name)
    );
  }

  private handleRows(count: number, list: GeoDestino[]) {
    if (this.count != undefined) {
      this.scrollToService.scrollTo({
        offset: this.isMobile ? 0 : 0,
        target: 'cms-geoguide-container',
        duration: 100,
      });
    }
    this.rows = list;
    this.count = count;
  }

  onSelectChangeLocation(ev) {
    const value = ev.target ? ev.target.value : '';
    this.locationMobile = value;
    if (this.locationMobile !== '') {
      this.filter(this.sectionMobile, this.locationMobile);
    }
  }

  onSelectChangeSection(ev) {
    const value = ev.target ? ev.target.value : '';
    if (value !== '') {
      for (let section of this.getSectionsDetail()) {
        if (section.display === value) {
          this.sectionMobile = section.name;
          break;
        }
      }
    } else {
      this.sectionMobile = 'hotel';
    }
    if (this.locationMobile !== '') {
      this.filter(this.sectionMobile, this.locationMobile);
    }
  }
}
