import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { MediaService } from '@cms-app/shared';

import { Color } from '../../models/theme.model';
import { PVFamily } from '../../models/pv-family.model';
import { Family } from '../../models/family.model';

import { ThemeService } from '../../providers/theme.service';
import { PVFamiliesService } from '../../providers/pv-families.service';

@Component({
  selector: 'cms-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css'],
})
export class CategoriesListComponent implements OnInit {
  @Input() set family(family: string) {
    if (this._family && !family) {
      this.currentFilter = null;
      this.currentSubFilter = null;
      this.emitFamiliesFilter();
    }

    this._family = family;

    this.find();
  }
  @Input() color: Color = 'red';

  @Output() families = new EventEmitter<{ name: string; families: string[] }>();
  @Output() all = new EventEmitter<void>();

  rows: PVFamily[] = [];
  count = 0;
  loading = false;

  query: string = '';
  _family: string;
  currentFilter = null;
  currentSubFilter = null;
  size$: Observable<'xs' | 'sm' | 'md' | 'lg'>;

  constructor(
    private mediaService: MediaService,
    public theme: ThemeService,
    private familiesService: PVFamiliesService
  ) {}

  ngOnInit() {
    this.find();

    this.size$ = this.mediaService.register();
  }

  find(options = {}) {
    let PVfamilyId = '5dd4fa099e90e004c432a083';
    if (!this.loading) {
      this.loading = true;
      this.familiesService
        .find({
          ...options,
          per_page: 100,
          expand:
            'families.name,families.families,families.source,families>families.name',
        })
        .subscribe(
          ({ list, count }) => {
            // console.log({ list, count });

            this.rows = this._family
              ? list.filter((row) => row._id === this._family)
              : list;
            this.rows.sort((a, b) => {
              if (a._id === PVfamilyId) {
                return -1;
              }

              if (b._id === PVfamilyId) {
                return 1;
              }

              return 0;
            });
            this.count = count;
            this.loading = false;

            if (this._family) {
              this.setFamilyFilter(this.rows[0]);
            }
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  onSelectChange(ev) {
    const value = ev.target ? ev.target.value : '';

    if (value && value.length > 0) {
      const family = this.rows.find((row) => row._id === value);

      if (family) {
        this.setFamilyFilter(family);
      } else {
        this.families.emit(null);
        this.currentFilter = null;
      }
    } else {
      this.families.emit(null);
      this.currentFilter = null;
    }
  }

  setFamilyFilter(family: PVFamily) {
    if (family._id === this.currentFilter && !this._family) {
      this.currentFilter = null;
      this.currentSubFilter = null;
    } else {
      this.currentFilter = family._id;
    }
    this.emitFamiliesFilter();
  }

  setSelectColor(subfamily){
    if(this.currentSubFilter && subfamily.families[0] === this.currentSubFilter[0]){
      return this.theme.color;
    }else{
      return "'#333'";
    }
  }

  setSubfamilyFilter(subfamily) {
    if (
      this.currentSubFilter &&
      subfamily.families[0] === this.currentSubFilter[0]
    ) {
      this.currentSubFilter = null;
    } else {
      this.currentSubFilter = subfamily.families;
    }
    this.emitFamiliesFilter();
  }

  getSubfamilies(family: PVFamily) {
    let subFamiliesList = [];
    const subfamilies = this.familiesService
      .subfamilies(family)
      .filter((family) => this.familiesService.match(family.name, this.query));
    for (let subFamily of subfamilies) {
      let exists = false;
      let sf = {
        name: '',
        families: [],
      };
      if (subFamiliesList.length > 0) {
        for (let object of subFamiliesList) {
          if (object.name.toLowerCase() === subFamily.name.toLowerCase()) {
            exists = true;
          }
        }
      }
      if (exists) {
        for (let so of subFamiliesList) {
          if (so.name.toLowerCase() === subFamily.name.toLowerCase()) {
            so.families.push(subFamily._id);
          }
        }
      } else {
        sf.name = subFamily.name;
        sf.families.push(subFamily._id);
        subFamiliesList.push(sf);
      }
    }
    return subFamiliesList;
  }

  emitFamiliesFilter() {
    if (!this.currentFilter) {
      this.families.emit(null);
    } else {
      const family = this.rows.find((row) => row._id === this.currentFilter);
      console.log(family)
      const subfamilies = ((family as any).families as PVFamily[])
        .reduce((acc, subfamily) => {
          if (subfamily.families.length < 1) {
            return [...acc, subfamily._id];
          } else {
            const rows = (subfamily.families as any[]).map((row) => row._id);
            return [...acc, ...rows];
          }
        }, []);
        let sFArray = [];
        if(this.currentSubFilter){
        for(let subfamily of subfamilies){
           for(let sf of this.currentSubFilter){
            if(subfamily === sf){
              sFArray.push(subfamily);
            }
          }
        }
      }else{
        for(let subfamily of subfamilies){
             sFArray.push(subfamily);

       }
      }
      console.log(sFArray)
      this.families.emit({
        name: family.name,
        families: sFArray,
      });
    }
  }
}
