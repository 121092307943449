import { Component, Input, OnInit, Inject } from '@angular/core';

import { GeoDestinoType, GeoDestino } from '../../models/geodestino.model';
import { GeodestinosService } from '../../providers/geodestinos.service';
import { CMS_ENV, CMSEnv, QueryStringService } from '@cms-app/shared';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'cms-geodestino-section',
  templateUrl: './geodestino-section.component.html',
  styleUrls: ['./geodestino-section.component.css']
})
export class GeoDestinoSectionComponent implements OnInit {
  @Input() section: GeoDestinoType = 'alojamiento';

  @Input() q: string;
  @Input() code: string;
  @Input() city: string;
  @Input() family: string;

  page: number = 1;
  per_page: number = GeodestinosService.DEFAULT_PER_PAGE;
  count: number;
  rows: GeoDestino[] = [];
  searchForm: FormGroup;
  query: string;

  constructor(
    @Inject(CMS_ENV) private env: CMSEnv,
    private geodestinos: GeodestinosService,
    private fb: FormBuilder,
    private queryService: QueryStringService,
  ) {}

  ngOnInit() {
    this.fetch().subscribe(({ count, list }) => this.handleRows(count, list));

    const params: any = this.queryService.parse(window.location.search);
    this.searchForm = this.fb.group({
      query: [params.q ? decodeURIComponent(params.q) : ''],
    });
    if (params && params.q && params.q.length > 0) {
      this.doSearch();
    }
  }

  get loaded() {
    return this.count !== undefined;
  }

  fetch(page = 1) {
    return this.geodestinos.fetch(
      this.section,
      {
        q: this.query,
        code: this.code,
        location: this.city
      },
      {
        page,
        per_page: this.per_page
      }
    );
  }

  doSearch(){
    const q = this.searchForm.get('query').value;
    this.query = q ? q.trim() : '';
    this.fetch(1).subscribe(({ count, list }) => this.handleRows(count, list));
    this.query='';
  }

  pageChanged(event: any): void {
    if (this.page !== event.page) {
      this.page = event.page;
      this.fetch(event.page).subscribe(({ count, list }) =>
        this.handleRows(count, list)
      );
    }
  }

  preppendAssetsUrl(url: string): string {
    return `${this.env.assetsUrl}${url}`;
  }

  private handleRows(count: number, list: GeoDestino[]) {
    this.rows = list;
    this.count = count;
  }
}
