import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FilesModule } from '@portales/files';

import { SharedModule } from '@cms-app/shared/shared.module';
import { GeoDestinosModule } from '@cms-app/geodestinos/geodestinos.module';

import { AdminBarsComponent } from './containers/bars/bars.component';
import { AdminHotelsComponent } from './containers/hotels/hotels.component';
import { AdminRestaurantsComponent } from './containers/restaurants/restaurants.component';
import { AdminGeoDestinoDetailComponent } from './containers/geodestino-detail/geodestino-detial.component';

import { ROUTES } from './admin-geodestinos.routing';
import { AdminActivitiesComponent } from './containers/activities/activities.component';
import { AdminLocationsComponent } from './containers/locations/locations.component';
import { AdminLocationDetailComponent } from './containers/location-detail/location-detail.component';

const containers = [
  AdminBarsComponent,
  AdminHotelsComponent,
  AdminRestaurantsComponent,
  AdminGeoDestinoDetailComponent,
  AdminActivitiesComponent,
  AdminLocationsComponent,
  AdminLocationDetailComponent
];

@NgModule({
  declarations: [...containers],
  exports: [...containers],
  imports: [
    NgxDatatableModule,
    SharedModule,
    ReactiveFormsModule,
    GeoDestinosModule,
    RouterModule.forChild(ROUTES),
    FilesModule,
    FormsModule
  ]
})
export class AdminGeodestinosModule {}
