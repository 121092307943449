import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RssSrc } from '@cms-app/rss/models/rss-src.model';
import { RssSrcService } from '@cms-app/rss/providers/rss-src.service';


@Component({
  selector: 'cms-admin-rss-src',
  templateUrl: './rss-src.component.html'
})
export class AdminRssSrcComponent implements OnInit {
  rows: RssSrc[] = [];
  count = 0;
  page = 1;
  per_page = 20;
  query: string = '';
  loading = false;

  constructor(
    private router: Router,
    private rssSrcService: RssSrcService,
  ) {}

  ngOnInit() {
    this.find();
  }

  checkLink(row: RssSrc) {
    window.open(row.url, '_blank');
  }

  update(row: RssSrc) {
    this.router.navigate(
      ['admin', 'rss', 'rss-src', row._id],
      {
        state: {
          data: row,
        }
      }
    );
  }

  delete(row: RssSrc){
    this.rssSrcService.delete(row._id);
    this.find();
  }

  onPage({ offset }) {
    this.page = offset + 1;
    this.find();
  }

  onQuery(query: string) {
    this.query = query;
    this.find();
  }

  find() {
    this.loading = true;
    this.rssSrcService
      .find(
        { page: this.page || 1, per_page: this.per_page },
        this.query
      )
      .subscribe(
        ({ list, count }) => {
          this.rows = list;
          this.count = count;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onAdd() {
    this.router.navigate(['admin', 'rss', 'rss-src', 'new']);
  }
}
