<cms-tabs
  [initial]="geodestinos.mapTypeToNumber(initial)"
  [style.display]="hide ? 'none' : 'block'"
>
  <cms-tab
    name="Servicios Hoteleros"
    picture="https://www.riasbaixas.info/files/theme/images/hoteles.png"
    *ngIf="isSectionActive('hotel')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="hotel"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Viviendas / Apartamentos"
    picture="https://www.riasbaixas.info/files/theme/images/apartamento.png"
    *ngIf="isSectionActive('apartamento')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="apartamento"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Casas Rurales"
    picture="https://www.riasbaixas.info/files/theme/images/crurales.png"
    *ngIf="isSectionActive('rural')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="rural"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
    <cms-tab
    name="Campings"
    picture="https://www.riasbaixas.info/files/theme/images/camping.png"
    *ngIf="isSectionActive('camping')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="camping"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Albergues"
    picture="https://www.riasbaixas.info/files/theme/images/albergue.png"
    *ngIf="isSectionActive('albergue')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="albergue"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Pensiones"
    picture="https://www.riasbaixas.info/files/theme/images/pension.png"
    *ngIf="isSectionActive('pension')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="pension"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Restaurantes"
    picture="https://www.riasbaixas.info/files/theme/images/restaurantes.png"
    *ngIf="isSectionActive('comer')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="comer"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Salud y Bienestar"
    picture="https://www.riasbaixas.info/files/theme/images/bienestar.png"
    *ngIf="isSectionActive('salud')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="salud"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Actividades Deportivas"
    picture="https://www.riasbaixas.info/files/theme/images/ocio.png"
    *ngIf="isSectionActive('deporte')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="deporte"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Compras"
    picture="https://www.riasbaixas.info/files/theme/images/compras.png"
    *ngIf="isSectionActive('compras')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="compras"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
  <cms-tab
    name="Servicios"
    picture="https://www.riasbaixas.info/files/theme/images/cultura.png"
    *ngIf="isSectionActive('servicios')"
  >
    <cms-geodestino-section
      [q]="q"
      [code]="code"
      [city]="city"
      [family]="family"
      section="servicios"
      *sectionLazy
    ></cms-geodestino-section>
  </cms-tab>
</cms-tabs>
