import { Component, Inject, Input, OnInit } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { GeoDestino } from '@cms-app/geodestinos';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';

@Component({
  selector: 'cms-geodestino-promo-item',
  templateUrl: './promo-item.component.html',
  styleUrls: ['./promo-item.component.css']
})
export class PromoItemComponent {
  @Input() geodestino: GeoDestino;

  public link = "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;800&display=swap"

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private sanitizer: DomSanitizer){}

  getBackGround(){
    const imageUrl=this.geodestino.img[0];
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
  }
  getBackGroundSello(){
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.env.assetsUrl}/assets/sello-descuento.png)`)
  }

  goLink(){
    window.open(this.geodestino.promoUrl, "_blank");
  }

}
