import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CoursesService } from '@cms-app/courses';

@Component({
  selector: 'cms-section-title',
  template: `
    <div class="content-title" [formGroup]="searchForm">
      <button
        *ngIf="showAdd"
        class="btn btn-primary"
        style="margin: -8px 10px 0px 0px"
        (click)="searching = !searching"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
      <div class="form-inline" *ngIf="searching" style="display: inline-block">
        <input
          type="text"
          class="form-control"
          style="margin-top: -8px"
          placeholder="Buscar por Nombre"
          formControlName="query"
        />
        <select
        *ngIf="searching && showSearchBySource"
        [id]='source'
        formControlName='source'
        style="color: #000000"
        >
        <option value=""></option>
        <option *ngFor="let source of sources" [value]="source" style="color: #000000">{{source}}</option>
        </select>
        <button
          *ngIf="searching && showExcel"
          class="btn btn-success"
          style="margin: -8px 10px 0px 0px"
          (click)="doExport()"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </button>
      </div>
      <ng-content *ngIf="!searching"></ng-content>
      <button
        *ngIf="showAdd"
        class="btn btn-success pull-right"
        style="margin: 5px 10px 0px 0px"
        (click)="add.emit()"
      >
        +
      </button>
    </div>
  `,
  styleUrls: ['./section-title.component.css']
})
export class SectionTitleComponent implements OnInit {
  @Input() showAdd = true;
  @Input() showExcel = false;
  @Input() showQuery = true;
  @Input() showSearchBySource = false;
  @Output() add = new EventEmitter<void>();
  @Output() query = new EventEmitter<string>();
  @Output() queryBySource = new EventEmitter<string>();
  @Output() excel = new EventEmitter<string>();

  searching = false;
  searchForm: FormGroup;
  sources: string[];
  source: string;

  constructor(private fb: FormBuilder, private courseService: CoursesService) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      query: ['', [Validators.required]],
      source: ['', [Validators.required]]
    });

    this.searchForm
      .get('query')
      .valueChanges.subscribe(q => this.query.emit(q));

      this.searchForm
      .get('source')
      .valueChanges.subscribe(q => {
        this.queryBySource.emit(q);
      });

    this.courseService.getSources().subscribe(res => {
      this.sources=res;
    });
  }

  doExport() {
    const query = this.searchForm.get('query').value;

    if (query && query.length > 0) {
      this.excel.emit(query);
    }
  }
}
