<cms-admin>
  <cms-section-title (query)="onQuery($event)" (add)="onAdd()"
    >Familias de Sistema</cms-section-title
  >
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [limit]="100"
    [loadingIndicator]="loading"
    [rowHeight]="getRowHeight()"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        ID
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row._id }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Subfamilias
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div *ngFor="let subfamily of row.families">
          {{ subfamily.name }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right">
          <button
            class="btn btn-primary"
            (click)="update(row)"
            placement="left"
          >
            E
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
