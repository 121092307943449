import { Component, Inject, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { filter, switchMap, startWith } from 'rxjs/operators';
import { combineLatest, of, Observable } from 'rxjs';

import { CMS_ENV, CMSEnv, QueryStringService } from '@cms-app/shared';

import { Color } from '../../models/theme.model';
import { CoursePlatform } from '../../models/platform.course';
import { Course } from '../../models/course.model';
import { Family } from '../../models/family.model';

import { ThemeService } from '../../providers/theme.service';
import { CoursesService } from '../../providers/courses.service';
import { EntrustService } from '../../providers/entrust.service';
import { CoursePlatformsService } from '../../providers/platforms.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'cms-course-entrust',
  templateUrl: './course-entrust.component.html',
  styleUrls: ['./course-entrust.component.css'],
})
export class CourseEntrustComponent implements OnInit {
  @Input() catalog: string;
  @Input() okurl: string = 'inscripcion-ok.html';
  @Input() color: Color = 'red';
  @Input() onlycredit: string = 'false';

  @Input() platform: CoursePlatform = this.platforms.guess();

  course: Course;

  inscriptionForm: FormGroup;
  step = 1;
  agent: string;
  sending = false;

  get privacy() {
    return this.platforms.privacy(this.platform);
  }

  constructor(
    @Inject(CMS_ENV) public env: CMSEnv,
    public sanitizer: DomSanitizer,
    private query: QueryStringService,
    private localeService: BsLocaleService,
    private router: Router,
    public theme: ThemeService,
    private route: ActivatedRoute,
    private entrust: EntrustService,
    private courses: CoursesService,
    private platforms: CoursePlatformsService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if(this.onlycredit == 'true'){
      this.step = 2;
    }
    this.localeService.use('es');
    this.theme.schema = this.color;
    let obs: Observable<Course>;
    if (this.env.elements) {
      const params: any = this.query.parse(window.location.search);

      if (params && params.id) {
        obs = this.courses.findOne(params.id);
      }
    } else {
      this.course = this.route.snapshot.data.course;

      if (this.course) {
        obs = of(this.course);
        this.route.params
          .pipe(
            filter((params) => params.id !== this.course._id),
            switchMap((params) => this.courses.findOne(params.id))
          )
          .subscribe((course) => {
            this.course = course;
          });
      }
    }

    (obs || of(null)).subscribe((course) => {
      this.course = course;

      this.inscriptionForm = this.fb.group({
        bonus: ['yes'],
        agent: [''],
        company: this.fb.group({
          businessName: [''],
          vatIdentifier: [''],
          address: [''],
          zipCode: [''],
          city: [''],
          state: [''],
          since: [
            new Date().getFullYear(),
            [Validators.required, Validators.pattern(/[0-9]*/)],
          ],
          agreement: [''],
          ssAccount: [0],
          email: ['', [Validators.required,  Validators.email]],
          phone: [''],
          phone2: [''],
          accountNumber: [
            '',
            [Validators.pattern(this.entrust.accountNumberRE)],
          ],
          delegate: [false],
          contactPerson: [''],
          employees: [1],
          sector: [''],
          year: [new Date().getFullYear(), [Validators.pattern(/[0-9]*/)]],
          observations: [''],
        }),
        privacy: [false, [Validators.requiredTrue]],
        newsletter: [false],
        representative: this.fb.group({
          name: [''],
          vatIdentifier: [''],
        }),
        students: this.fb.array([]),
      });

      this.addStudent();

      this.inscriptionForm
        .get('bonus')
        .valueChanges.pipe(startWith(this.inscriptionForm.get('bonus').value))
        .subscribe((value) => {
          const only = value === 'entrust';
          const yes = value === 'yes';

          [
            'businessName',
            'vatIdentifier',
            'phone',
            'since',
            'employees',
            'year',
          ].forEach((field) => {
            this.inscriptionForm
              .get('company')
              .get(field)
              .setValidators(yes || only ? [Validators.required] : null);
          });
          ['sector'].forEach((field) => {
            this.inscriptionForm
              .get('company')
              .get(field)
              .setValidators(yes ? [Validators.required] : null);
          });
          ['name', 'vatIdentifier'].forEach((field) => {
            this.inscriptionForm
              .get('representative')
              .get(field)
              .setValidators(yes || only ? [Validators.required] : null);
          });

          if (only) {
            const students = <FormArray>this.inscriptionForm.get('students');
            students.controls.forEach((student: FormGroup) => {
              Object.keys(student.controls).forEach((key) => {
                student.get(key).setValidators(null);
              });
            });
          } else {
            const students = <FormArray>this.inscriptionForm.get('students');
            students.controls.forEach((student: FormGroup) => {
              ['name', 'subname', 'subname2', 'email', 'course', 'sex'].forEach(
                (key) => {
                  student.get(key).setValidators([Validators.required]);
                }
              );
              [
                'phone',
                'vatIdentifier',
                'ssNumber',
                'birthdate',
                'studies',
                'cotization',
              ].forEach((key) => {
                student
                  .get(key)
                  .setValidators(yes ? [Validators.required] : null);
              });
            });
          }
        });
    });
  }

  addStudent() {
    const students = <FormArray>this.inscriptionForm.get('students');
    const bonus = this.inscriptionForm.get('bonus').value;
    let student: FormGroup;
    if(bonus === 'no'){
      student = this.fb.group({
        name: ['', [Validators.required]],
        subname: ['', [Validators.required]],
        subname2: [''],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required]],
        course: [this.course ? this.course.name : '', [Validators.required]],
        vatIdentifier: ['', [Validators.required]],
        sex: ['', [Validators.required]],
        ssNumber: [''],
        birthdate: [''],// Validación fecha para datapicker
        studies: [''],
        cotization: [''],
        observations: ['']
      });
    }else{
      student = this.fb.group({
        name: ['', [Validators.required]],
        subname: ['', [Validators.required]],
        subname2: [''],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', bonus ? [Validators.required] : []],
        course: [this.course ? this.course.name : '', [Validators.required]],
        vatIdentifier: ['', bonus ? [Validators.required] : []],
        ssNumber: ['', bonus ? [Validators.required] : []],
        sex: ['', [Validators.required]],
        birthdate: [
          '',
          bonus ? [Validators.required] : [], // Validación fecha para datapicker
        ],
        studies: ['', bonus ? [Validators.required] : []],
        cotization: ['', bonus ? [Validators.required] : []],
        observations: ['']
      });
    }

    if(this.course && this.course.ucm){
      student.addControl('poll', this.fb.group({
        title: ['', [Validators.required]],
          graduation_year: ['', [Validators.required]],
          first_time: ['', [Validators.required]],
          ucm_link: ['', [Validators.required]],
          actual_job: ['', [Validators.required]],
          job_type: ['', [Validators.required]],
          actual_occupation: ['', [Validators.required]],
          sector: ['', [Validators.required]],
          job:['', [Validators.required]],
          motivation: ['', [Validators.required]],
          ucm_motivation: ['', [Validators.required]],
          course_motivation: ['', [Validators.required]]
      }));
    }
    students.push(student);
  }

  removeStudent(idx: number) {
    const students = <FormArray>this.inscriptionForm.get('students');

    students.removeAt(idx);
  }

  send() {
    if (!this.sending && this.inscriptionForm.valid) {
      const formData = this.inscriptionForm.value;
      let agentElement = this.inscriptionForm.get('agent');
      if(agentElement){
        this.agent = agentElement.value;
      }else{
        this.agent = "-";
      }
      this.inscriptionForm.removeControl('agent');

      if (formData.bonus === 'entrust') {
        this.sending = true;
        this.entrust
          .pushOnlyEntrust(formData, this.platform, this.agent)
          .subscribe(
            (entrust: { _id: string }) => {
              if (this.env.elements) {
                window.open(
                  `${window.location.origin}/${this.okurl}?entrust=${entrust._id}`,
                  '_self'
                );
              } else {
                this.router.navigate(['entrust', 'ok', entrust._id]);
              }
              this.sending = false;
            },
            () => {
              this.sending = false;
            }
          );
      }
      if (formData.bonus === 'yes') {
        this.sending = true;
        combineLatest(
          this.entrust.pushOnlyEntrust(formData, this.platform, this.agent),
          this.entrust.pushYesEntrust(formData, this.platform, this.agent)
        ).subscribe(
          ([_, entrusts]: [any, Array<{ _id: string }>]) => {
            if (entrusts[0]) {
              if (this.env.elements) {
                window.open(
                  `${window.location.origin}/${this.okurl}?entrust=${entrusts[0]._id}`,
                  '_self'
                );
              } else {
                this.router.navigate(['entrust', 'ok', entrusts[0]._id]);
              }
            }

            this.sending = false;
          },
          () => {
            this.sending = false;
          }
        );
      }
      if (formData.bonus === 'no') {
        this.sending = true;
        this.entrust.pushNoEntrust(formData, this.platform).subscribe(
          (entrusts: Array<{ _id: string }>) => {
            if (entrusts[0]) {
              if (this.env.elements) {
                window.open(
                  `${window.location.origin}/${this.okurl}?entrust=${entrusts[0]._id}`,
                  '_self'
                );
              } else {
                this.router.navigate(['entrust', 'ok', entrusts[0]._id]);
              }
            }

            this.sending = false;
          },
          () => {
            this.sending = false;
          }
        );
      }
    } else {
      this.handleError(this.inscriptionForm);
    }
  }

  handleError(form: FormGroup) {
    if (window.console) {
      console.log(this.inscriptionForm);
    }

    const privacy = form.get('privacy');
    if (privacy.invalid) {
      privacy.markAsTouched();
      this.step = 2;
      return;
    }

    const studentsArray = form.get('students') as FormArray;
    if (studentsArray.invalid) {
      this.step = 1;

      studentsArray.controls.forEach((student: FormGroup) => {
        if (student.invalid) {
          this.handleErrorFormGroup(student);
        }
      });

      return;
    }
    const companyForm = form.get('company') as FormGroup;

    if (companyForm.invalid) {
      this.step = 2;
      this.handleErrorFormGroup(companyForm);
    }
  }

  handleErrorFormGroup(group: FormGroup) {
    Object.keys(group.controls).forEach((key) => {
      group.controls[key].markAsTouched();
    });
  }

  // ROUTES
  goToCourses() {
    if (this.env.elements) {
      const params: any = this.query.parse(window.location.search);

      window.open(this.catalog || params.back, '_self');
    } else {
      this.router.navigate(['courses', 'catalogue']);
    }
  }

  onStepChange(step: number) {
    console.log(step);
    this.step = step;
  }

  getCatalog() {
    const params: any = this.query.parse(window.location.search);

    return this.catalog || params.back;
  }

  goToCourse(related: Course) {
    this.router.navigate(['admin', 'courses', related._id]);
  }

  goToFamily(related: Family) {
    this.router.navigate(['admin', 'families', related._id]);
  }
}
