import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { LogListComponent } from './components/logs-list/log-list.component';
import { ROUTES } from './scrapper.routing';
import { ProviderListComponent } from './components/provider-list/provider-list.component';
import { ProviderDetailComponent } from './components/provider-detail/provider-detail.component';
import { ComparatorComponent } from './components/comparator/comparator.component';
import { ProviderDetailGeodestinosComponent } from './components/provider-geodestinos-detail/provider-detail-geodestinos.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations:[
    LogListComponent,
    ProviderListComponent,
    ProviderDetailComponent,
    ComparatorComponent,
    ProviderDetailGeodestinosComponent,
    MaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forChild(ROUTES),
    NgxDatatableModule,
    AdminSharedModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot()
  ],

})
export class ScrapperModule { }
