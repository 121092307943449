import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PVFamiliesService } from '@cms-app/courses';
var AdminPVFamiliesComponent = /** @class */ (function () {
    function AdminPVFamiliesComponent(router, familiesService) {
        this.router = router;
        this.familiesService = familiesService;
        this.rows = [];
        this.count = 0;
        this.page = 1;
        this.query = '';
        this.loading = false;
    }
    AdminPVFamiliesComponent.prototype.ngOnInit = function () {
        this.find();
    };
    AdminPVFamiliesComponent.prototype.onAdd = function () {
        this.router.navigate(['admin', 'iefp', 'pv-families', '0']);
    };
    AdminPVFamiliesComponent.prototype.update = function (row) {
        this.router.navigate(['admin', 'iefp', 'pv-families', row._id]);
    };
    AdminPVFamiliesComponent.prototype.onPage = function (_a) {
        var offset = _a.offset;
        this.page = offset + 1;
        this.find();
    };
    AdminPVFamiliesComponent.prototype.onQuery = function (query) {
        this.query = query;
        this.find();
    };
    AdminPVFamiliesComponent.prototype.find = function () {
        var _this = this;
        var options = {};
        if (this.page) {
            options.page = this.page;
        }
        if (this.query) {
            options['name>$like'] = this.query;
        }
        this.loading = true;
        this.familiesService
            .find(tslib_1.__assign({}, options, { expand: 'families.name', sort: 'order', per_page: 25 }))
            .subscribe(function (_a) {
            var list = _a.list, count = _a.count;
            _this.rows = list;
            _this.count = count;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    AdminPVFamiliesComponent.prototype.getRowHeight = function () {
        return function (row) { return (!!row ? 25 + 22 * row.families.length : 50); };
    };
    return AdminPVFamiliesComponent;
}());
export { AdminPVFamiliesComponent };
