import { PipeTransform } from '@angular/core';
var ColorPipe = /** @class */ (function () {
    function ColorPipe() {
    }
    ColorPipe.prototype.transform = function (color) {
        if (this.isValidHexColor(color)) {
            return this.hexToRgba(color);
        }
        return color;
    };
    ColorPipe.prototype.isValidHexColor = function (color) {
        return /^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i.test(color);
    };
    ColorPipe.prototype.hexToRgba = function (hex, a) {
        var hashlessHex = this.removeHash(hex);
        var hexObject = this.parseHex(hashlessHex);
        var decimalObject = this.hexesToDecimals(hexObject);
        return this.formatRgb(decimalObject, a);
    };
    ColorPipe.prototype.removeHash = function (hex) {
        return hex.charAt(0) === '#' ? hex.slice(1) : hex;
    };
    ColorPipe.prototype.parseHex = function (nakedHex) {
        var isShort = 3 === nakedHex.length || 4 === nakedHex.length;
        var twoDigitHexR = isShort
            ? "" + nakedHex.slice(0, 1) + nakedHex.slice(0, 1)
            : nakedHex.slice(0, 2);
        var twoDigitHexG = isShort
            ? "" + nakedHex.slice(1, 2) + nakedHex.slice(1, 2)
            : nakedHex.slice(2, 4);
        var twoDigitHexB = isShort
            ? "" + nakedHex.slice(2, 3) + nakedHex.slice(2, 3)
            : nakedHex.slice(4, 6);
        var twoDigitHexA = (isShort
            ? "" + nakedHex.slice(3, 4) + nakedHex.slice(3, 4)
            : nakedHex.slice(6, 8)) || 'ff';
        return {
            r: twoDigitHexR,
            g: twoDigitHexG,
            b: twoDigitHexB,
            a: twoDigitHexA
        };
    };
    ColorPipe.prototype.hexesToDecimals = function (_a) {
        var r = _a.r, g = _a.g, b = _a.b, a = _a.a;
        return {
            r: this.hexToDecimal(r),
            g: this.hexToDecimal(g),
            b: this.hexToDecimal(b),
            a: +(this.hexToDecimal(a) / 255).toFixed(2)
        };
    };
    ColorPipe.prototype.hexToDecimal = function (hex) {
        return parseInt(hex, 16);
    };
    ColorPipe.prototype.formatRgb = function (decimalObject, parameterA) {
        var r = decimalObject.r, g = decimalObject.g, b = decimalObject.b, parsedA = decimalObject.a;
        var a = this.isNumeric(parameterA) ? parameterA : parsedA;
        return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
    };
    ColorPipe.prototype.isNumeric = function (n) {
        return !isNaN(parseFloat(String(n))) && isFinite(n);
    };
    return ColorPipe;
}());
export { ColorPipe };
