import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Color } from '../../models/theme.model';

@Component({
  selector: 'cms-course-entrust-stepper',
  templateUrl: './course-entrust-stepper.component.html',
  styleUrls: ['./course-entrust-stepper.component.css']
})
export class CourseEntrustStepper {
  @Input() step: number;
  @Input() set type(type: string) {
    this._type = type;

    setTimeout(() => {
      if (type === 'entrust' && this.step === 1) {
        this.stepChange.emit(2);
      }
    }, 0);
  }
  @Input() color: Color = 'red';

  @Output() stepChange = new EventEmitter<number>();

  _type: string;
}
