import { Component, OnInit, Inject, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, min, switchMap } from 'rxjs/operators';

import {
  CMS_ENV,
  CMSEnv,
  QueryStringService,
  SeoService
} from '@cms-app/shared';

import { Color } from '../../models/theme.model';
import { Course } from '../../models/course.model';
import { Family } from '../../models/family.model';

import { ThemeService } from '../../providers/theme.service';
import { CoursesService } from '../../providers/courses.service';
import { idLocale } from 'ngx-bootstrap';


@Component({
  selector: 'cms-course-page',
  templateUrl: './course-page.component.html',
  styleUrls: ['./course-page.component.css']
})
export class CoursePageComponent implements OnInit {
  @Input() catalog: string;
  @Input() color: Color = 'red';
  @Input() register: string;
  course: Course;

  constructor(
    @Inject(CMS_ENV) public env: CMSEnv,
    public sanitizer: DomSanitizer,
    private query: QueryStringService,
    private router: Router,
    public theme: ThemeService,
    private route: ActivatedRoute,
    public courses: CoursesService,
    private seo: SeoService
  ) {}

  ngOnInit() {
    this.theme.schema = this.color;
    if (this.env.elements) {
      const params: any = this.query.parse(window.location.search);
      this.courses.findOne(params.id).subscribe(course => {
        this.course = course;
        const keys = this.course.search.split(' ');
        this.seo.setPage({
          title: this.course.name || this.course.namePV,
          description: this.course.shortDescription || this.course.shortDescriptionPV,
          keywords: keys.join(',')
        });
      });
    } else {
      this.course = this.route.snapshot.data.course;
      const keys = this.course.search.split(' ');
      this.seo.setPage({
        title: this.course.name || this.course.namePV,
        description: this.course.shortDescription || this.course.shortDescriptionPV,
        keywords: keys.join(',')
      });

      this.route.params
        .pipe(
          filter(params => params.id !== this.course._id),
          switchMap(params => this.courses.findOne(params.id))
        )
        .subscribe(course => {
          this.course = course;
          this.seo.setPage({
            title: this.course.name,
            description: this.course.shortDescription
          });

        });
    }
  }

  // ROUTES
  goToCourses() {
    if (this.env.elements) {
      const params: any = this.query.parse(window.location.search);

      window.open(this.catalog || params.back, '_self');
    } else {
      this.router.navigate(['courses', 'catalogue']);
    }
  }

  getCatalog() {
    const params: any = this.query.parse(window.location.search);

    return this.catalog || params.back;
  }

  goToCourse(related: Course) {
    this.router.navigate(['admin', 'courses', related._id]);
  }

  goToFamily(related: Family) {
    this.router.navigate(['admin', 'families', related._id]);
  }

  // Download goals PDF (only source fcontenido)
  downloadGoals(ref) {
    let r = ref.toString().padStart(5, "0");
    const link= "https://midocumentacion.es/catalogo/contenidos/"+r+"%20Objetivos.pdf"
    return `<div><a href="${link}" target="_blank" class="badge">Descargar Objetivos</a></div>`;
  }
  // Download syllabus PDF (only source fcontenido)
  downloadSyllabus(ref) {
    let r = ref.toString().padStart(5, "0");
    const link = "https://midocumentacion.es/catalogo/contenidos/"+r+"%20Indice.pdf";
    return `<div><a href="${link}" target="_blank" class="badge">Descargar Programa</a></div>`;
  }

  getFamily(course: any){
    return course.families[0].name
  }
}
