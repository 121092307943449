import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CMS_ENV, CMSEnv } from '../models/environment';
import { RequestInformation } from '../models/request-info.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  send(data: RequestInformation) {
    const site = this.getHost();
    const to = `info@${site}`;
    const bod = {
      ...data,
      site,
      to
    };
    console.log(bod)
    return this.http.post(`${this.env.baseUrl}/send-email`, {
      ...data,
      site,
      to
    });
  }

  private getHost(): string {
    const host = window.location.host.split('.');

    return host.slice(host.length - 2).join('.');
  }
}
