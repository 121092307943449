<h1><input type="text" placeholder="Sectores" [(ngModel)]="query" /></h1>
<nav *ngIf="(size$ | async) !== 'xs'">
  <ul>
    <li
      [className]="theme.schema"
      *ngFor="let family of (rows | categoriesFilter: query)"
      [class.active]="family._id === currentFilter"
    >
      <a
        [className]="theme.schema"
        [style.color]="
          (family._id === currentFilter ? theme.color : '#111') | color
        "
        (click)="setFamilyFilter(family)"
        >{{ family.name }}</a
      >
      <ul
        class="subfamilies"
        *ngIf="query.length > 0 || family._id === currentFilter"
      >
        <li
          [class.active]="currentSubFilter && subfamily.families[0] === currentSubFilter[0]"
          *ngFor="let subfamily of getSubfamilies(family)"
        >
          <a
            [style.color]="setSelectColor(subfamily) | color"
            (click)="setSubfamilyFilter(subfamily)"
            >{{ subfamily.name }}</a
          >
        </li>
      </ul>
    </li>
    <li *ngIf="!!_family">
      <a (click)="all.emit()">VER TODOS</a>
    </li>
  </ul>
</nav>
<div *ngIf="(size$ | async) === 'xs'">
  <div class="form-group" style="padding: 0 20px">
    <select class="form-control" (change)="onSelectChange($event)">
      <option value="">SELECCIONE ...</option>
      <option [value]="family._id" *ngFor="let family of rows">{{
        family.name
      }}</option>
    </select>
  </div>
</div>
