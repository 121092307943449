import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VisionService {
  constructor(private http: HttpClient) {}

  documentTextDetection(url: string): Observable<string[]> {
    const params = new HttpParams().set('url', url);

    return this.http.get<string[]>(
      'https://api.galicia.info/vision/document-text',
      { params }
    );
  }
}
