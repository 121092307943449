<cms-admin>
  <cms-section-title (query)="onQuery($event)">Encomiendas</cms-section-title>
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
    [externalPaging]="true"
    (page)="onPage($event)"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Descripción
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div style="margin-top: -8px">{{ row.description1 }}</div>
        <div>{{ row.description2 }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Documento
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.kind === 'yes'" class="label label-success"
          >INS. BONIFICADA</span
        >
        <span *ngIf="row.kind === 'no'" class="label label-warning"
          >INS. NO BONIFICADA</span
        >
        <span *ngIf="row.kind === 'only'" class="label label-info"
          >ENCOMIENDA</span
        >
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fuente
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.source }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Respuesta
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="!row.status" class="label label-default">DESCONOCIDO</span>
        <span
          *ngIf="row.status && row.status === 'Success'"
          class="label label-success"
          >OK</span
        >
        <span
          *ngIf="row.status && row.status !== 'Success'"
          class="label label-danger"
          >ERROR</span
        >
        &nbsp;
        <span *ngIf="row.status && row.status !== 'Success'">{{
          row.status
        }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fecha / Hora
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.createdAt | date: 'medium':'CET':'es' }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
