import {
  Directive,
  OnInit,
  Renderer2,
  ViewContainerRef,
  ElementRef,
  Input
} from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';

@Directive({
  selector: '[cmsDatatable]'
})
export class DataTableDirective implements OnInit {
  @Input() public mrutaDatatable: number;

  constructor(
    private datatable: DatatableComponent,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit() {
    this.datatable.rowHeight = this.mrutaDatatable ? this.mrutaDatatable : 50;
    this.datatable.footerHeight = 50;
    this.datatable.headerHeight = 36;
    this.datatable.limit = this.datatable.limit || 10;
    this.datatable.columnMode = ColumnMode.force;
    this.renderer.addClass(this.el.nativeElement, 'material');
    this.renderer.addClass(this.el.nativeElement, 'striped');
    this.renderer.addClass(this.el.nativeElement, 'expandable');

    setTimeout(() => {
      if (this.datatable && this.datatable.columns instanceof Array) {
        this.datatable.columns.forEach(
          (column: any) => (column.headerClass = 'is-header')
        );
      }
    });
  }
}
