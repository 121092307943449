import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CMSEnv, CMS_ENV } from '@cms-app/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RssSrc } from '../models/rss-src.model';

@Injectable({
  providedIn: 'root'
})
export class RssSrcService {
  endpoint = '/rsssrc';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  find(
    options: any = { page: 1, per_page: 10 },
    query?: string
  ): Observable<{
    list: RssSrc[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;

    let params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );
    if(query){
      params = params.set('name>$like', query)
    }

    return this.http
      .get<RssSrc[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  getRssSrcById(id: string):Observable<RssSrc>{
    return this.http.get<RssSrc>(`${this.env.baseUrl}${this.endpoint}/${id}`);
  }

  add(payload: Partial<RssSrc>) {
    return this.http.post<RssSrc>(
      `${this.env.baseUrl}${this.endpoint}`,
      payload
    );
  }

  update(rssSrc: string, payload: Partial<RssSrc>) {
    return this.http.patch<RssSrc>(
      `${this.env.baseUrl}${this.endpoint}/${rssSrc}`,
      payload
    );
  }

  delete(id: string){
    return this.http.delete<RssSrc>(`${this.env.baseUrl}${this.endpoint}/${id}`).subscribe(() => console.log('borrado'));
  }
}
