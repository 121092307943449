<cms-admin *ngIf="!!pvFamilies">
  <cms-section-title (query)="onQuery($event)" (add)="onAdd()"
    >Familias</cms-section-title
  >
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [limit]="100"
    [rowHeight]="getRowHeight()"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Fuente
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <cms-source [row]="row"></cms-source>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Usado
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div *ngFor="let reference of row.reference">{{ reference }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right">
          <button
            class="btn btn-primary"
            (click)="update(row)"
            tooltip="Actualizar Familia"
            placement="left"
          >
            E
          </button>
          <button
            class="btn btn-info"
            (click)="checkLink(row)"
            tooltip="Comprobar Enlace"
            placement="left"
          >
            <i class="fa fa-link" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
