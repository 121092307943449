/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./promo-item.component";
import * as i3 from "../../../shared/models/environment";
import * as i4 from "@angular/platform-browser";
var styles_PromoItemComponent = [i0.styles];
var RenderType_PromoItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoItemComponent, data: {} });
export { RenderType_PromoItemComponent as RenderType_PromoItemComponent };
export function View_PromoItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row promo-card"]], [[4, "background-image", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row sello-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "sello"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sello-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "% "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "buffer-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "row geoname-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "geoname-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "geoname-span"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "geoname"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getBackGround(); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getBackGroundSello(); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.geodestino.promoDiscount; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.geodestino.namePV || _co.geodestino.name); _ck(_v, 10, 0, currVal_3); }); }
export function View_PromoItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-geodestino-promo-item", [], null, null, null, View_PromoItemComponent_0, RenderType_PromoItemComponent)), i1.ɵdid(1, 49152, null, 0, i2.PromoItemComponent, [i3.CMS_ENV, i4.DomSanitizer], null, null)], null, null); }
var PromoItemComponentNgFactory = i1.ɵccf("cms-geodestino-promo-item", i2.PromoItemComponent, View_PromoItemComponent_Host_0, { geodestino: "geodestino" }, {}, []);
export { PromoItemComponentNgFactory as PromoItemComponentNgFactory };
