<cms-admin>
  <cms-section-title
    [showExcel]="false"
    (add)="onAdd()"
    >Usuarios
  </cms-section-title>
  <ngx-datatable
    cmsDatatable
    [rows]="rows"
    [loadingIndicator]="loading"
    [count]="count || 0"
  >
    <ngx-datatable-column>
      <ng-template let-column="column" ngx-datatable-header-template>
        Nombre
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.user }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="pull-right actions">
          <button
            class="btn btn-primary"
            (click)="update(row)"
            tooltip="Ver / Editar"
            placement="top"
          >
            E
          </button>
          <button
            class="btn btn-secondary"
            (click)="delete(row)"
            tooltip="Borrar"
            placement="top"
          >
            X
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</cms-admin>
