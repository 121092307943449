import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CMS_ENV, CMSEnv } from '@cms-app/shared';

import { Family } from '../models/family.model';

@Injectable({
  providedIn: 'root'
})
export class FamiliesService {
  endpoint = '/families';

  constructor(@Inject(CMS_ENV) private env: CMSEnv, private http: HttpClient) {}

  find(
    options: any = { page: 1, per_page: 10, top: true }
  ): Observable<{
    list: Family[];
    count: number;
  }> {
    options.page = options.hasOwnProperty('page') ? options.page : 1;
    options.per_page = options.hasOwnProperty('per_page')
      ? options.per_page
      : 10;
    //options.top = options.hasOwnProperty('top') ? options.top : true;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<Family[]>(`${this.env.baseUrl}${this.endpoint}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  groupByName(
    options: any = { page: 1, per_page: 10, top: true }
  ): Observable<{
    list: Family[];
    count: number;
  }> {
    options.top = options.hasOwnProperty('top') ? options.top : true;

    const params = Object.keys(options).reduce(
      (acc: HttpParams, key: string) => {
        return acc.set(key, String(options[key]));
      },
      new HttpParams()
    );

    return this.http
      .get<Family[]>(`${this.env.baseUrl}${this.endpoint}/group-by-name`, {
        params,
        observe: 'response'
      })
      .pipe(
        map(response => ({
          list: response.body,
          count: parseInt(response.headers.get('X-Total-Count'), 10)
        }))
      );
  }

  addFamily(family: Partial<Family>) {
    return this.http.post<Family>(`${this.env.baseUrl}${this.endpoint}`, {
      name: family.name,
      families: family.families || [],
      url: family.url || '',
      top: family.top !== false ? true : false,
      source: family.source || 'galicia'
    });
  }

  empty(): Family {
    return {
      _id: null,
      name: '',
      url: null,
      top: true,
      source: 'galicia',
      families: []
    };
  }

  findOne(family: string): Observable<Family> {
    const params = new HttpParams().set('expand', 'related,families.name');

    return this.http.get<Family>(
      `${this.env.baseUrl}${this.endpoint}/${family}`,
      {
        params
      }
    );
  }

  add(payload: Partial<Family>) {
    return this.http.post<Family>(
      `${this.env.baseUrl}${this.endpoint}`,
      payload
    );
  }

  update(family: string, payload: Partial<Family>) {
    return this.http.patch<Family>(
      `${this.env.baseUrl}${this.endpoint}/${family}`,
      payload
    );
  }
}
