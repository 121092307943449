import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { FileUploader as BaseFileUploader } from 'ng2-file-upload';

export { BaseFileUploader };

export const TOKEN = new InjectionToken<string>('[File Module] Image Token');
export const BASE_URL = new InjectionToken<string>('[File Module] Base Url');

export interface FileUploadOptions {
  transformations: string;
}

export interface FileUploadTransformation {
  width: number;
  height: number;
  extent: boolean;
  name?: string;
}

export interface FileUpload {
  path: string;
  status: number;
}

export interface FileUploader extends BaseFileUploader {
  events: Observable<FileUpload>;
}
